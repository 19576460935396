import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./_about-us.component.sass']
})
export class AboutUsComponent implements OnInit {
  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.route.fragment.subscribe(fragment => {
      setTimeout(() => {
        if (fragment) {
          const element = document.getElementById(fragment);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }
      }, 100);
    });

    const currentFragment = this.route.snapshot.fragment;
    if (currentFragment) {
      setTimeout(() => {
        const element = document.getElementById(currentFragment);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100);
    }
  }
}