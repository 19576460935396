import { Component } from '@angular/core';
import { Utils } from '../../util/utils';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { logger } from '../../util/Logger';
import { GlobalApi } from '../../api/global.api';
import { CartService } from '../../services/cart.service';

@Component({
  selector: 'app-cart-complete',
  templateUrl: './cart-complete.component.html',
  styleUrls: []
})
export class CartCompleteComponent {
  pendingApproval: false;
  order: any;
  inError: boolean = false;
  errorMessage: string | null;

  constructor(
    public utils: Utils,
    public route: ActivatedRoute,
    private router: Router,
    private cartService: CartService,
    public globals: GlobalApi,
  ) {
  }

  ngOnInit() {
    window.scroll(0,0);
    const signature = "CartCompleteComponent.ngOnInit: ";
    logger.silly("Completing Transaction");

    this.route.data.subscribe(data => {
      if (data.hasOwnProperty('pendingApproval')) {
        this.pendingApproval = data['pendingApproval'];
        logger.silly(signature + "PendingApproval = " + this.pendingApproval);
      }
    });

    this.route.queryParams.subscribe(params => {
      if (params.hasOwnProperty('rescode') && params['rescode'] !== '00' && params['rescode'] !== '08') {
        logger.error(signature + 'Detected an error in the attempted transaction');
        this.router.navigate(['cart/checkout'], { fragment: params['restext'] });
        return;
      }

      //settdate=20191113&expirydate=122019&callback_status_code=503&restext=Approved&fingerprint=df2df7123a5c468f1e590ce2b15eeddcadb87182&merchant=3Y40010&refid=SO-TEST-0000002&pan=444433...111&summarycode=1&rescode=00&txnid=450019&timestamp=20191113052243
      this.globals.withSession(() => {
        logger.silly(signature + 'Verifying local test environment');
        if (
          this.utils.hasEachOf(params,
            'settdate', 'expirydate', 'merchant',
            'restext', 'fingerprint', 'merchant', 'refid',
            'pan', 'summarycode', 'rescode', 'txnid', 'timestamp'
          ) &&
          environment.version === "LOCAL"
        ) {
          logger.info(signature + "Performing test environment only transaction update");
          this.cartService.completeCCTransaction({
            "settdate": params.settdate,
            "expirydate": params.expirydate,
            "restext": params.restext,
            "fingerprint": params.fingerprint,
            "merchant": params.merchant,
            "refid": params.refid,
            "pan": params.pan,
            "summarycode": params.summarycode,
            "rescode": params.rescode,
            "txnid": params.txnid,
            "timestamp": params.timestamp
          })
            .subscribe(() => {
              logger.info(signature + "Test environment only update complete");
              // Silently do nothing after publishing the CC Transaction back to the server
            },
              err => {
                logger.info(signature + "Error performing test environment update");
                logger.error(err);
              });
        }
      });
    });

    this.route.params.subscribe(params => {
      if (params.hasOwnProperty('orderId')) {
        const orderId = Number(params['orderId']);

        this.cartService.get(orderId)
          .subscribe(cart => {
            this.order = cart;
          },
            () => {
            });
      }
    });
  }

  ngOnDestroy() { }

  getFullName() {
    if (!this.globals.loggedIn) return 'Anonymous';
    return `${this.globals.User.firstName} ${this.globals.User.lastName}`;
  }

  generateGradient(colorString: string): string {
    if (colorString) {
      const colors = colorString.split('/');
      if (colors.length >= 2) {
        const firstColor = colors[0].trim();
        const secondColor = colors[1].trim();
        return `linear-gradient(0deg, ${firstColor} 50%, ${secondColor} 50%)`;
      } else if (colorString) {
        return colorString;
      } else {
        return 'default';
      }
    } else {
      return 'default';
    }
  }
}
