<div class="main-right-content" *ngIf="order">
	<!-- Page Breadcrumb start here... -->
	<nav aria-label="breadcrumb" class="pageBreadcrumb">
		<ol class="breadcrumb mb-0">
			<li class="breadcrumb-item"><a [routerLink]="[orderUrl]">Orders </a></li>
			<li class="breadcrumb-item active" aria-current="page">
				{{ order.attrs.orderNumber || "Not Specified" }}
			</li>
		</ol>
	</nav>
	<!-- Page Breadcrumb end here... -->

	<!-- manage-user main start here... -->
	<div class="manageUser">
		<div class="d-flex flex-column flex-md-row align-items-start justify-content-between">
			<h1 class="title">{{ order.attrs.orderNumber || "Not Specified" }}</h1>
			<div class="edit-btns d-none d-lg-flex">
				<div>
					<p class="status">
						Status:
						<span class="badge bg-{{ fetchColor(order.attrs.status) }}-25 lh-normal">
							<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
								<circle cx="10" cy="10" r="2" fill="transparent"></circle>
							</svg>
							{{ order.attrs.status || "Loading" }}
						</span>
					</p>
				</div>
				<a *ngIf="order.attrs.orderNumber && isShipment" [routerLink]="[shipmentDetailUrl]"
					[queryParams]="{ orderId: order.id }" class="btn btn-secondary">
					View shipment
				</a>
				<a (click)="approveOrder()" *ngIf="showOrderApproval()" class="btn btn-primary">
					Approve Order
				</a>
				<a (click)="orderModals.openModifyModal(order.attrs)" *ngIf="showOrderApproval()" class="btn btn-secondary">
					Modify Order
				</a>
				<a (click)="orderModals.openCancelModal(order.attrs)" *ngIf="showOrderApproval()" class="btn btn-danger">
					Reject Order
				</a>
				<button [disabled]="isReOrder" (click)="reOrder()" *ngIf="showReOrder()" class="btn btn-secondary">
					Reorder
				</button>
			</div>
		</div>

		<div class="order-top-detail">
			<ul>
				<li>
					<p>
						<span>Order Placed:</span>
						{{ order.attrs.orderedAt | date : "dd MMM, yyyy" }}
					</p>
				</li>
				<li>
					<p>
						<span>Order Number:</span>
						{{ order.attrs.orderNumber || "Not Specified" }}
					</p>
				</li>
				<li *ngIf="order.attrs.status == 'REJECTED'">
					<p>
						<span>Reject Reason: </span>
						{{ order.attrs.rejectionReason || "N/A" }}
					</p>
				</li>
			</ul>
		</div>

		<div class="edit-btns w-sm-100 w-auto align-items-start d-flex d-lg-none">
			<div>
				<p class="status flex-row mb-0">
					Status:
					<span class="badge bg-{{ fetchColor(order.attrs.status) }}-25">
						<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
							<circle cx="10" cy="10" r="2" fill="transparent"></circle>
						</svg>
						{{ order.attrs.status || "Loading" }}
					</span>
				</p>
			</div>
			<a *ngIf="order.attrs.orderNumber && isShipment" [routerLink]="[shipmentDetailUrl]"
				[queryParams]="{ orderId: order.id }" class="btn btn-secondary w-100">
				View shipment
			</a>
		</div>

		<div class="manage-user-list">
			<div class="edit-users order-cards">
				<div class="edit-card card order-card">
					<div class="card-head">
						<h4 class="head-title">Shipping address</h4>
					</div>
					<div class="card-body order-content">
						<div class="order-add">
							<p>
								{{ order.attrs.contactFirstName || "" }}
								{{ order.attrs.contactLastName || "" }}
							</p>
							<p>{{ order.attrs.contactEmail || "" }}</p>
						</div>
						<div class="order-add" *ngIf="order.attrs.shippingDetails">
							<p>{{ addressDisplayText(order.attrs.shippingDetails, true) }}</p>
						</div>
					</div>
				</div>
				<div class="edit-card card order-card">
					<div class="card-head">
						<h4 class="head-title">Order details</h4>
					</div>
					<div class="card-body">
						<div class="order-add">
							<p>
								<span>Purchase Order number:</span>
								{{ order.attrs.orderReference || "Not Specified" }}
							</p>
						</div>
						<div class="order-add">
							<p>
								<span>Business unit & job title:</span>
								{{
								order.attrs.businessUnitId
								? getBusinessUnitText(order.attrs)
								: "N/A"
								}}
							</p>
						</div>
						<div class="order-add" *ngIf="getSupportingDocuments().length">
							<span>Pack Names Documents</span>
							<p *ngFor="let doc of getSupportingDocuments()">
								<a [href]="doc.s3Url" target="_blank">{{
									doc.fileName || "None"
									}}</a>
							</p>
						</div>

						<div class="order-add" *ngIf="placedByUser">
							<p>
								<span>Order Placed By:</span>
								{{ placedByUser.firstName + " " + placedByUser.lastName }}
								<strong>{{ placedByUser.email }}</strong>
							</p>
						</div>

						<div class="order-add" *ngIf="order.orderFieldValue.length">
							<p>
								<span>Custome Order Field Values</span>
							</p>
							<div *ngFor="let values of order.orderFieldValue">
								<strong>{{ values.customerUserField ? values.customerUserField.fieldName : 'N/A' }}:</strong>
								{{values.fieldValue}}
							</div>
						</div>
					</div>
				</div>
				<div class="edit-card card order-card">
					<div class="card-head">
						<h4 class="head-title">Tracking & Notes</h4>
					</div>
					<div class="card-body">
						<div class="order-add">
							<p>
								<span>Tracking details</span>
								{{ order.attrs.trackingDetail || "Not Specified" }}
								<a *ngIf="globals.isAdmin" (click)="showTrackingEditModal(); open(content)"
									class="btn btn-secondary-icon shadow-none p-0 d-inline-block">
									<span>
										<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
											<path
												d="M10 15.9999H16.75M13.375 3.62493C13.6734 3.32656 14.078 3.15894 14.5 3.15894C14.7089 3.15894 14.9158 3.20009 15.1088 3.28004C15.3019 3.36 15.4773 3.47719 15.625 3.62493C15.7727 3.77266 15.8899 3.94805 15.9699 4.14108C16.0498 4.33411 16.091 4.54099 16.091 4.74993C16.091 4.95886 16.0498 5.16574 15.9699 5.35877C15.8899 5.5518 15.7727 5.72719 15.625 5.87493L6.25 15.2499L3.25 15.9999L4 12.9999L13.375 3.62493Z"
												stroke="#818898" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
										</svg>
									</span>
								</a>
							</p>
							<p>
								<a *ngIf="order.attrs.trackingDetailUrl" [href]="order.attrs.trackingDetailUrl" target="_blank"
									class="btn btn-secondary p-0 shadow-none">
									Tracking Link
									<span>
										<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
											<path
												d="M8.5 10.75C8.82209 11.1806 9.23302 11.5369 9.70491 11.7947C10.1768 12.0525 10.6986 12.2058 11.235 12.2442C11.7713 12.2826 12.3097 12.2052 12.8135 12.0173C13.3173 11.8294 13.7748 11.5353 14.155 11.155L16.405 8.90497C17.0881 8.19772 17.4661 7.25046 17.4575 6.26722C17.449 5.28398 17.0546 4.34343 16.3593 3.64815C15.664 2.95287 14.7235 2.55849 13.7403 2.54995C12.757 2.5414 11.8098 2.91938 11.1025 3.60247L9.8125 4.88497"
												stroke="#818898" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
											<path
												d="M11.4997 9.24992C11.1776 8.81933 10.7666 8.46304 10.2947 8.20522C9.82285 7.9474 9.30103 7.79409 8.76467 7.75567C8.22832 7.71726 7.68997 7.79465 7.18615 7.98259C6.68233 8.17053 6.22483 8.46462 5.84466 8.84492L3.59466 11.0949C2.91157 11.8022 2.53359 12.7494 2.54213 13.7327C2.55068 14.7159 2.94506 15.6565 3.64034 16.3517C4.33562 17.047 5.27617 17.4414 6.25941 17.45C7.24264 17.4585 8.1899 17.0805 8.89716 16.3974L10.1797 15.1149"
												stroke="#818898" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
										</svg>
									</span>
								</a>
							</p>
						</div>
						<div class="order-add">
							<p>
								<span>Order note</span>
								{{ order.attrs.comment || "No Notes Added" }}
							</p>
						</div>
						<div class="order-add">
							<p>
								<span>delivery note</span>
								{{ order.attrs.notes || "No Notes Added" }}
							</p>
						</div>
						<!-- <div class="order-add" *ngIf="hasSalesContactDetail()">
							<p>
								<span>Sales Contact</span>
								{{order.attrs.salesContactName}}
								<strong>{{order.attrs.salesContactEmail}}</strong>
							</p>
						</div> -->
					</div>
				</div>
				<div class="edit-card card order-card">
					<div class="card-head">
						<h4 class="head-title">Payment details</h4>
					</div>
					<div class="card-body">
						<ul class="payment-detail">
							<li class="order-add">
								<span>Subtotal</span>
								<p>${{ utils.twoDecimalPlacesString(order.attrs.subTotal) }}</p>
							</li>
							<li class="order-add">
								<span>GST</span>
								<p>${{ utils.twoDecimalPlacesString(order.attrs.taxTotal) }}</p>
							</li>
							<li class="order-add">
								<span>Order total</span>
								<p>
									${{
									utils.twoDecimalPlacesString(
									order.attrs.grandTotal - consumedAllocation
									)
									}}
								</p>
							</li>
						</ul>
						<div class="order-add">
							<p>
								<span>Payment Method</span>
								{{
								order.transaction && order.transaction.responseTimeStamp
								? "Credit Card"
								: "Invoice"
								}}
							</p>
						</div>
					</div>
				</div>
			</div>

			<!-- table-content for lg screen start here... -->
			<div class="manage-table main-table d-none d-lg-block table-responsive">
				<table class="table">
					<thead>
						<tr>
							<th>Product</th>
							<th>Code</th>
							<th>Quantity</th>
							<th>Price</th>
						</tr>
					</thead>

					<tbody>
						<tr *ngFor="let item of order.items">
							<td>
								<div class="table-order">
									<div class="order-img">
										<img src="{{ item.product.imageUrl }}" alt="No image" />
									</div>
									<div class="order-table-content">
										<h5 class="title text-wrap">
											{{ item.product.name }}
										</h5>
										<ul class="order-detail-list">
											<li>
												<p>Color:</p>
												<span>
													<span class="color color-dark" [style.background]="
															generateGradient(item.variation?.colour)
														"></span>
													{{ item.variation?.colour || "default" }}</span>
											</li>
											<li>
												<p>Size:</p>
												<span> {{ item.variation?.size || "default" }}</span>
											</li>
											<li>
												<p>Quantity:</p>
												<span>{{ item.quantity }}</span>
											</li>
										</ul>
										<ul class="order-detail-list" *ngIf="item.decorations && item.decorations.length">
											<li class="align-items-start">
												<p>Decoration:</p>
												<div class="d-flex flex-column">
													<span *ngFor="let decoration of item.decorations">
														{{ decoration.decoration.name }}
														<ng-container *ngFor="let option of decoration.options">
															<br />{{ option.decorationOption.name }}:
															{{ option.value }}
														</ng-container>
													</span>
												</div>
											</li>
										</ul>
									</div>
								</div>
							</td>
							<td>
								<p>{{ item.product.code }}</p>
							</td>
							<td>
								<p>{{ getTotalQuantity(item) }}</p>
							</td>
							<td>
								<p>
									${{
									utils.twoDecimalPlacesString(
									item.price || item.product.price
									)
									}}
								</p>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<!-- table-content for lg screen end here... -->

			<!-- Cards for sm screen start here... -->
			<div class="manage-cards d-lg-none">
				<div class="card manage-card" *ngFor="let item of order.items">
					<div class="card-body">
						<ul class="card-list">
							<li>
								<div class="manage-grid">
									<span class="head"> Product </span>
									<div class="table-order">
										<div class="order-head">
											<div class="order-img">
												<img src="{{ item.product.imageUrl }}" alt="No image" />
											</div>
											<h5 class="title">
												{{ item.product.name }}
											</h5>
										</div>

										<div class="order-table-content">
											<ul class="order-detail-list">
												<li>
													<p>Color:</p>
													<span>
														<span class="color"></span>
														{{ item.variation?.colour || "default" }}</span>
												</li>
												<li>
													<p>Size:</p>
													<span> {{ item.variation?.size || "default" }}</span>
												</li>
												<li>
													<p>Quantity:</p>
													<span> 1</span>
												</li>
											</ul>
											<ul class="order-detail-list">
												<li>
													<p>Decoration:</p>
													<span *ngFor="let decoration of item.decorations">
														{{ decoration.decoration.name }}</span>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</li>
							<li>
								<div class="manage-grid">
									<span class="head"> Code </span>
									<div>
										<p>{{ item.product.code }}</p>
									</div>
								</div>
							</li>
							<li>
								<div class="manage-grid">
									<span class="head"> Quantity </span>
									<p>{{ getTotalQuantity(item) }}</p>
								</div>
							</li>
							<li>
								<div class="manage-grid">
									<span class="head"> Price </span>
									<p>
										${{
										utils.twoDecimalPlacesString(
										item.price || item.product.price
										)
										}}
									</p>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<!-- Cards for sm screen end here... -->
		</div>
	</div>
	<!-- manage-user main end here... -->
</div>

<!--- /Tracking Edit Modal --->
<ng-template #content let-c="close" let-d="dismiss">
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Tracking Details Edit</h4>
	</div>
	<div class="modal-body">
		<div class="row mb-0">
			<div class="col-12">
				<div class="form-group mb-3">
					<label class="form-label" for="TrackingDetail">Tracking Detail</label>
					<input id="TrackingDetail" type="text" class="form-control" placeholder="Tracking Details"
						[(ngModel)]="trackingDetail" />
				</div>
			</div>
			<div class="col-12">
				<div class="form-group mb-3">
					<label class="form-label" for="TrackingDetailURL">Tracking Detail Link</label>
					<input id="TrackingDetailURL" type="text" class="form-control" placeholder="Tracking Details Link"
						[(ngModel)]="trackingDetailUrl" />
				</div>
			</div>
		</div>

		<div class="d-flex align-items-center justify-content-end gap-3">
			<button type="button" class="btn btn-secondary" (click)="c('Cancel click')">
				Cancel
			</button>
			<button type="button" class="btn btn-primary px-3" (click)="saveTrackingInformation(); c('Save click')">
				Save
			</button>
		</div>
	</div>
</ng-template>
<!--- /Tracking Edit Modal --->

<app-shared-order-model #orderModals (saveCompleted)="onSaveCompleted()"></app-shared-order-model>