import {
  AuthApi
} from '../api/auth.api';
import { Injectable } from "@angular/core";
import { JwtService } from './jwt.service';
import { apiCallWrapper } from '../api/api.util';
import { NotificationsService } from 'angular2-notifications';
import { BehaviorSubject, Observable, Subscription, distinctUntilChanged, filter, interval, map, of, startWith, switchMap, tap, throwError } from 'rxjs';
import { IAuthPayload, IForgotPayload, IJWTPayload, IResetPayload } from '../model/auth.model';
import { GlobalApi } from '../api/global.api';
import { logger } from '../util/Logger';
import { SessionApi } from '../api/session.api';
import { has } from '../util/object.util';
import { UnleashedCustomerExtended } from '../model/unleashed.model';
import { ConversionService } from './conversion.service';
import Raven from 'raven-js';
import { pick, result } from 'lodash';
import { Router } from '@angular/router';
import { SecurityService } from './security.service';
import { CognitoSSOSession, SSOSession } from './sso.service';

interface auth {
  email: string,
  password: string
}

const className = "AuthService";
@Injectable()
export class AuthService {
  public $userPassAuthRequested = new BehaviorSubject<boolean>(false);
  private stickyMenuSubject = new BehaviorSubject<string>('');
  private accountMenuSubject = new BehaviorSubject<string>('');
  constructor(
    private authApi: AuthApi,
    private jwtService: JwtService,
    public notifications: NotificationsService,
    public globals: GlobalApi,
    private readonly session: SessionApi,
    private readonly conversionService: ConversionService,
    public router: Router,
    private securityService: SecurityService
  ) {
    this.moniterAuthType();
  }

  /**
 * @description Guarantees that every time the user data is cleared, the authentication type is also cleared
 */
  moniterAuthType() {
    this.session.$userData
      .pipe(
        filter(data => !data)
      )
      .subscribe({
        next: () => {
          this.$userPassAuthRequested.next(false);
        }
      });
  }


  /**
   * @description Refreshes the authentication token by making a request to the server using the provided JWT strings.
   * @param {string} jwtString - The access token JWT string.
   * @param {string} jwtRefreshString - The refresh token JWT string.
   * @returns {Observable<IJWTPayload>} - An observable that emits the refreshed JWT payload upon successful token refresh.
   * @public
   * @example
   * ```
   * const accessToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9...'; // Access token JWT string
   * const refreshToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9...'; // Refresh token JWT string
   *
   * auth.refreshToken(accessToken, refreshToken)
   *   .subscribe(jwtPayload => {
   *     console.log(jwtPayload); // The refreshed JWT payload
   *   }, error => {
   *     console.error(error); // Handle error
   *   });
   * ```
   */
  public readonly refreshToken = (jwtString: string, jwtRefreshString: string): Observable<IJWTPayload> => {
    return apiCallWrapper(
      this.authApi.refresh({ accessToken: jwtString, refreshToken: jwtRefreshString }),
      {
        notificationsService: this.notifications,
        action: "Obtaining new access keys"
      }
    )
  }

  /**
   * @description Resets the user's password by making a request to the server using the provided reset payload.
   * @param {IResetPayload} opts - The payload object for the password reset request.
   * @returns {Observable<any>} - An observable that emits the response from the password reset request.
   * @public
   * @example
   * ```
   * const resetPayload = {
   *   email: 'john@example.com',
   *   newPassword: 'newPassword123'
   * };
   *
   * auth.reset(resetPayload)
   *   .subscribe(response => {
   *     console.log(response); // The response from the password reset request
   *   }, error => {
   *     console.error(error); // Handle error
   *   });
   * ```
   */
  public reset = (opts: IResetPayload) => {
    return apiCallWrapper(
      this.authApi.reset(opts),
      {
        notificationsService: this.notifications,
        action: "Changing password"
      }
    );
  }

  /**
   * @description Authenticates the user by making a request to the server using the provided authentication payload.
   * @param {IAuthPayload} opts - The payload object for the authentication request.
   * @returns {Observable<any>} - An observable that emits the response from the authentication request.
   * @public
   * @example
   * ```
   * const authPayload = {
   *   username: 'john@example.com',
   *   password: 'password123'
   * };
   *
   * auth.authenticate(authPayload)
   *   .subscribe(response => {
   *     console.log(response); // The response from the authentication request
   *   }, error => {
   *     console.error(error); // Handle error
   *   });
   * ```
   */

  public authenticate = (opts: IAuthPayload) => {
    return this.authApi.authenticate(opts)
      .pipe(
        tap(() => this.$userPassAuthRequested.next(true))
      )
  }

  public sso = (opts: SSOSession) => {
    return this.authApi.sso(opts)
  }

  /**
   * @description Sends a forgot password request by making a request to the server using the provided forgot payload.
   * @param {IForgotPayload} opts - The payload object for the forgot password request.
   * @returns {Observable<any>} - An observable that emits the response from the forgot password request.
   * @public
   * @example
   * ```
   * const forgotPayload = {
   *   email: 'john@example.com'
   * };
   *
   * auth.forgot(forgotPayload)
   *   .subscribe(response => {
   *     console.log(response); // The response from the forgot password request
   *   }, error => {
   *     console.error(error); // Handle error
   *   });
   * ```
   */

  public forgot = (opts: IForgotPayload) => {
    return apiCallWrapper(
      this.authApi.forgot(opts),
      {
        notificationsService: this.notifications,
        action: "Reset Password",
        failTitle: "Reset Failed",
        successTitle: "Reset Complete",
        successMessage: "Instructions were sent to your email address"
      }
    )
  }

  /**
   * @description Checks if the user is authenticated by evaluating the current JWT payload.
   * @returns {boolean} - A boolean value indicating whether the user is authenticated or not.
   * @public
   * @example
   * ```
   * const authenticated = auth.isAuthenticated();
   * console.log(authenticated); // true or false
   * ```
   */
  public isAuthenticated = () => this.jwtService.currentJwtPayload$.getValue() !== null;

  private _globalsInformationMonitor: Subscription;
  private _globalsInformationObservable: BehaviorSubject<GlobalApi | null> = new BehaviorSubject(null);

  /**
 * Retrieves data from the server to describe information relative to the current JWT
 * Protected against multiple calls causing race until session readiness is established
 */
  globalsInformation(): Observable<GlobalApi | null> {
    const signature = className + '.globalsInformation: ';
    logger.silly(signature + 'Monitoring Global Information');

    return of(this.globals._ready)
      .pipe(
        switchMap((isReady: boolean) => {
          if (!this._globalsInformationMonitor) {
            this._globalsInformationMonitor = interval(500)
              .pipe(
                // And immediately
                startWith(0),
                // Get the JWT
                map(() => this.jwtService.getJWTString()),
                // Suppress repeat, sequential outcomes
                distinctUntilChanged(),
                // Verify the JWT
                map(() => this.jwtService.decodeJWT()),
                // Handle a JWTVerified state change
                switchMap(jwtData => {
                  const jwtVerified = this.jwtService.verifyJWT(jwtData as any);
                  if (!jwtVerified) {
                    this.globals.resetValues();
                    this.globals.ready();
                    logger.silly(signature + `Could not verify JWT`);
                    this.session.$jwtData.next(null);

                    return of(this.globals);
                  }
                  logger.silly(signature + 'Updating JWT Data');
                  this.session.$jwtData.next(jwtData);

                  logger.silly(signature + `Fetching Session Information`);
                  return this.getMyInformation()
                    .pipe(
                      map(() => {
                        logger.silly(signature + `Fetched Session Information`);
                        this.globals.loggedIn = true;

                        this.globals.ready();

                        return this.globals;
                      })
                    );
                }),
              )
              .subscribe(globals => {
                this._globalsInformationObservable.next(globals)
              });
          }

          return this._globalsInformationObservable.pipe(
            filter(globals => !!globals)
          );
        })
      );
  }

  private readonly getMyInformation = () => {
    const signature = className + '.getMyInformation: ';
    logger.silly(signature + 'Fetching current user information');
    return this.session.readAuthData()
      .pipe(
        tap(() => logger.info(signature + 'Found new data')),
        switchMap(this.handleInformationData)
      );
  };

  handleInformationData = (information: any): Observable<boolean> => {
    const signature = className + ".handleInformationData: ";

    if (!information) {
      logger.silly(signature + `Information data was falsy`);
      return of(false);
    }

    logger.debug(signature + "Handling Information Data");

    if (!has(information, 'id')) {
      logger.error(signature + 'Information was of unexpected format and will cause uncaught exception');
      return of(false);
    }

    this.globals.userId = information.id;
    this.globals.isAdmin = information.isAdmin;
    this.globals.User = this.conversionService.convertToAdmin(information);

    if (information.actual && information.actual.id !== information.id) {
      this.globals.isEmulatingUser = true;
    }

    if (information.shippingDetails) {
      this.globals.User.ShippingDetails =
        this.conversionService.convertToShippingDetails(information.shippingDetails);
    }

    const customers: UnleashedCustomerExtended[] = information.customerUsers?.map(customerUser => {
      const customerConverted = this.conversionService.convertToCustomer(customerUser.customer);
      const customerExtended = Object.assign(new UnleashedCustomerExtended(), customerConverted);
      const customerUserConverted = this.conversionService.convertToCustomerUser({
        ...customerUser,
        user: pick(information, ['firstName', 'lastName', 'email', 'contactNumber']),
      });

      customerExtended.CustomerUser = customerUserConverted;
      customerExtended.CustomerUserRole = customerUser.userRole;
      customerExtended.IsDefault = !result(customerUser, 'isGuest');
      return customerExtended;
    });
    this.globals.customerAccess = this.globals.isAdmin ? [] : customers;

    /**
     * This shouldn't have to be done but we're going to do it anyway because it is the easiest way to find that information right now.
     *
     * Fetch the JWT token from the JWT service, find the customerId on the token and that is the destination customer
     */
    const tokenData = this.jwtService.decodeJWT();

    const defaultCustomerData: UnleashedCustomerExtended | undefined = customers?.find((customer) => {
      return customer.IsDefault;
    });

    if (defaultCustomerData) {
      this.globals.actualCustomer = defaultCustomerData;
    }

    if (tokenData?.customerId && customers) {
      const customerData = customers.find(data => {
        return data.id === tokenData.customerId
      });

      if (customerData) {
        this.globals.customer = customerData;
        logger.debug(signature + `User assumed access to Customer[${customerData.CustomerName}] with Role[${this.globals.currentRole?.name}]`);
      } else {
        logger.error(signature + 'User attempted to assume customer[' + tokenData.customerId + '] from key[' + tokenData + '] however access was denied.');
      }
    } else if (!this.globals.isAdmin) {

      if (!defaultCustomerData) {
        logger.debug(signature + `No default customer data was found`);
        return of(false);
      }
      this.globals.customer = defaultCustomerData;
      logger.debug(signature + `User defaulted access to Customer[${defaultCustomerData.CustomerName}] with Role[${this.globals.currentRole}]`);
    } else {
      logger.debug(signature + `Customer access is not relevant for user`);
      this.globals.customer = null;
    }

    if (this.globals && this.globals.User && this.globals.User.email) {
      Raven.setUserContext({
        email: this.globals.User.email
      });
    }

    if (information.customerUsers && !this.globals.isAdmin) {
      if (!has(this.globals, 'customer.id')) {
        logger.error(signature + 'Global Customer was of unexpected format and will cause uncaught exception');
      }

      const customerUser = information.customerUsers.find(customerUser => customerUser.id = this.globals.customer?.id);

      if (customerUser && customerUser.shippingDetails)
        this.globals.shippingDetails = customerUser.shippingDetails;
    }

    return of(true);
  };

  public logOut = () => {
    return this.authApi.logOut();
  }

  /**
 * Uses business rules to determine where the user should be sent in a "default" scenario
 */
  readonly navigateToDefaultUrl = async (): Promise<any> => {
    const signature = "UserLoginService.navigateToCustomerUrl: ";

    let route: string | undefined = '/manage/dashboard';

    if (has(this.globals, 'customer.defaultUri.length', (len) => len)) {
      route = this.globals.customer?.defaultUri;
    }

    this.securityService.hasProductAccess().subscribe(hasProductAccess => {
      if (!hasProductAccess && route && route.match(/^\/products/i)) {
        route = '/manage/dashboard';
      }

      logger.debug(signature + `Navigating to ${route}`);

      return this.router.navigate([route]);
    });
  }

  switchUser(customerId: number, userId: number) {
    const signature = "AuthService" + '.switchUser: ';
    return this.performAuthenticationRequest(this.authApi.switch(customerId, userId))
      .pipe(
        tap(() => logger.silly(signature + `Switched into Customer[${customerId}] and User[${userId}]`)),
        map(() => true)
      );
  }

  /** Common authentication and post authentication behaviours */
  public performAuthenticationRequest(observable: Observable<IJWTPayload>) {
    return observable
      .pipe(
        tap(payload => this.persistJwtPayload(payload)),
        switchMap(() => this.handleAuthentication())
      )
  }

  private persistJwtPayload(payload: IJWTPayload): Observable<void> {
    const signature = className + ".persistJwtPayload: ";
    if (!(this.jwtService.saveJWTData(payload) && this.globals.authenticate())) {
      logger.info(signature + `Clearing JWT Data`);
      this.jwtService.removeJWTData();

      return throwError("Error Saving JWT Payload");
    }

    logger.silly(signature + `Persisted JWT Data`);
    return of();
  }

  private handleAuthentication() {
    const signature = "AuthenticationService.afterAuthentication: ";

    logger.debug(signature + "Finalizing Login");

    return this.session.readAuthData()
      .pipe(
        switchMap(result => this.handleInformationData(result)),
        tap(() => this.globals.ready())
      );
  }

  /** Remove user emulation and switch back to self */
  unswitchUser() {
    return this.performAuthenticationRequest(
      this.authApi.unswitch());
  }

  public register = (firstName: string, lastName: string, email: string, secret: string) => {
    return apiCallWrapper(
      this.authApi.register(firstName, lastName, email, secret),
      {
        notificationsService: this.notifications,
        action: "Authenticating"
      }
    )
  }

  public accountCreate = (opts: { userName: string, firstname: string, company: string, lastname: string, mobile: string }) => {
    return this.authApi.accountCreate(opts);
  }

  public contactUs = (opts: { userName: string, firstname: string, company: string, lastname: string, mobile: string, generalDesc: string }) => {
    return this.authApi.contactUs(opts);
  }

  setStickyMenu(menuType: string): void {
    this.stickyMenuSubject.next(menuType);
  }

  getStickyMenu(): Observable<string> {
    return this.stickyMenuSubject.asObservable();
  }

  setAccountMenu(menuType: string): void {
    this.accountMenuSubject.next(menuType);
  }

  getAccountMenu(): Observable<string> {
    return this.accountMenuSubject.asObservable();
  }

  readonly acceptGuestInvite = (email: string, token: string) => {
    return this.authApi.acceptGuestInvite(email, token);
  };

  readonly denyGuestInvite = (email: string, token: string) => {
    return this.authApi.denyGuestInvite(email, token);
  };
}
