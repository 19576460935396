<div [class.main-right-content]="!customerGuid">
	<!-- Page Breadcrumb start here... -->
	<nav aria-label="breadcrumb" class="pageBreadcrumb" *ngIf="!customerGuid">
		<ol class="breadcrumb mb-0">
			<li class="breadcrumb-item">
				<a [routerLink]="['/manage/customer']">Customers</a>
			</li>
			<li class="breadcrumb-item active" aria-current="page">Add Customer</li>
		</ol>
	</nav>
	<!-- Page Breadcrumb end here... -->
	<div [class.manageUser]="!customerGuid">
		<div class="d-flex flex-column flex-lg-row align-items-start justify-content-between manage-top gap-4"
			*ngIf="!customerGuid">
			<h1 class="title mb-0">Add Customer</h1>
		</div>
		<div [class.manage-user-list]="!customerGuid">
			<div class="customer-wizard">
				<div class="wizard-tabs">
					<ul class="nav nav-pills" id="pills-tab" role="tablist">
						<li class="nav-item" role="presentation">
							<button class="nav-link" (click)="setActive(1)" [class.active]="activeClassId == 1"
								[class.complete]="activeClassId >= 2" type="button">
								<span>
									<svg class="incompleted" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
										fill="none">
										<path
											d="M16 16.75V15.25C16 14.4544 15.6839 13.6913 15.1213 13.1287C14.5587 12.5661 13.7956 12.25 13 12.25H7C6.20435 12.25 5.44129 12.5661 4.87868 13.1287C4.31607 13.6913 4 14.4544 4 15.25V16.75M13 6.25C13 7.90685 11.6569 9.25 10 9.25C8.34315 9.25 7 7.90685 7 6.25C7 4.59315 8.34315 3.25 10 3.25C11.6569 3.25 13 4.59315 13 6.25Z"
											stroke="transparent" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
									</svg>
									<svg class="completed" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
										fill="none">
										<path
											d="M17.5 9.31039V10.0004C17.4991 11.6177 16.9754 13.1914 16.007 14.4868C15.0386 15.7821 13.6775 16.7297 12.1265 17.1883C10.5756 17.6469 8.91794 17.5918 7.40085 17.0313C5.88376 16.4708 4.58849 15.435 3.70822 14.0782C2.82795 12.7214 2.40984 11.1164 2.51626 9.50262C2.62267 7.88881 3.24791 6.35263 4.29871 5.12319C5.34951 3.89375 6.76959 3.03692 8.34714 2.6805C9.92469 2.32407 11.5752 2.48714 13.0525 3.14539"
											stroke="transparent" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
										<path d="M17.5 4L10 11.5075L7.75 9.2575" stroke="transparent" stroke-width="1.5"
											stroke-linecap="round" stroke-linejoin="round" />
									</svg>
								</span>
								<div>
									<h6>Step 1</h6>
									<h5>Customer Details</h5>
								</div>
							</button>
						</li>
						<li class="nav-item">
							<div class="border-top-item"></div>
						</li>
						<li class="nav-item" role="presentation">
							<button class="nav-link" (click)="setActive(2)" [class.active]="activeClassId == 2"
								[class.complete]="activeClassId >= 3" type="button">
								<span>
									<svg class="incompleted" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
										fill="none">
										<path
											d="M13.75 3.25023C13.947 3.05324 14.1808 2.89699 14.4382 2.79038C14.6956 2.68378 14.9714 2.62891 15.25 2.62891C15.5286 2.62891 15.8044 2.68378 16.0618 2.79038C16.3192 2.89699 16.553 3.05324 16.75 3.25023C16.947 3.44721 17.1032 3.68106 17.2098 3.93843C17.3165 4.1958 17.3713 4.47165 17.3713 4.75023C17.3713 5.0288 17.3165 5.30465 17.2098 5.56202C17.1032 5.81939 16.947 6.05324 16.75 6.25023L6.625 16.3752L2.5 17.5002L3.625 13.3752L13.75 3.25023Z"
											stroke="transparent" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
									</svg>
									<svg class="completed" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
										fill="none">
										<path
											d="M17.5 9.31039V10.0004C17.4991 11.6177 16.9754 13.1914 16.007 14.4868C15.0386 15.7821 13.6775 16.7297 12.1265 17.1883C10.5756 17.6469 8.91794 17.5918 7.40085 17.0313C5.88376 16.4708 4.58849 15.435 3.70822 14.0782C2.82795 12.7214 2.40984 11.1164 2.51626 9.50262C2.62267 7.88881 3.24791 6.35263 4.29871 5.12319C5.34951 3.89375 6.76959 3.03692 8.34714 2.6805C9.92469 2.32407 11.5752 2.48714 13.0525 3.14539"
											stroke="transparent" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
										<path d="M17.5 4L10 11.5075L7.75 9.2575" stroke="transparent" stroke-width="1.5"
											stroke-linecap="round" stroke-linejoin="round" />
									</svg>
								</span>
								<div>
									<h6>Step 2</h6>
									<h5>Purchase Order Pattern</h5>
								</div>
							</button>
						</li>
						<li class="nav-item">
							<div class="border-top-item"></div>
						</li>
						<li class="nav-item" role="presentation">
							<button class="nav-link" (click)="setActive(3)" [class.active]="activeClassId == 3"
								[class.complete]="activeClassId >= 4" type="button">
								<span>
									<svg class="incompleted" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
										fill="none">
										<path
											d="M17.5 10H13L11.5 12.25H8.5L7 10H2.5M17.5 10V14.5C17.5 14.8978 17.342 15.2794 17.0607 15.5607C16.7794 15.842 16.3978 16 16 16H4C3.60218 16 3.22064 15.842 2.93934 15.5607C2.65804 15.2794 2.5 14.8978 2.5 14.5V10M17.5 10L14.9125 4.8325C14.7883 4.58259 14.5969 4.37228 14.3597 4.22521C14.1226 4.07814 13.8491 4.00015 13.57 4H6.43C6.15094 4.00015 5.87745 4.07814 5.64028 4.22521C5.40312 4.37228 5.21168 4.58259 5.0875 4.8325L2.5 10"
											stroke="transparent" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
									</svg>
									<svg class="completed" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
										fill="none">
										<path
											d="M17.5 9.31039V10.0004C17.4991 11.6177 16.9754 13.1914 16.007 14.4868C15.0386 15.7821 13.6775 16.7297 12.1265 17.1883C10.5756 17.6469 8.91794 17.5918 7.40085 17.0313C5.88376 16.4708 4.58849 15.435 3.70822 14.0782C2.82795 12.7214 2.40984 11.1164 2.51626 9.50262C2.62267 7.88881 3.24791 6.35263 4.29871 5.12319C5.34951 3.89375 6.76959 3.03692 8.34714 2.6805C9.92469 2.32407 11.5752 2.48714 13.0525 3.14539"
											stroke="transparent" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
										<path d="M17.5 4L10 11.5075L7.75 9.2575" stroke="transparent" stroke-width="1.5"
											stroke-linecap="round" stroke-linejoin="round" />
									</svg>
								</span>
								<div>
									<h6>Step 3</h6>
									<h5>Employee Purchase Mode</h5>
								</div>
							</button>
						</li>
						<li class="nav-item">
							<div class="border-top-item"></div>
						</li>
						<li class="nav-item" role="presentation">
							<button class="nav-link" (click)="setActive(4)" [class.active]="activeClassId == 4"
								[class.complete]="activeClassId >= 5" type="button">
								<span>
									<svg class="incompleted" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
										fill="none">
										<path
											d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z"
											stroke="transparent" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
										<path d="M10 5.5V10L13 11.5" stroke="transparent" stroke-width="1.5" stroke-linecap="round"
											stroke-linejoin="round"></path>
									</svg>
									<svg class="completed" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
										fill="none">
										<path
											d="M17.5 9.31039V10.0004C17.4991 11.6177 16.9754 13.1914 16.007 14.4868C15.0386 15.7821 13.6775 16.7297 12.1265 17.1883C10.5756 17.6469 8.91794 17.5918 7.40085 17.0313C5.88376 16.4708 4.58849 15.435 3.70822 14.0782C2.82795 12.7214 2.40984 11.1164 2.51626 9.50262C2.62267 7.88881 3.24791 6.35263 4.29871 5.12319C5.34951 3.89375 6.76959 3.03692 8.34714 2.6805C9.92469 2.32407 11.5752 2.48714 13.0525 3.14539"
											stroke="transparent" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
										<path d="M17.5 4L10 11.5075L7.75 9.2575" stroke="transparent" stroke-width="1.5"
											stroke-linecap="round" stroke-linejoin="round" />
									</svg>
								</span>
								<div>
									<h6>Step 4</h6>
									<h5>Discount Rate & Reminders</h5>
								</div>
							</button>
						</li>
					</ul>
				</div>
				<div class="tab-content" id="pills-tabContent">
					<div class="tab-pane fade" [class.show]="activeClassId == 1" [class.active]="activeClassId == 1">
						<div class="edit-users">
							<div class="edit-card card">
								<div class="card-head">
									<h4 class="head-title">
										<span>
											<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
												<path
													d="M16 16.75V15.25C16 14.4544 15.6839 13.6913 15.1213 13.1287C14.5587 12.5661 13.7956 12.25 13 12.25H7C6.20435 12.25 5.44129 12.5661 4.87868 13.1287C4.31607 13.6913 4 14.4544 4 15.25V16.75"
													fill="url(#paint0_radial_0_17046)" fill-opacity="0.5" />
												<path
													d="M10 9.25C11.6569 9.25 13 7.90685 13 6.25C13 4.59315 11.6569 3.25 10 3.25C8.34315 3.25 7 4.59315 7 6.25C7 7.90685 8.34315 9.25 10 9.25Z"
													fill="url(#paint1_radial_0_17046)" fill-opacity="0.5" />
												<path
													d="M16 16.75V15.25C16 14.4544 15.6839 13.6913 15.1213 13.1287C14.5587 12.5661 13.7956 12.25 13 12.25H7C6.20435 12.25 5.44129 12.5661 4.87868 13.1287C4.31607 13.6913 4 14.4544 4 15.25V16.75M13 6.25C13 7.90685 11.6569 9.25 10 9.25C8.34315 9.25 7 7.90685 7 6.25C7 4.59315 8.34315 3.25 10 3.25C11.6569 3.25 13 4.59315 13 6.25Z"
													stroke="#272835" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
												<defs>
													<radialGradient id="paint0_radial_0_17046" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
														gradientTransform="translate(10 3.925) rotate(90) scale(9.7875 8.7)">
														<stop stop-color="#8BD6EF" />
														<stop offset="1" stop-color="#8BD6EF" stop-opacity="0" />
													</radialGradient>
													<radialGradient id="paint1_radial_0_17046" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
														gradientTransform="translate(10 3.925) rotate(90) scale(9.7875 8.7)">
														<stop stop-color="#8BD6EF" />
														<stop offset="1" stop-color="#8BD6EF" stop-opacity="0" />
													</radialGradient>
												</defs>
											</svg>
										</span>
										Customer Details
									</h4>
								</div>
								<div class="card-body" *ngIf="customer">
									<div class="row">
										<div class="col-12">
											<div class="uploader">
												<h5 class="form-title">LOGO</h5>
												<div class="form-group w-100">
													<label for="logoFile" class="btn btn-secondary w-100">Upload</label>
													<input class="form-control d-none" type="file" id="logoFile"
														(change)="persistS3Image($event)" />
												</div>
												<div class="preview-content">
													<img src="{{ customer.imageUrl }}" [class.d-none]="!customer.imageUrl" alt="customer" />
													<div class="preview-before" *ngIf="!customer.imageUrl">
														<h3>
															<span>
																<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 20 20"
																	fill="none">
																	<path
																		d="M16.75 12.25V15.25C16.75 15.6478 16.592 16.0294 16.3107 16.3107C16.0294 16.592 15.6478 16.75 15.25 16.75H4.75C4.35218 16.75 3.97064 16.592 3.68934 16.3107C3.40804 16.0294 3.25 15.6478 3.25 15.25V12.25"
																		stroke="#dfe2e5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
																	</path>
																	<path d="M13.75 7L10 3.25L6.25 7" stroke="#dfe2e5" stroke-width="1.5"
																		stroke-linecap="round" stroke-linejoin="round"></path>
																	<path d="M10 3.25V12.25" stroke="#dfe2e5" stroke-width="1.5" stroke-linecap="round"
																		stroke-linejoin="round"></path>
																</svg>
															</span>
															Logo
														</h3>
													</div>
												</div>
											</div>
										</div>
										<div class="col-12">
											<h5 class="form-title">PERSONAL DETAILS</h5>
										</div>
										<div class="col-12 col-lg-12">
											<div class="form-group" *ngIf="!customerGuid">
												<label for="unleashedProductSelector" class="form-label">Select Customer</label>
												<ng-select #selectCustomer [virtualScroll]="true" [loading]="isLoading"
													(scrollToEnd)="fetchMore()" [searchable]="true" (change)="syncCustomerFromUnleashed()"
													(search)="onSearch($event)" [loading]="iscustomerLoading" [notFoundText]="noItemsFoundText"
													[clearable]="true">
													<ng-option *ngFor="
															let data of unleashedCustomerListResult.items
														" [value]="data.Guid">{{ data.CustomerName }}</ng-option>
												</ng-select>
											</div>
										</div>
										<div class="col-12 col-lg-6">
											<div class="form-group">
												<label for="customerName" class="form-label">Customer Name</label>
												<input type="text" class="form-control" id="customerName" placeholder=""
													[(ngModel)]="customer.CustomerName" />
											</div>
										</div>
										<div class="col-12 col-lg-6">
											<div class="form-group">
												<label for="customerCode" class="form-label">Customer Code</label>
												<input type="text" class="form-control" id="customerCode" placeholder=""
													value="{{ customer.CustomerCode }}" disabled />
											</div>
										</div>
										<div class="col-12 col-lg-6">
											<div class="form-group">
												<label for="accessMode" class="form-label">Access Mode</label>
												<select #selectedProducts (change)="
														changeSelectedProduct(selectedProducts.value)
													" class="form-select" aria-label="Default select example" id="accessMode">
													<option [selected]="customer.AssignedProductsOnly" value="true">
														Selected Products Only
													</option>
													<option [selected]="!customer.AssignedProductsOnly" value="false">
														All Products
													</option>
												</select>
											</div>
										</div>
										<div class="col-12 col-lg-6">
											<div class="form-group">
												<label for="handlingFee" class="form-label"> Small Order Fee</label>
												<select #handlingFee class="form-select handlefree" aria-label="Default select example"
													id="handlingFee" (change)="
														customer.HandlingFee = handlingFee.value === 'true'
													">
													<option [selected]="customer.HandlingFee" value="true">
														Fee on Purchase Below Threshold
													</option>
													<option [selected]="!customer.HandlingFee" value="false">
														No Small Order Fee
													</option>
												</select>
											</div>
										</div>
										<div class="col-12 col-lg-6" *ngIf="customer.HandlingFee">
											<div class="form-group">
												<label for="handlingFeeAmount" class="form-label"> Small Order Fee Amount</label>
												<input type="text" class="form-control" id="handlingFeeAmount" placeholder=""
													[(ngModel)]="customer.HandlingFeeAmount" />
											</div>
										</div>
										<div class="col-12 col-lg-6" *ngIf="customer.HandlingFee">
											<div class="form-group">
												<label for="handlingFeeThreshhold" class="form-label"> Small Order Fee Threshhold</label>
												<input type="text" class="form-control" id="handlingFeeThreshhold" placeholder=""
													[(ngModel)]="customer.HandlingFeeThreshold" />
											</div>
										</div>
										<div class="col-12" *ngIf="customer.HandlingFee">
											<div class="form-group">
												<label for="handlingFeeCoPay" class="form-label"> Small Order Fee CoPay</label>
												<input type="text" class="form-control" id="handlingFeeCoPay" placeholder=""
													[(ngModel)]="customer.HandlingFeeCoPay" />
											</div>
										</div>
										<div class="col-12">
											<h5 class="form-title">Allocation Approvals</h5>
										</div>
										<div class="col-12 col-lg-10">
											<div class="form-group form-check">
												<input type="checkbox" class="form-check-input" id="Approval1" [value]="true"
													[(ngModel)]="customer.enableOrdersRequireApproval" />
												<label class="form-check-label" for="Approval1">Send Allocations for Approval
													<span>Approvals are sent when approvals are enabled at
														EITHER customer or user level</span>
												</label>
											</div>
										</div>
										<div class="col-12 col-lg-10">
											<div class="form-group form-check">
												<input type="checkbox" class="form-check-input" id="Approval2" [value]="true"
													[(ngModel)]="customer.allowPackOrderNames" />
												<label class="form-check-label" for="Approval2">Enable Pack Order Names functionality
													<span>Users will be permitted to upload supporting
														documents</span>
												</label>
											</div>
										</div>
										<div class="col-12 col-lg-10">
											<div class="form-group form-check">
												<input type="checkbox" class="form-check-input" id="Approval3"
													[(ngModel)]="customer.stopCredit" />
												<label class="form-check-label" for="Approval3">Customer is on a stop credit
													<span>A message will be displayed to every user</span>
												</label>
											</div>
										</div>
										<div class="col-12 col-lg-10">
											<div class="form-group form-check">
												<input type="checkbox" [value]="true" class="form-check-input" id="ApprovalOb"
													[(ngModel)]="customer.obsolete" />
												<label class="form-check-label" for="ApprovalOb">Customer is obsolete
													<span>Automatically resets nightly.</span>
												</label>
											</div>
										</div>
										<div class="col-12 col-lg-10">
											<div class="form-group form-check">
												<input type="checkbox" [value]="true" class="form-check-input" id="Approval4"
													[(ngModel)]="customer.disable2faCheck" />
												<label class="form-check-label" for="Approval4">Disable 2fa Check
													<span>2fa will still be available. Notifications will not
														be sent.</span>
												</label>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div class="edit-card card">
								<div class="card-head">
									<h4 class="head-title">
										<span>
											<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
												<path
													d="M4 4H16C16.825 4 17.5 4.675 17.5 5.5V14.5C17.5 15.325 16.825 16 16 16H4C3.175 16 2.5 15.325 2.5 14.5V5.5C2.5 4.675 3.175 4 4 4Z"
													stroke="#272835" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
												<path d="M17.5 5.5L10 10.75L2.5 5.5" stroke="#272835" stroke-width="1.5" stroke-linecap="round"
													stroke-linejoin="round" />
											</svg>
										</span>
										Select Required Fields
									</h4>
								</div>
								<div class="card-body" *ngIf="customer">
									<div class="col-12 col-lg-10">
										<div class="form-group form-check">
											<input type="checkbox" class="form-check-input" id="contact" [value]="true"
												[(ngModel)]="requiredField.contact" />
											<label class="form-check-label" for="contact">Contact number
											</label>
										</div>
									</div>
									<div class="col-12 col-lg-10">
										<div class="form-group form-check">
											<input type="checkbox" class="form-check-input" id="cost" [value]="true"
												[(ngModel)]="requiredField.costAccount" />
											<label class="form-check-label" for="cost">Cost account number
											</label>
										</div>
									</div>
									<div class="col-12 col-lg-10">
										<div class="form-group form-check">
											<input type="checkbox" class="form-check-input" id="employe" [value]="true"
												[(ngModel)]="requiredField.employeNumber" />
											<label class="form-check-label" for="employe">Employee number
											</label>
										</div>
									</div>
									<div class="col-12 col-lg-10">
										<div class="form-group form-check">
											<input type="checkbox" class="form-check-input" id="job" [value]="true"
												[(ngModel)]="requiredField.jobTittle" />
											<label class="form-check-label" for="job">Job title
											</label>
										</div>
									</div>
									<div class="col-12 col-lg-10">
										<div class="form-group form-check">
											<input type="checkbox" class="form-check-input" id="business" [value]="true"
												[(ngModel)]="requiredField.businessUnit" />
											<label class="form-check-label" for="business">Business unit
											</label>
										</div>
									</div>
									<div class="col-12 col-lg-10">
										<div class="form-group form-check">
											<input type="checkbox" class="form-check-input" id="start" [value]="true"
												[(ngModel)]="requiredField.startDate" />
											<label class="form-check-label" for="start">Start date
											</label>
										</div>
									</div>
									<div class="col-12 col-lg-10">
										<div class="form-group form-check">
											<input type="checkbox" class="form-check-input" id="address" [value]="true"
												[(ngModel)]="requiredField.address" />
											<label class="form-check-label" for="address">Address
											</label>
										</div>
									</div>
								</div>
							</div>

						</div>
					</div>
					<div class="tab-pane fade" [class.show]="activeClassId == 2" [class.active]="activeClassId == 2">
						<div class="edit-users">
							<div>
								<div class="edit-card card">
									<div class="card-head">
										<h4 class="head-title">
											<span>
												<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
													<path
														d="M16.75 13.0004V7.00041C16.7497 6.73737 16.6803 6.47902 16.5487 6.25129C16.417 6.02355 16.2278 5.83444 16 5.70291L10.75 2.70291C10.522 2.57126 10.2633 2.50195 10 2.50195C9.7367 2.50195 9.47803 2.57126 9.25 2.70291L4 5.70291C3.7722 5.83444 3.58299 6.02355 3.45135 6.25129C3.31971 6.47902 3.25027 6.73737 3.25 7.00041V13.0004C3.25027 13.2635 3.31971 13.5218 3.45135 13.7495C3.58299 13.9773 3.7722 14.1664 4 14.2979L9.25 17.2979C9.47803 17.4296 9.7367 17.4989 10 17.4989C10.2633 17.4989 10.522 17.4296 10.75 17.2979L16 14.2979C16.2278 14.1664 16.417 13.9773 16.5487 13.7495C16.6803 13.5218 16.7497 13.2635 16.75 13.0004Z"
														stroke="#272835" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
													<path d="M3.45215 6.21973L9.99965 10.0072L16.5471 6.21973" stroke="#272835" stroke-width="1.5"
														stroke-linecap="round" stroke-linejoin="round" />
													<path d="M10 17.56V10" stroke="#272835" stroke-width="1.5" stroke-linecap="round"
														stroke-linejoin="round" />
												</svg>
											</span>
											Purchase Order Pattern
										</h4>
									</div>
									<div class="card-body">
										<div class="row">
											<div class="col-12">
												<div class="form-group form-check">
													<input type="checkbox" class="form-check-input" [value]="true" [(ngModel)]="
															customerPurchasePattern.purchaseOrderRequired
														" id="PurchaseOrder" (click)="enablePurchaseOrderNumber()" />
													<label class="form-check-label" for="PurchaseOrder">Purchase Order Number Required
													</label>
												</div>
											</div>
											<div class="col-12" *ngIf="customerPurchasePattern.purchaseOrderRequired">
												<div class="form-group">
													<label for="orderPattern" class="form-label">Purchase Order Pattern
														<span>(Advanced)</span>
													</label>
													<input type="text" class="form-control" id="orderPattern" [(ngModel)]="
															customerPurchasePattern.purchaseOrderPattern
														" [disabled]="
															customerPurchasePattern.purchaseOrderOption ==
															'any'
														" />
													<div class="form-text">
														Supply a pattern for validating purchase orders. For
														Example [a-ZA-Z0-9]{{ "{" }}0,8{{ "}" }}
														would allow any letter or number with a length of 0
														to 8.
													</div>
												</div>
											</div>
											<div class="col-12 col-lg-6" *ngIf="customerPurchasePattern.purchaseOrderRequired">
												<div class="form-group form-check">
													<input type="radio" class="form-check-input" id="OrderNumber"
														(click)="setPurchaseOrderConfig()" [(ngModel)]="
															customerPurchasePattern.purchaseOrderOption
														" value="any" />
													<label class="form-check-label" for="OrderNumber">Any Purchase Order Number
													</label>
												</div>
											</div>
											<div class="col-12 col-lg-6" *ngIf="customerPurchasePattern.purchaseOrderRequired">
												<div class="form-group form-check">
													<input type="radio" class="form-check-input" id="Custom"
														(click)="enableCustomPurchaseOrderNumber()" [(ngModel)]="
															customerPurchasePattern.purchaseOrderOption
														" value="custom" />
													<label class="form-check-label" for="Custom">Custom
													</label>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="edit-card card">
									<div class="card-head">
										<h4 class="head-title">
											<span>
												<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
													<path d="M10 1.75V18.25" stroke="#272835" stroke-width="1.5" stroke-linecap="round"
														stroke-linejoin="round" />
													<path
														d="M13.75 4.75H8.125C7.42881 4.75 6.76113 5.02656 6.26884 5.51884C5.77656 6.01113 5.5 6.67881 5.5 7.375C5.5 8.07119 5.77656 8.73887 6.26884 9.23116C6.76113 9.72344 7.42881 10 8.125 10H11.875C12.5712 10 13.2389 10.2766 13.7312 10.7688C14.2234 11.2611 14.5 11.9288 14.5 12.625C14.5 13.3212 14.2234 13.9889 13.7312 14.4812C13.2389 14.9734 12.5712 15.25 11.875 15.25H5.5"
														stroke="#272835" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
												</svg>
											</span>
											Allocation Approvals
										</h4>
									</div>
									<div class="card-body" *ngIf="customer">
										<div class="col-12 col-lg-10">
											<div class="form-group form-check">
												<input type="checkbox" class="form-check-input" id="Approval1" [value]="true"
													[(ngModel)]="customer.disableAddressEdit" />
												<label class="form-check-label" for="Approval1">Only Users with Always Edit Addresses permission
													may
													change Shipping Addresses
												</label>
											</div>
										</div>
										<div class="col-12 col-lg-10">
											<div class="form-group form-check">
												<input type="checkbox" class="form-check-input" id="Approval2" [value]="true"
													[(ngModel)]="customer.showProductSearchCategories" />
												<label class="form-check-label" for="Approval2">Show Product Search Categories
												</label>
											</div>
										</div>
										<div class="col-12 col-lg-10">
											<div class="form-group form-check">
												<input type="checkbox" class="form-check-input" id="Approval3" [value]="true"
													[(ngModel)]="customer.showProductSearchCollections" />
												<label class="form-check-label" for="Approval3">Show Product Search Collections
												</label>
											</div>
										</div>
										<div class="col-12 col-lg-10">
											<div class="form-group form-check">
												<input type="checkbox" class="form-check-input" id="Approval4" [value]="true"
													[(ngModel)]="customer.enableBulkOrder" />
												<label class="form-check-label" for="Approval4">Enable Bulk Order
												</label>
											</div>
										</div>
										<div class="col-12 col-lg-10">
											<div class="form-group form-check">
												<input type="checkbox" class="form-check-input" id="Approval4" [value]="true"
													[(ngModel)]="customer.automaticCollectionSelection" />
												<label class="form-check-label" for="Approval4">Automatic Collection Selection in Cart
												</label>
											</div>
										</div>
										<div class="col-12 col-lg-10">
											<div class="form-group form-check">
												<input type="checkbox" class="form-check-input" id="approval-manager" [value]="true"
													[(ngModel)]="customer.enableUsersRequireApproval" />
												<label class="form-check-label" for="approval-manager">All User must have approval manager
												</label>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="edit-card card">
								<div class="card-head">
									<h4 class="head-title">
										<span>
											<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
												<path
													d="M4 4H16C16.825 4 17.5 4.675 17.5 5.5V14.5C17.5 15.325 16.825 16 16 16H4C3.175 16 2.5 15.325 2.5 14.5V5.5C2.5 4.675 3.175 4 4 4Z"
													stroke="#272835" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
												<path d="M17.5 5.5L10 10.75L2.5 5.5" stroke="#272835" stroke-width="1.5" stroke-linecap="round"
													stroke-linejoin="round" />
											</svg>
										</span>
										Landing & Email
									</h4>
								</div>
								<div class="card-body" *ngIf="customer">
									<div class="row">
										<landing-page-edit [customer]="customer"></landing-page-edit>
										<div class="col-12">
											<div class="form-group">
												<label for="handlingFee" class="form-label">Default Warehouse
												</label>
												<select #wareHouse class="form-select" aria-label="Default select example" id="handlingFee"
													(change)="setWarehouseDefault(wareHouse.value)">
													<option *ngFor="let data of warehouseList" [selected]="defaultWarehouse === data.text"
														[value]="data.id">
														{{ data.text }}
													</option>
												</select>
											</div>
										</div>
										<div class="col-12">
											<div class="form-group">
												<label for="InterceptMail" class="form-label">Intercept Email</label>
												<input type="email" class="form-control" id="InterceptMail" placeholder="admin@yourdomain.com"
													[(ngModel)]="customer.redirectEmail" />
												<div class="form-text">
													When filled ALL emails to users and guests will be
													sent to this address instead.
												</div>
											</div>
										</div>
										<div class="col-12">
											<div class="form-group">
												<label for="HandCopy" class="form-label">HandCopy Emailling Fee</label>
												<input type="text" class="form-control" id="HandCopy" placeholder="admin@yourdomain.com"
													[(ngModel)]="customer.ccEmail" />
												<div class="form-text">
													When filled a copy of ALL emails to users and guests
													will be sent to this address.
												</div>
											</div>
										</div>
										<div class="col-12">
											<div class="form-group form-check">
												<input type="checkbox" class="form-check-input" id="selfRegistration" [value]="true"
													[(ngModel)]="customer.RegisterEnabled" />
												<label class="form-check-label" for="selfRegistration">Enable Self Registration
												</label>
											</div>
										</div>
										<div class="col-12" *ngIf="customer.RegisterEnabled">
											<div class="form-group">
												<label for="emailFormat" class="form-label">Self Registration Email Format
												</label>
												<input type="email" class="form-control" id="emailFormat"
													placeholder="Email Format (eg. *@google.com$)" [(ngModel)]="customer.RegisterEmailPattern" />
												<div class="form-text">
													Only Emails which contain this pattern will be able to
													self register. This ensure that appropriate people can
													register.
												</div>
											</div>
										</div>
										<div class="col-12" *ngIf="customer.RegisterEnabled">
											<div class="form-group">
												<label for="registerUser" class="form-label">Self Registration User Role</label>
												<select #userRole class="form-select" aria-label="Default select example" id="registerUser"
													(change)="setRoleToCustomer(userRole.value)">
													{{
													customer.RegisterUserRole
													}}
													<option [selected]="!customer.RegisterUserRole">
														Select Role
													</option>
													<option *ngFor="let customerRole of this.getRoles()" [selected]="
															customer.RegisterUserRole == customerRole.id
														" [value]="customerRole.id">
														{{ customerRole.name }}
													</option>
												</select>
												<div class="form-text">
													Select the User Level which self-registered users will
													be assigned
												</div>
											</div>
										</div>
										<div class="col-12" *ngIf="customer.RegisterEnabled">
											<div class="form-group">
												<label for="registerLink" class="form-label">Registration Link</label>
												<a class="form-control" target="_blank" [href]="getRegisterURL()">{{ getRegisterURL() }}</a>
												<div class="form-text">
													Share this link with appropriate people to register.
													Rebuilding this link will invaildate the old one.
												</div>
												<div class="form-text">
													Note: You must save before this link will become
													activated.
												</div>
											</div>
										</div>
										<div class="col-12" *ngIf="customer.RegisterEnabled">
											<div class="form-group">
												<button class="btn btn-primary" type="button" (click)="updateRegistrationSecret(true)">
													ReBuild
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="tab-pane fade" [class.show]="activeClassId == 3" [class.active]="activeClassId == 3">
						<purchase-mode-edit *ngIf="customer" [customer]="customer"></purchase-mode-edit>
					</div>
					<div class="tab-pane fade" [class.show]="activeClassId == 4" [class.active]="activeClassId == 4">
						<div class="edit-users">
							<div>
								<price-discount-edit *ngIf="customer" [customer]="customer"></price-discount-edit>
								<customer-user-field *ngIf="customer && customer.id" [customer]="customer.id"></customer-user-field>
							</div>
							<div>
								<div class="edit-card card" *ngIf="
										customer &&
										customer.reminders &&
										customer.reminders.rows.length > 0
									">
									<div class="card-head">
										<h4 class="head-title">
											<span>
												<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
													<path
														d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z"
														fill="white" stroke="#272835" stroke-width="1.5" stroke-linecap="round"
														stroke-linejoin="round" />
													<path d="M10 5.5V10L13 11.5" stroke="#272835" stroke-width="1.5" stroke-linecap="round"
														stroke-linejoin="round" />
												</svg>
											</span>
											Reminders
										</h4>
									</div>
									<div class="card-body">
										<div class="row" *ngFor="
												let reminder of customer.reminders.rows;
												let i = index
											">
											<div class="col-12 col-lg-5 col-xxl-6">
												<div class="form-group">
													<label for="Number" class="form-label">Number of units</label>
													<input type="number" class="form-control" disabled="true" value="{{ reminder.noOfUnit }}"
														placeholder="0" disabled="true" value="{{ reminder.noOfUnit }}" placeholder="0" />
												</div>
											</div>
											<div class="col-12 col-lg-5">
												<div class="form-group">
													<label for="Number2" class="form-label">Number of units</label>
													<input type="number2" class="form-control" disabled="true" value="{{ reminder.noOfUnit }}"
														placeholder="0" disabled="true" value="{{ reminder.unit }}" placeholder="0" />
												</div>
											</div>
											<div class="col-12 col-lg-2 col-xxl-1 align-self-end">
												<div class="form-group">
													<button class="btn btn-secondary-icon" type="button" (click)="removeReminder(i)">
														<span>
															<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
																fill="none">
																<path d="M3.25 5.5H4.75H16.75" stroke="#666D80" stroke-width="1.5"
																	stroke-linecap="round" stroke-linejoin="round" />
																<path
																	d="M7 5.5V4C7 3.60218 7.15804 3.22064 7.43934 2.93934C7.72064 2.65804 8.10218 2.5 8.5 2.5H11.5C11.8978 2.5 12.2794 2.65804 12.5607 2.93934C12.842 3.22064 13 3.60218 13 4V5.5M15.25 5.5V16C15.25 16.3978 15.092 16.7794 14.8107 17.0607C14.5294 17.342 14.1478 17.5 13.75 17.5H6.25C5.85218 17.5 5.47064 17.342 5.18934 17.0607C4.90804 16.7794 4.75 16.3978 4.75 16V5.5H15.25Z"
																	stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
															</svg>
														</span>
													</button>
												</div>
											</div>
											<div class="col-12">
												<ul class="list-view">
													<li>
														<p class="form-text">
															This reminder will remind users
															{{
															reminder.noOfUnit > 0
															? reminder.noOfUnit +
															" after " +
															reminder.unit
															: reminder.noOfUnit * -1 +
															" " +
															reminder.unit +
															" before"
															}}
															allocation reset.
														</p>
													</li>
												</ul>
											</div>
										</div>
									</div>
								</div>
								<div class="edit-card card">
									<div class="card-head">
										<h4 class="head-title">
											<span>
												<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
													<path
														d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z"
														fill="white" stroke="#272835" stroke-width="1.5" stroke-linecap="round"
														stroke-linejoin="round" />
													<path d="M10 5.5V10L13 11.5" stroke="#272835" stroke-width="1.5" stroke-linecap="round"
														stroke-linejoin="round" />
												</svg>
											</span>
											Add reminders
										</h4>
									</div>
									<div class="card-body">
										<div class="row">
											<div class="col-12 col-md-6 col-xxl-3">
												<div class="form-group">
													<label for="Number" class="form-label">Number of units</label>
													<input #units type="number" (change)="reminder.noOfUnit = +units.value" class="form-control"
														id="Number" placeholder="0" [(ngModel)]="reminder.noOfUnit" />
												</div>
											</div>
											<div class="col-12 col-md-6">
												<div class="form-group">
													<label for="Unit" class="form-label">Select unit
													</label>
													<select #reminders class="form-select" aria-label="Default select example" id="Unit"
														(change)="reminder.unit = reminders.value">
														<option [selected]="!reminder.unit">
															Select unit
														</option>
														<option *ngFor="let unit of reminderUnitsOptions" [value]="unit.key"
															[selected]="reminder.unit == unit.key">
															{{ unit.value }}
														</option>
													</select>
												</div>
											</div>
											<div class="col-12 col-xxl-3 align-self-end">
												<div class="form-group">
													<button class="btn btn-primary w-100" type="button" (click)="addReminder()">
														Add Reminder
													</button>
												</div>
											</div>
											<div class="col-12">
												<ul class="list-view">
													<li>
														<p class="form-text">
															Any value above 0 will send reminders after
															allocation reset.
														</p>
													</li>
													<li>
														<p class="form-text">
															Any value below 0 will send reminders before
															allocation reset.
														</p>
													</li>
													<li>
														<p class="form-text">
															E.G. a value of 1 day will send the reminder 1 day
															after reset. A value of -1 day will send the
															reminder 1 day before reset.
														</p>
													</li>
												</ul>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="wizard-footer">
					<button class="btn btn-secondary" type="button" [routerLink]="['/manage/customer']">
						Cancel
					</button>
					<div class="d-flex flex-column flex-md-row align-items-center justify-content-end gap-4 gap-md-2">
						<button class="btn btn-primary" type="button" (click)="saveCustomer()">
							{{ activeClassId >= 4 ? "Save" : "Continue" }}
							<span>
								<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
									<path d="M8.2002 13.6004L11.8002 10.0004L8.2002 6.40039" stroke="white" stroke-width="1.5"
										stroke-linecap="round" stroke-linejoin="round" />
								</svg>
							</span>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>