<div class="main-right-content">
	<!-- manage-user main start here... -->
	<div class="manageUser">
		<div class="d-flex align-items-start justify-content-between">
			<h1 class="title">System</h1>
		</div>

		<div class="manage-user-list">
			<div class="edit-users">
				<div class="edit-card card">
					<div class="card-head">
						<h4 class="head-title">
							<span>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="20"
									height="20"
									viewBox="0 0 20 20"
									fill="none"
								>
									<path
										d="M16 16.75V15.25C16 14.4544 15.6839 13.6913 15.1213 13.1287C14.5587 12.5661 13.7956 12.25 13 12.25H7C6.20435 12.25 5.44129 12.5661 4.87868 13.1287C4.31607 13.6913 4 14.4544 4 15.25V16.75"
										fill="white"
									/>
									<path
										d="M10 9.25C11.6569 9.25 13 7.90685 13 6.25C13 4.59315 11.6569 3.25 10 3.25C8.34315 3.25 7 4.59315 7 6.25C7 7.90685 8.34315 9.25 10 9.25Z"
										fill="white"
									/>
									<path
										d="M16 16.75V15.25C16 14.4544 15.6839 13.6913 15.1213 13.1287C14.5587 12.5661 13.7956 12.25 13 12.25H7C6.20435 12.25 5.44129 12.5661 4.87868 13.1287C4.31607 13.6913 4 14.4544 4 15.25V16.75M13 6.25C13 7.90685 11.6569 9.25 10 9.25C8.34315 9.25 7 7.90685 7 6.25C7 4.59315 8.34315 3.25 10 3.25C11.6569 3.25 13 4.59315 13 6.25Z"
										stroke="#272835"
										stroke-width="1.5"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
								</svg>
							</span>
							System Functions
						</h4>
					</div>
					<div class="card-body">
						<form action="">
							<div class="row">
								<div class="col-12 col-lg-6">
									<div class="form-group">
										<button
											class="btn btn-secondary w-100"
											type="button"
											(click)="executeEmployeeAllocations()"
										>
											Process Employee Allocations
										</button>
									</div>
								</div>
								<div class="col-12 col-lg-6">
									<div class="form-group">
										<button
											class="btn btn-secondary w-100"
											type="button"
											(click)="syncDiscountRates()"
										>
											Sync Customer Discount Amount
										</button>
									</div>
								</div>
								<div class="col-12">
									<div class="form-group">
										<button
											class="btn btn-secondary"
											type="button"
											(click)="syncProductPrice()"
										>
											Sync All products Base Price (Default Sell Price)
										</button>
									</div>
								</div>
								<div class="col-12">
									<div class="form-group">
										<label for="ProductId" class="form-label">Product ID</label>
										<input
											type="number"
											class="form-control"
											id="ProductId"
											name="productId"
											[(ngModel)]="syncProductId"
										/>
										<div id="requiredHelp" class="form-text">
											Each of these processes will cause the server to begin
											modifying data and may not necessesarily finish for some
											time until the process is complete. These should not be
											executed repeatedly.
										</div>
									</div>
								</div>
								<div class="col-12">
									<div class="form-group">
										<button
											class="btn btn-secondary"
											type="button"
											(click)="syncProductPrice(syncProductId)"
										>
											Sync Specific products Base Price (Default Sell Price)
										</button>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
				<div>
					<div class="edit-card card">
						<div class="card-head">
							<h4 class="head-title">
								<span>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="20"
										height="20"
										viewBox="0 0 20 20"
										fill="none"
									>
										<path
											d="M16 16.75V15.25C16 14.4544 15.6839 13.6913 15.1213 13.1287C14.5587 12.5661 13.7956 12.25 13 12.25H7C6.20435 12.25 5.44129 12.5661 4.87868 13.1287C4.31607 13.6913 4 14.4544 4 15.25V16.75"
											fill="white"
										/>
										<path
											d="M10 9.25C11.6569 9.25 13 7.90685 13 6.25C13 4.59315 11.6569 3.25 10 3.25C8.34315 3.25 7 4.59315 7 6.25C7 7.90685 8.34315 9.25 10 9.25Z"
											fill="white"
										/>
										<path
											d="M16 16.75V15.25C16 14.4544 15.6839 13.6913 15.1213 13.1287C14.5587 12.5661 13.7956 12.25 13 12.25H7C6.20435 12.25 5.44129 12.5661 4.87868 13.1287C4.31607 13.6913 4 14.4544 4 15.25V16.75M13 6.25C13 7.90685 11.6569 9.25 10 9.25C8.34315 9.25 7 7.90685 7 6.25C7 4.59315 8.34315 3.25 10 3.25C11.6569 3.25 13 4.59315 13 6.25Z"
											stroke="#272835"
											stroke-width="1.5"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
									</svg>
								</span>
								Server Message
							</h4>
						</div>
						<div class="card-body">
							<div class="row">
								<div class="col-12">
									<div class="form-group">
										<label for="messageContent" class="form-label"
											>Message Content</label
										>
										<input
											type="text"
											class="form-control"
											id="messageContent"
											name="content"
											placeholder="Our latest round of updates will be deployed this evening between 7PM and 8PM"
											[(ngModel)]="serverMessage"
										/>
									</div>
								</div>
								<div class="col-12">
									<div class="form-group">
										<label for="Action" class="form-label"
											>Message Severity
										</label>
										<select
											#severity
											class="form-select"
											aria-label="Default select example"
											id="Action"
											(change)="serverMessageSeverity = severity.value"
										>
											<option [selected]="!serverMessageSeverity" value="disabled">
												Select an Alert Level
											</option>
											<option
												*ngFor="let data of allOptions"
												[value]="data.id"
												[selected]="serverMessageSeverity == data.id"
											>
												<button class="btn btn-{{ data.id }}" type="button">
													{{ data.text }}
												</button>
											</option>
										</select>
									</div>
								</div>
								<div class="col-12">
									<button
										class="btn btn-primary w-sm-100"
										type="button"
										(click)="setServerMessage()"
									>
										Set Server Message
										<span>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="20"
												height="20"
												viewBox="0 0 20 20"
												fill="none"
											>
												<path
													d="M8.2002 13.6004L11.8002 10.0004L8.2002 6.40039"
													stroke="white"
													stroke-width="1.5"
													stroke-linecap="round"
													stroke-linejoin="round"
												/>
											</svg>
										</span>
									</button>
								</div>
							</div>
						</div>
					</div>
					<div class="edit-card card">
						<div class="card-head">
							<h4 class="head-title">
								<span>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="20"
										height="20"
										viewBox="0 0 20 20"
										fill="none"
									>
										<path
											d="M16 16.75V15.25C16 14.4544 15.6839 13.6913 15.1213 13.1287C14.5587 12.5661 13.7956 12.25 13 12.25H7C6.20435 12.25 5.44129 12.5661 4.87868 13.1287C4.31607 13.6913 4 14.4544 4 15.25V16.75"
											fill="white"
										/>
										<path
											d="M10 9.25C11.6569 9.25 13 7.90685 13 6.25C13 4.59315 11.6569 3.25 10 3.25C8.34315 3.25 7 4.59315 7 6.25C7 7.90685 8.34315 9.25 10 9.25Z"
											fill="white"
										/>
										<path
											d="M16 16.75V15.25C16 14.4544 15.6839 13.6913 15.1213 13.1287C14.5587 12.5661 13.7956 12.25 13 12.25H7C6.20435 12.25 5.44129 12.5661 4.87868 13.1287C4.31607 13.6913 4 14.4544 4 15.25V16.75M13 6.25C13 7.90685 11.6569 9.25 10 9.25C8.34315 9.25 7 7.90685 7 6.25C7 4.59315 8.34315 3.25 10 3.25C11.6569 3.25 13 4.59315 13 6.25Z"
											stroke="#272835"
											stroke-width="1.5"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
									</svg>
								</span>
								Customer Merge
							</h4>
						</div>
						<div class="card-body">
							<form action="">
								<div class="row">
									<div class="col-12">
										<div class="form-group">
											<label for="SourceCustomer" class="form-label"
												>Source Customer</label
											>
											<select
												#customer1
												class="form-select"
												aria-label="Default select example"
												id="selectCustomer"
												(change)="sourceCustomer = customer1.value"
											>
												<option selected="">Select a Customer</option>
												<option
													*ngFor="let data of customerSelectOptions"
													[value]="data.id"
												>
													{{ data.text }}
												</option>
											</select>
										</div>
									</div>
									<div class="col-12">
										<div class="form-group">
											<label for="DestinationCustomer" class="form-label"
												>Destination Customer</label
											>
											<select
												#customer2
												class="form-select"
												aria-label="Default select example"
												id="selectCustomer"
												(change)="destinationCustomer = customer2.value"
											>
												<option selected="">Select a Customer</option>
												<option
													*ngFor="let data of customerSelectOptions"
													[value]="data.id"
												>
													{{ data.text }}
												</option>
											</select>
										</div>
									</div>
									<div class="col-12">
										<button
											class="btn btn-primary w-sm-100"
											type="button"
											(click)="prepareCustomerMerge()"
											[disabled]="!sourceCustomer || !destinationCustomer"
										>
											Merge Customers
											<span>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="20"
													height="20"
													viewBox="0 0 20 20"
													fill="none"
												>
													<path
														d="M8.2002 13.6004L11.8002 10.0004L8.2002 6.40039"
														stroke="white"
														stroke-width="1.5"
														stroke-linecap="round"
														stroke-linejoin="round"
													/>
												</svg>
											</span>
										</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- manage-user main end here... -->
</div>
