<header class="header position-relative border-bottom-1">
	<div class="max-width-container">
		<div class="backs d-flex align-items-center justify-content-start my-0 my-lg-4">
			<a [routerLink]="['/cart']" class="btn btn-back p-0 gap-3" role="button">
				<span class="shadow-none p-0 w-auto h-auto">
					<svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
						<path d="M4.8002 8.60039L1.2002 5.00039L4.8002 1.40039" stroke="#666D80" stroke-width="1.5"
							stroke-linecap="round" stroke-linejoin="round"></path>
					</svg>
				</span>
				Back to cart
			</a>
			<!-- Main-logo Checkout start here... -->
			<div class="logo-box mx-md-auto">
				<a [routerLink]="['/home']" class="navbar-brand webheader-logo"><img src="../../../../assets/images/logo.svg"
						alt="" /></a>
			</div>
			<!-- Main-logo Checkout end here... -->
		</div>
	</div>
</header>

<section class="product-detail-container pt-lg-0">
	<div class="max-width-container">
		<div class="container">
			<div class="checkout-content">
				<div class="details-fill">
					<div class="product-list-head mb-45">
						<h4 class="main-title">Checkout</h4>
					</div>
					<div class="checkout-fill-content">
						<h4 class="brand-title">
							<span>
								<svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
									<path
										d="M5.6425 7.26222C7.84212 6.21023 9.56357 4.42504 10.5518 2.19354C9.91423 1.52409 9.08539 1.04591 8.19279 0.759002L7.71461 0.599609C7.68273 0.663366 7.65085 0.759006 7.65085 0.822763C6.88577 2.9905 5.32372 4.74382 3.21974 5.73206C1.75332 6.43339 0.66945 7.70853 0.159393 9.2387L0 9.71688C0.0637571 9.74875 0.159394 9.78063 0.223151 9.78063C0.828844 10.0038 1.43454 10.2907 2.00835 10.6414C2.80531 9.20682 4.08045 7.99543 5.6425 7.26222Z"
										fill="black" />
									<path opacity="0.8"
										d="M14.8866 7.39062C13.7071 9.17582 12.0813 10.6104 10.073 11.5667C8.44717 12.3318 7.20391 13.7663 6.6301 15.4878L6.4707 16.0297C7.0764 16.5716 7.8096 16.9861 8.60656 17.2411L9.08474 17.4005C9.11662 17.3367 9.1485 17.2411 9.1485 17.1773C9.91358 15.0096 11.4756 13.2563 13.5796 12.268C15.046 11.5667 16.1618 10.2916 16.64 8.76141L16.7994 8.28323C16.1299 8.09196 15.4923 7.77317 14.8866 7.39062Z"
										fill="black" />
									<path opacity="0.9"
										d="M5.19633 14.3689C5.99329 12.4562 7.45971 10.8941 9.34054 10.0015C11.062 9.1727 12.4965 7.89756 13.4848 6.33551C12.7515 5.63418 12.1459 4.83722 11.6996 3.88086C10.52 6.0486 8.67109 7.80193 6.40772 8.8858C5.1007 9.49149 4.0487 10.5435 3.41113 11.8186C4.11246 12.4881 4.68628 13.3169 5.13258 14.2414C5.13258 14.2414 5.16445 14.3052 5.19633 14.3689Z"
										fill="black" />
								</svg>
							</span>
							{{ $firstName | async }}
						</h4>
						<hr class="bg-dash-gap" />
						<div class="edit-card card shadow-none rounded-0">
							<h4 *ngIf="message != null" class="text-danger fs-4">
								{{ message }}
							</h4>
							<app-cart-checkout-contact #checkoutContact [attrs]="cartService.cart.attrs" [message]="message"
								[messageSeverity]="messageSeverity" (addressChanged)="addressUpdated()"></app-cart-checkout-contact>

							<app-payment-method #paymentMethod [ccTransaction]="ccTransaction"></app-payment-method>

							<div class="row">
								<div class="col-12" *ngIf="allowPackOrderNames">
									<div class="uploader">
										<div>
											<h5 class="form-title">Pack Orders Into Names</h5>
											<div class="form-text">
												Would you like us to pack your order into names?
											</div>
										</div>
										<div class="form-group w-100">
											<label for="logoFile" class="btn btn-secondary w-100">Upload documents</label>
											<input class="form-control d-none" type="file" id="logoFile"
												(change)="persistS3Documents($event)" />
										</div>
										<div *ngIf="getSupportingDocuments().length" class="preview-content h-100">
											<a *ngFor="
													let document of getSupportingDocuments();
													let i = index
												" [href]="document.s3Url" target="_blank">
												{{ document.fileName }}
											</a>
										</div>
									</div>
								</div>
								<custom-user-field *ngIf="customerUserId" [customerId]="customerId" [customerUserId]="customerUserId"
									[isCheckout]="true" #customUserFieldForm></custom-user-field>
								<div class="col-12">
									<button class="btn btn-secondary rounded-0 shadow-none ship-btn" type="button"
										data-bs-toggle="collapse" data-bs-target="#shippingAddress" aria-expanded="false"
										aria-controls="shippingAddress">
										<span>
											<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
												<path class="path-opacity" d="M10 4.75V15.25" stroke="#36394A" stroke-width="1.5"
													stroke-linecap="round" stroke-linejoin="round" />
												<path d="M4.75 10H15.25" stroke="#36394A" stroke-width="1.5" stroke-linecap="round"
													stroke-linejoin="round" />
											</svg>
										</span>
										Shipping notes
									</button>
									<div class="collapse" id="shippingAddress">
										<div class="edit-card card mt-3 card-body">
											<div class="form-group">
												<label for="shippingAdd" class="form-label">
													Add Notes
												</label>
												<input class="form-control" type="text" id="shippingAdd" name="notes"
													[(ngModel)]="cartService.cart.attrs.notes" />
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-12 col-md-10">
									<div class="form-group form-check">
										<input class="form-check-input" id="Approval4" [value]="true" type="checkbox" checked="checked"
											(click)="checkoutConfirmed = false" *ngIf="checkoutConfirmed" />
										<input class="form-check-input" id="Approval4" [value]="false" type="checkbox"
											(click)="checkoutConfirmed = true" *ngIf="!checkoutConfirmed" />
										<label class="form-check-label d-inline mb-0 pt-1" for="Approval4"><span class="d-none">I agree with
												the</span> I have
											reviewed my cart contents and confirmed that the selected
											garments and sizes are accurate.
										</label>
									</div>
								</div>
								<div class="col-12 col-md-10" *ngIf="globals.loggedIn && globals.isEmulatingUser">
									<div class="form-group form-check">
										<input class="form-check-input" id="getEmail" [value]="false" type="checkbox"
											[(ngModel)]="getOrderEmail" />
										<label class="form-check-label d-inline mb-0 pt-1" for="getEmail">Send order confirmation to "{{globals.User.actual && globals.User.actual.email}}"
										</label>
									</div>
								</div>
								<div class="col-12">
									<button (click)="placeOrder()" class="btn btn-primary w-100" role="button">
										Place order
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="checkout-product">
					<div class="card edit-card">
						<div class="card-head">
							<h4 class="head-title gap-1">
								Products
								<span class="badge bg-alpha-25 py-2 px-3">{{
									cartService.items.length
									}}</span>
							</h4>
						</div>
						<div class="card-body">
							<div class="checkout-items">
								<app-cart-checkout-items *ngFor="let item of cartService.items" [item]="item"
									class="row product-grid_detail" [showPrice]="anyItemHasPrice()"></app-cart-checkout-items>
							</div>
						</div>
					</div>
					<div class="card cart-total edit-card">
						<div class="card-head">
							<h4 class="head-title">Order details</h4>
						</div>
						<div class="balance-content">
							<ul>
								<li *ngIf="totalBalance > 0">
									<p>Current balance</p>
									<p>${{ utils.twoDecimalPlacesString(totalBalance) }}</p>
								</li>
								<li *ngIf="totalBalance > 0">
									<p>After payment</p>
									<p>
										${{
										utils.twoDecimalPlacesString(
										totalBalance - this.paymentMethod.balance
										)
										}}
									</p>
								</li>
							</ul>
						</div>
						<div class="card-body">
							<ul>
								<li>
									<p>Account balance</p>
									<p>
										{{ this.paymentMethod.balance > 0 ? "-" : "" }}${{
										utils.twoDecimalPlacesString(this.paymentMethod.balance)
										}}
									</p>
								</li>
								<li>
									<p>Subtotal</p>
									<p>
										${{
										utils.twoDecimalPlacesString(cartService.getSubtotal())
										}}
									</p>
								</li>
								<li>
									<p>Small Order Fee</p>
									<p>
										${{
										utils.twoDecimalPlacesString(cartService.getHandlingFee())
										}}
									</p>
								</li>
								<li>
									<p>GST</p>
									<p>
										${{ utils.twoDecimalPlacesString(cartService.getTax()) }}
									</p>
								</li>
							</ul>
							<hr class="bg-dash" />
							<ul *ngIf="anyItemHasPrice()">
								<li>
									<p class="fw-bold">Total</p>
									<p class="fw-bold">
										${{
										utils.twoDecimalPlacesString(
										cartService.getGrandTotal() - this.paymentMethod.balance
										)
										}}
									</p>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<app-footer></app-footer>