import { Component, ElementRef, OnDestroy, OnInit, Query, ViewChild } from '@angular/core';
import { Subject, combineLatest, distinctUntilChanged, takeUntil } from 'rxjs';
import { SessionApi } from '../api/session.api';
import { IQueryFilter, QueryResult } from '../model/query.filter.class';
import { CartAttrs } from '../model/cart.model';
import { CartService } from '../services/cart.service';
import { User } from '@sentry/angular-ivy';
import { ProductService } from '../services/product.service';
import { NewProduct } from '../model/ddb.model';
import { Utils } from '../util/utils';

import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexDataLabels,
  ApexStroke,
  ApexYAxis,
  ApexTitleSubtitle,
  ApexLegend,
  ApexGrid,
  ApexResponsive
} from "ng-apexcharts";
import { AuthService } from '../services/auth.service';
import { SecurityService } from '../services/security.service';
import { GlobalApi } from '../api/global.api';
import { UnleashedCustomerExtended } from '../model/unleashed.model';
import { AuthApi } from '../api/auth.api';
import { NotificationsService } from 'angular2-notifications';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CollectionService } from '../services/collection.service';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  stroke: ApexStroke;
  dataLabels: ApexDataLabels;
  yaxis: ApexYAxis;
  title: ApexTitleSubtitle;
  labels: string[];
  legend: ApexLegend;
  subtitle: ApexTitleSubtitle;
  grid: ApexGrid;
};
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./_dashboard.component.sass']
})
export class DashboardComponent implements OnInit, OnDestroy {
  public user: User;
  public completedOrderCount: number;
  public completionPercentage: number;
  public backOrderCount: number;
  public approveOrderCount: number;
  public totalOrderCount: number;
  public todayOrder: number;
  public customerId: number | undefined = undefined;
  public actualUserId: number;
  public orderPath = '/account/orders';
  public products: { product: NewProduct, count: number }[] = [];
  public isSwitch: boolean;
  query: IQueryFilter = new IQueryFilter({
    filter: {
      $and: [{
        status: {
          $ne: "PENDING"
        }
      }, {
        status: {
          $ne: "DELETED"
        }
      }]
    },
    sortBy: 'updated_at',
    order: 'desc'
  });
  queryResult: QueryResult<CartAttrs> = new QueryResult();
  chartQuery: IQueryFilter = new IQueryFilter({
    limit: 1
  });
  public isLoading: boolean;
  @ViewChild("chart") chart: ChartComponent;
  private unsubscribe$ = new Subject<void>();
  public chartOptions: Partial<{
    series: ApexAxisChartSeries;
    chart: ApexChart;
    xaxis: ApexXAxis;
    stroke: ApexStroke;
    dataLabels: ApexDataLabels;
    yaxis: ApexYAxis;
    title: ApexTitleSubtitle;
    labels: string[];
    legend: ApexLegend;
    subtitle: ApexTitleSubtitle;
    grid: ApexGrid,
    responsive: ApexResponsive[]
  }> = {};
  public productQuery = new IQueryFilter({ limit: 5 });
  public total: number = 0;
  public fetchedChatData: number;
  public totalGrandTotal: number = 0;
  public canShowChart: boolean = false;
  hasOwnDetailAccess: boolean = false;
  @ViewChild('cancel_model') public cancel_model: ElementRef;
  public cancelReason: string;
  public deletedOrderId: number;
  public isShowAllocation: number;

  constructor(
    private readonly session: SessionApi,
    private cartService: CartService,
    private productService: ProductService,
    public utils: Utils,
    public authService: AuthService,
    public securityService: SecurityService,
    public globals: GlobalApi,
    public authApi: AuthApi,
    public collectionService: CollectionService,
    private notifications: NotificationsService
  ) {
    this.chartOptions = {
      chart: {
        type: "area",
        height: 400,
        width: 1200,
        zoom: {
          enabled: false
        }
      }
    };
  }


  ngOnInit(): void {
    this.checkUser();
    this.fetchTopProducts();
  }

  sortCustomers() {
    this.globals.customerAccess.sort((a, b) => {
      if (a.Guid === this.globals.customer?.Guid) return -1;
      if (b.Guid === this.globals.customer?.Guid) return 1;
      return 0;
    });

    return this.globals.customerAccess;
  }

  monitorAccess() {
    this.securityService.hasReportsAccess().pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(hasReportAccess => {
      this.canShowChart = this.securityService.isAdmin() || hasReportAccess;
      if (this.canShowChart) {
        this.fetchChartData();
      }
    })
    this.securityService.hasOwnDetailAccess().pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(hasOwnDetailAccess => this.hasOwnDetailAccess = hasOwnDetailAccess);
  }


  checkRoute() {
    window.scroll(0, 0);
  }

  loadOrders() {
    if (this.authService.isAuthenticated()) {
      this.cartService.getOrderAttrsList(this.query)
        .pipe(
          takeUntil(this.unsubscribe$)
        )
        .subscribe(queryResult => {
          this.isLoading = false;
          this.queryResult = queryResult;
          this.totalOrderCount = queryResult.totalOrders;
          this.completedOrderCount = queryResult.completedOrders;
          this.backOrderCount = queryResult.backOrders;
          this.approveOrderCount = queryResult.approvalOrder;
          this.completionPercentage = this.totalOrderCount > 0 ? (this.completedOrderCount / this.totalOrderCount) * 100 : 0;
          this.todayOrder = queryResult.todayOrder;
        });
    }
  }

  checkUser() {
    combineLatest(
      this.session.$userData.pipe(distinctUntilChanged())
    ).pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(([userData]) => {
      this.monitorAccess();
      this.total = 0;
      if (userData) {
        this.user = userData;
        if (this.user.isAdmin) {
          this.orderPath = '/manage/orders';
        }
      }
      if (!userData?.isAdmin && userData?.customerUsers?.length) {
        if (this.session.$jwtData.value?.emulateCustomerUserId) {
          this.actualUserId = this.session.$jwtData.value?.emulateCustomerUserId;
        }
        this.customerId = this.session.$customerData.value?.id;
        this.collectionService.getCollections().subscribe(avlCollection => {
          this.isShowAllocation = avlCollection.reduce((total, coll) => {
            return total + (coll.allocationCount || 0);
          }, 0);
        })
      }
      this.loadOrders();
    });
  }

  updateTotal(total: number) {
    this.total = total;
  }

  /**
 * @description UI helper method to determine if the order approval functionality should be presented to the user
 *
 * @param item
 */
  showOrderApproval(item: CartAttrs) {
    return (
      item.status.toUpperCase() === 'APPROVAL'
      && (
        this.user.isAdmin
        || this.isApprovingManager(item)
      )
    );
  }

  /**
  /**
   * @description UI Helper method to determine if the current user is the approving manager
   *
   * @param item
   */
  isApprovingManager(item: CartAttrs) {
    if (!this.globals.User) return false;
    return item.approvedById && this.globals.User.actualUserId === item.approvedById;
  }

  public get isAdmin(): boolean {
    return !!this.globals.isAdmin;
  }

  /**
 * @description Approves an order
 *
 * @param item
 */
  approveOrder(item: CartAttrs) {
    this.cartService.approveCart(item.orderId)
      .pipe(
        takeUntil(this.unsubscribe$)
      )
      .subscribe(() => {
        this.loadOrders();
      },
        (err) => {
          console.log(err);
        })
  }


  onSaveCompleted() {
    this.loadOrders();
  }

  fetchTopProducts() {
    this.productService.getTopProducts(this.productQuery).pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(res => {
      this.products = res;
    })
  }

  fetchChartData() {
    let chartSeries: { name: string, data: number[] }[] = [];
    this.cartService.fetchChartData(this.chartQuery).pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe((res) => {
      this.fetchedChatData = res.length;
      res.forEach((obj) => {
        let grand_total: number[] = [];
        for (let data of Object.values(obj)) {
          this.totalGrandTotal += data.grand_total;
          grand_total.push(data.grand_total);
        }
        chartSeries.push({
          name: Object.values(obj)[0].date.substring(0, 7),
          data: grand_total
        });

        grand_total = [];
      });
      this.chartOptions = {
        series: chartSeries,
        chart: {
          type: "area",
          height: 400,
          width: '100%',
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: "smooth"
        },
        xaxis: {
          type: "category",
          labels: {
            formatter: function (val) {
              return val;
            }
          }
        },
        yaxis: {
          show: true,
          min: 0,
          labels: {
            show: true,
            formatter: function (val) {
              return '$' + val;
            },
          },
        },
        legend: {
          horizontalAlign: "right"
        },
        grid: {
          position: 'back',
          borderColor: "gray",
          strokeDashArray: 5,
          xaxis: {
            lines: {
              show: false,
            }
          },
          yaxis: {
            lines: {
              show: true
            }
          }

        },
        responsive: [{
          breakpoint: 576,
          options: {
            chart: {
              type: "area",
              height: 250,
              width: 600,
              zoom: {
                enabled: false
              }
            },
            grid: {
              position: 'back',
              borderColor: "gray",
              strokeDashArray: 5,
              xaxis: {
                lines: {
                  show: false,
                }
              },
              yaxis: {
                lines: {
                  show: true
                }
              }

            }
          }
        }, {
          breakpoint: 900, options: {
            grid: {
              position: 'back',
              borderColor: "gray",
              strokeDashArray: 5,
              xaxis: {
                lines: {
                  show: false,
                }
              },
              yaxis: {
                lines: {
                  show: true
                }
              }

            }
          }
        }]
      };
    })
  }

  getDefaultChart(): ApexChart {
    return {
      type: "area",
      height: 350,
      zoom: {
        enabled: false
      }
    };
  }

  getDefaultResp() {
    return [{
      breakpoint: 576,
      options: {
        chart: {
          type: "area",
          height: 250,
          width: 600,
          zoom: {
            enabled: false
          }
        },
        grid: {
          position: 'back',
          borderColor: "gray",
          strokeDashArray: 5,
          xaxis: {
            lines: {
              show: false,
            }
          },
          yaxis: {
            lines: {
              show: true
            }
          }

        }
      }
    },
    {
      breakpoint: 900, options: {
        grid: {
          position: 'back',
          borderColor: "blue",
          strokeDashArray: 5,
          xaxis: {
            lines: {
              show: false,
            }
          },
          yaxis: {
            lines: {
              show: true
            }
          }

        }
      }
    }]
  }

  setChartType(limit: string) {
    this.chartQuery.limit = +limit;
    this.totalGrandTotal = 0;
    this.fetchChartData();
  }

  setQuery(limit: string) {
    this.productQuery.limit = +limit;
    this.fetchTopProducts();
  }

  public switchCustomer(customer: UnleashedCustomerExtended) {
    if (customer.id) {
      this.isSwitch = true;
      this.authService.switchUser(customer.id, this.globals.User.actualUserId).pipe(
        takeUntil(this.unsubscribe$)
      ).subscribe((res) => {
        this.collectionService.loadCollections();
        this.notifications.success('Switch', 'Your access was updated');
        this.isSwitch = false;
      }, err => {
        this.isSwitch = false;
        this.notifications.error('Switch', 'Something wrong');
      });
    }
  }

  fetchColor(status: string) {
    if (status === "PROCESSING") {
      return 'aqua';
    }
    if (status === "COMPLETED") {
      return 'green';
    }
    if (status === "BACKORDERED") {
      return 'orange';
    }
    if (status === "APPROVAL") {
      return 'purple';
    }
    if (status === "DELETED") {
      return 'red';
    }
    if (status === "PLACED") {
      return 'blue';
    }
    if (status === "REJECTED") {
      return 'brown';
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public capitalizeFirstLetter = (str: string) => {
    str = str.toLowerCase();
    return str ? str[0].toUpperCase() + str.slice(1) : '';
  };
}
