<div class="main-right-content">
	<!-- manage-user main start here... -->
	<div class="manageUser">
		<div class="d-flex align-items-start justify-content-between">
			<h1 class="title">{{ language.pageTitle || "Edit" }}</h1>
		</div>

		<div class="manage-user-list">
			<!-- category for lg screen start here... -->
			<div class="manage-category d-none d-md-block">
				<div class="category-head">
					<p>Name</p>
					<p *ngIf="canCascade">Level</p>
					<p>Action</p>
				</div>
				<div class="category-container">
					<ul>
						<li *ngFor="let item of theEnum; let topIdx = index">
							<div class="first-list">
								<div class="user-name">
									<h5 class="d-flex align-items-center gap-2">
										<button
											*ngIf="canCascade"
											class="btn btn-secondary-icon btn-table shadow-none"
											type="button"
											[attr.data-bs-toggle]="'collapse'"
											[attr.data-bs-target]="'#collapse' + topIdx"
											aria-expanded="false"
											aria-controls="tableCollapse1"
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="20"
												height="20"
												viewBox="0 0 20 20"
												fill="none"
											>
												<path
													d="M4.75 10H15.25"
													stroke="#818898"
													stroke-width="1.5"
													stroke-linecap="round"
													stroke-linejoin="round"
												/>
												<path
													d="M10 4.75L15.25 10L10 15.25"
													stroke="#818898"
													stroke-width="1.5"
													stroke-linecap="round"
													stroke-linejoin="round"
												/>
											</svg>
										</button>
										<div class="form-group w-100">
											<input
												type="text"
												name=""
												id="category1"
												class="form-control"
												[(ngModel)]="item.name"
												[disabled]="!item.isEdit"
											/>
										</div>
									</h5>
								</div>
								<div *ngIf="canCascade" class="level">
									<span class="badge bg-aqua-25">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="20"
											height="20"
											viewBox="0 0 20 20"
											fill="none"
										>
											<circle cx="10" cy="10" r="2" fill="transparent"></circle>
										</svg>
										{{ item.level }}</span
									>
								</div>
								<div class="edit">
									<div
										*ngIf="!item.isEdit"
										class="d-flex align-items-center justify-content-start"
									>
										<a
											(click)="openRemoveModal(item, delete_confirm_modal)"
											class="btn btn-delete"
											role="button"
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="20"
												height="20"
												viewBox="0 0 20 20"
												fill="none"
											>
												<path
													d="M3.25 5.5H4.75H16.75"
													stroke="#818898"
													stroke-width="1.5"
													stroke-linecap="round"
													stroke-linejoin="round"
												></path>
												<path
													d="M7 5.5V4C7 3.60218 7.15804 3.22064 7.43934 2.93934C7.72064 2.65804 8.10218 2.5 8.5 2.5H11.5C11.8978 2.5 12.2794 2.65804 12.5607 2.93934C12.842 3.22064 13 3.60218 13 4V5.5M15.25 5.5V16C15.25 16.3978 15.092 16.7794 14.8107 17.0607C14.5294 17.342 14.1478 17.5 13.75 17.5H6.25C5.85218 17.5 5.47064 17.342 5.18934 17.0607C4.90804 16.7794 4.75 16.3978 4.75 16V5.5H15.25Z"
													stroke="#818898"
													stroke-width="1.5"
													stroke-linecap="round"
													stroke-linejoin="round"
												></path>
											</svg>
										</a>
										<a
											(click)="editEnum(item)"
											class="btn btn-edit"
											role="button"
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="20"
												height="20"
												viewBox="0 0 20 20"
												fill="none"
											>
												<path
													d="M10 15.9992H16.75M13.375 3.62419C13.6734 3.32582 14.078 3.1582 14.5 3.1582C14.7089 3.1582 14.9158 3.19936 15.1088 3.27931C15.3019 3.35926 15.4773 3.47646 15.625 3.62419C15.7727 3.77193 15.8899 3.94732 15.9699 4.14035C16.0498 4.33338 16.091 4.54026 16.091 4.74919C16.091 4.95813 16.0498 5.16501 15.9699 5.35804C15.8899 5.55107 15.7727 5.72646 15.625 5.87419L6.25 15.2492L3.25 15.9992L4 12.9992L13.375 3.62419Z"
													stroke="#818898"
													stroke-width="1.5"
													stroke-linecap="round"
													stroke-linejoin="round"
												/>
											</svg>
										</a>
									</div>
									<div
										*ngIf="item.isEdit"
										class="d-flex align-items-center gap-3"
									>
										<button
											class="btn btn-secondary"
											type="button"
											(click)="item.isEdit = false"
										>
											Cancel
										</button>
										<button
											class="btn btn-secondary"
											type="button"
											(click)="editEnum(item, true)"
										>
											<span>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="20"
													height="20"
													viewBox="0 0 20 20"
													fill="none"
												>
													<path
														d="M10 15.9992H16.75M13.375 3.62419C13.6734 3.32582 14.078 3.1582 14.5 3.1582C14.7089 3.1582 14.9158 3.19936 15.1088 3.27931C15.3019 3.35926 15.4773 3.47646 15.625 3.62419C15.7727 3.77193 15.8899 3.94732 15.9699 4.14035C16.0498 4.33338 16.091 4.54026 16.091 4.74919C16.091 4.95813 16.0498 5.16501 15.9699 5.35804C15.8899 5.55107 15.7727 5.72646 15.625 5.87419L6.25 15.2492L3.25 15.9992L4 12.9992L13.375 3.62419Z"
														stroke="#818898"
														stroke-width="1.5"
														stroke-linecap="round"
														stroke-linejoin="round"
													/>
												</svg> </span
											>Save
										</button>
									</div>
								</div>
							</div>

							<app-enum-list-items
								class="collapse"
								[id]="'collapse' + topIdx"
								[canCascade]="canCascade"
								[key]="key"
								[theEnum]="item"
								*ngIf="canCascade && item"
								[payload]="payload"
							></app-enum-list-items>
						</li>
						<ul>
							<div class="third-list">
								<div class="user-name">
									<div class="form-group">
										<input
											#newItem
											type="text"
											name=""
											id="category123"
											class="form-control"
											placeholder="Enter Name of the {{ language.singular }}"
										/>
									</div>
								</div>
								<div class="edit">
									<div class="d-flex align-items-center">
										<button
											class="btn btn-secondary"
											type="button"
											(click)="saveEnum(newItem)"
										>
											<span>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="20"
													height="20"
													viewBox="0 0 20 20"
													fill="none"
												>
													<path
														d="M10 4.75V15.25"
														stroke="#36394A"
														stroke-width="1.5"
														stroke-linecap="round"
														stroke-linejoin="round"
													/>
													<path
														d="M4.75 10H15.25"
														stroke="#36394A"
														stroke-width="1.5"
														stroke-linecap="round"
														stroke-linejoin="round"
													/>
												</svg>
											</span>
											Add
										</button>
									</div>
								</div>
							</div>
						</ul>
					</ul>
				</div>
			</div>
			<!-- category for lg screen end here... -->

			<!-- category Cards for sm screen start here... -->
			<div class="manage-cards d-md-none">
				<div
					class="card manage-card"
					*ngFor="let item of theEnum; let topIdx = index"
				>
					<div class="card-body">
						<ul class="card-list">
							<li>
								<div class="manage-grid">
									<span class="head"> Name </span>
									<div class="user-name align-items-start">
										<div class="form-group w-100">
											<input
												type="text"
												name=""
												id="category1"
												class="form-control"
												[(ngModel)]="item.name"
												[disabled]="!item.isEdit"
											/>
										</div>
									</div>
								</div>
							</li>
							<li>
								<div class="manage-grid" *ngIf="canCascade">
									<span class="head"> Level </span>
									<div *ngIf="canCascade" class="level">
										<span class="badge bg-aqua-25">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="20"
												height="20"
												viewBox="0 0 20 20"
												fill="none"
											>
												<circle
													cx="10"
													cy="10"
													r="2"
													fill="transparent"
												></circle>
											</svg>
											{{ item.level }}</span
										>
									</div>
								</div>
							</li>
							<li>
								<div class="manage-grid">
									<span class="head"> Actions: </span>
									<div
										class="d-flex align-items-center w-100 justify-content-end"
									>
										<div
											*ngIf="!item.isEdit"
											class="d-flex align-items-center justify-content-start"
										>
											<a
												(click)="openRemoveModal(item, delete_confirm_modal)"
												class="btn btn-delete"
												role="button"
											>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="20"
													height="20"
													viewBox="0 0 20 20"
													fill="none"
												>
													<path
														d="M3.25 5.5H4.75H16.75"
														stroke="#818898"
														stroke-width="1.5"
														stroke-linecap="round"
														stroke-linejoin="round"
													></path>
													<path
														d="M7 5.5V4C7 3.60218 7.15804 3.22064 7.43934 2.93934C7.72064 2.65804 8.10218 2.5 8.5 2.5H11.5C11.8978 2.5 12.2794 2.65804 12.5607 2.93934C12.842 3.22064 13 3.60218 13 4V5.5M15.25 5.5V16C15.25 16.3978 15.092 16.7794 14.8107 17.0607C14.5294 17.342 14.1478 17.5 13.75 17.5H6.25C5.85218 17.5 5.47064 17.342 5.18934 17.0607C4.90804 16.7794 4.75 16.3978 4.75 16V5.5H15.25Z"
														stroke="#818898"
														stroke-width="1.5"
														stroke-linecap="round"
														stroke-linejoin="round"
													></path>
												</svg>
											</a>
											<a
												(click)="editEnum(item)"
												class="btn btn-edit"
												role="button"
											>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="20"
													height="20"
													viewBox="0 0 20 20"
													fill="none"
												>
													<path
														d="M10 15.9992H16.75M13.375 3.62419C13.6734 3.32582 14.078 3.1582 14.5 3.1582C14.7089 3.1582 14.9158 3.19936 15.1088 3.27931C15.3019 3.35926 15.4773 3.47646 15.625 3.62419C15.7727 3.77193 15.8899 3.94732 15.9699 4.14035C16.0498 4.33338 16.091 4.54026 16.091 4.74919C16.091 4.95813 16.0498 5.16501 15.9699 5.35804C15.8899 5.55107 15.7727 5.72646 15.625 5.87419L6.25 15.2492L3.25 15.9992L4 12.9992L13.375 3.62419Z"
														stroke="#818898"
														stroke-width="1.5"
														stroke-linecap="round"
														stroke-linejoin="round"
													/>
												</svg>
											</a>
										</div>
										<div
											*ngIf="item.isEdit"
											class="d-flex align-items-center gap-3"
										>
											<button
												class="btn btn-secondary"
												type="button"
												(click)="item.isEdit = false"
											>
												Cancel
											</button>
											<button
												class="btn btn-secondary"
												type="button"
												(click)="editEnum(item, true)"
											>
												<span>
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="20"
														height="20"
														viewBox="0 0 20 20"
														fill="none"
													>
														<path
															d="M10 15.9992H16.75M13.375 3.62419C13.6734 3.32582 14.078 3.1582 14.5 3.1582C14.7089 3.1582 14.9158 3.19936 15.1088 3.27931C15.3019 3.35926 15.4773 3.47646 15.625 3.62419C15.7727 3.77193 15.8899 3.94732 15.9699 4.14035C16.0498 4.33338 16.091 4.54026 16.091 4.74919C16.091 4.95813 16.0498 5.16501 15.9699 5.35804C15.8899 5.55107 15.7727 5.72646 15.625 5.87419L6.25 15.2492L3.25 15.9992L4 12.9992L13.375 3.62419Z"
															stroke="#818898"
															stroke-width="1.5"
															stroke-linecap="round"
															stroke-linejoin="round"
														/>
													</svg> </span
												>Edit
											</button>
										</div>
										<button
											*ngIf="canCascade"
											class="btn btn-secondary-icon btn-table btn-order shadow-none"
											type="button"
											[attr.data-bs-toggle]="'collapse'"
											[attr.data-bs-target]="'#collapse' + topIdx"
											aria-expanded="false"
											aria-controls="tableCollapse1"
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="20"
												height="20"
												viewBox="0 0 20 20"
												fill="none"
											>
												<path
													d="M6.3999 8.19922L9.9999 11.7992L13.5999 8.19922"
													stroke="#666D80"
													stroke-width="1.5"
													stroke-linecap="round"
													stroke-linejoin="round"
												/>
											</svg>
										</button>
									</div>
								</div>
							</li>
						</ul>
						<app-enum-list-items
							class="collapse"
							[id]="'collapse' + topIdx"
							[canCascade]="canCascade"
							[key]="key"
							[theEnum]="item"
							*ngIf="canCascade && item"
							[payload]="payload"
						></app-enum-list-items>
					</div>
				</div>
				<div class="third-list d-flex align-items-center gap-3 justify-content-between">
					<div class="user-name w-100">
						<div class="form-group w-100">
							<input
								#newItem2
								type="text"
								name=""
								id="category123"
								class="form-control"
								placeholder="Enter Name of the {{ language.singular }}"
							/>
						</div>
					</div>
					<div class="edit">
						<div class="d-flex align-items-center">
							<button
								class="btn btn-secondary"
								type="button"
								(click)="saveEnum(newItem2)"
							>
								<span>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="20"
										height="20"
										viewBox="0 0 20 20"
										fill="none"
									>
										<path
											d="M10 4.75V15.25"
											stroke="#36394A"
											stroke-width="1.5"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
										<path
											d="M4.75 10H15.25"
											stroke="#36394A"
											stroke-width="1.5"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
									</svg>
								</span>
								Add
							</button>
						</div>
					</div>
				</div>
			</div>
			<!-- category Cards for sm screen end here... -->
			<div class="mt-3">
				<button class="btn btn-primary w-sm-100" type="button" (click)="save()">
					<span>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="20"
							height="20"
							viewBox="0 0 20 20"
							fill="none"
						>
							<path
								d="M13.75 16.75V10.75H6.25V16.75M6.25 3.25V7H12.25M15.25 16.75H4.75C4.35218 16.75 3.97064 16.592 3.68934 16.3107C3.40804 16.0294 3.25 15.6478 3.25 15.25V4.75C3.25 4.35218 3.40804 3.97064 3.68934 3.68934C3.97064 3.40804 4.35218 3.25 4.75 3.25H13L16.75 7V15.25C16.75 15.6478 16.592 16.0294 16.3107 16.3107C16.0294 16.592 15.6478 16.75 15.25 16.75Z"
								stroke="white"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							></path>
						</svg>
					</span>
					Save Changes
				</button>
			</div>
		</div>
	</div>
	<!-- manage-user main end here... -->
</div>

<ng-template
	#delete_confirm_modal
	let-modal
	let-c="close"
	let-d="dismiss"
	class="deleteModal"
>
	<div class="modal-content">
		<div class="modal-body">
			<button
				type="button"
				class="btn btn-icon btn-cancel shadow-none"
				(click)="d('Cancel')"
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="20"
					height="20"
					viewBox="0 0 20 20"
					fill="none"
				>
					<path
						d="M14.5 5.5L5.5 14.5M5.5 5.5L14.5 14.5"
						stroke="#666D80"
						stroke-width="1.5"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
				</svg>
			</button>
			<div class="delete-content">
				<div class="dlt-icon">
					<span
						><svg
							xmlns="http://www.w3.org/2000/svg"
							width="20"
							height="20"
							viewBox="0 0 20 20"
							fill="none"
						>
							<path
								d="M3.25 5.5H4.75H16.75"
								stroke="#666D80"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
							<path
								d="M7 5.5V4C7 3.60218 7.15804 3.22064 7.43934 2.93934C7.72064 2.65804 8.10218 2.5 8.5 2.5H11.5C11.8978 2.5 12.2794 2.65804 12.5607 2.93934C12.842 3.22064 13 3.60218 13 4V5.5M15.25 5.5V16C15.25 16.3978 15.092 16.7794 14.8107 17.0607C14.5294 17.342 14.1478 17.5 13.75 17.5H6.25C5.85218 17.5 5.47064 17.342 5.18934 17.0607C4.90804 16.7794 4.75 16.3978 4.75 16V5.5H15.25Z"
								stroke="#666D80"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
						</svg>
					</span>
				</div>
				<div>
					<h4 class="title">Remove {{ language.singular }}</h4>
					<p class="desc">
						Would you like to remove this {{ language.singular }}?
					</p>
				</div>
			</div>
			<div class="btns">
				<button type="button" class="btn btn-danger" (click)="c('Delete')">
					Remove
				</button>
				<button type="button" class="btn btn-secondary" (click)="d('Cancel')">
					Cancel
				</button>
			</div>
		</div>
	</div>
</ng-template>
