import { Injectable } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { apiCallWrapper, httpParams, queryToParams } from '../api/api.util';
import { IQueryFilter, QueryResult } from '../model/query.filter.class';
import { ProductApi } from '../api/product.api';
import { HasId } from '../model/generics';
import { Category, IProductDraftItem, IProductDrafts, IValidateProductUriOpts, NewProduct } from '../model/ddb.model';
import { has } from 'lodash';
import { SessionApi } from '../api/session.api';
import { GlobalApi } from '../api/global.api';

@Injectable()
export class ProductService {
	private sortSubject = new BehaviorSubject<string | undefined>(undefined);
	private sortFilterSubject = new BehaviorSubject<boolean>(true);

	constructor(
		private notifications: NotificationsService,
		private productApi: ProductApi,
		private session: SessionApi,
		private globals: GlobalApi
	) {
	}

	public list(query: IQueryFilter): Observable<QueryResult<HasId & NewProduct>> {
		return apiCallWrapper(
			this.productApi.list(query),
			{
				notificationsService: this.notifications,
				action: "Fetching products"
			}
		)
	}

	readonly getProductList = (query: IQueryFilter, additionalParams: { [key: string]: any } = {}) => {
		let params = queryToParams(query);

		// /** If the user is not an admin, a customerId must be provided */
		// const userData = this.session.$userData.value;

		// if (!userData || !userData.isAdmin) {
		//   params = params.append("customerId", customerId ? customerId : this.guaranteeCustomerId());
		// }

		for (let prop in additionalParams) {
			params = params.append(prop, additionalParams[prop]);
		}

		return this.productApi.getProductList(params)
			.pipe(
				map((result) => {
					result.rows = result.rows.map(item => {
						let instance = new NewProduct();
						Object.assign(instance, item);
						return instance;
					});
					return result;
				})
			)
	};

	readonly getUnAuthProductList = (query: IQueryFilter, additionalParams: { [key: string]: any } = {}) => {
		let params = queryToParams(query);
		for (let prop in additionalParams) {
			params = params.append(prop, additionalParams[prop]);
		}
		return this.productApi.getUnAuthProductList(params)
			.pipe(
				map((result) => {
					result.rows = result.rows.map(item => {
						let instance = new NewProduct();
						Object.assign(instance, item);
						return instance;
					});
					return result;
				})
			)
	};

	readonly getAllocationProductList = (query: IQueryFilter) => {
		let params = queryToParams(query);
		return this.productApi.getAllocationProductList(params).pipe(
			map((result) => {
				if (result.length == 0) {
					return { rows: [], count: 0 };
				}
				result.rows = result.rows.map(item => {
					let instance = new NewProduct();
					Object.assign(instance, item);
					return instance;
				});
				return result;
			})
		);
	}


	readonly getProductById = (id: number | string, includeArchived: boolean = false): Observable<NewProduct> => {
		const params: any = {};
		if (includeArchived) {
			params.scope = "IncludeArchived";
		}
		return this.productApi.get(id)
			.pipe(
				map(result => {
					let instance = new NewProduct();

					Object.assign(instance, result);

					if (instance.variations && instance.variations.length) {
						instance.variations = instance.variations.sort((a, b) => {
							if (a.displayOrder !== null && b.displayOrder !== null) {
								if (a.displayOrder === b.displayOrder && a.id && b.id) {
									return a.id - b.id;
								} else {
									return a.displayOrder - b.displayOrder;
								}
							} else {
								if (a.displayOrder === null && b.displayOrder === null) {
									return 0;
								} else if (a.displayOrder === null) {
									return 1;
								} else {
									return -1;
								}
							}
						});
					}


					/** Order the categories in accordance with their parent */
					if (instance.categories.length) {
						const sortedCategories: any[] = [];
						while (instance.categories.length) {
							if (!sortedCategories.length) {
								let topmostCategory;
								for (let n = 0; n < instance.categories.length; n++) {
									if (instance.categories[n].parentId === null) {
										topmostCategory = instance.categories[n];
										instance.categories.splice(n, 1);
										break;
									}
								}

								if (!topmostCategory)
									break;

								sortedCategories.push(topmostCategory);
							} else {
								let lastCategory = sortedCategories[sortedCategories.length - 1];
								let nextCategory: Category | null = null;
								for (let n = 0; n < instance.categories.length; n++) {
									if (instance.categories[n].parentId === lastCategory.id) {
										nextCategory = instance.categories[n];
										instance.categories.splice(n, 1);
										break;
									}
								}

								if (!nextCategory)
									break;

								sortedCategories.push(nextCategory);
							}
						}

						instance.categories = sortedCategories;
					}

					return instance;
				})
			)
	};

	public getProductDraft(id?: number): Observable<IProductDrafts> {
		return apiCallWrapper(
			this.productApi.getProductDraft(id),
			{
				notificationsService: this.notifications,
				action: "Fetching Draft"
			}
		)
	}

	readonly validateProductUri = (data: IValidateProductUriOpts): Observable<{ unique: boolean }> => {
		return apiCallWrapper(
			this.productApi.validateProductUri(data),
			{
				notificationsService: this.notifications,
				action: "Validate Product URI"
			}
		)
	}

	public create(data: NewProduct) {
		return apiCallWrapper(
			this.productApi.create(data),
			{
				notificationsService: this.notifications,
				action: "Create Product"
			}
		)
	}

	public cloneProduct(product: NewProduct) {
		return apiCallWrapper(
			this.productApi.cloneProduct(product),
			{
				notificationsService: this.notifications,
				action: "Clone Product"
			}
		)
	}

	public deleteProduct(id: number | string) {
		return apiCallWrapper(
			this.productApi.deleteProduct(id),
			{
				notificationsService: this.notifications,
				action: "Delete Product"
			}
		)
	}

	public restoreProduct(id: number | string) {
		return apiCallWrapper(
			this.productApi.restoreProduct(id),
			{
				notificationsService: this.notifications,
				action: "Restore Product"
			}
		)
	}

	/** Used to guarantee that the passed customer id is acceptable and valid */
	private readonly guaranteeCustomerId = (customerId?: number | string): string => {
		if (!this.session) {
			throw new Error("Globals is missing in communication service");
		}

		if (!customerId || isNaN(Number(customerId)) || !this.globals.isAdmin) {
			const authCustomer = this.session.$customerData.value;
			if (!authCustomer || !authCustomer.id)
				throw new Error("Invalid Customer for api call");

			return String(authCustomer.id);
		}

		return customerId.toString();
	}

	public readonly getProductByUri = (uri: string) => {

		let params = httpParams();

		// const userData = this.session.$userData.getValue();

		// if (!(userData && userData.isAdmin)) {
		// 	const customerData = this.session.$customerData.getValue();
		// 	if (!customerData) {
		// 	} else {
		// 		params = params.append("customerId", customerData.id.toString());
		// 	}
		// }

		return this.productApi.getProductByUri(params, uri)
			.pipe(
				map(result => {
					let instance = new NewProduct();

					Object.assign(instance, result);

					return instance;
				})
			)
	};

	public readonly getUnAuthProductByUri = (uri: string) => {
		let params = httpParams();
		return this.productApi.getUnAuthProductByUri(params, uri)
			.pipe(
				map(result => {
					let instance = new NewProduct();

					Object.assign(instance, result);

					return instance;
				})
			)
	};


	setSort(sort: string | undefined): void {
		this.sortSubject.next(sort);
	}

	getSort(): Observable<string | undefined> {
		return this.sortSubject.asObservable();
	}

	getTopProducts(query: IQueryFilter) {
		let params = queryToParams(query);
		return this.productApi.getTopProducts(params);
	}

	setFilterSort(sort: boolean): void {
		this.sortFilterSubject.next(sort);
	}

	getFilterSort(): Observable<boolean> {
		return this.sortFilterSubject.asObservable();
	}

}
