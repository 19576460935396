import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { logger } from '../util/Logger';
import { createUrl } from '../api/api.util';
import { IAuthDataResponse } from '../model/session.model';
import { GlobalApi } from '../api/global.api';

@Injectable()
export class UserRoleGuard implements CanActivate {
  protected permissions: string[] = [];
  protected roles: string[] = [];

  constructor(
    private readonly router: Router,
    private httpClient: HttpClient,
    public globals:GlobalApi
  ) { }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    this.permissions = route.data.permissions || [];
    this.roles = route.data.roles || [];

    return this.httpClient.get<IAuthDataResponse>(createUrl('auth', 'authorization', 'information')).pipe(
      catchError(() => {
        logger.debug('Error loading user data');
        return of(null);
      }),
      map((resp: IAuthDataResponse | null) => {
        if (!resp) {
          logger.info('User is not authenticated.');
          this.router.navigate(['']);
          return false;
        }


        // if (this.globals.currentRole === null) {
        //   logger.silly('Bypassing role check due to Super Admin User');
        //   return true;
        // }
        if (!this.globals.currentRole || typeof this.globals.currentRole?.permissions !== 'string') {
          logger.error('Current Admin Role or Permissions presented in an unexpected format');
          return false;
        }

        const currentPermissions = this.globals.currentRole?.permissions.split(",");
        const hasPermission = this.permissions.some(permission => permission === 'all' || currentPermissions.includes(permission));
        if (!hasPermission) {
          logger.error('User failed role check. Redirecting to Login');
          this.router.navigate(['/home']);
        }

        return hasPermission;
      })
    );
  }
}
