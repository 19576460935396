import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { logger } from '../util/Logger';
import { createUrl } from '../api/api.util';
import { IAuthDataResponse } from '../model/session.model';

@Injectable()
export class RoleGuard implements CanActivate {
  protected permissions: string[] = [];
  protected roles: string[] = [];

  constructor(
    private readonly router: Router,
    private httpClient: HttpClient
  ) { }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    this.permissions = route.data.permissions || [];
    this.roles = route.data.roles || [];

    return this.httpClient.get<IAuthDataResponse>(createUrl('auth','authorization', 'information')).pipe(
      catchError(() => {
        logger.debug('Error loading user data');
        return of(null);
      }),
      map((resp: IAuthDataResponse | null) => {
        if (!resp) {
          logger.info('User is not authenticated.');
          this.router.navigate(['']);
          return false;
        }

        if (resp.isAdmin && resp.adminRole === null) {
          logger.silly('Bypassing role check due to Super Admin User');
          return true;
        }

        if (!resp.adminRole || typeof resp.adminRole.permissions !== 'string') {
          logger.error('Current Admin Role or Permissions presented in an unexpected format');
          return false;
        }

        const currentPermissions = resp.adminRole.permissions.split(",");
        const hasPermission = this.permissions.some(permission => permission === 'all' || currentPermissions.includes(permission));

        if (!hasPermission) {
          logger.error(`User does not have any of role ${this.permissions.join(",")}. Redirecting to Login`);
          this.router.navigate(['/home']);
        }

        return hasPermission;
      })
    );
  }
}
