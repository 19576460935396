<div class="main-right-content">
	<nav aria-label="breadcrumb" class="pageBreadcrumb">
		<ol class="breadcrumb mb-0">
			<li class="breadcrumb-item">
				<a (click)="getRoute()">Collections</a>
			</li>
			<li class="breadcrumb-item active" aria-current="page">
				{{ model.id ? "Edit" : "Add new" }} Collection
			</li>
		</ol>
	</nav>

	<div class="manageUser">
		<div class="manage-user-list">
			<div class="customer-wizard">
				<div class="wizard-tabs">
					<ul class="nav nav-pills" id="pills-tab">
						<li class="nav-item">
							<button class="nav-link" (click)="setActive(1)" [class.active]="activeClassId == 1"
								[class.complete]="activeClassId >= 2" type="button">
								<span>
									<svg class="incompleted" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
										fill="none">
										<path
											d="M16 16.75V15.25C16 14.4544 15.6839 13.6913 15.1213 13.1287C14.5587 12.5661 13.7956 12.25 13 12.25H7C6.20435 12.25 5.44129 12.5661 4.87868 13.1287C4.31607 13.6913 4 14.4544 4 15.25V16.75M13 6.25C13 7.90685 11.6569 9.25 10 9.25C8.34315 9.25 7 7.90685 7 6.25C7 4.59315 8.34315 3.25 10 3.25C11.6569 3.25 13 4.59315 13 6.25Z"
											stroke="transparent" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
									</svg>
									<svg class="completed" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
										fill="none">
										<path
											d="M17.5 9.31039V10.0004C17.4991 11.6177 16.9754 13.1914 16.007 14.4868C15.0386 15.7821 13.6775 16.7297 12.1265 17.1883C10.5756 17.6469 8.91794 17.5918 7.40085 17.0313C5.88376 16.4708 4.58849 15.435 3.70822 14.0782C2.82795 12.7214 2.40984 11.1164 2.51626 9.50262C2.62267 7.88881 3.24791 6.35263 4.29871 5.12319C5.34951 3.89375 6.76959 3.03692 8.34714 2.6805C9.92469 2.32407 11.5752 2.48714 13.0525 3.14539"
											stroke="transparent" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
										<path d="M17.5 4L10 11.5075L7.75 9.2575" stroke="transparent" stroke-width="1.5"
											stroke-linecap="round" stroke-linejoin="round" />
									</svg>
								</span>
								<div>
									<h6>Step 1</h6>
									<h5>Overview</h5>
								</div>
							</button>
						</li>
						<li class="nav-item" *ngIf="!isCustomer">
							<div class="border-top-item"></div>
						</li>
						<li class="nav-item" *ngIf="!isCustomer">
							<button class="nav-link" (click)="setActive(2)" type="button" [class.active]="activeClassId == 2"
								[class.complete]="activeClassId >= 3">
								<span>
									<svg class="incompleted" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
										fill="none">
										<path
											d="M18.25 16.75V15.25C18.2495 14.5853 18.0283 13.9396 17.621 13.4142C17.2138 12.8889 16.6436 12.5137 16 12.3475M13.75 16.75V15.25C13.75 14.4544 13.4339 13.6913 12.8713 13.1287C12.3087 12.5661 11.5456 12.25 10.75 12.25H4.75C3.95435 12.25 3.19129 12.5661 2.62868 13.1287C2.06607 13.6913 1.75 14.4544 1.75 15.25V16.75M13 3.3475C13.6453 3.51273 14.2173 3.88803 14.6257 4.41424C15.0342 4.94044 15.2559 5.58763 15.2559 6.25375C15.2559 6.91988 15.0342 7.56706 14.6257 8.09327C14.2173 8.61948 13.6453 8.99478 13 9.16M10.75 6.25C10.75 7.90685 9.40685 9.25 7.75 9.25C6.09315 9.25 4.75 7.90685 4.75 6.25C4.75 4.59315 6.09315 3.25 7.75 3.25C9.40685 3.25 10.75 4.59315 10.75 6.25Z"
											stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
									</svg>
									<svg class="completed" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
										fill="none">
										<path
											d="M17.5 9.31039V10.0004C17.4991 11.6177 16.9754 13.1914 16.007 14.4868C15.0386 15.7821 13.6775 16.7297 12.1265 17.1883C10.5756 17.6469 8.91794 17.5918 7.40085 17.0313C5.88376 16.4708 4.58849 15.435 3.70822 14.0782C2.82795 12.7214 2.40984 11.1164 2.51626 9.50262C2.62267 7.88881 3.24791 6.35263 4.29871 5.12319C5.34951 3.89375 6.76959 3.03692 8.34714 2.6805C9.92469 2.32407 11.5752 2.48714 13.0525 3.14539"
											stroke="transparent" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
										<path d="M17.5 4L10 11.5075L7.75 9.2575" stroke="transparent" stroke-width="1.5"
											stroke-linecap="round" stroke-linejoin="round" />
									</svg>
								</span>
								<div>
									<h6>Step 2</h6>
									<h5>Customers</h5>
								</div>
							</button>
						</li>
						<li class="nav-item">
							<div class="border-top-item"></div>
						</li>
						<li class="nav-item">
							<button class="nav-link" type="button" (click)="setActive(isCustomer ? 2 : 3)"
								[class.active]="isCustomer ? activeClassId == 2 : activeClassId == 3"
								[class.complete]="isCustomer ? activeClassId >= 4 : activeClassId >= 4">
								<span>
									<svg class="incompleted" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
										fill="none">
										<path
											d="M16.75 12.9999V6.99993C16.7497 6.73688 16.6803 6.47853 16.5487 6.2508C16.417 6.02306 16.2278 5.83395 16 5.70243L10.75 2.70243C10.522 2.57077 10.2633 2.50146 10 2.50146C9.7367 2.50146 9.47803 2.57077 9.25 2.70243L4 5.70243C3.7722 5.83395 3.58299 6.02306 3.45135 6.2508C3.31971 6.47853 3.25027 6.73688 3.25 6.99993V12.9999C3.25027 13.263 3.31971 13.5213 3.45135 13.7491C3.58299 13.9768 3.7722 14.1659 4 14.2974L9.25 17.2974C9.47803 17.4291 9.7367 17.4984 10 17.4984C10.2633 17.4984 10.522 17.4291 10.75 17.2974L16 14.2974C16.2278 14.1659 16.417 13.9768 16.5487 13.7491C16.6803 13.5213 16.7497 13.263 16.75 12.9999Z"
											stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
										<path d="M3.45215 6.21997L9.99965 10.0075L16.5471 6.21997" stroke="#666D80" stroke-width="1.5"
											stroke-linecap="round" stroke-linejoin="round" />
										<path d="M10 17.56V10" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
											stroke-linejoin="round" />
									</svg>
									<svg class="completed" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
										fill="none">
										<path
											d="M17.5 9.31039V10.0004C17.4991 11.6177 16.9754 13.1914 16.007 14.4868C15.0386 15.7821 13.6775 16.7297 12.1265 17.1883C10.5756 17.6469 8.91794 17.5918 7.40085 17.0313C5.88376 16.4708 4.58849 15.435 3.70822 14.0782C2.82795 12.7214 2.40984 11.1164 2.51626 9.50262C2.62267 7.88881 3.24791 6.35263 4.29871 5.12319C5.34951 3.89375 6.76959 3.03692 8.34714 2.6805C9.92469 2.32407 11.5752 2.48714 13.0525 3.14539"
											stroke="transparent" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
										<path d="M17.5 4L10 11.5075L7.75 9.2575" stroke="transparent" stroke-width="1.5"
											stroke-linecap="round" stroke-linejoin="round" />
									</svg>
								</span>
								<div>
									<h6>Step 3</h6>
									<h5>Products</h5>
								</div>
							</button>
						</li>
					</ul>
				</div>
				<div class="tab-content">
					<div class="tab-pane fade" [class.show]="activeClassId == 1" [class.active]="activeClassId == 1">
						<div class="edit-users">
							<div>
								<div class="edit-card card">
									<div class="card-head">
										<h4 class="head-title">
											<span>
												<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
													<path
														d="M16.75 12.9999V6.99993C16.7497 6.73688 16.6803 6.47853 16.5487 6.2508C16.417 6.02306 16.2278 5.83395 16 5.70243L10.75 2.70243C10.522 2.57077 10.2633 2.50146 10 2.50146C9.7367 2.50146 9.47803 2.57077 9.25 2.70243L4 5.70243C3.7722 5.83395 3.58299 6.02306 3.45135 6.2508C3.31971 6.47853 3.25027 6.73688 3.25 6.99993V12.9999C3.25027 13.263 3.31971 13.5213 3.45135 13.7491C3.58299 13.9768 3.7722 14.1659 4 14.2974L9.25 17.2974C9.47803 17.4291 9.7367 17.4984 10 17.4984C10.2633 17.4984 10.522 17.4291 10.75 17.2974L16 14.2974C16.2278 14.1659 16.417 13.9768 16.5487 13.7491C16.6803 13.5213 16.7497 13.263 16.75 12.9999Z"
														stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
													<path d="M3.45215 6.21997L9.99965 10.0075L16.5471 6.21997" stroke="#666D80" stroke-width="1.5"
														stroke-linecap="round" stroke-linejoin="round" />
													<path d="M10 17.56V10" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
														stroke-linejoin="round" />
												</svg>
											</span>
											Collection 1
										</h4>
									</div>
									<div class="card-body">
										<form action="">
											<div class="row">
												<div class="col-12">
													<div class="form-group">
														<label for="code" class="form-label">Name</label>
														<input type="text" class="form-control" id="code" placeholder="" name="name"
															[(ngModel)]="model.name" />
													</div>
												</div>
											</div>
										</form>
									</div>
								</div>
								<div class="edit-card card">
									<div class="card-head">
										<h4 class="head-title">
											<span>
												<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
													<path
														d="M17.0797 9.28744L10.1872 16.1799C9.34285 17.0243 8.19762 17.4987 7.00348 17.4987C5.80934 17.4987 4.66411 17.0243 3.81973 16.1799C2.97535 15.3356 2.50098 14.1903 2.50098 12.9962C2.50098 11.8021 2.97535 10.6568 3.81973 9.81244L10.7122 2.91994C11.2752 2.35702 12.0386 2.04077 12.8347 2.04077C13.6308 2.04077 14.3943 2.35702 14.9572 2.91994C15.5202 3.48286 15.8364 4.24635 15.8364 5.04244C15.8364 5.83853 15.5202 6.60202 14.9572 7.16494L8.05723 14.0574C7.77577 14.3389 7.39403 14.497 6.99598 14.497C6.59793 14.497 6.21619 14.3389 5.93473 14.0574C5.65327 13.776 5.49514 13.3942 5.49514 12.9962C5.49514 12.5981 5.65327 12.2164 5.93473 11.9349L12.3022 5.57494"
														stroke="#272835" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
												</svg>
											</span>
											Display options
										</h4>
									</div>
									<div class="card-body">
										<form action="">
											<div class="row">
												<div class="col-12 col-lg-10">
													<div class="form-group form-check">
														<input type="radio" class="form-check-input" id="Approval1" name="accessMode" [value]="1"
															[(ngModel)]="model.accessMode" />
														<label class="form-check-label" for="Approval1">This group is available to all users
														</label>
													</div>
												</div>
												<div class="col-12 col-lg-10">
													<div class="form-group form-check">
														<input type="radio" class="form-check-input" id="Approval2" name="accessMode" [value]="0"
															[(ngModel)]="model.accessMode" />
														<label class="form-check-label" for="Approval2">This group is only available to selected
															customers
														</label>
													</div>
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="tab-pane fade" *ngIf="!isCustomer" [class.active]="activeClassId == 2"
						[class.show]="activeClassId == 2">
						<div class="add-products">
							<div class="form-group">
								<label for="selectCustomer" class="form-label d-none"></label>
								<ng-select id="selectCustomer" #customer [virtualScroll]="true"
									(scrollToEnd)="handleCustomerListGet(true)" [searchable]="true" (search)="onCustomerSearch($event)"
									[notFoundText]="noCustomerFoundText" [multiple]="true" (change)="onSelectChange(customer)">
									<ng-option *ngFor="let data of customerSelectOptions" [value]="data">{{data.text}}</ng-option>
								</ng-select>
							</div>
							<div class="form-group">
								<a class="btn btn-primary w-sm-100" (click)="addSelectedCustomer()" role="button">
									<span>
										<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
											<path d="M10 4.75V15.25" stroke="white" stroke-width="1.5" stroke-linecap="round"
												stroke-linejoin="round"></path>
											<path d="M4.75 10H15.25" stroke="white" stroke-width="1.5" stroke-linecap="round"
												stroke-linejoin="round"></path>
										</svg>
									</span>
									Add Customer(s)
								</a>
							</div>
						</div>

						<div class="edit-users">
							<div class="edit-card card" *ngFor="let customer of model.customers; let i = index">
								<div class="card-head">
									<h4 class="head-title">
										<span>
											<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
												<path
													d="M16 16.75V15.25C16 14.4544 15.6839 13.6913 15.1213 13.1287C14.5587 12.5661 13.7956 12.25 13 12.25H7C6.20435 12.25 5.44129 12.5661 4.87868 13.1287C4.31607 13.6913 4 14.4544 4 15.25V16.75"
													fill="white"></path>
												<path
													d="M10 9.25C11.6569 9.25 13 7.90685 13 6.25C13 4.59315 11.6569 3.25 10 3.25C8.34315 3.25 7 4.59315 7 6.25C7 7.90685 8.34315 9.25 10 9.25Z"
													fill="white"></path>
												<path
													d="M16 16.75V15.25C16 14.4544 15.6839 13.6913 15.1213 13.1287C14.5587 12.5661 13.7956 12.25 13 12.25H7C6.20435 12.25 5.44129 12.5661 4.87868 13.1287C4.31607 13.6913 4 14.4544 4 15.25V16.75M13 6.25C13 7.90685 11.6569 9.25 10 9.25C8.34315 9.25 7 7.90685 7 6.25C7 4.59315 8.34315 3.25 10 3.25C11.6569 3.25 13 4.59315 13 6.25Z"
													stroke="#272835" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
											</svg>
										</span>
										Customer {{ i + 1 }}
									</h4>
									<button class="btn btn-secondary-icon" type="button" (click)="removeCustomer(customer)">
										<span>
											<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
												<path d="M3.25 5.5H4.75H16.75" stroke="#272835" stroke-width="1.5" stroke-linecap="round"
													stroke-linejoin="round"></path>
												<path
													d="M7 5.5V4C7 3.60218 7.15804 3.22064 7.43934 2.93934C7.72064 2.65804 8.10218 2.5 8.5 2.5H11.5C11.8978 2.5 12.2794 2.65804 12.5607 2.93934C12.842 3.22064 13 3.60218 13 4V5.5M15.25 5.5V16C15.25 16.3978 15.092 16.7794 14.8107 17.0607C14.5294 17.342 14.1478 17.5 13.75 17.5H6.25C5.85218 17.5 5.47064 17.342 5.18934 17.0607C4.90804 16.7794 4.75 16.3978 4.75 16V5.5H15.25Z"
													stroke="#272835" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
											</svg>
										</span>
									</button>
								</div>
								<div class="card-body">
									<form action="">
										<div class="row">
											<div class="col-12">
												<div class="form-group">
													<label for="Name" class="form-label">Name</label>
													<input type="text" class="form-control" id="Name" placeholder="" value="{{
															customer.customer
																? customer.customer.name
																: customer.name
														}}" disabled />
												</div>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
					<div class="tab-pane fade" [class.active]="isCustomer ? activeClassId == 2 : activeClassId == 3"
						[class.show]="isCustomer ?  activeClassId == 2 : activeClassId == 3">
						<div class="manage-user-list">
							<!-- Add products content start here... -->
							<div class="add-products">
								<div class="form-group">
									<label for="selectProduct" class="form-label d-none"></label>
									<ng-select id="selectCustomer" #product [virtualScroll]="true" (scrollToEnd)="initProductSelect(true)"
										[searchable]="true" (search)="onProductSearch($event)" (change)="selectProducts()"
										[notFoundText]="noProductFoundText" [multiple]="true" (change)="onSelectChange(product)">
										<ng-option *ngFor="let data of productResult.rows" [value]="data.id">{{data.name}}</ng-option>
									</ng-select>
								</div>
								<div class="form-group">
									<a class="btn btn-primary w-sm-100" (click)="addProduct()" role="button">
										<span>
											<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
												<path d="M10 4.75V15.25" stroke="white" stroke-width="1.5" stroke-linecap="round"
													stroke-linejoin="round" />
												<path d="M4.75 10H15.25" stroke="white" stroke-width="1.5" stroke-linecap="round"
													stroke-linejoin="round" />
											</svg>
										</span>
										Add Product(s)
									</a>
								</div>
							</div>
							<!-- Add products content end here... -->

							<!-- products add-edit cards start here... -->
							<div class="edit-users">
								<div class="edit-card card" *ngFor="let product of model.products; let i = index">
									<div class="card-head gap-4">
										<h4 class="head-title">
											<span>
												<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
													<path
														d="M13.375 8.05041L6.625 4.15791M3.45251 6.22041L10 10.0079L16.5475 6.22041M10 17.5604V10.0004M16.75 13.0004V7.00041C16.7497 6.73737 16.6803 6.47902 16.5487 6.25129C16.417 6.02355 16.2278 5.83444 16 5.70291L10.75 2.70291C10.522 2.57126 10.2633 2.50195 10 2.50195C9.73669 2.50195 9.47803 2.57126 9.25 2.70291L4 5.70291C3.7722 5.83444 3.58299 6.02355 3.45135 6.25129C3.31971 6.47902 3.25027 6.73737 3.25 7.00041V13.0004C3.25027 13.2635 3.31971 13.5218 3.45135 13.7495C3.58299 13.9773 3.7722 14.1664 4 14.2979L9.25 17.2979C9.47803 17.4296 9.73669 17.4989 10 17.4989C10.2633 17.4989 10.522 17.4296 10.75 17.2979L16 14.2979C16.2278 14.1664 16.417 13.9773 16.5487 13.7495C16.6803 13.5218 16.7497 13.2635 16.75 13.0004Z"
														stroke="#272835" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
												</svg>
											</span>
											Product {{ product && product.product && product.product.name }}
										</h4>
										<button *ngIf="product" class="btn btn-secondary-icon" type="button"
											(click)="removeProduct(product.productId.toString())">
											<span>
												<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
													<path d="M3.25 5.5H4.75H16.75" stroke="#272835" stroke-width="1.5" stroke-linecap="round"
														stroke-linejoin="round" />
													<path
														d="M7 5.5V4C7 3.60218 7.15804 3.22064 7.43934 2.93934C7.72064 2.65804 8.10218 2.5 8.5 2.5H11.5C11.8978 2.5 12.2794 2.65804 12.5607 2.93934C12.842 3.22064 13 3.60218 13 4V5.5M15.25 5.5V16C15.25 16.3978 15.092 16.7794 14.8107 17.0607C14.5294 17.342 14.1478 17.5 13.75 17.5H6.25C5.85218 17.5 5.47064 17.342 5.18934 17.0607C4.90804 16.7794 4.75 16.3978 4.75 16V5.5H15.25Z"
														stroke="#272835" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
												</svg>
											</span>
										</button>
									</div>
									<div class="card-body">
										<div class="row">
											<div class="col-12 col-lg-6">
												<div class="form-group">
													<label for="Name" class="form-label">Name</label>
													<textarea *ngIf="product.product" type="text" class="form-control" id="Name"
														[placeholder]="product.product.name" disabled></textarea>
												</div>
											</div>
											<div class="col-12 col-lg-6">
												<div class="form-group">
													<label for="Code" class="form-label">Product Code</label>
													<input type="text" class="form-control" id="Code" placeholder="" [value]="
															(product.product && product.product.code) || 'N/A'
														" disabled />
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<!-- products add-edit cards end here... -->
						</div>
					</div>
				</div>
				<div class="wizard-footer">
					<button class="btn btn-secondary" type="button" [routerLink]="['/manage/collections']">Cancel</button>
					<div class="d-flex flex-column flex-md-row align-items-center justify-content-end gap-4 gap-md-2">
						<button class="btn btn-primary" type="button" (click)="saveModel()">
							{{ isCustomer ? activeClassId >= 2 ? "Save" : "Continue" : activeClassId >= 3 ? "Save" : "Continue" }}
							<span>
								<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
									<path d="M8.2002 13.6004L11.8002 10.0004L8.2002 6.40039" stroke="white" stroke-width="1.5"
										stroke-linecap="round" stroke-linejoin="round" />
								</svg>
							</span>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>