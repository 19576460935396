<!-- ============================ -->
<!-- Transforming section start here... -->
<!-- ============================ -->
<section class="transforming">
	<div class="max-width-container rounded-5">
		<div class="transback container">
			<div class="contents">
				<div class="items">
					<h1>
						Transforming the way you order
						<span class="text-warning">uniforms</span>
					</h1>
					<p class="text-secondary">Because uniforms matter.</p>
					<div class="button-grps">
						<button class="btn btn-primary" (click)="productRoute(true)">
							Explore Solutions
						</button>
						<button class="btn btn-light" routerLink="/contactus">
							Contact us
						</button>
					</div>
				</div>
				<div class="items position-relative">
					<img src="../../../../assets/images/banner1.png" alt="" />
					<div class="position-absolute allocation-box bg-white">
						<div class="alocbox">
							<a href="javascript:void(0);" class="pe-none">
								<img src="../../../../assets/images/pcard.svg" alt="" />
							</a>
							<span>
								<div class="fs-6">Allocation</div>
								<div class="fs-6">4 garments</div>
							</span>
						</div>
					</div>
					<a
						href="javascript:void(0);"
						class="d-flex align-items-center justify-content-center bg-white rounded-circle position-absolute cart-box pe-none"
					>
						<svg
							width="18"
							height="18"
							viewBox="0 0 18 18"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M0.75 0.75H3.75L5.76 10.7925C5.82858 11.1378 6.01643 11.448 6.29066 11.6687C6.56489 11.8895 6.90802 12.0067 7.26 12H14.55C14.902 12.0067 15.2451 11.8895 15.5193 11.6687C15.7936 11.448 15.9814 11.1378 16.05 10.7925L17.25 4.5H4.5M15.75 15.75C15.75 16.1642 15.4142 16.5 15 16.5C14.5858 16.5 14.25 16.1642 14.25 15.75C14.25 15.3358 14.5858 15 15 15C15.4142 15 15.75 15.3358 15.75 15.75ZM7.5 15.75C7.5 16.1642 7.16421 16.5 6.75 16.5C6.33579 16.5 6 16.1642 6 15.75C6 15.3358 6.33579 15 6.75 15C7.16421 15 7.5 15.3358 7.5 15.75Z"
								stroke="#000000"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							></path>
						</svg>
					</a>
					<div class="hpba position-absolute">
						<button class="btn btn-primary pe-none">Add to cart</button>
					</div>
				</div>
			</div>
			<div class="ourclientsec">
				<h5 class="text-secondary text-center">Our clients</h5>
				<ul>
					<li>
						<img src="../../../../assets/images/clients-4.svg" alt="" />
					</li>
					<li>
						<img src="../../../../assets/images/Emeco.svg" alt="" />
					</li>
					<li>
						<img src="../../../../assets/images/clients-2.svg" alt="" />
					</li>
					<li>
						<img src="../../../../assets/images/clients-5.svg" alt="" />
					</li>
					<li>
						<img src="../../../../assets/images/clients-3.svg" alt="" />
					</li>
				</ul>
			</div>
		</div>
	</div>
</section>
<!-- ============================ -->
<!-- Transforming section end here... -->
<!-- ============================ -->

<!-- ============================ -->
<!-- Hivis section start here... -->
<!-- ============================ -->
<section class="hivis">
	<div class="max-width-container">
		<div class="container">
			<div class="contents">
				<div class="items position-relative">
					<img
						class="w-100 h-100 rounded-4"
						src="../../../../assets/images/Frame 1160445408.png"
						alt=""
					/>
					<div class="position-absolute">
						<h4 class="text-white fw-medium">Hi-Vis Uniforms</h4>
					</div>
				</div>
				<div class="innerbox">
					<div class="newitems">
						<div class="boxes position-relative">
							<img
								class="w-100 rounded-4 d-none d-md-block"
								src="../../../../assets/images/Frame 1160445415.png"
								alt=""
							/>
							<img
								class="w-100 rounded-4 d-block d-md-none"
								src="../../../../assets/images/hivismob1.png"
								alt=""
							/>
							<div class="position-absolute">
								<h4 class="text-white fw-medium">PPE</h4>
							</div>
						</div>
						<div class="boxes position-relative">
							<img
								class="w-100 rounded-4 d-none d-md-block"
								src="../../../../assets/images/Frame 1160445417.png"
								alt=""
							/>
							<img
								class="w-100 rounded-4 d-block d-md-none"
								src="../../../../assets/images/hivismob2.png"
								alt=""
							/>
							<div class="position-absolute">
								<h4 class="text-white fw-medium">Corporate</h4>
							</div>
						</div>
						<div class="boxes position-relative">
							<img
								class="w-100 rounded-4 d-none d-md-block"
								src="../../../../assets/images/Frame 1160445416.png"
								alt=""
							/>
							<img
								class="w-100 rounded-4 d-block d-md-none"
								src="../../../../assets/images/hivismob3.png"
								alt=""
							/>
							<div class="position-absolute">
								<h4 class="text-white fw-medium">Healthcare</h4>
							</div>
						</div>
						<div class="boxes position-relative">
							<div class="">
								<img
									class="w-100 rounded-4 d-none d-md-block"
									src="../../../../assets/images/bgwarnidesk.jpg"
									alt=""
								/>
								<img
									class="w-100 rounded-4 d-block d-md-none"
									src="../../../../assets/images/hivismob4.jpg"
									alt=""
								/>
								<div class="bag">
									<button class="shopbag-btn">
										<img
											src="../../../../assets/images/shopping-bag.svg"
											alt=""
										/>
									</button>
								</div>
								<div class="position-absolute">
									<h3 class="text-white mb-2 d-none">+2307</h3>
									<h4>
										<a
											*ngIf="!checkAuthenticated()"
											class="text-white fw-medium"
											role="button"
											(click)="productRoute()"
											>Explore our Catalogue
										</a>
										<a
											*ngIf="checkAuthenticated()"
											class="text-white fw-medium pe-none"
											role="button"
											>Explore our Catalogue
										</a>
									</h4>
									<p class="text-white fw-normal fs-5">Coming soon</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<!-- ============================ -->
<!-- Hivis section end here... -->
<!-- ============================ -->

<!-- ============================ -->
<!-- weKnow section start here... -->
<!-- ============================ -->
<section class="weKnow">
	<div class="max-width-container rounded-5">
		<div class="container">
			<h2 class="text-white"><span>We know</span> what you need</h2>
			<div class="contents">
				<div class="items shild">
					<span>
						<img src="../../../../assets/images/fi_shield.svg" alt="" />
					</span>
					<div class="">
						<h4 class="text-white py-2">In-house Customisation</h4>
						<p class="text-white">
							Across all of our facilities to keep your branding consistent and
							delivered fast.
						</p>
					</div>
				</div>
				<div class="items shild">
					<span>
						<img src="../../../../assets/images/fi_shield.svg" alt="" />
					</span>
					<div class="">
						<h4 class="text-white py-2">Eureka Safety</h4>
						<p class="text-white">
							Our own exclusive brand, so we can offer you competitive pricing
							without sacrificing on quality.
						</p>
					</div>
				</div>
				<div class="items shild">
					<span>
						<img src="../../../../assets/images/fi_shield.svg" alt="" />
					</span>
					<div class="">
						<h4 class="text-white py-2">National Coverage</h4>
						<p class="text-white">
							With a warehouse full of stock and customisation services in all
							locations, we don’t just open offices.
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<!-- ============================ -->
<!-- weKnow section end here... -->
<!-- ============================ -->

<!-- ============================ -->
<!-- about section end here... -->
<!-- ============================ -->
<section class="about">
	<div class="max-width-container">
		<div class="container">
			<div class="contents">
				<div class="position-relative abbanner">
					<img src="../../../../assets/images/aboutframe.png" alt="" />
				</div>
			</div>
			<div class="contentsb">
				<div class="items">
					<div class="minhead text-warning">
						<img src="../../../../assets/images/infoicon.svg" alt="" />ABOUT US
					</div>
					<h3>
						Through continued investment in technology investment and workforce
						output, we have set ourselves apart from our competitors in a way
						that adds immense value to our clients.
					</h3>
					<h5>
						With the use of SUMS, we guarantee a customised system and approach
						when dealing with all aspects of your order.
					</h5>
					<a [routerLink]="['/aboutUs']" role="button" class="btn btn-light">
						Read more
						<img
							class="ms-2"
							src="../../../../assets/images/arrowright.svg"
							alt=""
						/>
					</a>
				</div>
			</div>
			<div class="hrline">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="1200"
					height="2"
					viewBox="0 0 1200 2"
					fill="none"
				>
					<path d="M0 1L1200 1.0001" stroke="#DFE1E7" stroke-dasharray="8 8" />
				</svg>
			</div>
			<div class="contents">
				<div class="items">
					<h5 class="text-secondary text-center fs-4 mx-auto">
						Some of our partners
					</h5>
					<ul class="brns">
						<li>
							<img src="../../../../assets/images/partners.svg" alt="" />
						</li>
						<li>
							<img src="../../../../assets/images/partners-1.svg" alt="" />
						</li>
						<li>
							<img src="../../../../assets/images/partners-2.svg" alt="" />
						</li>
						<li>
							<img src="../../../../assets/images/partners-3.svg" alt="" />
						</li>
						<li>
							<img src="../../../../assets/images/partners-4.svg" alt="" />
						</li>
						<li>
							<img src="../../../../assets/images/partners-5.svg" alt="" />
						</li>
						<li>
							<img src="../../../../assets/images/partners-6.svg" alt="" />
						</li>
						<li>
							<img src="../../../../assets/images/partners-7.svg" alt="" />
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</section>
<!-- ============================ -->
<!-- about section end here... -->
<!-- ============================ -->

<!-- ============================ -->
<!-- sumsProduct section start here... -->
<!-- ============================ -->
<section class="sumsProduct">
	<div class="max-width-container rounded-5">
		<div class="contents">
			<div class="itemsb position-relative">
				<div class="img-box">
					<!-- <img src="../../../../assets/images/image 10.png" alt="" /> -->
					<img
						class="desktopviewn"
						src="../../../../assets/images/Frame 1410126136.png"
						alt=""
					/>
					<img
						class="mobviewn"
						src="../../../../assets/images/graphic.png"
						alt=""
					/>
				</div>

				<div class="d-none">
					<div class="orders rounded-4 position-absolute">
						<h4>Orders</h4>
						<div class="itembox rounded-4 mb-2">
							<ul>
								<li
									class="d-flex align-items-center mb-4 justify-content-between"
								>
									<div>SO-RS-0046973</div>
									<span class="badge rounded-pill bg-light-warning text-warning"
										>Processing</span
									>
								</li>
								<li class="mb-4">
									<div class="text-secondary mb-2">Dates</div>
									<div>Order date: 4:30pm, Sep 25,2023</div>
								</li>
								<li class="mb-4">
									<div class="text-secondary mb-2">CONTACT</div>
									<div>Dominik Tyka dominiktyka@gmail.com</div>
								</li>
								<li class="mb-4">
									<div class="text-secondary mb-2">Total</div>
									<div>$518.76</div>
								</li>
							</ul>
						</div>
						<div class="itembox rounded-4 mb-2">
							<div class="accordion accordion-flush" id="ordersaccordian">
								<div class="accordion-item">
									<h2 class="accordion-header mb-3">
										<button
											class="accordion-button collapsed"
											type="button"
											data-bs-toggle="collapse"
											data-bs-target="#flush-collapseOne"
											aria-expanded="false"
											aria-controls="flush-collapseOne"
										>
											Products
											<span
												class="d-flex align-items-center justify-content-center ms-3 bg-white rounded-circle"
												>1</span
											>
										</button>
									</h2>
									<div
										id="flush-collapseOne"
										class="accordion-collapse collapse"
										data-bs-parent="#ordersaccordian"
									>
										<div class="accordion-body">
											<ul>
												<li
													class="d-flex align-items-center mb-4 justify-content-between"
												>
													<div>SO-RS-0046973</div>
													<span
														class="badge rounded-pill bg-light-warning text-warning"
														>Processing</span
													>
												</li>
												<li class="mb-4">
													<div class="text-secondary mb-2">Dates</div>
													<div>Order date: 4:30pm, Sep 25,2023</div>
												</li>
												<li class="mb-4">
													<div class="text-secondary mb-2">CONTACT</div>
													<div>Dominik Tyka dominiktyka@gmail.com</div>
												</li>
												<li class="mb-4">
													<div class="text-secondary mb-2">Total</div>
													<div>$518.76</div>
												</li>
											</ul>
										</div>
									</div>
								</div>
								<div class="accordion-item">
									<h2 class="accordion-header mb-3">
										<button
											class="accordion-button collapsed"
											type="button"
											data-bs-toggle="collapse"
											data-bs-target="#flush-collapseTwo"
											aria-expanded="false"
											aria-controls="flush-collapseTwo"
										>
											Products
											<span
												class="d-flex align-items-center justify-content-center ms-3 bg-white rounded-circle"
												>2</span
											>
										</button>
									</h2>
									<div
										id="flush-collapseTwo"
										class="accordion-collapse collapse"
										data-bs-parent="#accordionFlushExample"
									>
										<div class="accordion-body">
											<ul>
												<li
													class="d-flex align-items-center mb-4 justify-content-between"
												>
													<div>SO-RS-0046973</div>
													<span
														class="badge rounded-pill bg-light-warning text-warning"
														>Processing</span
													>
												</li>
												<li class="mb-4">
													<div class="text-secondary mb-2">Dates</div>
													<div>Order date: 4:30pm, Sep 25,2023</div>
												</li>
												<li class="mb-4">
													<div class="text-secondary mb-2">CONTACT</div>
													<div>Dominik Tyka dominiktyka@gmail.com</div>
												</li>
												<li class="mb-4">
													<div class="text-secondary mb-2">Total</div>
													<div>$518.76</div>
												</li>
											</ul>
										</div>
									</div>
								</div>
								<div class="accordion-item">
									<h2 class="accordion-header mb-3">
										<button
											class="accordion-button collapsed"
											type="button"
											data-bs-toggle="collapse"
											data-bs-target="#flush-collapseThree"
											aria-expanded="false"
											aria-controls="flush-collapseThree"
										>
											Products
											<span
												class="d-flex align-items-center justify-content-center ms-3 bg-white rounded-circle"
												>3</span
											>
										</button>
									</h2>
									<div
										id="flush-collapseThree"
										class="accordion-collapse collapse"
										data-bs-parent="#accordionFlushExample"
									>
										<div class="accordion-body">
											<ul>
												<li
													class="d-flex align-items-center mb-4 justify-content-between"
												>
													<div>SO-RS-0046973</div>
													<span
														class="badge rounded-pill bg-light-warning text-warning"
														>Processing</span
													>
												</li>
												<li class="mb-4">
													<div class="text-secondary mb-2">Dates</div>
													<div>Order date: 4:30pm, Sep 25,2023</div>
												</li>
												<li class="mb-4">
													<div class="text-secondary mb-2">CONTACT</div>
													<div>Dominik Tyka dominiktyka@gmail.com</div>
												</li>
												<li class="mb-4">
													<div class="text-secondary mb-2">Total</div>
													<div>$518.76</div>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="size-selector">
						<div class="frm">
							<input
								type="radio"
								class=""
								name="options-base"
								id="option5"
								autocomplete="off"
								checked
							/>
							<label class="labelA" for="option5"></label>
							<input
								type="radio"
								class=""
								name="options-base"
								id="option6"
								autocomplete="off"
							/>
							<label class="labelB" for="option6"></label>
						</div>
					</div>
					<div class="position-absolute alloca bg-white">
						<div class="p-3 d-flex align-items-center gap-3">
							<a
								href="#"
								class="border rounded-circle d-flex align-items-center justify-content-center p-3"
							>
								<img src="../../../../assets/images/pcard.svg" alt="" />
							</a>
							<span>
								<div class="fs-6">2 garments</div>
							</span>
						</div>
					</div>
					<div class="position-absolute sizes bg-white">
						<div class="d-flex align-items-center gap-3 p-3 rads">
							<input type="radio" id="size-s" name="size" value="s" checked />
							<label for="size-s">S</label>

							<input type="radio" id="size-m" name="size" value="m" />
							<label for="size-m">M</label>

							<input type="radio" id="size-l" name="size" value="l" />
							<label for="size-l">L</label>

							<input type="radio" id="size-xl" name="size" value="xl" />
							<label for="size-xl">XL</label>

							<input type="radio" id="size-xxl" name="size" value="xxl" />
							<label for="size-xxl">XXL</label>
						</div>
					</div>
					<div class="position-absolute sumbgsvg">
						<img src="../../../../assets/images/safetybgimg.svg" alt="" />
					</div>
					<button class="addbutton position-absolute">
						<img src="../../../../assets/images/plusIcon.svg" alt="" />
					</button>
				</div>
			</div>
			<div class="items">
				<div class="minhead text-warning">
					<img src="../../../../assets/images/infoicon.svg" alt="" />
					SUMS - PRODUCT
				</div>
				<h2>
					Safety Uniform <br />
					Management System
				</h2>
				<p>
					Reali values efficiency and customer satisfaction more than anything
					else, which is why we tackle every project with our exclusive and
					customised in-house system: Safety Uniform Management System (SUMS).
				</p>
				<div class="btngrps">
					<button
						class="btn btn-primary"
						(click)="navigateAbove()"
						[routerLink]="['/safety-uniform-management-system']"
					>
						Get to know us
					</button>
					<a
						href="https://www.seek.com.au/reali-supply-jobs/in-All-Brisbane-QLD"
						target="_blank"
						class="btn btn-light"
						role="button"
					>
						Join our team
					</a>
				</div>
			</div>
		</div>
	</div>
</section>
<!-- ============================ -->
<!-- sumsProduct section end here... -->
<!-- ============================ -->

<!-- ============================ -->
<!-- access section start here... -->
<!-- ============================ -->
<section class="access py-lg-0">
	<div class="max-width-container">
		<div class="container">
			<div class="contents">
				<div class="items">
					<div class="img-box">
						<img src="../../../../assets/images/printer.svg" alt="" />
					</div>
					<p>Viewing individual product offerings, pricing and product data</p>
				</div>
				<div class="items">
					<div class="img-box">
						<img src="../../../../assets/images/globe.svg" alt="" />
					</div>
					<p>
						Multiple user logins for each client account with different user
						roles
					</p>
				</div>
				<div class="items">
					<div class="img-box">
						<img src="../../../../assets/images/circle.svg" alt="" />
					</div>
					<p>
						Specialised customisations available for individual client needs
					</p>
				</div>
				<div class="items">
					<div class="img-box">
						<img src="../../../../assets/images/fi_book.svg" alt="" />
					</div>
					<p>
						Ongoing development roadmap and production timelines, based on
						client feedback
					</p>
				</div>
				<div class="items">
					<div class="img-box">
						<img src="../../../../assets/images/circle.svg" alt="" />
					</div>
					<p>Placing and reviewing orders</p>
				</div>
				<div class="items">
					<div class="img-box">
						<img src="../../../../assets/images/placeholder.svg" alt="" />
					</div>
					<p>Performance reporting</p>
				</div>
				<div class="items">
					<div class="img-box">
						<img src="../../../../assets/images/Interaction.svg" alt="" />
					</div>
					<p>Review client spending</p>
				</div>
				<div class="items">
					<div class="img-box">
						<img src="../../../../assets/images/truck.svg" alt="" />
					</div>
					<p>24/7 accessibility</p>
				</div>
			</div>
		</div>
	</div>
</section>
<!-- ============================ -->
<!-- access section end here... -->
<!-- ============================ -->

<!-- ===================================== -->
<!-- BMD-Testimonial section start here... -->
<!-- ===================================== -->
<section class="whatcl">
	<div class="max-width-container">
		<div class="container">
			<div class="minhead text-warning">
				<img src="../../../../assets/images/infoicon.svg" alt="" />TESTIMONIALS
			</div>
			<h2 class="mb-5">What clients say <span class="inhh">about us</span></h2>
			<ngx-slick-carousel
				class="carousel testimonials"
				#slickModal="slick-carousel"
				[config]="slideConfig"
			>
				<div class="contents" ngxSlickItem *ngFor="let slide of slides">
					<div class="items">
						<div class="img-box">
							<img src="{{ slide.logo }}" alt="Logo" />
						</div>
						<p>
							{{ slide.text }}
							<br />
							<br />
							<strong>{{ slide.user }}</strong> , {{ slide.subText }}
						</p>
					</div>
					<div class="client">
						<div *ngFor="let data of slide.images" class="client-item">
							<img src="{{ data.img }}" alt="" width="100%" />
						</div>
					</div>
				</div>
			</ngx-slick-carousel>
		</div>
	</div>
</section>
<!-- =================================== -->
<!-- BMD-Testimonial section end here... -->
<!-- =================================== -->

<!-- ============================ -->
<!-- what_clients section start here... -->
<!-- ============================ -->
<section class="what_clients d-none">
	<div class="max-width-container">
		<div class="container">
			<div class="minhead text-warning">
				<img src="../../../../assets/images/infoicon.svg" alt="" />
				BLOG
			</div>
			<div class="top-heading">
				<h2>
					All the latest trends
					<span class="inhh"
						>, best practices, helpful tips and industry news.</span
					>
				</h2>
				<button class="btn btn-light d-inline w-auto">All blog posts</button>
			</div>
			<div class="contents">
				<div class="items">
					<a href="javascript:void(0);">
						<div class="card">
							<div class="card-body">
								<div class="img-box">
									<span class="btn rounded-circle wlcliconr">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="16"
											height="16"
											viewBox="0 0 16 16"
											fill="none"
										>
											<path
												d="M1 8H15M15 8L8 1M15 8L8 15"
												stroke="black"
												stroke-width="1.5"
												stroke-linecap="round"
												stroke-linejoin="round"
											/>
										</svg>
									</span>
									<img
										class="rounded-4 d-none d-md-block"
										src="../../../../assets/images/Frame 1160445416.jpg"
										alt=""
									/>
									<img
										class="rounded-4 d-block d-md-none"
										src="../../../../assets/images/mobwlc1.png"
										alt=""
									/>
								</div>
								<h6 class="text-sky">UNIFORMS</h6>
								<h5>There are many variations of passages</h5>
								<p class="text-secondary">
									There are many variations of passages of Lorem Ipsum
									available, but the majority have suffered alteration in some
									form, by injected humour, or randomised words which don't look
									even slightly believable.
								</p>
							</div>
						</div>
					</a>
				</div>
				<div class="items">
					<a href="javascript:void(0);">
						<div class="card">
							<div class="card-body">
								<div class="img-box">
									<span class="btn rounded-circle wlcliconr">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="16"
											height="16"
											viewBox="0 0 16 16"
											fill="none"
										>
											<path
												d="M1 8H15M15 8L8 1M15 8L8 15"
												stroke="black"
												stroke-width="1.5"
												stroke-linecap="round"
												stroke-linejoin="round"
											/>
										</svg>
									</span>
									<img
										class="rounded-4 d-none d-md-block"
										src="../../../../assets/images/Frame 1160445416 (1).jpg"
										alt=""
									/>
									<img
										class="rounded-4 d-block d-md-none"
										src="../../../../assets/images/mobwlc2.png"
										alt=""
									/>
								</div>
								<h6 class="text-warning">REALI</h6>
								<h5>There are many variations of passages</h5>
								<p class="text-secondary">
									There are many variations of passages of Lorem Ipsum
									available, but the majority have suffered alteration in some
									form, by injected humour, or randomised words which don't look
									even slightly believable.
								</p>
							</div>
						</div>
					</a>
				</div>
				<div class="items">
					<a href="javascript:void(0);">
						<div class="card">
							<div class="card-body">
								<div class="img-box">
									<span class="btn rounded-circle wlcliconr">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="16"
											height="16"
											viewBox="0 0 16 16"
											fill="none"
										>
											<path
												d="M1 8H15M15 8L8 1M15 8L8 15"
												stroke="black"
												stroke-width="1.5"
												stroke-linecap="round"
												stroke-linejoin="round"
											/>
										</svg>
									</span>
									<img
										class="rounded-4 d-none d-md-block"
										src="../../../../assets/images/Frame 1160445416 (2).jpg"
										alt=""
									/>
									<img
										class="rounded-4 d-block d-md-none"
										src="../../../../assets/images/mobwlc3.png"
										alt=""
									/>
								</div>
								<h6 class="text-sky">UNIFORMS</h6>
								<h5>There are many variations of passages</h5>
								<p class="text-secondary">
									There are many variations of passages of Lorem Ipsum
									available, but the majority have suffered alteration in some
									form, by injected humour, or randomised words which don't look
									even slightly believable.
								</p>
							</div>
						</div>
					</a>
				</div>
				<div class="items">
					<a href="javascript:void(0);">
						<div class="card">
							<div class="card-body">
								<div class="img-box">
									<span class="btn rounded-circle wlcliconr">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="16"
											height="16"
											viewBox="0 0 16 16"
											fill="none"
										>
											<path
												d="M1 8H15M15 8L8 1M15 8L8 15"
												stroke="black"
												stroke-width="1.5"
												stroke-linecap="round"
												stroke-linejoin="round"
											/>
										</svg>
									</span>
									<img
										class="rounded-4 d-none d-md-block"
										src="../../../../assets/images/Frame 1160445416 (3).jpg"
										alt=""
									/>
									<img
										class="rounded-4 d-block d-md-none"
										src="../../../../assets/images/mobwlc4.png"
										alt=""
									/>
								</div>
								<h6 class="text-warning">REALI</h6>
								<h5>There are many variations of passages</h5>
								<p class="text-secondary">
									There are many variations of passages of Lorem Ipsum
									available, but the majority have suffered alteration in some
									form, by injected humour, or randomised words which don't look
									even slightly believable.
								</p>
							</div>
						</div>
					</a>
				</div>
			</div>
		</div>
	</div>
</section>
<!-- ============================ -->
<!-- what_clients section end here... -->
<!-- ============================ -->
