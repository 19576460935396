import { EventEmitter, Injectable, Output } from "@angular/core";
import { UnleashedCustomerExtended } from "../model/unleashed.model";
import { User } from "../model/user.model";
import { ShippingDetailsClass } from "../model/shippingDetail.model";
import { BehaviorSubject, Observable, distinctUntilChanged, skip } from "rxjs";
import { IRole } from "../model/role.model";
import { Router } from "@angular/router";
import { JwtService } from "../services/jwt.service";
import { SessionApi } from "./session.api";
import { logger } from "../util/Logger";

export interface IFavoriteList {
  UserID: string;
  Guid: string;
  Name: string;
  IsShared?: boolean;
  Products?: Array<{
    productGuid: string;
  }>;
  ProductCount?: number;
}

@Injectable()
export class GlobalApi {
  private className = "Globals";

  public isEmulatingUser: boolean = false;
  public loggedIn: boolean = false;

  isAdmin = false;
  userId: string | null = null;
  token: string | null = null;
  User: User;

  actualCustomer: UnleashedCustomerExtended = new UnleashedCustomerExtended()

  get customerUserId(): number | null | undefined {
    if (this.isAdmin || !this.actualCustomer || !this.actualCustomer.CustomerUser) return null;

    const customerUserNum = Number(this.actualCustomer.CustomerUser.UserID);

    if (isNaN(customerUserNum)) return null;

    return customerUserNum;
  }

  /** Guarantees the customerAccess array exists */
  get customerAccess(): Array<UnleashedCustomerExtended> {
    return this._customerAccess || [];
  }
  set customerAccess(val: Array<UnleashedCustomerExtended>) {
    this._customerAccess = val || [];
  }
  _customerAccess: Array<UnleashedCustomerExtended> = [];

  /** Refactor */

  shippingDetails: ShippingDetailsClass;

  public readonly readyState = new BehaviorSubject<boolean>(false);

  /** /Refactor */

  // The default customer
  public readonly customerSubject = new BehaviorSubject<UnleashedCustomerExtended | null>(null);

  get customerObservable(): Observable<UnleashedCustomerExtended | null> {
    return this.customerSubject.asObservable();
  }

  get customerChangedObservable(): Observable<any> {
    return this.customerObservable
      .pipe(
        skip(1),
        distinctUntilChanged()
      );
  }

  set customer(customer: UnleashedCustomerExtended | null) {
    this.customerSubject.next(customer);
  }

  get customer(): UnleashedCustomerExtended | null {
    return this.customerSubject.getValue();
  }

  get currentRole(): IRole | null {
    if (
      this.customer &&
      this.customer.CustomerUserRole &&
      this.customer.CustomerUserRole.name &&
      this.customer.CustomerUserRole.name.length
    ) {
      return this.customer.CustomerUserRole as IRole;
    }

    return null;
  }

  // This is the customer the user is currently acting within, and their role within that customer

  @Output()
  sessionReset: EventEmitter<any> = new EventEmitter();

  @Output()
  sessionChanged: EventEmitter<any> = new EventEmitter();
  /* Getter and setter for logged in to clear the queue */
  _ready = false;
  sessionQueue: Array<() => void> = [];

  constructor(
    public router: Router,
    private readonly jwtService: JwtService,
    private readonly session: SessionApi
  ) {
  }

  /**
   * @description Decodes the user JWT and sets appropriate session properties based on the information found
   * @returns {boolean} True when authentication against the stored JWT is successful, false otherwise
   */
  readonly authenticate = (): boolean => {
    const signature = this.className + ".authenticate: ";

    const jwtData = this.jwtService.decodeJWT();

    if (!jwtData) {
      logger.silly(signature + "Authentication failed due to Falsy jwtData");
      this.session.$jwtData.next(null);
      return false;
    }

    logger.silly(signature + `Updating JWT Data`);
    this.session.$jwtData.next(jwtData);

    this.loggedIn = true;
    this.isEmulatingUser = !!jwtData.emulateUser;

    logger.silly(signature + `Authentication Succeeded. IsEmulatingUser[${this.isEmulatingUser}]`);

    this.sessionChanged.emit();

    return true;
  };

  reset = () => {
    this.resetValues();
    this.sessionReset.emit();
    this.sessionChanged.emit();
  };

  resetValues() {
    const signature = this.className + ".resetValues: ";
    logger.silly(signature + `Reset`);
    this.loggedIn = false;
    this.isEmulatingUser = false;

    this.isAdmin = false;
    this.userId = null;
    this.token = null;
    this._ready = true;
    this.customer;
    this.User;
    this.customerAccess = [];

    this.shippingDetails;
  }

  ready = () => {
    const signature = this.className + ".ready: ";
    logger.info(signature + "Session is Ready");
    this._ready = true;
    this.sessionChanged.emit();
    this.readyState.next(true);
    this.clearSessionQueue();
  };

  withSession = (func: (_?: any) => void) => {
    const signature = this.className + ".withSession: ";
    /**
     * The reason we check the length is if it has length, it needs to execute in order
     */
    if (!this._ready || this.sessionQueue.length) {
      logger.silly(signature + "Pushing function to session Queue");
      this.sessionQueue.push(func);
    } else {
      func();
    }

    this.clearSessionQueue();
  };

  clearSessionQueue = () => {
    const signature = "Globals.clearSessionQueue: ";

    if (this.sessionQueue.length && this._ready) {
      /** 
       * Note: Before changing this comment read the method carefully. In its current iteration
       * it executes the menu items in the order they were added to the queue
       */
      logger.info(signature + `Executing Session Queue Function[0] of ${this.sessionQueue.length}`);

      (this.sessionQueue.splice(0, 1))[0]();

      this.clearSessionQueue();
    }
  };

  /*
   * This function should check if the user is currently authenticated, and if they are not, redirect them
   */
  requireAuth = (closure: any) => {
    const signature = "Globals.requireAuth: ";

    if (!this.token) {
      logger.info(signature + "User must be authentication to proceed");
      this.router.navigate(['']);
    } else {
      logger.silly(signature + "User has valid token and may proceed");
      return closure();
    }
  }

  /**
   * Returns the customerId Appropriate for the current user
   */
  getCustomerId = (customerId?: number | string | null, throwOnNull: boolean = false): number | null => {
    if (this.isAdmin) {
      if (customerId !== null && customerId !== undefined)
        return Number(customerId);
      if (throwOnNull)
        throw new Error("Must have a customerId to return when dealing with an admin user");

      return null;
    }

    if (this.customer)
      return this.customer.id || null;

    if (throwOnNull)
      throw new Error("Must have a valid customerId");

    return null;
  }
}
