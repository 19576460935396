<div class="table-head">
  <div class="position-relative">
    <span class="search-icon">
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path
          d="M16.7499 16.75L13.4874 13.4875M15.25 9.25C15.25 12.5637 12.5637 15.25 9.25 15.25C5.93629 15.25 3.25 12.5637 3.25 9.25C3.25 5.93629 5.93629 3.25 9.25 3.25C12.5637 3.25 15.25 5.93629 15.25 9.25Z"
          stroke="#818898" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </span>
    <input #searchText type="text" class="form-control search-control" id="searchUsers" placeholder="Search"
      (keyup)="updateSearchTerm(searchText.value)" />
  </div>
  <div class="d-flex flex-column flex-xl-row align-items-end align-items-xl-center justify-content-end w-100 gap-3">
    <div *ngIf="isScope" class="sorted">
      <h5>Exclude by:</h5>
      <div class="dropdown">
        <button class="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
          {{ scopeText }}
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M6.40039 8.19922L10.0004 11.7992L13.6004 8.19922" stroke="#36394A" stroke-width="1.5"
                stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </span>
        </button>
        <ul class="dropdown-menu">
          <li *ngFor="let item of scopeOptions" (click)="updateScopeField(item.scope)">
            <a class="dropdown-item">{{item.text}}</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="sorted">
      <h5>Sort by:</h5>
      <div class="dropdown">
        <button class="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
          {{ sortText }}
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M6.40039 8.19922L10.0004 11.7992L13.6004 8.19922" stroke="#36394A" stroke-width="1.5"
                stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </span>
        </button>
        <ul class="dropdown-menu">
          <li *ngFor="let item of sortOptions" (click)="updateSortField(item.field)">
            <a class="dropdown-item">{{item.text}}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>