import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { has } from 'lodash';
import { Subject, Subscription, combineLatest, debounceTime, distinctUntilChanged } from 'rxjs';
import { SessionApi } from 'src/app/main/api/session.api';
import { IDocument } from 'src/app/main/model/document.model';
import { IQueryFilter, QueryResult, ScopeOption } from 'src/app/main/model/query.filter.class';
import { DocumentService } from 'src/app/main/services/document.service';
import { SecurityService } from 'src/app/main/services/security.service';
import { SubscriptionGroup } from 'src/app/main/util/subscriptionGroup';

@Component({
  selector: 'app-customer-document',
  templateUrl: './document.component.html',
  styleUrls: []
})
export class DocumentComponent implements OnInit, OnDestroy {
  private readonly subscriptionGroup = new SubscriptionGroup();
  public isAdmin: boolean = false;
  public canEditDocuments = false;
  public editLink = '';
  public totalCountActive: number = 0;
  public currentPageActive: number = 1;
  private searchTerms: Subject<string> = new Subject<string>();
  private searchSubscription: Subscription;
  public isCustomer: boolean = false;

  public query: IQueryFilter = new IQueryFilter({
    sortBy: 'name',
    scope: 'ExcludeArchived'
  });

  public sortOptions = [{
    id: 1,
    text: "Document Name",
    field: "name"
  }];

  readonly scopeOptions = [{
    text: "Exclude Archived (Default)",
    scope: "ExcludeArchived"
  }, {
    text: "Include Archived",
    scope: "IncludeArchived"
  }, {
    text: "Archived Only",
    scope: "ArchivedOnly"
  }]

  public queryResult: QueryResult<IDocument> = new QueryResult();
  public isLoading: boolean;
  constructor(
    private documentService: DocumentService,
    public securityService: SecurityService,
    public activatedRoute: ActivatedRoute,
    private session: SessionApi,
  ) { }

  ngOnInit(): void {
    this.monitorAccess();
    this.search();
    this.subscriptionGroup.add(
      combineLatest(
        this.activatedRoute.params,
        this.session.$userData,
        this.session.$customerData
      ).subscribe(([params, userData, customerData]) => {
        let customerId: number | null = null;
        this.editLink = '/account/documents/edit';
        if (userData?.isAdmin) {
          this.isAdmin = true;
          if (has(params, "customerId")) {
            customerId = Number(params["customerId"]);
            const currentUrl = this.activatedRoute.snapshot.url.join('/');
            if (currentUrl.includes('view')) {
              this.editLink = `/manage/customer/view/${customerId}/document`;
            } else {
              this.editLink = `/manage/customer/edit/${customerId}/document`;
            }
            this.query.filter.customerId = customerId;
            this.loadQueryResult();
          }
        } else if (customerData) {
          this.query.filter.customerId = customerData.id;
          this.isCustomer = true;
          this.loadQueryResult();
        }

      })
    );
  }

  monitorAccess() {
    // View access is inferred by access to this component
    this.subscriptionGroup.add(this.securityService.canEditDocumentAccess().subscribe(canEditDocuments => this.canEditDocuments = canEditDocuments))
  }

  /**
 * @description Fetches the query result from the API using the current query object
 */
  loadQueryResult(): void {
    this.isLoading = true;
    if (this.query.sortBy === "updated_at")
      this.query.order = "desc";
    else
      this.query.order = "asc";

    this.documentService.list(this.query)
      .subscribe(queryResult => {
        this.isLoading = false;
        this.queryResult = queryResult;
      });
  }

  /**
 * @description Deletes an item from the database
 * @param {string} id The item to delete
 */
  async deleteItem(id: number | undefined) {
    if (id) {
      this.documentService.delete(id).subscribe(() => {
        this.loadQueryResult();
      });
    }
  }

  search() {
    this.searchSubscription = this.searchTerms.pipe(
      debounceTime(500),
      distinctUntilChanged(),
    ).subscribe(searchTerm => {
      if (searchTerm && searchTerm.length)
        this.query.filter.name = { $like: '%' + searchTerm + '%' };
      else
        delete this.query.filter.name;

      this.loadQueryResult();
    });
  }

  pageChangedActive(page: number) {
    this.currentPageActive = page;
    this.loadQueryResult()
  }

  /**
  * @description Ensures the page number is in sync across multiple pagination components
  *
  * @param {number} pageSize Broadcast pageSize value
  */
  pageSizeChanged(pageSize: number): void {
    this.query.limit = pageSize;
    this.currentPageActive = 1;
    this.loadQueryResult();
  }

  updateSearchTerm(searchTerm: string): void {
    this.searchTerms.next(searchTerm);
  }

  updateSortField(sort: string) {
    this.query.sortBy = sort;
    this.loadQueryResult();
  }

  updateScopeField(scope: string) {
    this.query.scope = scope as ScopeOption;
    this.loadQueryResult();
  }

  /**
 * @description UI helper method for retrieving the text of the selected sort option
 * @returns {string | undefined}
 */
  getSortText = (): string | undefined => {
    if (!this.query.sortBy)
      return undefined;

    const sortOption = this.sortOptions.find(option => option.field === this.query.sortBy);

    if (sortOption)
      return sortOption.text;
    return undefined;
  };

  /**
* @description UI helper method for retrieving the text of the selected scope option
* @returns {string | undefined}
*/
  getScopeText = (): string | undefined => {
    if (!this.query.scope)
      return undefined;

    const scopeOption = this.scopeOptions.find(option => option.scope === this.query.scope);

    if (scopeOption)
      return scopeOption.text;
    return undefined;
  };

  ngOnDestroy() {
    if (this.subscriptionGroup) {
      if (this.subscriptionGroup) {
        this.subscriptionGroup.unsubscribe();
      }
    }
  }
}
