<div class="main-right-content">
	<div
		class="d-flex flex-column flex-md-row gap-4 align-items-start justify-content-between"
	>
		<div class="w-sm-100">
			<h4 class="slogon">Nice to see you again!</h4>
			<h2 class="userName" *ngIf="user">Hello, {{ user.firstName }}</h2>
		</div>
		<div
			class="d-flex flex-column gap-4 align-items-end justify-content-between w-sm-100"
			*ngIf="user && !user.isAdmin"
		>
			<div
				class="d-flex align-items-center justify-content-between justify-content-md-end gap-5 w-sm-100 w-auto"
			>
				<div class="progress-garments" *ngIf="isShowAllocation">
					<app-allocation-loder (totalCalculated)="updateTotal($event)"></app-allocation-loder>
					<p>
						<span>Balance</span>
						{{ total }} garment{{ total == 1 ? "" : "s" }}
					</p>
				</div>
				<a
					*ngIf="hasOwnDetailAccess && actualUserId"
					[routerLink]="[
						'/account/details',
						actualUserId,
						'customer',
						customerId
					]"
					class="btn btn-primary"
					>Edit profile
					<span>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="20"
							height="20"
							viewBox="0 0 20 20"
							fill="none"
						>
							<path
								d="M8.2002 13.6004L11.8002 10.0004L8.2002 6.40039"
								stroke="white"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							></path>
						</svg>
					</span>
				</a>
				<a
					*ngIf="!user.isAdmin && actualUserId"
					[routerLink]="['/account/employe/view', actualUserId]"
					[queryParams]="{ isProfile: true }"
					class="btn btn-primary"
					>View profile
					<span>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="20"
							height="20"
							viewBox="0 0 20 20"
							fill="none"
						>
							<path
								d="M8.2002 13.6004L11.8002 10.0004L8.2002 6.40039"
								stroke="white"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							></path>
						</svg>
					</span>
				</a>
			</div>
			<!-- Multi Account dropdown for customer start here... -->
			<div class="multi-account" *ngIf="user && !user.isAdmin">
				<h5>Account:</h5>
				<div class="dropdown">
					<button
						class="btn bg-white main-shadow align-items-start justify-content-between justify-content-md-center position-relative"
						type="button"
						data-bs-toggle="dropdown"
						aria-expanded="false"
						[disabled]="isSwitch"
					>
						<ul class="account bg-white">
							<li *ngIf="globals.customer?.Guid === sortCustomers()[0].Guid">
								Current
							</li>
							<li *ngIf="globals.customer?.Guid !== sortCustomers()[0].Guid">
								Switch
							</li>
							<li
								class="fw-bold text-dark d-flex align-items-center justify-content-start gap-2"
								*ngIf="user"
							>
								{{ user.firstName }} {{ user.lastName }}
								<span class="badge rounded-pill ps-3 py-1">{{
									user.customerUsers &&
										user.customerUsers[0] &&
										user.customerUsers[0].userRole &&
										this.user.customerUsers[0].userRole.name
								}}</span>
							</li>
							<li>
								{{ sortCustomers()[0].CustomerName }}
							</li>
						</ul>
						<span class="ms-4">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="20"
								height="20"
								viewBox="0 0 20 20"
								fill="none"
							>
								<path
									d="M6.40039 8.19922L10.0004 11.7992L13.6004 8.19922"
									stroke="#36394A"
									stroke-width="1.5"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
							</svg>
						</span>

						<div class="position-absolute bg-loader" *ngIf="isSwitch">
							<div class="spinner-border text-primary-2" role="status">
								<span class="visually-hidden">Loading...</span>
							</div>
						</div>
					</button>
					<div class="dropdown-menu main-shadow">
						<div class="max-h-account" *ngIf="!isSwitch">
							<ul
								class="account bg-white p-4 m-2"
								*ngFor="let customer of sortCustomers()"
							>
								<li
									class="text-secondary text-start"
									*ngIf="globals.customer?.Guid === customer.Guid"
								>
									Current
								</li>
								<li
									(click)="switchCustomer(customer)"
									class="text-secondary text-start"
									*ngIf="globals.customer?.Guid !== customer.Guid"
								>
									Switch
								</li>
								<li
									class="fw-bold text-dark d-flex align-items-center justify-content-start gap-2"
									(click)="switchCustomer(customer)"
								>
									{{ user.firstName }} {{ user.lastName }}
									<span class="badge rounded-pill ps-3 py-1">{{
										user.customerUsers &&
											user.customerUsers[0] &&
											user.customerUsers[0].userRole &&
											this.user.customerUsers[0].userRole.name
									}}</span>
								</li>
								<li
									(click)="switchCustomer(customer)"
									class="text-secondary mt-1"
								>
									{{ customer.CustomerName }}
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<!-- Multi Account dropdown for customer end here... -->
		</div>
	</div>
	<!-- top-cards start here... -->
	<div class="dash-card">
		<div class="card border-0">
			<div
				class="card-body d-flex align-items-start justify-content-center flex-column"
			>
				<span class="card-logo">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="20"
						height="20"
						viewBox="0 0 20 20"
						fill="none"
					>
						<path
							d="M16.75 13.0004V7.00041C16.7497 6.73737 16.6803 6.47902 16.5487 6.25129C16.417 6.02355 16.2278 5.83444 16 5.70291L10.75 2.70291C10.522 2.57126 10.2633 2.50195 10 2.50195C9.73669 2.50195 9.47803 2.57126 9.25 2.70291L4 5.70291C3.7722 5.83444 3.58299 6.02355 3.45135 6.25129C3.31971 6.47902 3.25027 6.73737 3.25 7.00041V13.0004C3.25027 13.2635 3.31971 13.5218 3.45135 13.7495C3.58299 13.9773 3.7722 14.1664 4 14.2979L9.25 17.2979C9.47803 17.4296 9.73669 17.4989 10 17.4989C10.2633 17.4989 10.522 17.4296 10.75 17.2979L16 14.2979C16.2278 14.1664 16.417 13.9773 16.5487 13.7495C16.6803 13.5218 16.7497 13.2635 16.75 13.0004Z"
							fill="url(#paint0_radial_0_18227)"
							fill-opacity="0.5"
						/>
						<path
							d="M3.45251 6.22041L10 10.0079L16.5475 6.22041"
							fill="url(#paint1_radial_0_18227)"
							fill-opacity="0.5"
						/>
						<path
							d="M13.375 8.05041L6.625 4.15791M3.45251 6.22041L10 10.0079L16.5475 6.22041M10 17.5604V10.0004M16.75 13.0004V7.00041C16.7497 6.73737 16.6803 6.47902 16.5487 6.25129C16.417 6.02355 16.2278 5.83444 16 5.70291L10.75 2.70291C10.522 2.57126 10.2633 2.50195 10 2.50195C9.73669 2.50195 9.47803 2.57126 9.25 2.70291L4 5.70291C3.7722 5.83444 3.58299 6.02355 3.45135 6.25129C3.31971 6.47902 3.25027 6.73737 3.25 7.00041V13.0004C3.25027 13.2635 3.31971 13.5218 3.45135 13.7495C3.58299 13.9773 3.7722 14.1664 4 14.2979L9.25 17.2979C9.47803 17.4296 9.73669 17.4989 10 17.4989C10.2633 17.4989 10.522 17.4296 10.75 17.2979L16 14.2979C16.2278 14.1664 16.417 13.9773 16.5487 13.7495C16.6803 13.5218 16.7497 13.2635 16.75 13.0004Z"
							stroke="#272835"
							stroke-width="1.5"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
						<defs>
							<radialGradient
								id="paint0_radial_0_18227"
								cx="0"
								cy="0"
								r="1"
								gradientUnits="userSpaceOnUse"
								gradientTransform="translate(10 3.25488) rotate(90) scale(13.2455 11.8747)"
							>
								<stop stop-color="#8BD6EF" />
								<stop offset="1" stop-color="#8BD6EF" stop-opacity="0" />
							</radialGradient>
							<radialGradient
								id="paint1_radial_0_18227"
								cx="0"
								cy="0"
								r="1"
								gradientUnits="userSpaceOnUse"
								gradientTransform="translate(10 3.25488) rotate(90) scale(13.2455 11.8747)"
							>
								<stop stop-color="#8BD6EF" />
								<stop offset="1" stop-color="#8BD6EF" stop-opacity="0" />
							</radialGradient>
						</defs>
					</svg>
				</span>
				<div
					class="d-flex align-items-end justify-content-between w-100 gap-4 gap-md-0"
				>
					<div>
						<h4 class="title">Total orders</h4>
						<h5 class="counts">{{ totalOrderCount }}</h5>
					</div>
					<div class="d-flex align-items-center justify-content-end text-end">
						<span class="badge bg-green-25"
							>{{ utils.twoDecimalPlacesString(completionPercentage) }}%</span
						>
						<p class="growth">+{{ todayOrder }} orders today</p>
					</div>
				</div>
			</div>
		</div>
		<div class="card border-0">
			<div
				class="card-body d-flex align-items-start justify-content-center flex-column"
			>
				<span class="card-logo">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="20"
						height="20"
						viewBox="0 0 20 20"
						fill="none"
					>
						<path
							d="M8.71758 3.89589L2.36508 14.5009C2.2341 14.7277 2.1648 14.9849 2.16407 15.2468C2.16334 15.5087 2.23119 15.7662 2.3609 15.9938C2.4906 16.2213 2.67762 16.4109 2.90336 16.5438C3.12909 16.6766 3.38568 16.748 3.64758 16.7509H16.3526C16.6145 16.748 16.8711 16.6766 17.0968 16.5438C17.3225 16.4109 17.5096 16.2213 17.6393 15.9938C17.769 15.7662 17.8368 15.5087 17.8361 15.2468C17.8354 14.9849 17.7661 14.7277 17.6351 14.5009L11.2826 3.89589C11.1489 3.67547 10.9606 3.49323 10.736 3.36675C10.5113 3.24027 10.2579 3.17383 10.0001 3.17383C9.74228 3.17383 9.48882 3.24027 9.26418 3.36675C9.03954 3.49323 8.85128 3.67547 8.71758 3.89589Z"
							fill="url(#paint0_radial_0_18236)"
							fill-opacity="0.5"
						/>
						<path
							d="M8.71758 3.89589L2.36508 14.5009C2.2341 14.7277 2.1648 14.9849 2.16407 15.2468C2.16334 15.5087 2.23119 15.7662 2.3609 15.9938C2.4906 16.2213 2.67762 16.4109 2.90336 16.5438C3.12909 16.6766 3.38568 16.748 3.64758 16.7509H16.3526C16.6145 16.748 16.8711 16.6766 17.0968 16.5438C17.3225 16.4109 17.5096 16.2213 17.6393 15.9938C17.769 15.7662 17.8368 15.5087 17.8361 15.2468C17.8354 14.9849 17.7661 14.7277 17.6351 14.5009L11.2826 3.89589C11.1489 3.67547 10.9606 3.49323 10.736 3.36675C10.5113 3.24027 10.2579 3.17383 10.0001 3.17383C9.74228 3.17383 9.48882 3.24027 9.26418 3.36675C9.03954 3.49323 8.85128 3.67547 8.71758 3.89589V3.89589Z"
							stroke="#272835"
							stroke-width="1.5"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
						<path
							d="M10 7.75V10.75"
							stroke="#272835"
							stroke-width="1.5"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
						<path
							d="M10 13.75H10.0067"
							stroke="#272835"
							stroke-width="1.5"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
						<defs>
							<radialGradient
								id="paint0_radial_0_18236"
								cx="0"
								cy="0"
								r="1"
								gradientUnits="userSpaceOnUse"
								gradientTransform="translate(10.0001 3.85268) rotate(90) scale(9.84337 11.3622)"
							>
								<stop stop-color="#EF5C6D" />
								<stop offset="1" stop-color="#EF5C6D" stop-opacity="0" />
							</radialGradient>
						</defs>
					</svg>
				</span>
				<div
					class="d-flex align-items-end justify-content-between w-100 gap-4 gap-md-0"
				>
					<div>
						<h4 class="title">Backorders</h4>
						<h5 class="counts">{{ backOrderCount }}</h5>
					</div>
					<div class="d-flex align-items-center justify-content-end text-end">
						<a
							[routerLink]="[orderPath]"
							[queryParams]="{ activeTab: 3 }"
							role="button"
							class="btn dash-btn gap-0"
						>
							Go to orders
							<span
								><svg
									xmlns="http://www.w3.org/2000/svg"
									width="20"
									height="20"
									viewBox="0 0 20 20"
									fill="none"
								>
									<path
										d="M8.2002 13.6004L11.8002 10.0004L8.2002 6.40039"
										stroke="#666D80"
										stroke-width="1.5"
										stroke-linecap="round"
										stroke-linejoin="round"
									/></svg
							></span>
						</a>
					</div>
				</div>
			</div>
		</div>
		<div class="card border-0">
			<div
				class="card-body d-flex align-items-start justify-content-center flex-column"
			>
				<span class="card-logo">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="20"
						height="20"
						viewBox="0 0 20 20"
						fill="none"
					>
						<path
							d="M17.5 9.31039V10.0004C17.4991 11.6177 16.9754 13.1914 16.007 14.4868C15.0386 15.7821 13.6775 16.7297 12.1265 17.1883C10.5756 17.6469 8.91794 17.5918 7.40085 17.0313C5.88376 16.4708 4.58849 15.435 3.70822 14.0782C2.82795 12.7214 2.40984 11.1164 2.51626 9.50262C2.62267 7.88881 3.24791 6.35263 4.29871 5.12319C5.34951 3.89375 6.76959 3.03692 8.34714 2.6805C9.9247 2.32407 11.5752 2.48714 13.0525 3.14539"
							fill="url(#paint0_radial_0_18243)"
							fill-opacity="0.5"
						/>
						<path
							d="M17.5 9.31039V10.0004C17.4991 11.6177 16.9754 13.1914 16.007 14.4868C15.0386 15.7821 13.6775 16.7297 12.1265 17.1883C10.5756 17.6469 8.91794 17.5918 7.40085 17.0313C5.88376 16.4708 4.58849 15.435 3.70822 14.0782C2.82795 12.7214 2.40984 11.1164 2.51626 9.50262C2.62267 7.88881 3.24791 6.35263 4.29871 5.12319C5.34951 3.89375 6.76959 3.03692 8.34714 2.6805C9.92469 2.32407 11.5752 2.48714 13.0525 3.14539"
							stroke="#272835"
							stroke-width="1.5"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
						<path
							d="M17.5 4L10 11.5075L7.75 9.2575"
							stroke="#272835"
							stroke-width="1.5"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
						<defs>
							<radialGradient
								id="paint0_radial_0_18243"
								cx="0"
								cy="0"
								r="1"
								gradientUnits="userSpaceOnUse"
								gradientTransform="translate(4 14.0004) rotate(-30.9638) scale(14.5774)"
							>
								<stop stop-color="#6BECD4" />
								<stop offset="1" stop-color="#6BECD4" stop-opacity="0" />
							</radialGradient>
						</defs>
					</svg>
				</span>
				<div
					class="d-flex align-items-end justify-content-between w-100 gap-4 gap-md-0"
				>
					<div>
						<h4 class="title">Completed</h4>
						<h5 class="counts">{{ completedOrderCount }}</h5>
					</div>
					<div
						class="d-flex align-items-start align-items-md-center justify-content-end text-end"
					>
						<span class="badge bg-green-25"
							>{{ utils.twoDecimalPlacesString(completionPercentage) }}%</span
						>
						<p class="growth">
							{{ completedOrderCount }}/{{ totalOrderCount }} orders completed
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- top-cards end here... -->

	<div class="product-order">
		<div class="card border-0">
			<div
				class="card-head d-flex flex-column flex-md-row flex-lg-column flex-xl-row align-items-center align-items-lg-start justify-content-between gap-3"
			>
				<div class="d-flex align-items-center justify-content-start head-gap">
					<span>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="20"
							height="20"
							viewBox="0 0 20 20"
							fill="none"
						>
							<path
								d="M13.333 2.5H0.833008V13.3333H13.333V2.5Z"
								fill="url(#paint0_radial_0_18256)"
								fill-opacity="0.5"
							/>
							<path
								d="M13.333 6.66667H16.6663L19.1663 9.16667V13.3333H13.333V6.66667Z"
								fill="url(#paint1_radial_0_18256)"
								fill-opacity="0.5"
							/>
							<path
								d="M4.58301 17.5C5.7336 17.5 6.66634 16.5673 6.66634 15.4167C6.66634 14.2661 5.7336 13.3333 4.58301 13.3333C3.43241 13.3333 2.49967 14.2661 2.49967 15.4167C2.49967 16.5673 3.43241 17.5 4.58301 17.5Z"
								fill="url(#paint2_radial_0_18256)"
								fill-opacity="0.5"
							/>
							<path
								d="M15.4163 17.5C16.5669 17.5 17.4997 16.5673 17.4997 15.4167C17.4997 14.2661 16.5669 13.3333 15.4163 13.3333C14.2657 13.3333 13.333 14.2661 13.333 15.4167C13.333 16.5673 14.2657 17.5 15.4163 17.5Z"
								fill="url(#paint3_radial_0_18256)"
								fill-opacity="0.5"
							/>
							<path
								d="M13.333 13.3333V2.5H0.833008V13.3333H13.333ZM13.333 13.3333H19.1663V9.16667L16.6663 6.66667H13.333V13.3333ZM6.66634 15.4167C6.66634 16.5673 5.7336 17.5 4.58301 17.5C3.43241 17.5 2.49967 16.5673 2.49967 15.4167C2.49967 14.2661 3.43241 13.3333 4.58301 13.3333C5.7336 13.3333 6.66634 14.2661 6.66634 15.4167ZM17.4997 15.4167C17.4997 16.5673 16.5669 17.5 15.4163 17.5C14.2657 17.5 13.333 16.5673 13.333 15.4167C13.333 14.2661 14.2657 13.3333 15.4163 13.3333C16.5669 13.3333 17.4997 14.2661 17.4997 15.4167Z"
								stroke="#06273B"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
							<defs>
								<radialGradient
									id="paint0_radial_0_18256"
									cx="0"
									cy="0"
									r="1"
									gradientUnits="userSpaceOnUse"
									gradientTransform="translate(9.99968 3.25) rotate(90) scale(10.875 13.2917)"
								>
									<stop stop-color="#8BD6EF" />
									<stop offset="1" stop-color="#8BD6EF" stop-opacity="0" />
								</radialGradient>
								<radialGradient
									id="paint1_radial_0_18256"
									cx="0"
									cy="0"
									r="1"
									gradientUnits="userSpaceOnUse"
									gradientTransform="translate(9.99968 3.25) rotate(90) scale(10.875 13.2917)"
								>
									<stop stop-color="#8BD6EF" />
									<stop offset="1" stop-color="#8BD6EF" stop-opacity="0" />
								</radialGradient>
								<radialGradient
									id="paint2_radial_0_18256"
									cx="0"
									cy="0"
									r="1"
									gradientUnits="userSpaceOnUse"
									gradientTransform="translate(9.99968 3.25) rotate(90) scale(10.875 13.2917)"
								>
									<stop stop-color="#8BD6EF" />
									<stop offset="1" stop-color="#8BD6EF" stop-opacity="0" />
								</radialGradient>
								<radialGradient
									id="paint3_radial_0_18256"
									cx="0"
									cy="0"
									r="1"
									gradientUnits="userSpaceOnUse"
									gradientTransform="translate(9.99968 3.25) rotate(90) scale(10.875 13.2917)"
								>
									<stop stop-color="#8BD6EF" />
									<stop offset="1" stop-color="#8BD6EF" stop-opacity="0" />
								</radialGradient>
							</defs>
						</svg>
					</span>
					<h4 class="text-nowrap">
						Orders requiring approval
						<p class="mb-0">{{ approveOrderCount }}</p>
					</h4>
				</div>
				<div
					class="d-flex align-items-center justify-content-between justify-content-md-end head-gap flex-wrap gap-3"
				>
					<a
						[routerLink]="[orderPath]"
						*ngIf="approveOrderCount > 0"
						class="btn"
						role="button"
						>Approve all</a
					>
					<a [routerLink]="[orderPath]" role="button" class="btn dash-btn gap-0">
						Go to orders
						<span
							><svg
								xmlns="http://www.w3.org/2000/svg"
								width="20"
								height="20"
								viewBox="0 0 20 20"
								fill="none"
							>
								<path
									d="M8.2002 13.6004L11.8002 10.0004L8.2002 6.40039"
									stroke="#666D80"
									stroke-width="1.5"
									stroke-linecap="round"
									stroke-linejoin="round"
								/></svg
						></span>
					</a>
				</div>
			</div>
			<div class="card-body">
				<div
					class="order-approval d-flex align-items-center justify-content-between position-relative"
					*ngFor="let item of queryResult.rows"
				>
					<div class="approval-content">
						<a
							[routerLink]="[orderPath, item.id]"
							class="btn stretched-link p-0 shadow-none"
							role="button"
						>
							<h4>{{ item.orderNumber }}</h4>
						</a>
						<h5>{{ item.contactFirstName }} {{ item.contactLastName }}</h5>
						<h5 class="mb-0">${{ item.grandTotal }}</h5>
					</div>
					<div class="approval-btns d-flex align-items-end flex-column">
						<p>{{ item.orderedAt | date : "h:mma, MMM d, yyyy" }}</p>
						<div class="d-flex align-items-center">
							<button (click)="orderModals.openCancelModal(item)" class="btn btn-cancel me-2"
								*ngIf="showOrderApproval(item)">
								<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
									<path d="M14.5 5.5L5.5 14.5M5.5 5.5L14.5 14.5" stroke="#DF1C41" stroke-width="1.5"
										stroke-linecap="round" stroke-linejoin="round" />
								</svg>
							</button>
							<button (click)="orderModals.openModifyModal(item)" class="btn btn-cancel me-2"
								*ngIf="showOrderApproval(item)">
								<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
									<path
										d="M10 15.9992H16.75M13.375 3.62419C13.6734 3.32582 14.078 3.1582 14.5 3.1582C14.7089 3.1582 14.9158 3.19936 15.1088 3.27931C15.3019 3.35926 15.4773 3.47646 15.625 3.62419C15.7727 3.77193 15.8899 3.94732 15.9699 4.14035C16.0498 4.33338 16.091 4.54026 16.091 4.74919C16.091 4.95813 16.0498 5.16501 15.9699 5.35804C15.8899 5.55107 15.7727 5.72646 15.625 5.87419L6.25 15.2492L3.25 15.9992L4 12.9992L13.375 3.62419Z"
										stroke="#008000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
								</svg>
							</button>
							<button (click)="approveOrder(item)" class="btn btn-approved" type="button"
								*ngIf="showOrderApproval(item)">
								<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
									<path d="M16 5.5L7.75 13.75L4 10" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
										stroke-linejoin="round" />
								</svg>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="card border-0">
			<div class="card-head d-flex align-items-center justify-content-between">
				<div class="d-flex align-items-center justify-content-start head-gap">
					<span>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="20"
							height="20"
							viewBox="0 0 20 20"
							fill="none"
						>
							<path
								d="M3.33301 16.2493C3.33301 15.6968 3.5525 15.1669 3.9432 14.7762C4.3339 14.3855 4.86381 14.166 5.41634 14.166H16.6663"
								fill="url(#paint0_radial_0_18327)"
								fill-opacity="0.5"
							/>
							<path
								d="M5.41634 1.66602H16.6663V18.3327H5.41634C4.86381 18.3327 4.3339 18.1132 3.9432 17.7225C3.5525 17.3318 3.33301 16.8019 3.33301 16.2493V3.74935C3.33301 3.19681 3.5525 2.66691 3.9432 2.27621C4.3339 1.88551 4.86381 1.66602 5.41634 1.66602Z"
								fill="url(#paint1_radial_0_18327)"
								fill-opacity="0.5"
							/>
							<path
								d="M3.33301 16.2493C3.33301 15.6968 3.5525 15.1669 3.9432 14.7762C4.3339 14.3855 4.86381 14.166 5.41634 14.166H16.6663M3.33301 16.2493C3.33301 16.8019 3.5525 17.3318 3.9432 17.7225C4.3339 18.1132 4.86381 18.3327 5.41634 18.3327H16.6663V1.66602H5.41634C4.86381 1.66602 4.3339 1.88551 3.9432 2.27621C3.5525 2.66691 3.33301 3.19681 3.33301 3.74935V16.2493Z"
								stroke="#06273B"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
							<defs>
								<radialGradient
									id="paint0_radial_0_18327"
									cx="0"
									cy="0"
									r="1"
									gradientUnits="userSpaceOnUse"
									gradientTransform="translate(9.99968 2.49935) rotate(90) scale(12.0833 9.66667)"
								>
									<stop stop-color="#8BD6EF" />
									<stop offset="1" stop-color="#8BD6EF" stop-opacity="0" />
								</radialGradient>
								<radialGradient
									id="paint1_radial_0_18327"
									cx="0"
									cy="0"
									r="1"
									gradientUnits="userSpaceOnUse"
									gradientTransform="translate(9.99968 2.49935) rotate(90) scale(12.0833 9.66667)"
								>
									<stop stop-color="#8BD6EF" />
									<stop offset="1" stop-color="#8BD6EF" stop-opacity="0" />
								</radialGradient>
							</defs>
						</svg>
					</span>
					<h4>Top products</h4>
				</div>
				<div class="d-flex align-items-center justify-content-end head-gap">
					<div class="dropdown top-product">
						<button
							class="btn dash-btn dropdown-toggle"
							type="button"
							data-bs-toggle="dropdown"
							aria-expanded="false"
						>
							Top {{ productQuery.limit }}
							<span
								><svg
									xmlns="http://www.w3.org/2000/svg"
									width="20"
									height="20"
									viewBox="0 0 20 20"
									fill="none"
								>
									<path
										d="M6.40039 8.19922L10.0004 11.7992L13.6004 8.19922"
										stroke="#818898"
										stroke-width="1.5"
										stroke-linecap="round"
										stroke-linejoin="round"
									/></svg
							></span>
						</button>
						<ul class="dropdown-menu more-menu drop-list-menu">
							<li class="drop-item">
								<a (click)="setQuery('5')" class="dropdown-link" role="button">
									Top 5
								</a>
							</li>
							<li class="drop-item">
								<a (click)="setQuery('10')" class="dropdown-link" role="button">
									Top 10
								</a>
							</li>
							<li class="drop-item">
								<a (click)="setQuery('20')" class="dropdown-link" role="button">
									Top 20
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="card-body" *ngIf="products.length">
				<div class="orders position-relative" *ngFor="let data of products">
					<div class="order-img">
						<img
							src="{{
								data.product.imageUrl || '../../../assets/images/product-1.png'
							}}"
							alt="product-1"
						/>
					</div>
					<div class="order-content">
						<div>
							<a
								[routerLink]="['/product/' + data.product.uri]"
								class="btn text-start stretched-link p-0 shadow-none"
							>
								<h4>{{ data.product.name }}</h4>
							</a>
							<p class="mb-0" *ngIf="data.product.brand">
								{{ data.product.brand.name }}
							</p>
						</div>
						<div class="order-price">
							<span class="badge">{{ data.count }}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<!-- chart-bar start here... -->
	<div class="chart-card" *ngIf="canShowChart">
		<div
			class="chart-card-head d-flex align-items-start justify-content-between w-100"
		>
			<div>
				<h5 class="title">Money spent</h5>
				<h4 class="price">
					${{ utils.twoDecimalPlacesString(totalGrandTotal) }}
				</h4>
			</div>
			<div class="growth-wise">
				<ul>
					<li>
						<a
							(click)="setChartType('1')"
							class="btn"
							[class.active]="chartQuery.limit == 1"
							role="button"
							>1 month</a
						>
					</li>
					<li>
						<a
							(click)="setChartType('3')"
							class="btn"
							role="button"
							[class.active]="chartQuery.limit == 3"
							>3 months</a
						>
					</li>
					<li>
						<a
							(click)="setChartType('6')"
							class="btn"
							role="button"
							[class.active]="chartQuery.limit == 6"
							>6 months</a
						>
					</li>
					<li>
						<a
							(click)="setChartType('12')"
							class="btn"
							role="button"
							[class.active]="chartQuery.limit == 12"
							>1 year</a
						>
					</li>
				</ul>
			</div>
		</div>
		<div
			class="chart-img w-100 table-responsive"
			*ngIf="chartOptions && fetchedChatData"
		>
			<apx-chart
				[series]="chartOptions.series ?? []"
				[chart]="chartOptions.chart ?? getDefaultChart()"
				[xaxis]="chartOptions.xaxis ?? {}"
				[stroke]="chartOptions.stroke ?? {}"
				[dataLabels]="chartOptions.dataLabels ?? {}"
				[yaxis]="chartOptions.yaxis ?? {}"
				[labels]="chartOptions.labels ?? []"
				[legend]="chartOptions.legend ?? {}"
				[title]="chartOptions.title ?? {}"
				[subtitle]="chartOptions.subtitle ?? {}"
				[grid]="chartOptions.grid ?? {}"
			>
			</apx-chart>
		</div>
	</div>

	<!-- Recently order table start here... -->
	<div class="recent-orders">
		<h5 class="title">Recent orders</h5>
		<div class="recent-order-table table-responsive">
			<table class="table table-borderless">
				<thead>
					<tr>
						<th>Order No</th>
						<th>Contact</th>
						<th>Order date</th>
						<th>Status</th>
						<th>Total</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let item of queryResult.rows">
						<td>
							<h5 [routerLink]="[orderPath, item.id]">
								{{ item.orderNumber }}
							</h5>
						</td>
						<td>
							<p>{{ item.contactFirstName }} {{ item.contactLastName }}</p>
							<p>{{ item.contactEmail }}</p>
						</td>
						<td>
							<p>{{ item.orderedAt | date : "h:mma, MMM d, yyyy" }}</p>
						</td>
						<td>
							<span class="badge bg-{{ fetchColor(item.status) }}-25">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="20"
									height="20"
									viewBox="0 0 20 20"
									fill="none"
								>
									<circle cx="10" cy="10" r="2" fill="#0C4E6E" />
								</svg>
								{{
								item.status == "APPROVAL"
								? "Pending Approval"
								: capitalizeFirstLetter(item.status)
								}}</span>
						</td>
						<td>
							<p>${{ utils.twoDecimalPlacesString(item.grandTotal) }}</p>
						</td>
					</tr>
					<tr *ngIf="queryResult.rows.length === 0 && !isLoading">
						<td colspan="6">
							<div class="no-results-content">
								<h4 class="title">No Recent Orders!</h4>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
	<!-- Recently order table end here... -->

	<!-- footer-for-Admin-adhboard start here... -->
	<footer class="dash-footer">
		<div class="flinks">
			<h6 class="fw-bold text-secondary">SUPPORT</h6>
			<ul>
				<li>
					<a
						role="button"
						class="footer-link"
						[routerLink]="['/shipping-delivery']"
						(click)="checkRoute()"
						>Shipping & Delivery</a
					>
				</li>
				<li>
					<a
						role="button"
						[routerLink]="['/returns-exchanges']"
						(click)="checkRoute()"
						class="footer-link"
						>Returns & Exchanges</a
					>
				</li>
				<li>
					<a class="footer-link" role="button" href="javascript:void(0);"
						>Size Guide</a
					>
				</li>
				<li>
					<a class="footer-link" role="button" href="javascript:void(0);"
						>FAQ</a
					>
				</li>
			</ul>
		</div>
		<div class="copy-right">
			<p class="text-secondary">Copyright © 2024 Reali</p>
		</div>
	</footer>
	<!-- footer-for-Admin-adhboard start here... -->
</div>

<app-shared-order-model #orderModals (saveCompleted)="onSaveCompleted()"></app-shared-order-model>
