import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { IQueryFilter, QueryResult } from '../../model/query.filter.class';
import { IGroup } from '../../model/group.model';
import { GroupService } from '../../services/group.service';
import { Subject, Subscription, debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { UnleashedCustomer } from '../../model/unleashed.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-group-list',
  templateUrl: './list.component.html',
  styleUrls: []
})
export class GroupListComponent implements OnInit, OnChanges, OnDestroy {
  public totalCountActive: number = 0;
  public currentPageActive: number = 1;
  private searchTerms: Subject<string> = new Subject<string>();
  @Input() customer: UnleashedCustomer;
  isCustomer: boolean = false;
  private unsubscribe$ = new Subject<void>();
  query: IQueryFilter = new IQueryFilter({
    sortBy: 'name'
  });
  public isLoading: boolean;

  sortOptions = [{
    id: 1,
    text: "Group Name",
    field: "name"
  }];

  queryResult: QueryResult<IGroup> = new QueryResult();
  constructor(
    private groupService: GroupService,
    public route: Router,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(queryparams => {
      this.currentPageActive = +queryparams['page'] || 1;
      this.query.limit = +queryparams['pageSize'] || 10;
      this.query.skip = (this.currentPageActive * this.query.limit) - this.query.limit;
      this.loadQueryResult();
    });
    this.search();
  }

  /**
  * @description UI helper method for retrieving the text of the selected sort option
  * @returns {string | undefined}
  */
  getSortText = (): string | undefined => {
    if (!this.query.sortBy)
      return undefined;

    const sortOption = this.sortOptions.find(option => option.field === this.query.sortBy);

    if (sortOption)
      return sortOption.text;
    return undefined;
  };

  pageChangedActive(page: number) {
    this.currentPageActive = page;
    this.loadQueryResult()
  }

  /**
 * @description Ensures the page number is in sync across multiple pagination components
 *
 * @param {number} pageSize Broadcast pageSize value
 */
  pageSizeChanged(pageSize: number): void {
    this.query.limit = pageSize;
    this.loadQueryResult();
  }

  loadQueryResult() {
    this.query.skip = (this.currentPageActive * this.query.limit) - this.query.limit;
    if (this.query.sortBy === "updated_at")
      this.query.order = "desc";
    else
      this.query.order = "asc";

    this.groupService.list(this.query).pipe(
      takeUntil(this.unsubscribe$)
    )
      .subscribe(queryResult => {
        this.isLoading = false;
        this.queryResult = queryResult;
        this.totalCountActive = queryResult.count;
      });
  }

  search() {
    this.searchTerms.pipe(
      debounceTime(500),
      distinctUntilChanged(),
    ).pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(searchTerm => {
      this.query.filter.name = { $like: searchTerm + '%' };
      this.loadQueryResult();
    });
  }

  updateSearchTerm(searchTerm: string): void {
    this.searchTerms.next(searchTerm);
  }

  updateSortField(sort: string) {
    this.query.sortBy = sort;
    this.loadQueryResult();
  }

  deleteGroup(groupId: number | undefined) {
    if (groupId) {
      this.groupService.delete(groupId).pipe(
        takeUntil(this.unsubscribe$)
      ).subscribe(() => {
        this.loadQueryResult();
      })
    }
  }

  ngOnChanges(): void {
    if (this.customer) {
      this.isCustomer = true;
      this.query.filter.customerId = this.customer.id;
      this.loadQueryResult();
    }
  }

  public openRemoveModal(groupId: number | undefined, content) {
    this.modalService.open(content, {
      size: 'sm', centered: true, backdrop: true, windowClass: 'deleteModal'
    }).result.then(() => {
      this.deleteGroup(groupId);
    }, () => { });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
