import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IQueryFilter } from '../../model/query.filter.class';
import { ActivatedRoute, NavigationEnd, Params, Router, RouterEvent } from '@angular/router';
import { EnumCreateParams, NewProduct } from '../../model/ddb.model';
import { SubscriptionGroup } from '../../util/subscriptionGroup';
import { ProductService } from '../../services/product.service';
import { CartService } from '../../services/cart.service';
import { ServerCartItem } from '../../model/cart.model';
import { Utils } from '../../util/utils';
import { GlobalApi } from '../../api/global.api';
import { Subject, debounceTime, takeUntil } from 'rxjs';
import { IShippingDetails, ShippingDetailsClass } from '../../model/shippingDetail.model';
import { SessionApi } from '../../api/session.api';
import { SecurityService } from '../../services/security.service';

@Component({
  selector: 'app-quick-order-list',
  templateUrl: './quick-order-list.component.html',
  styleUrls: []
})
export class QuickOrderListComponent implements OnInit, OnDestroy {
  private readonly subscriptionGroup = new SubscriptionGroup();
  public queryParams: Params = {};
  categories: EnumCreateParams[] = [];
  lastHandledUrl: string = '';
  public categoryName: string;
  query: IQueryFilter = new IQueryFilter({
    sortBy: 'name',
    limit: 5000
  });
  queryResult: any[] = [];
  filteredResult: any;
  public customerId: number;
  public existingCartItems: ServerCartItem[] = [];
  private unsubscribe$ = new Subject<void>();
  public totalGarment: number = 0;
  public avlGarment: number = 0;
  public activeId: number;
  public completionPercentage: number;
  public userShippingDetails: IShippingDetails | null;
  public allowCheckout: boolean = false;
  public isLoading = false;
  constructor(
    public activatedRoute: ActivatedRoute,
    public router: Router,
    private productService: ProductService,
    private cartService: CartService,
    public utils: Utils,
    public globals: GlobalApi,
    public session: SessionApi,
    private securityService: SecurityService
  ) {
  }
  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.queryParams = params;
      this.loadLastAddress();
      this.globals.withSession(() => {

        if (this.globals.customer?.id) {
          this.customerId = this.globals.customer.id;
          this.subscriptionGroup.add(
            this.router.events.subscribe((event: RouterEvent) => {
              if (event instanceof NavigationEnd) {
                if (window.location.href.match(/\/#\/quick-order([\/\?].*)?$/)) {
                  this.handleRouteParamsChange();
                }
              }
            })
          );

          this.subscriptionGroup.add(
            this.activatedRoute.fragment.subscribe(() => {
              this.handleRouteParamsChange();
            })
          );
        }
      });
    });

    this.activatedRoute.fragment.subscribe(fragment => {
      setTimeout(() => {
        if (fragment) {
          this.activeId = +fragment;
        }
      }, 100)
    });

    this.securityService.isStopCredit().pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(stopCredit => {
      this.allowCheckout = !stopCredit;
    });

    this.securityService.isObsolete().pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(obsolete => {
      this.allowCheckout = !obsolete;
    });

  };

  /**
  * @description ensures the breadcrumbs reflect the category tree that is currently in the url in the format of
  * /parent/childOfParent/childOfChildOf.../etc
  *
  * @param params
  */
  handleRouteParamsChange = () => {

    if (window.location.href === this.lastHandledUrl)
      return;

    this.lastHandledUrl = window.location.href;
    const uriParts = window.location.href.split("#");
    const filterBy = {
      category: false,
      text: false,
    };

    // Filter the categories
    const keyString = '/quick-order';
    if (uriParts.length > 1 && uriParts[1] !== keyString) {
      const categoryFilter = uriParts[1].replace(/\?.*$/, '').replace(/^\/quick-order\//, '');
      this.categoryName = categoryFilter;
      if (categoryFilter.length && categoryFilter !== keyString) {
        this.query.filter.uri = { $like: categoryFilter + '%' };

        filterBy.category = true;
      }
    }

    if (uriParts.length > 2) {
      const searchStringMatch = uriParts[2].match(/search=([^&]+)/);

      if (searchStringMatch && searchStringMatch.length > 1) {
        const searchTerm = decodeURI(searchStringMatch[1]);

        this.query.filter['$or'] = {
          name: { $like: '%' + searchTerm + '%' },
          code: { $like: '%' + searchTerm + '%' },
          uri: { $like: '%' + searchTerm + '%' },
          description: { $like: '%' + searchTerm + '%' },
        }

        filterBy.text = true;
      }
    }

    if (!filterBy.category) delete this.query.filter.uri;
    if (!filterBy.text) delete this.query.filter['$or'];

    this.getProducts();
    this.fetchCartItems();
  };

  /**
  * @description Retrives products from the server and attaches them to the component
  */
  getProducts = () => {
    this.isLoading = true;
    this.productService.getAllocationProductList(this.query)
      .pipe(
        debounceTime(1000),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(queryResult => {
        this.isLoading = false;
        this.queryResult = queryResult.rows;
        if (queryResult.rows.length && !this.activeId) {
          this.activeId = queryResult.rows[0].id;
          this.filteredResult = queryResult.rows[0];
        } else {
          this.filteredResult = this.queryResult.find(coll => coll.id == this.activeId);
        }
        if (this.queryResult.length) {
          this.totalGarment = this.queryResult[0].allocationCount;
          this.avlGarment = this.queryResult[0].allocationAvailable;
          this.completionPercentage = Math.round(((this.totalGarment - this.avlGarment) / this.totalGarment) * 100);
          const remainder = this.completionPercentage % 10;
          if (remainder >= 5) {
            this.completionPercentage = Math.ceil(this.completionPercentage / 10) * 10;
          } else {
            this.completionPercentage = Math.floor(this.completionPercentage / 10) * 10;
          }
        }

      });
  }

  loadLastAddress() {
    this.userShippingDetails = this.session.getUserProperty('shippingDetails');
    if (!this.userShippingDetails?.addressName && !this.userShippingDetails?.streetAddress) {
      this.cartService.getLastAddress().pipe(takeUntil(this.unsubscribe$)).subscribe(res => {
        this.userShippingDetails = res;
      });
    }
  }

  /**
* Generates easily read display text for any given shipping details item
* @param details
* @param includeName
*/
  addressDisplayText(details: (ShippingDetailsClass & { displayName?: string }), includeName = false) {
    return (includeName && (details.addressName || details.displayName) ? (details.displayName || details.addressName) + ': ' : '') +
      (details.streetAddress ? details.streetAddress + ' ' : '') +
      (details.streetAddress2 ? details.streetAddress2 + ' ' : '') +
      (details.suburb ? details.suburb : '');
  }

  setActive(id: number) {
    this.activeId = id;
    this.filteredResult = this.queryResult.find(coll => coll.id == id);
  }

  fetchCartItems() {
    this.cartService.getCartItems(this.customerId)
      .pipe(
        debounceTime(1000),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((items) => {
        for (let i of items) {
          let productImage = i.product.images.find(image => image.name === i.variation.colour)
          i.variation.imageUrl = productImage ? productImage.url : i.product.imageUrl as string | undefined;
        }
        this.existingCartItems = items;
      })
  }

  getUnitPrice(item: ServerCartItem) {
    const cartItems = item.asNewCartItem();
    return cartItems.getUnitPrice();
  }

  generateGradient(colorString: string): string {
    if (colorString) {
      const colors = colorString.split('/');
      if (colors.length >= 2) {
        const firstColor = colors[0].trim();
        const secondColor = colors[1].trim();
        return `linear-gradient(0deg, ${firstColor} 50%, ${secondColor} 50%)`;
      } else if (colorString) {
        return colorString;
      } else {
        return 'default';
      }
    } else {
      return 'default';
    }
  }

  handleAddToCartClicked() {
    this.fetchCartItems();
    this.getProducts();
  }

  /**
* @description advances the user to the next step of the checkout process
*/
  checkout = () => (this.allowCheckout ? this.router.navigate(['/cart/checkout']) : null);

  ngOnDestroy() {
    if (this.subscriptionGroup) {
      this.subscriptionGroup.unsubscribe();
    }
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
