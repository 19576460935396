import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { EnumCreateParams } from '../../model/ddb.model';
import { SecurityService } from '../../services/security.service';
import { CategoryService } from '../../services/category.service';
import { IQueryFilter, QueryResult } from '../../model/query.filter.class';
import { ActivatedRoute, NavigationEnd, Params, Router, RouterEvent } from '@angular/router';
import { ICollection } from '../../model/collection.model';
import { CollectionService } from '../../services/collection.service';
import _ from 'lodash';
import { SessionApi } from '../../api/session.api';
import { ProductService } from '../../services/product.service';
import { SubscriptionGroup } from '../../util/subscriptionGroup';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: []
})
export class CategoriesComponent implements OnInit, AfterViewInit, OnDestroy {
  private subscriptionGroup = new SubscriptionGroup();

  public categories: EnumCreateParams[] = [];
  queryParams: Params = {};
  primaryCategory = '';
  secondaryCategory = '';
  categoriesPath: string[] = [];
  collections: QueryResult<ICollection> = new QueryResult();
  breadcrumbParts: Array<{ link: string, text: string }> = [];
  @ViewChild('subCategory', { static: false }) subCategoryRef: ElementRef;
  showCollections: boolean = false;
  public isOpen: boolean = true;
  public sortText: string | undefined;
  public innerWidth: number;
  get totalProductCount(): number {
    if (this.categories && this.categories.length) {
      return this.categories
        .filter(val => !val.parentId)
        .reduce((sum, val) => {
          return sum + (val.count || 0);
        }, 0);
    }
    return 0;
  }

  sortOptions = [{
    text: "Product Name",
    field: "name"
  }, {
    text: "Price",
    field: "basePrice"
  }, {
    text: "Modified Date",
    field: "updatedAt"
  }, {
    text: "Modified By",
    field: "modified_by"
  }];

  constructor(
    private securityService: SecurityService,
    private categoryService: CategoryService,
    private router: Router,
    private collectionsService: CollectionService,
    private activatedRoute: ActivatedRoute,
    private session: SessionApi,
    private productService: ProductService,
    private cdRef: ChangeDetectorRef,
    private authService: AuthService
  ) {
    if (innerWidth > 992) {
      this.isOpen = true;
    } else {
      this.isOpen = false;
    }
  }

  ngAfterViewInit(): void {
    this.cdRef.detectChanges();
  }

  checkAuthenticated() {
    return this.authService.isAuthenticated();
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.queryParams = params;

      // if (this.showCategories) {
      this.subscriptionGroup.add(
        this.categoryService.allCategories$.subscribe({
          next: res => {
            this.handleCategoriesGet(res.rows)
          }
        })
      );
      // }

      this.session.$customerData.subscribe((customer) => {
        if (customer && customer.id && typeof customer.showProductSearchCollections === 'boolean' &&
          customer.showProductSearchCollections) {
          this.showCollections = true;
          this.collectionsService.list(
            new IQueryFilter({
              sortBy: 'name',
              limit: 200
            })
          )
            .subscribe(queryResult => this.collections = queryResult);
        } else {
          if (!this.showCategories || !this.checkAuthenticated()) {
            this.isOpen = false;
            this.productService.setFilterSort(false);
          }
        }
      });


      this.activatedRoute.firstChild?.params.subscribe(this.handleChildParamsChange);

      this.router.events.subscribe((event: RouterEvent) => {
        if (event instanceof NavigationEnd) {
          this.activatedRoute.firstChild?.params.subscribe(this.handleChildParamsChange);
        }
      });
    });
    this.getSortText();
  }

  ngOnDestroy(): void {
    this.subscriptionGroup.unsubscribe();
  }

  /**
* @description UI helper method for retrieving the text of the selected sort option
* @returns {string | undefined}
*/
  getSortText() {
    this.productService.getSort().subscribe(sort => {
      this.sortText = this.sortOptions.find(opt => opt.field === sort)?.text || "Product Name";
    });
  };


  checkFilter() {
    this.isOpen = !this.isOpen;
    this.productService.setFilterSort(this.isOpen);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    if (innerWidth > 992) {
      this.isOpen = true;
    } else {
      // this.isOpen = false;
    }
  }

  get showCategories(): boolean {
    return this.securityService ? this.securityService.showCategoriesInProductSearch() : true;
  }

  /**
 * @description Standardised conversion of category name to url format
 * @param categoryName
 */
  toUrlKey = (categoryName: string) => categoryName.replace(/\s/g, '_').toLowerCase();

  /**
 * @description Determines if the supplied category has any child categories
 *
 * @param {EnumCreateParams} parentCategory
 *
 * @returns {boolean}
 */
  hasChildCategories = (parentCategory: EnumCreateParams) => !!this.categories.find(category => category.parentId === parentCategory.id);

  /**
 * @description Handles the response from a category list get
 * @param items the response from the communication service
 */
  handleCategoriesGet = (items: EnumCreateParams[]) => this.categories = items;

  getCategoriesByParent = (parentCategory?: EnumCreateParams) => this.categories.filter(category => category.parentId === (parentCategory ? parentCategory.id : null));

  handleCategoryClick(primaryCategory?: string, secondaryCategory?: string, checked?: boolean, clearFilter?: boolean) {
    if (checked) {
      this.primaryCategory = primaryCategory ? this.toUrlKey(primaryCategory) : '';
      this.secondaryCategory = secondaryCategory ? this.toUrlKey(secondaryCategory) : '';
      this.categoriesPath = [];
      if (this.primaryCategory) {
        this.categoriesPath.push(this.primaryCategory);
      }

      let targetRoute = ['products'];
      if (this.primaryCategory && this.primaryCategory.length) {
        targetRoute.push(this.primaryCategory);
        if (this.secondaryCategory && this.secondaryCategory.length) {
          targetRoute.push(this.secondaryCategory);
        }
      }

      this.router.navigate(targetRoute, {
        queryParams: this.queryParams
      });

      if (clearFilter && this.subCategoryRef) {
        this.subCategoryRef.nativeElement.checked = false;
      }

      this.router.navigate(targetRoute, { queryParams: this.queryParams });
    }
  }

  /**
 * @description ensures the breadcrumbs reflect the category tree that is currently in the url in the format of
 * /parent/childOfParent/childOfChildOf.../etc
 *
 * @param params
 */
  handleChildParamsChange = () => {
    this.activatedRoute.fragment.subscribe(data => {
      if (data) {
        this.primaryCategory = '';
        this.secondaryCategory = '';
      }
    })
    const hash = window.location.hash;
    const uri = hash.substring('#/products'.length);
    if (!uri.includes('#')) {
      const uri = window.location.hash.replace(/^#\/products\/?/, '').replace(/\?.*$/, '');
      this.categoriesPath = uri.split(/\//);

      this.primaryCategory = '';
      this.secondaryCategory = '';

      if (this.categoriesPath.length > 0) {
        this.primaryCategory = this.categoriesPath[0].toLowerCase();
      }

      if (this.categoriesPath.length > 1) {
        this.secondaryCategory = this.categoriesPath[1].toLowerCase();
      }
    }

    this.buildBreadcrumbs();
  };

  buildBreadcrumbs = () => {
    this.breadcrumbParts = [];

    if (!this.categories || !this.categories.length) {
      return;
    }

    let uri = '/products';
    let parentId: string | null = null;

    for (let path of this.categoriesPath) {
      const category = this.categories.find(category => category.parentId === parentId && this.toUrlKey(path) === this.toUrlKey(category.name));

      if (category) {
        uri += '/' + this.toUrlKey(path);
        this.breadcrumbParts.push({ text: category.name, link: uri });

        parentId = category.id;
      } else {
        break;
      }
    }
  };

  handleCollectionClick(collection: ICollection) {
    let urlTree = this.router.parseUrl(this.router.url);
    urlTree.queryParams['collection'] = String(collection.id);
    this.router.navigateByUrl(urlTree);
  }

  /** Reloads the current page with all the queryParams stripped out */
  removeQueryParams(key: string) {
    this.queryParams = _.omit(this.queryParams, key);
    this.router.navigate([window.location.hash.replace(/\?.*$/, '').replace(/^#/, '')], {
      queryParams: this.queryParams
    });
  }

  updateSortField(sortField: string): void {
    this.productService.setSort(sortField);
  }

  checkCategoryName(category: string) {
    return this.primaryCategory.replace(/_/g, ' ') == category.toLowerCase();
  }

  checkSecondCategory(category: string) {
    return this.secondaryCategory.replace(/_/g, ' ') == category.toLowerCase();
  }
}
