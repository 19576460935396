<div class="manage-user-list">
	<app-search [sortText]="getSortText()" [sortOptions]="sortOptions" (searchChanged)="updateSearchTerm($event)"
		(sortChanged)="updateSortField($event)"></app-search>

	<!-- table-content for lg screen start here... -->
	<div class="manage-table main-table d-none d-lg-block" [class.table-responsive]="queryResult.rows.length > 1">
		<table class="table">
			<thead>
				<tr>
					<th>Order No</th>
					<th>Contact</th>
					<th>Order date</th>
					<th>Business Unit</th>
					<th>Status</th>
					<th>Total</th>
					<th>Action</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let item of queryResult.rows">
					<td>
						<div class="user-name">
							<h5>
								<a [routerLink]="getDetailLink(item.id)">{{
									item.orderNumber
									}}</a>
							</h5>
						</div>
					</td>
					<td>
						<p class="mb-0">
							{{ item.contactFirstName }} {{ item.contactLastName }}
						</p>
						<p class="mb-0">{{ item.contactEmail }}</p>
					</td>

					<td>
						<p>{{ item.orderedAt | date : "h:mma, MMM d, yyyy" }}</p>
					</td>
					<td>
						<p>{{ item.businessUnitId ? getBusinessUnitText(item) : "N/A" }}</p>
					</td>
					<td>
						<span class="badge bg-{{ fetchColor(item.status) }}-25">
							<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
								<circle cx="10" cy="10" r="2" fill="transparent"></circle>
							</svg>
							{{
							item.status == "APPROVAL"
							? "Pending Approval"
							: capitalizeFirstLetter(item.status)
							}}
						</span>
					</td>
					<td>
						<p>${{ utils.twoDecimalPlacesString(item.grandTotal) }}</p>
					</td>
					<td>
						<div class="d-flex align-items-center justify-content-start">
							<div class="dropdown" *ngIf="hasAnyActions(item)">
								<button class="btn btn-more dropdown-toggle" type="button" data-bs-toggle="dropdown"
									aria-expanded="false">
									<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
										<path
											d="M10 16C10.4142 16 10.75 15.6642 10.75 15.25C10.75 14.8358 10.4142 14.5 10 14.5C9.58579 14.5 9.25 14.8358 9.25 15.25C9.25 15.6642 9.58579 16 10 16Z"
											stroke="#818898" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
										<path
											d="M10 10.75C10.4142 10.75 10.75 10.4142 10.75 10C10.75 9.58579 10.4142 9.25 10 9.25C9.58579 9.25 9.25 9.58579 9.25 10C9.25 10.4142 9.58579 10.75 10 10.75Z"
											stroke="#818898" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
										<path
											d="M10 5.5C10.4142 5.5 10.75 5.16421 10.75 4.75C10.75 4.33579 10.4142 4 10 4C9.58579 4 9.25 4.33579 9.25 4.75C9.25 5.16421 9.58579 5.5 10 5.5Z"
											stroke="#818898" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
									</svg>
								</button>
								<ul class="dropdown-menu more-menu">
									<ul class="drop-list-menu">
										<li class="drop-item">
											<a (click)="approveOrder(item)" *ngIf="showOrderApproval(item)" class="dropdown-link"
												role="button">
												Approve
											</a>
										</li>
										<li class="drop-item">
											<a (click)="orderModals.openCancelModal(item)" *ngIf="showOrderApproval(item)"
												class="dropdown-link" role="button">
												Reject Order
											</a>
										</li>
										<li class="drop-item">
											<a (click)="orderModals.deleteOrder(item)" *ngIf="showDelete(item)" class="dropdown-link"
												role="button">
												Delete Order
											</a>
										</li>
										<li class="drop-item">
											<a (click)="resendConfirmationEmail(item)" *ngIf="showResendConfirmationEmail(item)"
												class="dropdown-link" role="button">
												Resend Email Confirmation
											</a>
										</li>
										<li class="drop-item">
											<a (click)="orderModals.openModifyModal(item)" *ngIf="showOrderApproval(item)"
												class="dropdown-link" role="button">
												Modify Details
											</a>
										</li>
										<li class="drop-item">
											<a (click)="resendApprovalEmail(item)" *ngIf="showResendApprovalEmail(item)" class="dropdown-link"
												role="button">
												Resend Approval Email
											</a>
										</li>
										<li class="drop-item">
											<a (click)="reOrder(item.id)" *ngIf="showReOrder(item)" class="dropdown-link" role="button">
												Reorder
											</a>
										</li>
									</ul>
								</ul>
							</div>
							<a [routerLink]="getDetailLink(item.id)" class="btn btn-edit" role="button">
								<svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
									<path d="M5.25 10H15.75" stroke="#818898" stroke-width="1.5" stroke-linecap="round"
										stroke-linejoin="round"></path>
									<path d="M10.5 4.75L15.75 10L10.5 15.25" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
										stroke-linejoin="round"></path>
								</svg>
							</a>
						</div>
					</td>
				</tr>
				<tr *ngIf="queryResult.rows.length === 0 && !isLoading">
					<td colspan="6">
						<div class="no-results-content">
							<div class="no-result-img for-admin">
								<img src="../../../../assets/images/No-result.png" alt="No-result" />
							</div>
							<h4 class="title">No Results Found!</h4>
						</div>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
	<!-- table-content for lg screen end here... -->

	<!-- Cards for sm screen start here... -->
	<div class="manage-cards d-lg-none">
		<div class="card manage-card" *ngFor="let item of queryResult.rows">
			<div class="card-body">
				<ul class="card-list">
					<li>
						<div class="manage-grid">
							<span class="head"> Order No </span>
							<div class="user-name">
								<h5>{{ item.orderNumber }}</h5>
							</div>
						</div>
					</li>
					<li>
						<div class="manage-grid">
							<span class="head"> Contact </span>
							<div>
								<p>{{ item.contactFirstName }} {{ item.contactLastName }}</p>
								<p>{{ item.contactEmail }}</p>
							</div>
						</div>
					</li>
					<li>
						<div class="manage-grid">
							<span class="head"> Order date </span>
							<p>{{ item.orderedAt | date : "h:mma, MMM d, yyyy" }}</p>
						</div>
					</li>
					<li>
						<div class="manage-grid">
							<span class="head"> Business Unit </span>
							<p>
								{{ item.businessUnitId ? getBusinessUnitText(item) : "N/A" }}
							</p>
						</div>
					</li>
					<li>
						<div class="manage-grid">
							<span class="head"> Status </span>
							<span class="badge bg-{{ fetchColor(item.status) }}-25 fw-bold">
								<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
									<circle cx="10" cy="10" r="2" fill="transparent"></circle>
								</svg>
								{{
								item.status == "APPROVAL"
								? "Pending Approval"
								: capitalizeFirstLetter(item.status)
								}}
							</span>
						</div>
					</li>
					<li>
						<div class="manage-grid">
							<span class="head"> Total </span>
							<p>${{ utils.twoDecimalPlacesString(item.grandTotal) }}</p>
						</div>
					</li>
					<li>
						<div class="manage-grid">
							<span class="head"> Actions: </span>
							<div class="d-flex align-items-center w-100 justify-content-end">
								<div *ngIf="hasAnyActions(item)">
									<button class="btn btn-more" type="button" data-bs-toggle="offcanvas"
										[attr.data-bs-target]="'#' + item.orderNumber" aria-controls="bottomMore">
										<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
											<path
												d="M10 16C10.4142 16 10.75 15.6642 10.75 15.25C10.75 14.8358 10.4142 14.5 10 14.5C9.58579 14.5 9.25 14.8358 9.25 15.25C9.25 15.6642 9.58579 16 10 16Z"
												stroke="#818898" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
											<path
												d="M10 10.75C10.4142 10.75 10.75 10.4142 10.75 10C10.75 9.58579 10.4142 9.25 10 9.25C9.58579 9.25 9.25 9.58579 9.25 10C9.25 10.4142 9.58579 10.75 10 10.75Z"
												stroke="#818898" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
											<path
												d="M10 5.5C10.4142 5.5 10.75 5.16421 10.75 4.75C10.75 4.33579 10.4142 4 10 4C9.58579 4 9.25 4.33579 9.25 4.75C9.25 5.16421 9.58579 5.5 10 5.5Z"
												stroke="#818898" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
										</svg>
									</button>
									<!-- more-offanvas start here... -->
									<div class="offcanvas more-offcanvas offcanvas-bottom" tabindex="-1" [id]="item.orderNumber"
										aria-labelledby="bottomMoreLabel">
										<div class="offcanvas-body">
											<ul class="more-menu">
												<div class="drop-head">
													<div>
														<h6 class="title">
															{{ item.orderNumber }}
														</h6>
														<p class="desc">{{ item.contactEmail }}</p>
													</div>
													<button type="button" class="btn btn-secondary-icon p-2 shadow-none"
														data-bs-dismiss="offcanvas" aria-label="Close">
														<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
															fill="none">
															<path d="M14.5 5.5L5.5 14.5M5.5 5.5L14.5 14.5" stroke="#666D80" stroke-width="1.5"
																stroke-linecap="round" stroke-linejoin="round" />
														</svg>
													</button>
												</div>
												<hr class="solid-line" />
												<ul class="drop-list-menu">
													<li class="drop-item">
														<a (click)="approveOrder(item)" *ngIf="showOrderApproval(item)" class="dropdown-link"
															role="button">
															Approve
														</a>
													</li>
													<li class="drop-item">
														<a (click)="orderModals.openCancelModal(item)" *ngIf="showOrderApproval(item)"
															class="dropdown-link" role="button">
															Reject Order
														</a>
													</li>
													<li class="drop-item">
														<a (click)="orderModals.openCancelModal(item)" *ngIf="showOrderApproval(item)"
															class="dropdown-link" role="button">
															Delete Order
														</a>
													</li>
													<li class="drop-item">
														<a (click)="orderModals.openModifyModal(item)" *ngIf="showOrderApproval(item)"
															class="dropdown-link" role="button">
															Modify Details
														</a>
													</li>
													<li class="drop-item">
														<a (click)="resendConfirmationEmail(item)" *ngIf="showResendConfirmationEmail(item)"
															class="dropdown-link" role="button">
															Resend Email Confirmation
														</a>
													</li>
													<li class="drop-item">
														<a (click)="resendApprovalEmail(item)" *ngIf="showResendApprovalEmail(item)"
															class="dropdown-link" role="button">
															Resend Approval Email
														</a>
													</li>
												</ul>
											</ul>
										</div>
									</div>
									<!-- more-offanvas end here... -->
								</div>
								<a [routerLink]="getDetailLink(item.id)" class="btn btn-edit" role="button">
									<svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
										<path d="M5.25 10H15.75" stroke="#818898" stroke-width="1.5" stroke-linecap="round"
											stroke-linejoin="round"></path>
										<path d="M10.5 4.75L15.75 10L10.5 15.25" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
											stroke-linejoin="round"></path>
									</svg>
								</a>
							</div>
						</div>
					</li>
				</ul>
			</div>
		</div>
		<div class="no-results-content" *ngIf="queryResult.rows.length == 0 && !isLoading">
			<div class="no-result-img for-admin">
				<img src="../../../../assets/images/No-result.png" alt="No-result" />
			</div>
			<h4 class="title">No Results Found!</h4>
		</div>
	</div>
	<!-- Cards for sm screen end here... -->

	<app-pagination [currentPage]="currentPageActive" [pageSize]="query.limit" [totalRows]="totalCountActive"
		(pageChange)="pageChangedActive($event)" (pageSizeChange)="pageSizeChanged($event)"></app-pagination>
</div>

<app-shared-order-model #orderModals (saveCompleted)="onSaveCompleted()"></app-shared-order-model>