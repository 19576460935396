import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { SizeChartColumn, SizeChartEntry, SizeChartRow } from '../../model/sizeChart.model';
import { SizeChartService } from '../../services/sizeChart.service';

@Component({
  selector: 'app-sizechart-edit',
  templateUrl: './sizechart-edit.component.html',
  styleUrls: []
})
export class SizechartEditComponent implements OnInit {
  isNew: boolean;
  sizeChart: SizeChartEntry;
  isLoading = true;
  sizeChartId: number;
  newSizeName = '';
  chartNameError: boolean = false;

  constructor(
    public route: ActivatedRoute,
    private sizeChartService: SizeChartService,
    public router: Router
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      if (!params.id) {
        this.isNew = true;
        this.sizeChart = new SizeChartEntry();
        this.isLoading = false;
      } else {
        this.isNew = false;
        this.isLoading = true;
        this.sizeChartId = params.id;
        this.handleLoading()
      }
    });
  }

  private readonly handleLoading = () => {
    this.sizeChartService.getById(this.sizeChartId)
      .subscribe((sizeChart) => {
        this.sizeChart = sizeChart;
        this.isLoading = false;
      });
  };

  isValid() {
    this.chartNameError = !this.sizeChart.name || this.sizeChart.name.trim().length == 0;

    return !this.chartNameError;
  }

  private readonly processSaving = () => {
    if (this.isNew) {
      return this.sizeChartService.create(this.sizeChart);
    } else {
      return this.sizeChartService.update(this.sizeChart);
    }
  };

  public saveChart() {
    if (!this.isValid())
      return;

    this.processSaving()
      .subscribe(() => {
        if (!this.isNew) {
          this.router.navigate(['/manage/sizeChart']);
        }
      });
  }
}
