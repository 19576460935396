// import { UnleashedProduct } from './Unleashed/ProductListResult';
// import { DecorationDraft, DecorationOption } from "./v2/decoration.class";
// import { IUserGroup } from './v3/group';
// import { IRole } from './v3/roles';
// import { INewUser } from './v3/user';
// import { IUserAllocation } from './v3/userAllocation';

import { ICustomerUser } from "./customer.user.model";
import { DecorationDraft, DecorationOption } from "./decoration.model";


// export class CartItem {
// 	Guid?: string;
// 	UserID: string;
// 	ProductID: string;
// 	VariationID: string;
// 	UnitPrice: number;
// 	Product?: Product;
// 	//Rasterised at the time of a purchase from the product
// 	Price?: number;

// 	//Total number of units being ordered
// 	Quantity = 1;
// 	//The selected Pack, if null, assume Singular/1
// 	Pack: any;
// 	//The total number of packs
// 	Units = 1;

// 	//Decorations for the cart item
// 	Decorations: Array<any> = [];
// }

export class TinyProduct {
	Guid: string;
	ProductName: string;
	ProductCode: string;
}

// export class Product {
// 	Guid?: string;
// 	ProductName: string;
// 	ProductCode: string;
// 	AccessMode: AccessMode = 1;

// 	/*
// 	 * These are each of the inventory items from unleashed
// 	 */
// 	Variations: ProductVariation[] = [];
// 	/*
// 	 * This is the decoration options for the product.
// 	 * Before being saved, this should be cut down to just be the following:
// 	 *
// 	 * Details about the decoration such as costing should be loaded when the product is loaded from the database
// 	 *
// 	 * {
// 	 *  Guid : string,
// 	 *  ProductName : string,
// 	 *  ProductCode : string
// 	 * }
// 	 */
// 	Decorations: TinyProduct[] = [];

// 	ImageUrl: string;
// 	DataSheetUrl: string;
// 	LastUpdatedDate: string = null;
// 	InStock = false;
// 	UnitSizesBool = false;
// 	SizeChartTable = false;
// 	SizeChartUrl: string;
// 	SizeChartData: string;
// 	UnitSizes: Array<Array<any>> = [];
// 	/* Pricing Properties */
// 	/*
// 	 Price should be fixed only when the cart has been ordered.
// 	 Until then the price should be recalculated, every time
// 	 A non admin user retrieves it
// 	 */
// 	Price: number = null;
// 	DomesticPrice: number = null;
// 	CommercialPrice: number = null;
// 	IndustrialPrice: number = null;

// 	/* Product weight (grams) */
// 	Weight: number = null;

// 	/*
// 	 This is an array of customers formatted like so.
// 	 A price of '' will cause the value to be retrieved appropriate for the customer level
// 	 A value of 0 will make this product free for this customer
// 	 [
// 	 { Guid : "customerID", Price: '' }
// 	 ]
// 	 */
// 	Customers: any = [];

// 	CustomerList: Array<string> = [];
// 	ProductType: string;
// 	Categories: string[];
// 	Brand: string = null;
// 	Description: string;
// 	Features: string;

// 	/* Things to be updated/written on save to make things easier */
// 	VariationCount: number;

// 	//Lower Case search string for indexing
// 	search: string;

// 	// Occasionally populated - The amount covered by the customer to the current user
// 	subsidyAmount: number;

// 	ColourImages: { [color: string]: string } = {
// 		Front: null,
// 		Back: null,
// 	};

// 	// Init the categories field - Perform each time productType changes
// 	initCategories = (): void => {
// 		if (!this.Categories || (this.Categories.length > 0 && (
// 			!this.ProductType ||
// 			(this.Categories[0] !== this.ProductType)
// 		)
// 		)) {
// 			this.Categories = [];

// 			if (this.ProductType) this.Categories.push(this.ProductType);
// 		}
// 	};

// 	// Essentially init post constructor
// 	evolve = () => {

// 		this.Variations.forEach(v => {
// 			if (v['DefaultSellPrice']) { delete v['DefaultSellPrice']; }
// 		});

// 		// Update customer custom pricing to display nothing when the user entered nothing
// 		// It should be null when the value is less than 0
// 		if (this.Customers) {
// 			this.Customers.forEach(c => {
// 				if (c.Price < 0) { c.Price = null; }
// 			});
// 		}

// 		/* If the colour Images do not include the key "Back", add it in */
// 		if (!Object.keys(this.ColourImages).find(k => k === 'Back')) {
// 			this.ColourImages['Back'] = null;
// 		}

// 		/* If the colour Images do not include the key "Front", add it in */
// 		if (!Object.keys(this.ColourImages).find(k => k === 'Front')) {
// 			this.ColourImages['Front'] = null;
// 		}

// 		// If the model has a default image that is not an existing colour image, append it to the Front
// 		if (this.ImageUrl && !this.ColourImages['Front'] && !Object.keys(this.ColourImages).find(k => this.ImageUrl === this.ColourImages[k])) {
// 			console.log("what");
// 			this.ColourImages['Front'] = this.ImageUrl;
// 		}

// 		// Permanent post-init functions for ensuring data integrity
// 		this.initCategories();
// 	};

// 	/*
// 	 A unique list of colours from the Variations, to hold unique display images
// 	 */
// 	getUniqueColours = (includeBack = true, includeFront = true) => {
// 		const Colours = includeFront ? ['Front'] : [];

// 		if (includeBack) { Colours.push('Back'); }

// 		if (this.Variations) {
// 			this.Variations.forEach(v => {
// 				if (v.Colour && !Colours.find(s => s === v.Colour)) {
// 					Colours.push(v.Colour);
// 				}
// 			});
// 		}

// 		Object.keys(this.ColourImages).forEach(c => {
// 			if (!Colours.find(co => c === co) && c.toLowerCase() !== 'back' && c.toLowerCase() !== 'front') {
// 				delete this.ColourImages[c];
// 			}
// 		});

// 		return Colours;
// 	};

// 	getUniqueSizes = () => {
// 		let Sizes = [];

// 		if (this.Variations) {
// 			this.Variations.forEach(v => {
// 				if (v.Size && !Sizes.find(s => s === v.Size)) { Sizes.push(v.Size); }
// 			});
// 		}

// 		return Sizes;
// 	};

// 	beforeSave = () => {

// 		/* Rasterise the customers into a customer list for selection from the DB */
// 		this.CustomerList = [];
// 		if (this.Customers) {
// 			this.Customers.forEach(c => {
// 				this.CustomerList.push(c.Guid);
// 				if (c.Price === null || c.Price.length === 0) { c.Price = -1; }
// 			});
// 		}

// 		if (this.Variations) {
// 			this.Variations.forEach(v => {
// 				if (v.hasOwnProperty('Size') && v.Size.length === 0) { delete v['Size']; }
// 				if (v.hasOwnProperty('Colour') && v.Colour.length === 0) { delete v['Colour']; }

// 				if (v.Options) {
// 					if (v.Options.length) {
// 						for (let oIdx = v.Options.length - 1; oIdx >= 0; oIdx--) {
// 							if (!v.Options[oIdx].Name || v.Options[oIdx].Name.length === 0)
// 								v.Options.splice(oIdx, 1);
// 						}
// 					}

// 					if (v.Options.length === 0)
// 						delete v.Options;
// 				}
// 			});
// 		}

// 		if (this.UnitSizes && (this.UnitSizes.length === 0 || !this.UnitSizesBool)) {
// 			delete this['UnitSizes'];
// 		}

// 		this.VariationCount = this.Variations.length;
// 		let searchFields = [
// 			'ProductName',
// 			'ProductType',
// 			'Categories',
// 			'ProductCode'
// 		];
// 		let searchString =
// 			(this.ProductName || '') +
// 			', ' +
// 			', ' +
// 			(this.ProductType || '') +
// 			', ' +
// 			(this.Categories ? this.Categories.join(', ') : '') +
// 			', ' +
// 			(this.ProductCode || '');

// 		this.search = searchString.toLowerCase();

// 		if (!this.Brand) { delete this.Brand; }
// 	};
// }

export class ProductColour {
	Name: string;
	ImageUrl: string;
}

export const ProductType = {
	clothing: 'Clothing',
	ppe: 'PPE',
	footwear: 'Footwear',
	'workplace-safety': 'Workplace Safety',
	decoration: "Decoration"
};

// export class ProductVariation {
// 	Name: string;
// 	Guid: string;
// 	Tags: string[] = [];
// 	ProductCode: string;
// 	Size: string;
// 	Colour: string;

// 	/*
// 	  Options are a list of free text fields that are presented
// 	  to the end user allowing them to add information to the item
// 	  such as "Name" for name badges
// 	 */
// 	Options: VariationOption[] = [];

// 	static fromUnleashedProduct(p: UnleashedProduct) {
// 		let v = new ProductVariation();

// 		if (p['Guid']) { v.Guid = p.Guid; }
// 		if (p['ProductCode']) { v.ProductCode = p.ProductCode; }
// 		if (p['ProductDescription']) { v.Name = p.ProductDescription; }

// 		return v;
// 	}
// }

export class VariationOption {
	Name: string = '';
	Required: boolean = false;
}

const ShortSize = {
	S: 'Small',
	M: 'Medium',
	L: 'Large',
	XL: 'XLarge',
	'2XL': '2XLarge',
	'3XL': '3XLarge',
	'4XL': '4XLarge',
	'5XL': '5XLarge'
};

export enum AccessMode {
	Manual = 0,
	Authorised = 1,
	UnAuthorised = 2
}

// export class ShippingDetails {
// 	Guid?: string = null;
// 	AddressName?: string = null;
// 	StreetAddress?: string = null;
// 	StreetAddress2?: string = null;
// 	Suburb?: string = null;
// 	City?: string = null;
// 	Region?: string = null;
// 	PostalCode?: string = null;
// 	Country = 'Australia';

// 	beforeSave = () => {
// 		Object.keys(this).forEach(k => {
// 			if (typeof this[k] === 'string' && this[k].length === 0) { this[k] = null; }
// 		});
// 	};
// }

export class ProductOption {
	Guid?: string;
	ImageUrl: string;
	Name: string;
}

/**
 * The upper case 1st letter is a left over from this being a serverless application in legacy
 * as such, the 1:1 mapping approach should be used until we can remove much of this confusing
 * and convoluted authentication behaviour
 */
// export class User {
// 	/** Dangerous property, avoid using where possible, and rely on actualUserID */
// 	UserID: string;
// 	actualUserId: number;
//     enableOrdersRequireApproval :Boolean = false;
// 	CustomerID: number;
// 	FirstName: string;
// 	LastName: string;
// 	SecureLoginType: string;
// 	Email: string;
// 	ContactNumber: string;
// 	ShippingDetails: ShippingDetails = new ShippingDetails();

// 	//ID of the cost center attached to the user
// 	CostCenter: string;
// 	// Orders over this amount go to the approvals manager
// 	ApprovalLimit: number | string;

// 	Role: string;
// 	IsAdmin = false;
// 	LastLogin: Date | null = null;
// 	BusinessUnit: any;
// 	IsGuest = false;
// 	adminRole : IRole
// 	/**
// 	 * Properties associated with customerUser only
// 	 */
// 	CostAccount: string;
// 	EmployeeNumber: string;

// 	/** 1:1 Property Mapping */
// 	approverId: number | null;
// 	businessUnitId: number;
// 	roleId: number;
// 	jobTitle: string;
// 	startDate: string; // CustomerUser Only
// 	actual: INewUser | null;
// 	adminRoleId:number;
// 	permissions:string;
// 	userAllocations: IUserAllocation[] = [];
// 	userGroups: IUserGroup[] = [];
// }

export interface IEnum {
	isEdit?: boolean;
	name: string;
	id: string;

	// Used during UI only for ease of display
	children?: any[],
	parentId?: string,
	level?: string
	count?: number
}

export interface EnumCreateParams {
	name: string;
	id: string;
	parentId?: string;
	children?: any[],
	level?: string,
	count?: number
}

export interface Brand extends IEnum { }

export interface ICategory extends IEnum { }

export interface Label extends IEnum { }

/**
 * Begin Code Refactor
 */

export interface NewBrand {
	id: number;
	name: string;
}

export class NewCustomer {
	name: string;
	code: string;
	ccEmail: string;
	deletedAt?: string;
	customerUsers: ICustomerUser[];
	customerUserCount: number;
}

export class NewGroup {
	name: string;
}

export class NewLabel {
	// id: string;
	text: string;
}

/**
 * Amalgamation of the properties you expect to see on both decoration and product, for ease
 */
export class DecorationOrProduct {
	id?: number;
	isDraft: boolean;
	name: string = "";
	accessMode: number = 1;
	weight: number | null;
	basePrice: number;
	price: number | null;
	customers: ProductCustomer[] = [];
	variations: NewProductVariation[] = [];
	imageUrl: string | null;
	images: ProductImage[] = [];
	inStock: boolean = false;
}

export class NewDecoration extends DecorationOrProduct {
	isRequired: boolean;
	maxPerProduct?: number;
	options: DecorationOption[] = [];
}

export class NewProduct extends DecorationOrProduct {
	id?: number;
	code: string = "";
	brand: NewBrand | null;
	brandId: number | null;
	categories: Category[] = [];
	description: string = "";
	features: string = "";
	usePackSizes: boolean = false;
	packSizes: NewPackSize[] = [];
	decorations: NewDecoration[] = [];
	type: string = "";
	uri: string = "";
	dataSheetUrl: null;
	sizeChartUrl: null;
	sizeChartId: number | null;
	//Temporary hoding
	sizeChart: any;
	deletedAt: string;
	subsidyAmount: number | null;
	variations: NewProductVariation[];
	labels: any;
	temporarySelectedLabels: any;
	updatedAt: string;
	brandName: () => string = () => this.brand ? this.brand.name : '';
}

export class NewPackSize {
	name: string;
	itemCount: number;
}

export interface ProductCustomer {
	customerId: number;
	customPrice: number | null;
	subsidyAmount: number | null;
	product: NewProduct,
	productId?: number,
	id?: number,
	customer?: { name: string }
}

export type IHasProduct = {
	product: NewProduct;
}

export interface IMightHaveId<T = number> {
	id?: T;
}

export interface ProductImage {
	name: string;
	url: string;
}

export interface Category extends EnumCreateParams { }

export interface NewProductVariation {
	id?: number;
	guid: string
	colour: string;
	name: string;
	productCode: string;
	size: string | number;
	displayOrder: null | number;
	imageUrl?: string;
	accessMode: number;
	customers: ProductVariationCustomer[];
}

export interface ProductVariationCustomer {
	variationId?: number | undefined;
	customerId: number;
	customer?: NewCustomer;
}

export type IProductDraftItem = {
	id: number;
	productId: number | null;
	productJSON: string;
};

export type IProductDrafts = {
	rows: IProductDraftItem[],
	count: number
}

export type IProductDraftResult = IProductDrafts;

export type IDecorationDraftResult = {
	rows: DecorationDraft[],
	count: number
}

/**
 * End Code Refactor
 */

export interface IEnum {
	isEdit?: boolean,
	name: string;
	id: string;

	// Used during UI only for ease of display
	children?: any[]
}

export interface EnumCreateParams {
	name: string;
	id: string;
	parentId?: string;
	children?: any[]
}

export interface Brand extends IEnum { }

export interface Label extends IEnum { }

export interface IValidateProductUriOpts {
	id?: number,
	categories: Category[]
	productName: string
}

export interface IRequiredField {
	contact: boolean,
	costAccount: boolean,
	employeNumber: boolean,
	jobTittle: boolean,
	businessUnit: boolean,
	startDate: boolean,
	address: boolean
}