<div class="main-right-content">
	<!-- Back-button start here... -->
	<div class="backs d-lg-flex align-items-center justify-content-between">
		<a [routerLink]="[pathBase]" [queryParams]="{customerId: customerId}" class="btn btn-back p-0 d-none d-lg-flex"
			role="button">
			<span>
				<svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
					<path d="M4.8002 8.60039L1.2002 5.00039L4.8002 1.40039" stroke="#666D80" stroke-width="1.5"
						stroke-linecap="round" stroke-linejoin="round" />
				</svg>
			</span>
			Back to reports
		</a>
		<!-- Page Breadcrumb start here... -->
		<nav aria-label="breadcrumb" class="pageBreadcrumb mb-0">
			<ol class="breadcrumb mb-0">
				<li class="breadcrumb-item"><a [routerLink]="[pathBase]" [queryParams]="{customerId: customerId}">Reports</a>
				</li>
				<li class="breadcrumb-item active" aria-current="page">
					Overspend List Report
				</li>
			</ol>
		</nav>
		<!-- Page Breadcrumb end here... -->
	</div>
	<!-- Back-button end here... -->

	<!-- manage-user main start here... -->
	<div class="manageUser">
		<div class="d-flex flex-column flex-lg-row align-items-start justify-content-between manage-top gap-4">
			<div>
				<h1 class="title mb-0">OverSpend List Report</h1>
				<p class="title-desc">
					List all orders and extended details in the account. Report can be
					exported to CSV.
				</p>
			</div>
			<div class="edit-btns w-sm-100">
				<button class="btn btn-secondary w-sm-100" type="button" (click)="downloadFile()" *ngIf="reportData.length">
					<span>
						<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
							<path
								d="M16.75 12.25V15.25C16.75 15.6478 16.592 16.0294 16.3107 16.3107C16.0294 16.592 15.6478 16.75 15.25 16.75H4.75C4.35218 16.75 3.97064 16.592 3.68934 16.3107C3.40804 16.0294 3.25 15.6478 3.25 15.25V12.25"
								stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
							<path d="M13.75 7L10 3.25L6.25 7" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
								stroke-linejoin="round" />
							<path d="M10 3.25V12.25" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
								stroke-linejoin="round" />
						</svg>
					</span>
					Export CSV
				</button>
			</div>
		</div>

		<div class="manage-user-list">
			<div class="edit-users order-report-card">
				<div class="edit-card card">
					<div class="card-head">
						<h4 class="head-title">
							<span>
								<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
									<path
										d="M12.025 5.7248C11.8876 5.865 11.8106 6.05349 11.8106 6.2498C11.8106 6.44612 11.8876 6.6346 12.025 6.7748L13.225 7.9748C13.3652 8.11222 13.5537 8.18919 13.75 8.18919C13.9463 8.18919 14.1348 8.11222 14.275 7.9748L17.1025 5.1473C17.4796 5.98069 17.5938 6.90923 17.4299 7.80916C17.2659 8.70909 16.8315 9.53769 16.1847 10.1845C15.5379 10.8313 14.7093 11.2657 13.8094 11.4296C12.9094 11.5936 11.9809 11.4794 11.1475 11.1023L5.96501 16.2848C5.66665 16.5832 5.26197 16.7508 4.84001 16.7508C4.41806 16.7508 4.01338 16.5832 3.71501 16.2848C3.41665 15.9864 3.24902 15.5818 3.24902 15.1598C3.24902 14.7378 3.41665 14.3332 3.71501 14.0348L8.89751 8.8523C8.52039 8.01891 8.4062 7.09038 8.57017 6.19044C8.73414 5.29051 9.16848 4.46192 9.8153 3.81509C10.4621 3.16827 11.2907 2.73393 12.1907 2.56996C13.0906 2.40599 14.0191 2.52018 14.8525 2.8973L12.0325 5.7173L12.025 5.7248Z"
										fill="url(#paint0_radial_0_3480)" fill-opacity="0.5" />
									<path
										d="M12.025 5.7248C11.8876 5.865 11.8106 6.05349 11.8106 6.2498C11.8106 6.44612 11.8876 6.63461 12.025 6.7748L13.225 7.9748C13.3652 8.11222 13.5537 8.18919 13.75 8.18919C13.9463 8.18919 14.1348 8.11222 14.275 7.9748L17.1025 5.1473C17.4796 5.98069 17.5938 6.90923 17.4299 7.80916C17.2659 8.70909 16.8315 9.53769 16.1847 10.1845C15.5379 10.8313 14.7093 11.2657 13.8094 11.4296C12.9094 11.5936 11.9809 11.4794 11.1475 11.1023L5.96501 16.2848C5.66665 16.5832 5.26197 16.7508 4.84001 16.7508C4.41806 16.7508 4.01338 16.5832 3.71501 16.2848C3.41665 15.9864 3.24902 15.5818 3.24902 15.1598C3.24902 14.7378 3.41665 14.3332 3.71501 14.0348L8.89751 8.8523C8.52039 8.01891 8.4062 7.09038 8.57017 6.19044C8.73414 5.29051 9.16848 4.46192 9.8153 3.81509C10.4621 3.16827 11.2907 2.73393 12.1907 2.56996C13.0906 2.40599 14.0191 2.52018 14.8525 2.8973L12.0325 5.7173L12.025 5.7248Z"
										stroke="#272835" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
									<defs>
										<radialGradient id="paint0_radial_0_3480" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
											gradientTransform="translate(10.3759 3.20976) rotate(90) scale(10.3339)">
											<stop stop-color="#8BD6EF" />
											<stop offset="1" stop-color="#8BD6EF" stop-opacity="0" />
										</radialGradient>
									</defs>
								</svg>
							</span>
							Filters
						</h4>
					</div>
					<div class="card-body">
						<div class="row">
							<div class="col-12 col-md-6">
								<div class="form-group">
									<label for="minamount" class="form-label">Minimum overspend amount</label>
									<input type="number" id="minamount" class="form-control" placeholder="Minimum overspend amount"
										#minInput />
								</div>
							</div>
							<div class="col-12 col-md-6">
								<div class="form-group">
									<label for="maxamount" class="form-label">Maximum overspend amount</label>
									<input type="number" id="maxamount" class="form-control" placeholder="Maximum overspend amount"
										#maxInput />
								</div>
							</div>

							<div class="col-12 col-md-6">
								<div class="form-group">
									<label for="selectUser" class="form-label">Authorized approver</label>
									<select class="form-select" aria-label="Default select example" id="selectUser" #customerUser
										(change)="ordersReportForm.approverId = +customerUser.value; filterChange()">
										<option selected="">Select a user</option>
										<option *ngFor="let data of customerUsers" [value]="data.id">
											{{ data.user.firstName + data.user.lastName }}
										</option>
									</select>
								</div>
							</div>
							<div class="col-12 col-md-6 edit-btns">
								<button class="btn btn-primary w-100" type="button"
									(click)="submitValues(minInput.value, maxInput.value)">
									Update Filters
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- table-content for lg screen start here... -->
			<div class="manage-table main-table d-none d-lg-block">
				<table class="table">
					<thead>
						<tr>
							<th>Order</th>
							<th>Status</th>
							<th>PO#</th>
							<th>Order date</th>
							<th>Order placed</th>
							<th>Approver</th>
							<th>Total Item</th>
							<th>Grand total</th>
							<th>OverSpend</th>
							<th>Dollar Allocation</th>
							<th>Garment Allocation</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let item of reportData">
							<td>
								<div class="user-name">
									<h5>{{ item.orderNumber || "Not Specified" }}</h5>
								</div>
							</td>
							<td>
								<span class="badge bg-green-25">
									<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
										<circle cx="10" cy="10" r="2" fill="transparent"></circle>
									</svg>
									{{ item.orderStatus }}</span>
							</td>
							<td>
								<p>{{ item.orderReference || "N/A" }}</p>
							</td>
							<td>
								<p>{{ getOrderDate(item.orderCreated) }}</p>
							</td>
							<td>
								<p>
									{{ item.orderedAt ? getOrderDate(item.orderedAt) : "N/A" }}
								</p>
							</td>
							<td>
								<div class="user-name">
									<h5>{{ item.approver || "N/A" }}</h5>
								</div>
							</td>

							<td>
								<p>{{ item.itemCount }}</p>
							</td>
							<td>
								<p>${{ item.grandTotal || "N/A" }}</p>
							</td>
							<td>
								<p>${{ item.overspend }}</p>
							</td>
							<td>
								<p>${{ item.allocationConsumption }}</p>
							</td>
							<td>
								{{ item.allocationCollectionConsumption }}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<!-- table-content for lg screen end here... -->

			<!-- Cards for sm screen start here... -->
			<div class="manage-cards d-lg-none">
				<div class="card manage-card" *ngFor="let item of reportData">
					<div class="card-body">
						<ul class="card-list">
							<li>
								<div class="manage-grid">
									<span class="head"> Order </span>
									<div class="user-name">
										<h5>{{ item.orderNumber || "Not Specified" }}</h5>
									</div>
								</div>
							</li>
							<li>
								<div class="manage-grid">
									<span class="head"> Status </span>
									<span class="badge bg-green-25">
										<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
											<circle cx="10" cy="10" r="2" fill="transparent"></circle>
										</svg>
										{{ item.orderStatus }}</span>
								</div>
							</li>
							<li>
								<div class="manage-grid">
									<span class="head"> PO# </span>
									<p>{{ item.orderReference || "N/A" }}</p>
								</div>
							</li>
							<li>
								<div class="manage-grid">
									<span class="head"> Order date </span>
									<p>{{ getOrderDate(item.orderCreated) }}</p>
								</div>
							</li>
							<li>
								<div class="manage-grid">
									<span class="head"> Order placed </span>
									<p>
										{{ item.orderedAt ? getOrderDate(item.orderedAt) : "N/A" }}
									</p>
								</div>
							</li>
							<li>
								<div class="manage-grid">
									<span class="head"> Approver </span>
									<div class="user-name">
										<h5>{{ item.approver || "N/A" }}</h5>
									</div>
								</div>
							</li>
							<li>
								<div class="manage-grid">
									<span class="head"> Total Item </span>
									<p>{{ item.itemCount }}</p>
								</div>
							</li>
							<li>
								<div class="manage-grid">
									<span class="head"> Grand total </span>
									<p>${{ item.grandTotal || "N/A" }}</p>
								</div>
							</li>
							<li>
								<div class="manage-grid">
									<span class="head"> OverSpend </span>
									<p>${{ item.overspend }}</p>
								</div>
							</li>
							<li>
								<div class="manage-grid">
									<span class="head"> Dollar Allocation </span>
									<p>${{ item.allocationConsumption }}</p>
								</div>
							</li>
							<li>
								<div class="manage-grid">
									<span class="head"> Garment Allocation </span>
									<p>{{ item.allocationCollectionConsumption }}</p>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<!-- Cards for sm screen end here... -->
		</div>
	</div>
</div>