<!-- Server Message start here... -->
<div class="system-message position-absolute mb-0 alert alert-{{
		serverMessage.severity
	}} py-1 text-center" [class.d-none]="removeServerMessage" style="z-index: 9999" *ngIf="showServerMessage">
	{{ serverMessage.message }}

	<button (click)="removeServerMessage = true;" class="btn btn-secondary-icon p-1" type="button">
		<svg _ngcontent-tug-c34="" xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 20 20"
			fill="none">
			<path _ngcontent-tug-c34="" d="M14.5 5.5L5.5 14.5M5.5 5.5L14.5 14.5" stroke="#0d0d12" stroke-width="1.5"
				stroke-linecap="round" stroke-linejoin="round"></path>
		</svg>
	</button>
</div>
<!-- Server Message end here... -->

<header class="header" [class.fixed-top]="!isAuth" [class.position-relative]="isAuth">
	<div class="max-width-container py-0">
		<nav class="navbar navbar-expand-lg p-0">
			<button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
				aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
				(click)="openMenu()">
				<span class="navbar-toggler-icon"></span>
			</button>
			<div class="logo-box">
				<a class="navbar-brand p-0 d-flex" [routerLink]="['/home']"><img src="../../../../assets/images/logo.svg"
						alt="" /></a>
			</div>
			<div class="mob">
				<ul class="loginbox gap-3 d-flex align-items-center">
					<li *ngIf="hasCartAccess">
						<a role="button" class="position-relative" [routerLink]="['/cart']">
							<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									d="M0.75 0.75H3.75L5.76 10.7925C5.82858 11.1378 6.01643 11.448 6.29066 11.6687C6.56489 11.8895 6.90802 12.0067 7.26 12H14.55C14.902 12.0067 15.2451 11.8895 15.5193 11.6687C15.7936 11.448 15.9814 11.1378 16.05 10.7925L17.25 4.5H4.5M15.75 15.75C15.75 16.1642 15.4142 16.5 15 16.5C14.5858 16.5 14.25 16.1642 14.25 15.75C14.25 15.3358 14.5858 15 15 15C15.4142 15 15.75 15.3358 15.75 15.75ZM7.5 15.75C7.5 16.1642 7.16421 16.5 6.75 16.5C6.33579 16.5 6 16.1642 6 15.75C6 15.3358 6.33579 15 6.75 15C7.16421 15 7.5 15.3358 7.5 15.75Z"
									stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
							</svg>
							<span class="cart-fill" *ngIf="cartService.cart && cartService.cart.itemCount">{{
								cartService.cart.itemCount }}</span>
						</a>
					</li>
					<li [class.active]="stickyMenu === 'login'" class="showmega" id="login">
						<a role="button" (click)="enableStickyMenu('login'); isShow = true">
							<svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									d="M13 14.75V13.25C13 12.4544 12.6839 11.6913 12.1213 11.1287C11.5587 10.5661 10.7956 10.25 10 10.25H4C3.20435 10.25 2.44129 10.5661 1.87868 11.1287C1.31607 11.6913 1 12.4544 1 13.25V14.75M10 4.25C10 5.90685 8.65685 7.25 7 7.25C5.34315 7.25 4 5.90685 4 4.25C4 2.59315 5.34315 1.25 7 1.25C8.65685 1.25 10 2.59315 10 4.25Z"
									stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
							</svg>
						</a>
						<!-- for after login user menu end here... -->
						<div *ngIf="checkAuthenticated()" class="usermenu megamenu bg-light rounded-5">
							<div class="">
								<div class="p-4">
									<div class="d-flex align-items-center gap-3" *ngIf="user">
										<div
											class="avat align-items-center justify-content-center d-flex rounded-circle text-secondary bg-white">
											{{ user.firstName.charAt(0).toUpperCase()
											}}{{ user.lastName.charAt(0).toUpperCase() }}
										</div>
										<div>
											<div class="text-black avatname">
												{{ user.firstName }} {{ user.lastName }}
											</div>
											<div class="text-secondary avatemail">
												{{ user.email }}
											</div>
										</div>
									</div>
									<button class="btn btn-secondary mt-4" *ngIf="isEmulatingUser" [disabled]="isBackToMe"
										(click)="unSwitch()" type="button">
										Back to me
									</button>
								</div>
								<div class="max-h-account" class="d-none">
									<ul class="bg-white p-4 m-2" *ngFor="let customer of global.customerAccess"
										(click)="switchCustomer(customer)">
										<li class="text-secondary" *ngIf="global.customer?.Guid === customer.Guid">
											Current
										</li>
										<li class="text-secondary" *ngIf="global.customer?.Guid !== customer.Guid">
											Switch
										</li>
										<li class="fw-bold d-flex align-items-center justify-content-start gap-2" *ngIf="user">
											{{ user.firstName }} {{ user.lastName }}
											<span class="badge rounded-pill ps-3 py-1">{{
												user.customerUsers &&
												user.customerUsers[0] &&
												user.customerUsers[0].userRole &&
												this.user.customerUsers[0].userRole.name
												}}</span>
										</li>
										<li class="text-secondary mt-1">
											{{ customer.CustomerName }}
										</li>
									</ul>
								</div>
								<ul class="bg-white p-4 m-2">
									<li [routerLink]="['/manage/dashboard']" class="mb-3" *ngIf="hasAccountAccess">
										<span class="pe-3"><img src="../../../../assets/images/usergr.svg" alt="" /></span>My Account
									</li>
									<li class="mb-3" *ngIf="user && user.isAdmin" [routerLink]="['/manage/dashboard']">
										<span class="pe-3"><img src="../../../../assets/images/usergr.svg" alt="" /></span>Settings
									</li>
									<li class="mb-3" *ngIf="isApprovalManager" [routerLink]="['/account/orders']">
										<span class="pe-3"><img src="../../../../assets/images/orders.svg" alt="" /></span>Orders
									</li>
									<li class="mb-3" *ngIf="hasCartAccess" [routerLink]="['/account/favorites']">
										<span class="pe-3"><img src="../../../../assets/images/mylists.svg" alt="" /></span>My lists
									</li>
									<hr *ngIf="global.loggedIn" />
									<li *ngIf="global.loggedIn && !isLogout" (click)="logOut(logout_confirm_modal)">
										<span class="pe-3"><img src="../../../../assets/images/log-out.svg" alt="" /></span>Log out
									</li>
								</ul>
							</div>
						</div>
						<!-- for after login user menu end here... -->
						<app-login *ngIf="!checkAuthenticated() && isShow" [requestEmail]="requestEmail"></app-login>
					</li>
				</ul>
			</div>
			<div class="collapse navbar-collapse">
				<!-- Navbar for lg screen start here... -->
				<ul class="navbar-nav mx-auto mb-2 mb-lg-0 d-none d-lg-flex">
					<li class="nav-item showmega" id="productCategory" (mouseenter)="changeStyle()">
						<a role="button" class="nav-link" aria-current="page" (click)="productRoute()"
							[class.active]="isProductsActive">All Products
							<img src="../../../../assets/images/arrow.svg" alt="" /></a>
						<!-- Mega Menu for lg screen start here... -->
						<div class="megamenu allpmega bg-white rounded-5" *ngIf="showCategories || showCollections"
							[class.collection]="showCollections && !showCategories" [class.d-none]="isCategorySelected"
							[class.category]="
								(showCategories || (user && user.isAdmin)) && !showCollections
							" [class.coll-cate]="showCategories && showCollections">
							<div class="allshowcontent">
								<div>
									<div class="leftul" *ngIf="showCollections">
										<h4 class="title">My Collections</h4>
										<ul>
											<li *ngFor="
													let collection of displayedCollections;
													let i = index
												" (click)="handleCollectionClick(collection.id)">
												<div class="img-box">
													<img src="{{ fetchImage(i, 'collections') }}" alt="" />
												</div>
												<div>{{ collection.name }}</div>
												<div class="aricon">
													<img src="../../../../assets/images/slidearrowright.svg" alt="" />
												</div>
											</li>
										</ul>
										<div *ngIf="collections.length == 0 && !isLoading">
											No Collections Found
										</div>
										<div class="svgline">
											<svg xmlns="http://www.w3.org/2000/svg" width="195" height="2" viewBox="0 0 195 2" fill="none">
												<path d="M0 1H195" stroke="#ECEFF3" stroke-dasharray="8 8" />
											</svg>
											<svg width="446" height="2" viewBox="0 0 446 2" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M0 1H446" stroke="#ECEFF3" stroke-dasharray="8 8" />
											</svg>
										</div>
										<button class="btn btn-light w-100" (click)="productRoute()">
											Show All Products ({{ totalProductCount }})
										</button>
									</div>
								</div>
								<div *ngIf="showCategories || (user && user.isAdmin)">
									<h4 class="title">Browse Reali's Products</h4>
									<div class="rightmenus">
										<div *ngFor="
												let category of getCategoriesByParent() | slice : 0 : 4;
												let i = index
											" class="tmitem">
											<div class="hbox" (click)="handleCategoryClick(category.name)">
												<div class="subheadname">{{ category.name }}</div>
												<div class="imgab">
													<img src="{{ fetchImage(i, 'categories') }}" alt="" />
												</div>
											</div>
											<ul class="subcalist">
												<li *ngFor="
														let category1 of getCategoriesByParent(category)
															| slice : 0 : 4
													">
													<a class="nav-link" role="button" (click)="
															handleCategoryClick(category.name, category1.name)
														">{{ category1.name }}</a>
												</li>
												<li>
													<a role="button" (click)="handleCategoryClick(category.name)"
														class="nav-link showall fw-bold">Show All</a>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
							<div class="text-end" [class.d-none]="showCollections && !showCategories">
								<button class="btn btn-light ms-auto" (click)="productRoute()">
									Show All
								</button>
							</div>
							<div class="spinner-border" role="status" *ngIf="isLoading">
								<span class="visually-hidden">Loading</span>
							</div>
						</div>
						<!-- Mega Menu for lg screen end here... -->
					</li>
					<li class="nav-item showmega" [class.active]="stickyMenu === 'solution'">
						<a role="button" class="nav-link" (click)="enableStickyMenu('solution')"
							routerLink="/safety-uniform-management-system" routerLinkActive="active">Solutions
							<img src="../../../../assets/images/arrow.svg" alt="" />
						</a>
						<!-- Mega Menu for lg screen start here... -->
						<div class="megamenu solmenu bg-white rounded-5">
							<ul>
								<li [routerLink]="['/safety-uniform-management-system']">
									<div class="img-box">
										<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path
												d="M7 16.75H13M10 13.75V16.75M4 3.25H16C16.8284 3.25 17.5 3.92157 17.5 4.75V12.25C17.5 13.0784 16.8284 13.75 16 13.75H4C3.17157 13.75 2.5 13.0784 2.5 12.25V4.75C2.5 3.92157 3.17157 3.25 4 3.25Z"
												stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
										</svg>
									</div>
									<a>
										<p>SUMS</p>
										<p class="text-secondary">
											Safety Uniform Management System
										</p>
									</a>
									<div class="aricon">
										<img src="../../../../assets/images/slidearrowright.svg" alt="" />
									</div>
								</li>
								<li [routerLink]="['/product-customisation']">
									<div class="img-box">
										<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path
												d="M9.25 4H4C3.60218 4 3.22064 4.15804 2.93934 4.43934C2.65804 4.72064 2.5 5.10218 2.5 5.5V16C2.5 16.3978 2.65804 16.7794 2.93934 17.0607C3.22064 17.342 3.60218 17.5 4 17.5H14.5C14.8978 17.5 15.2794 17.342 15.5607 17.0607C15.842 16.7794 16 16.3978 16 16V10.75"
												stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
											<path
												d="M14.875 2.87419C15.1734 2.57582 15.578 2.4082 16 2.4082C16.422 2.4082 16.8266 2.57582 17.125 2.87419C17.4234 3.17256 17.591 3.57724 17.591 3.99919C17.591 4.42115 17.4234 4.82582 17.125 5.12419L10 12.2492L7 12.9992L7.75 9.99919L14.875 2.87419Z"
												stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
										</svg>
									</div>
									<a>
										<p [routerLink]="['/product-customisation']">
											Product Customisation
										</p>
										<p class="text-secondary">
											In-house garment decoration services
										</p>
									</a>
									<div class="aricon">
										<img src="../../../../assets/images/slidearrowright.svg" alt="" />
									</div>
								</li>
								<li [routerLink]="['/eurekaSafety']">
									<div class="img-box">
										<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M10 17.5V7" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
												stroke-linejoin="round" />
											<path
												d="M4.75 10H2.5C2.5 11.9891 3.29018 13.8968 4.6967 15.3033C6.10322 16.7098 8.01088 17.5 10 17.5C11.9891 17.5 13.8968 16.7098 15.3033 15.3033C16.7098 13.8968 17.5 11.9891 17.5 10H15.25"
												stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
											<path
												d="M10 7C11.2426 7 12.25 5.99264 12.25 4.75C12.25 3.50736 11.2426 2.5 10 2.5C8.75736 2.5 7.75 3.50736 7.75 4.75C7.75 5.99264 8.75736 7 10 7Z"
												stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
										</svg>
									</div>
									<a>
										<p>Eureka Safety</p>
										<p class="text-secondary">Our own uniform brand</p>
									</a>
									<div class="aricon">
										<img src="../../../../assets/images/slidearrowright.svg" alt="" />
									</div>
								</li>
								<li [routerLink]="['/diverseSupplyChain']">
									<div class="img-box">
										<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
											<path
												d="M14.875 16.75C15.9105 16.75 16.75 15.9105 16.75 14.875C16.75 13.8395 15.9105 13 14.875 13C13.8395 13 13 13.8395 13 14.875C13 15.9105 13.8395 16.75 14.875 16.75Z"
												stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
											<path
												d="M5.125 16.75C6.16053 16.75 7 15.9105 7 14.875C7 13.8395 6.16053 13 5.125 13C4.08947 13 3.25 13.8395 3.25 14.875C3.25 15.9105 4.08947 16.75 5.125 16.75Z"
												stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
											<path d="M13 7H16L18.25 9.25V13H13V7Z" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
												stroke-linejoin="round" />
											<path d="M13 3.25H1.75V13H13V3.25Z" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
												stroke-linejoin="round" />
										</svg>
									</div>
									<a>
										<p>Diverse Supply Chain</p>
										<p class="text-secondary">With national coverage</p>
									</a>
									<div class="aricon">
										<img src="../../../../assets/images/slidearrowright.svg" alt="" />
									</div>
								</li>
							</ul>
						</div>
						<!-- Mega Menu for lg screen end here... -->
					</li>
					<li class="nav-item showmega" [class.active]="stickyMenu === 'industry'">
						<a role="button" routerLink="/industries" routerLinkActive="active" class="nav-link"
							(click)="enableStickyMenu('industry')">Industries
							<img src="../../../../assets/images/arrow.svg" alt="" />
						</a>
						<!-- Mega Menu for lg screen start here... -->
						<div class="megamenu indusmenu bg-white rounded-5">
							<ul>
								<li [routerLink]="['/industries']" fragment="mining">
									<div class="img-box">
										<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M4 17.5V12.25" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
												stroke-linejoin="round" />
											<path
												d="M4 12.25C4 12.25 4.75 11.5 7 11.5C9.25 11.5 10.75 13 13 13C15.25 13 16 12.25 16 12.25V3.25C16 3.25 15.25 4 13 4C10.75 4 9.25 2.5 7 2.5C4.75 2.5 4 3.25 4 3.25V12.25Z"
												stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
										</svg>
									</div>
									<div>
										<a>
											<p>Mining</p>
											<p class="text-secondary d-none">Meet our core team</p>
										</a>
									</div>
									<div class="aricon">
										<img src="../../../../assets/images/slidearrowright.svg" alt="" />
									</div>
								</li>
								<li [routerLink]="['/industries']" fragment="construction">
									<div class="img-box">
										<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path
												d="M12.025 5.72578C11.8876 5.86598 11.8106 6.05446 11.8106 6.25078C11.8106 6.44709 11.8876 6.63558 12.025 6.77578L13.225 7.97578C13.3652 8.1132 13.5537 8.19017 13.75 8.19017C13.9463 8.19017 14.1348 8.1132 14.275 7.97578L17.1025 5.14828C17.4796 5.98167 17.5938 6.9102 17.4299 7.81014C17.2659 8.71007 16.8315 9.53866 16.1847 10.1855C15.5379 10.8323 14.7093 11.2667 13.8094 11.4306C12.9094 11.5946 11.9809 11.4804 11.1475 11.1033L5.96501 16.2858C5.66665 16.5841 5.26197 16.7518 4.84001 16.7518C4.41806 16.7518 4.01338 16.5841 3.71501 16.2858C3.41665 15.9874 3.24902 15.5827 3.24902 15.1608C3.24902 14.7388 3.41665 14.3341 3.71501 14.0358L8.89751 8.85328C8.52039 8.01989 8.4062 7.09135 8.57017 6.19142C8.73414 5.29149 9.16848 4.46289 9.8153 3.81607C10.4621 3.16924 11.2907 2.7349 12.1907 2.57094C13.0906 2.40697 14.0191 2.52115 14.8525 2.89828L12.0325 5.71828L12.025 5.72578Z"
												stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
										</svg>
									</div>
									<div>
										<a>
											<p>Construction</p>
										</a>
									</div>
									<div class="aricon">
										<img src="../../../../assets/images/slidearrowright.svg" alt="" />
									</div>
								</li>
								<li [routerLink]="['/industries']" fragment="government">
									<div class="img-box">
										<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path
												d="M16 6.25H4C3.17157 6.25 2.5 6.92157 2.5 7.75V15.25C2.5 16.0784 3.17157 16.75 4 16.75H16C16.8284 16.75 17.5 16.0784 17.5 15.25V7.75C17.5 6.92157 16.8284 6.25 16 6.25Z"
												stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
											<path
												d="M13 16.75V4.75C13 4.35218 12.842 3.97064 12.5607 3.68934C12.2794 3.40804 11.8978 3.25 11.5 3.25H8.5C8.10218 3.25 7.72064 3.40804 7.43934 3.68934C7.15804 3.97064 7 4.35218 7 4.75V16.75"
												stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
										</svg>
									</div>
									<div>
										<a>
											<p>Government</p>
										</a>
									</div>
									<div class="aricon">
										<img src="../../../../assets/images/slidearrowright.svg" alt="" />
									</div>
								</li>
								<li [routerLink]="['/industries']" fragment="healthcare">
									<div class="img-box">
										<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path
												d="M16.6306 4.45692C16.2475 4.07367 15.7927 3.76965 15.2921 3.56223C14.7915 3.35481 14.2549 3.24805 13.7131 3.24805C13.1712 3.24805 12.6347 3.35481 12.1341 3.56223C11.6335 3.76965 11.1786 4.07367 10.7956 4.45692L10.0006 5.25192L9.20558 4.45692C8.43181 3.68315 7.38235 3.24845 6.28808 3.24845C5.1938 3.24845 4.14435 3.68315 3.37058 4.45692C2.59681 5.23069 2.16211 6.28014 2.16211 7.37442C2.16211 8.46869 2.59681 9.51815 3.37058 10.2919L4.16558 11.0869L10.0006 16.9219L15.8356 11.0869L16.6306 10.2919C17.0138 9.90885 17.3178 9.45403 17.5253 8.95343C17.7327 8.45284 17.8394 7.91628 17.8394 7.37442C17.8394 6.83255 17.7327 6.29599 17.5253 5.7954C17.3178 5.29481 17.0138 4.83998 16.6306 4.45692V4.45692Z"
												stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
										</svg>
									</div>
									<div>
										<a>
											<p>Healthcare</p>
										</a>
									</div>
									<div class="aricon">
										<img src="../../../../assets/images/slidearrowright.svg" alt="" />
									</div>
								</li>
							</ul>
						</div>
						<!-- Mega Menu for lg screen end here... -->
					</li>
					<li class="nav-item showmega" [class.active]="stickyMenu === 'about'">
						<a role="button" routerLink="/aboutUs" routerLinkActive="active" class="nav-link"
							(click)="enableStickyMenu('about')">About
							<img src="../../../../assets/images/arrow.svg" alt="" />
						</a>
						<!-- about megamenu start -->
						<div class="megamenu aboutmenu bg-white rounded-5">
							<ul>
								<li [routerLink]="['/aboutUs']" fragment="about">
									<div class="img-box">
										<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M17.5 2.5L9.25 10.75" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
												stroke-linejoin="round" />
											<path d="M17.5 2.5L12.25 17.5L9.25 10.75L2.5 7.75L17.5 2.5Z" stroke="#666D80" stroke-width="1.5"
												stroke-linecap="round" stroke-linejoin="round" />
										</svg>
									</div>
									<div>
										<a>
											<p>Our story</p>
											<p class="text-secondary">Learn our Origin story</p>
										</a>
									</div>
									<div class="aricon">
										<img src="../../../../assets/images/slidearrowright.svg" alt="" />
									</div>
								</li>
								<li [routerLink]="['/aboutUs']" fragment="location">
									<div class="img-box">
										<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path
												d="M17.25 8.5C17.25 13.75 10.5 18.25 10.5 18.25C10.5 18.25 3.75 13.75 3.75 8.5C3.75 6.70979 4.46116 4.9929 5.72703 3.72703C6.9929 2.46116 8.70979 1.75 10.5 1.75C12.2902 1.75 14.0071 2.46116 15.273 3.72703C16.5388 4.9929 17.25 6.70979 17.25 8.5Z"
												stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
											<path
												d="M10.5 10.75C11.7426 10.75 12.75 9.74264 12.75 8.5C12.75 7.25736 11.7426 6.25 10.5 6.25C9.25736 6.25 8.25 7.25736 8.25 8.5C8.25 9.74264 9.25736 10.75 10.5 10.75Z"
												stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
										</svg>
									</div>
									<div>
										<a>
											<p>Our locations</p>
											<p class="text-secondary">National coverage</p>
										</a>
									</div>
									<div class="aricon">
										<img src="../../../../assets/images/slidearrowright.svg" alt="" />
									</div>
								</li>
								<li [routerLink]="['/aboutUs']" fragment="team">
									<div class="img-box">
										<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path
												d="M18.25 16.75V15.25C18.2495 14.5853 18.0283 13.9396 17.621 13.4142C17.2138 12.8889 16.6436 12.5137 16 12.3475M13.75 16.75V15.25C13.75 14.4544 13.4339 13.6913 12.8713 13.1287C12.3087 12.5661 11.5456 12.25 10.75 12.25H4.75C3.95435 12.25 3.19129 12.5661 2.62868 13.1287C2.06607 13.6913 1.75 14.4544 1.75 15.25V16.75M13 3.3475C13.6453 3.51273 14.2173 3.88803 14.6257 4.41424C15.0342 4.94044 15.2559 5.58763 15.2559 6.25375C15.2559 6.91988 15.0342 7.56706 14.6257 8.09327C14.2173 8.61948 13.6453 8.99478 13 9.16M10.75 6.25C10.75 7.90685 9.40685 9.25 7.75 9.25C6.09315 9.25 4.75 7.90685 4.75 6.25C4.75 4.59315 6.09315 3.25 7.75 3.25C9.40685 3.25 10.75 4.59315 10.75 6.25Z"
												stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
										</svg>
									</div>
									<div>
										<a>
											<p>Our team</p>
											<p class="text-secondary">Meet our leaders</p>
										</a>
									</div>
									<div class="aricon">
										<img src="../../../../assets/images/slidearrowright.svg" alt="" />
									</div>
								</li>
								<li [routerLink]="['/aboutUs']" fragment="responsibility">
									<div class="img-box">
										<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M10.5 16H10.5067" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
												stroke-linejoin="round" />
											<path
												d="M7.89746 13.0825C8.65886 12.5416 9.56972 12.251 10.5037 12.251C11.4377 12.251 12.3486 12.5416 13.11 13.0825"
												stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
											<path
												d="M5.25 10.4125C6.73242 9.17776 8.6007 8.5016 10.53 8.5016C12.4593 8.5016 14.3276 9.17776 15.81 10.4125"
												stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
											<path
												d="M2.56494 7.74998C4.75676 5.81796 7.57816 4.75197 10.4999 4.75197C13.4217 4.75197 16.2431 5.81796 18.4349 7.74998"
												stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
										</svg>
									</div>
									<div>
										<a>
											<p>Social responsibility</p>
										</a>
									</div>
									<div class="aricon">
										<img src="../../../../assets/images/slidearrowright.svg" alt="" />
									</div>
								</li>
								<li [routerLink]="['/aboutUs']" fragment="pillar">
									<div class="img-box">
										<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path
												d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z"
												stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
											<path
												d="M10 14.5C12.4853 14.5 14.5 12.4853 14.5 10C14.5 7.51472 12.4853 5.5 10 5.5C7.51472 5.5 5.5 7.51472 5.5 10C5.5 12.4853 7.51472 14.5 10 14.5Z"
												stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
											<path
												d="M10 11.5C10.8284 11.5 11.5 10.8284 11.5 10C11.5 9.17157 10.8284 8.5 10 8.5C9.17157 8.5 8.5 9.17157 8.5 10C8.5 10.8284 9.17157 11.5 10 11.5Z"
												stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
										</svg>
									</div>
									<div>
										<a>
											<p>Our pillars</p>
											<p class="text-secondary">Company culture </p>
										</a>
									</div>
									<div class="aricon">
										<img src="../../../../assets/images/slidearrowright.svg" alt="" />
									</div>
								</li>
							</ul>
						</div>
						<!-- about megamenu end -->
					</li>
					<li class="nav-item">
						<a role="button" routerLink="/contactus" routerLinkActive="active" class="nav-link">Contact
						</a>
					</li>
				</ul>
				<!-- Navbar for lg screen end here... -->
				<div class="d-flex">
					<!-- Login and search for lg screen start here... -->
					<div class="loginbox">
						<ul class="list-unstyled d-flex align-items-center mb-0">
							<li *ngIf="checkAuthenticated()">
								<a (click)="openModel(searchpopup)">
									<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path
											d="M14.7499 14.75L11.4874 11.4875M13.25 7.25C13.25 10.5637 10.5637 13.25 7.25 13.25C3.93629 13.25 1.25 10.5637 1.25 7.25C1.25 3.93629 3.93629 1.25 7.25 1.25C10.5637 1.25 13.25 3.93629 13.25 7.25Z"
											stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
									</svg>
								</a>
							</li>
							<!-- user megamenu start -->
							<li class="position-relative py-3 showmega" [class.active]="stickyMenu === 'login'" id="login">
								<a (click)="enableStickyMenu('login'); isShow = true">
									<svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path
											d="M13 14.75V13.25C13 12.4544 12.6839 11.6913 12.1213 11.1287C11.5587 10.5661 10.7956 10.25 10 10.25H4C3.20435 10.25 2.44129 10.5661 1.87868 11.1287C1.31607 11.6913 1 12.4544 1 13.25V14.75M10 4.25C10 5.90685 8.65685 7.25 7 7.25C5.34315 7.25 4 5.90685 4 4.25C4 2.59315 5.34315 1.25 7 1.25C8.65685 1.25 10 2.59315 10 4.25Z"
											stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
									</svg>
								</a>
								<!-- for after login user menu end here... -->
								<div *ngIf="checkAuthenticated()" class="usermenu megamenu bg-light rounded-5">
									<div class="">
										<div class="p-4">
											<div class="d-flex align-items-center gap-3" *ngIf="user">
												<div
													class="avat align-items-center justify-content-center d-flex rounded-circle text-secondary bg-white">
													{{ user.firstName.charAt(0).toUpperCase()
													}}{{ user.lastName.charAt(0).toUpperCase() }}
												</div>
												<div>
													<div class="text-black avatname">
														{{ user.firstName }} {{ user.lastName }}
													</div>
													<div class="text-secondary avatemail">
														{{ user.email }}
													</div>
												</div>
											</div>
											<button class="btn btn-secondary mt-4" *ngIf="isEmulatingUser" [disabled]="isBackToMe"
												(click)="unSwitch()" type="button">
												Back to me
											</button>
										</div>
										<div class="max-h-account" class="d-none">
											<ul class="bg-white p-4 m-2" *ngFor="let customer of global.customerAccess"
												(click)="switchCustomer(customer)">
												<li class="text-secondary" *ngIf="global.customer?.Guid === customer.Guid">
													Current
												</li>
												<li class="text-secondary" *ngIf="global.customer?.Guid !== customer.Guid">
													Switch
												</li>
												<li class="fw-bold d-flex align-items-center justify-content-start gap-2" *ngIf="user">
													{{ user.firstName }} {{ user.lastName }}
													<span class="badge rounded-pill ps-3 py-1">{{
														user.customerUsers &&
														user.customerUsers[0] &&
														user.customerUsers[0].userRole &&
														this.user.customerUsers[0].userRole.name
														}}</span>
												</li>
												<li class="text-secondary mt-1">
													{{ customer.CustomerName }}
												</li>
											</ul>
										</div>
										<ul class="bg-white p-4 m-2">
											<li [routerLink]="['/manage/dashboard']" class="mb-3" *ngIf="hasAccountAccess">
												<span class="pe-3"><img src="../../../../assets/images/usergr.svg" alt="" /></span>My Account
											</li>
											<li class="mb-3" *ngIf="user && user.isAdmin" [routerLink]="['/manage/dashboard']">
												<span class="pe-3"><img src="../../../../assets/images/usergr.svg" alt="" /></span>Settings
											</li>
											<li class="mb-3" *ngIf="isApprovalManager" [routerLink]="['/account/orders']">
												<span class="pe-3"><img src="../../../../assets/images/orders.svg" alt="" /></span>Orders
											</li>
											<li class="mb-3" *ngIf="hasCartAccess" [routerLink]="['/account/favorites']">
												<span class="pe-3"><img src="../../../../assets/images/mylists.svg" alt="" /></span>My lists
											</li>
											<hr *ngIf="global.loggedIn" />
											<li *ngIf="global.loggedIn && !isLogout" (click)="logOut(logout_confirm_modal)">
												<span class="pe-3"><img src="../../../../assets/images/log-out.svg" alt="" /></span>Log out
											</li>
										</ul>
									</div>
								</div>
								<!-- for after login user menu end here... -->
								<app-login *ngIf="!checkAuthenticated() && isShow" [requestEmail]="requestEmail"></app-login>
							</li>
							<!-- user megamenu start -->
							<li class="pe-3" *ngIf="hasCartAccess">
								<a [routerLink]="['/cart']" role="button" class="hovsh position-relative">
									<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path
											d="M0.75 0.75H3.75L5.76 10.7925C5.82858 11.1378 6.01643 11.448 6.29066 11.6687C6.56489 11.8895 6.90802 12.0067 7.26 12H14.55C14.902 12.0067 15.2451 11.8895 15.5193 11.6687C15.7936 11.448 15.9814 11.1378 16.05 10.7925L17.25 4.5H4.5M15.75 15.75C15.75 16.1642 15.4142 16.5 15 16.5C14.5858 16.5 14.25 16.1642 14.25 15.75C14.25 15.3358 14.5858 15 15 15C15.4142 15 15.75 15.3358 15.75 15.75ZM7.5 15.75C7.5 16.1642 7.16421 16.5 6.75 16.5C6.33579 16.5 6 16.1642 6 15.75C6 15.3358 6.33579 15 6.75 15C7.16421 15 7.5 15.3358 7.5 15.75Z"
											stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
									</svg>
									<span class="cart-fill" *ngIf="cartService.cart && cartService.cart.itemCount">{{
										cartService.cart.itemCount }}</span>
								</a>
							</li>
							<!-- garments megamenu start -->
							<li *ngIf="hasCartAccess && isShowAllocation"
								class="d-flex showmega align-items-center gap-3 position-relative hleftline m-3 ms-4 ps-3">
								<a class="d-flex align-items-center justify-content-center">
									<app-allocation-loder (totalCalculated)="updateTotal($event)"
										(collections)="updateCollections($event)"></app-allocation-loder>
									<div class="megamenu garmentmenu bg-light rounded-5">
										<div class="">
											<div class="py-2 py-md-4 px-3 px-md-5">
												<div class="d-flex align-items-center gap-3">
													<app-allocation-loder (totalCalculated)="updateTotal($event)"
														(collections)="updateCollections($event)"></app-allocation-loder>
													<span class="text-secondary">Allocation:
														<span class="text-black fw-medium">{{ total }} garment{{
															total == 1 ? "" : "s"
															}}</span>
													</span>
												</div>
											</div>
											<div class="p-2 p-md-5 bg-white m-2 rounded-5 mgzs">
												<div class="esc">
													<p class="text-black fs-4">Express order</p>
													<p class="fs-5 text-secondary">
														Save time with our express order option, simply
														select your final items and checkout.
													</p>
													<p *ngIf="!collectionResult.length">
														You have no allocation avaliable
													</p>
												</div>
												<div class="max-h-account">
													<div class="councled" *ngFor="let item of cartService.items">
														<div class="img-box">
															<img [src]="item.variation.imageUrl" alt="" />
														</div>
														<div>
															<h5 class="mb-2" [routerLink]="'/product/' + item.product.uri">
																{{ item.product.name }}
															</h5>
															<div class="d-flex align-items-center mb-2">
																<div class="d-flex align-items-center gap-2 pe-2 me-2 border-end fs-5">
																	Color:
																	<div class="d-flex gap-2 align-items-center">
																		<span class="product-color" [style.background]="
																				generateGradient(item.variation.colour)
																			"></span>
																		<span class="text-black fs-5">{{
																			item.variation.colour || "default"
																			}}</span>
																	</div>
																</div>
																<div class="text-secondary pe-2 me-2 border-end fs-5">
																	Size:
																	<span class="text-black fs-5">{{
																		item.variation.size || "default"
																		}}</span>
																</div>
																<div class="text-secondary fs-5">
																	Quantity:
																	<span class="text-black fs-5">{{
																		item.quantity
																		}}</span>
																</div>
															</div>
															<div class="d-flex align-items-center gap-3" *ngIf="
																	item.decorations && item.decorations.length
																">
																<button class="addbutton">
																	<img src="../../../../assets/images/plusIcon.svg" alt="" />
																</button>
																<span>Decoration </span>
																<img src="../../../../assets/images/IIcon.svg" alt="" />
															</div>
														</div>
													</div>
												</div>
												<div class="btngrpss">
													<button
														class="btn w-100 btn-light mb-2 bg-white d-flex align-items-center justify-content-center"
														*ngFor="let data of collectionResult" [routerLink]="['/quick-order']"
														fragment="{{ data.id }}">
														Add {{ data.name }}
														<span class="bg-light rounded-circle">{{
															data.allocationAvailable
															}}</span>
													</button>
												</div>
												<div class="mkpo mb-2" *ngIf="collectionResult.length">
													<p>
														Place your order now before your allocation is gone
													</p>
													<p class="text-secondary">
														Your allocation resets: {{ processDates }}
													</p>
												</div>
												<button class="btn w-100 btn-primary" *ngIf="collectionResult.length"
													[routerLink]="['/quick-order']">
													Express order
												</button>
											</div>
											<div class="p-2 p-md-5 py-md-2 py-2 bg-white m-2 rounded-5">
												<div class="accordion accordion-flush grmac" id="garmentaccr">
													<div class="accordion-item">
														<h2 class="accordion-header">
															<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
																data-bs-target="#flush-collapseOne" aria-expanded="false"
																aria-controls="flush-collapseOne">
																Address details
															</button>
														</h2>
														<div id="flush-collapseOne" class="accordion-collapse collapse"
															data-bs-parent="#garmentaccr">
															<div class="accordion-body" *ngIf="
																	userShippingDetails &&
																	userShippingDetails.addressName &&
																	userShippingDetails.streetAddress
																">
																{{
																addressDisplayText(userShippingDetails, true)
																}}
															</div>
															<div class="accordion-body" *ngIf="!userShippingDetails">
																No Address Found
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</a>

								<span>{{ total }} garment{{
									total == 1 ? "" : "s"
									}}</span>
							</li>
						</ul>
					</div>
					<!-- Login and search for lg screen end here... -->
				</div>
			</div>
			<!-- Side Navbar for Small screen start here... -->
			<div class="mobilemenu" [class.active]="isMenuOpen" id="myDIV">
				<div class="mobilemain-menu">
					<div class="mainmenubox position-relative">
						<div>
							<div class="d-flex align-items-center justify-content-between mb-24">
								<span (click)="openMenu()" [class.d-none]="isSubMenuOpen">
									<img src="../../../../assets/images/closeicon.svg" alt="" />
								</span>
								<span [class.d-none]="!isSubMenuOpen" (click)="openSubMenu()">
									<img src="../../../../assets/images/chevron-left.svg" alt="" />
								</span>
								<div class="mname" *ngIf="hasAccountAccess" [class.d-none]="!isAccountMenuOpen">
									My Account
								</div>
								<div class="mname" *ngIf="user && user.isAdmin" [class.d-none]="!isAccountMenuOpen">
									Settings
								</div>
								<div class="mname" [class.d-none]="!isAllProductsOpen">
									All Products
								</div>
								<div class="mname" [class.d-none]="!isSolutionOpen">
									Solutions
								</div>
								<div class="mname" [class.d-none]="!isIndustryOpen">
									Industries
								</div>
								<div class="mname" [class.d-none]="!isAboutOpen">About</div>
								<div class="cursor-pointer" [class.d-none]="isSubMenuOpen" *ngIf="hasCartAccess">
									<a role="button" (click)="closeAll()" class="position-relative p-2" [routerLink]="['/cart']">
										<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path
												d="M0.75 0.75H3.75L5.76 10.7925C5.82858 11.1378 6.01643 11.448 6.29066 11.6687C6.56489 11.8895 6.90802 12.0067 7.26 12H14.55C14.902 12.0067 15.2451 11.8895 15.5193 11.6687C15.7936 11.448 15.9814 11.1378 16.05 10.7925L17.25 4.5H4.5M15.75 15.75C15.75 16.1642 15.4142 16.5 15 16.5C14.5858 16.5 14.25 16.1642 14.25 15.75C14.25 15.3358 14.5858 15 15 15C15.4142 15 15.75 15.3358 15.75 15.75ZM7.5 15.75C7.5 16.1642 7.16421 16.5 6.75 16.5C6.33579 16.5 6 16.1642 6 15.75C6 15.3358 6.33579 15 6.75 15C7.16421 15 7.5 15.3358 7.5 15.75Z"
												stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
										</svg>
										<span class="cart-fill" *ngIf="cartService.cart && cartService.cart.itemCount">{{
											cartService.cart.itemCount }}</span>
									</a>
									<!-- <img [routerLink]="['/cart']"  src="../../../../assets/images/cart.svg" alt="" /> -->
								</div>
								<span (click)="openMenu()" [class.d-none]="!isSubMenuOpen">
									<img src="../../../../assets/images/closeicon.svg" alt="" />
								</span>
							</div>
							<ul class="mvul mb-24" [class.noAccess]="!hasCartAccess">
								<li>
									<form class="w-100" action="">
										<div class="srbox">
											<input class="form-control" #searchText id="mobsearch" placeholder="Search" (keyup.enter)="
													navigateToProductPage(searchText.value, true)
												" />
										</div>
									</form>
								</li>
								<li class="fontmainmenu">MAIN MENU</li>
								<li (click)="openAccountMenu('product')">
									All Products
									<span><img src="../../../../assets/images/chevron-right.svg" alt="" /></span>
								</li>
								<li (click)="openAccountMenu('solution')">
									Solutions<span><img src="../../../../assets/images/chevron-right.svg" alt="" /></span>
								</li>
								<li (click)="openAccountMenu('industry')">
									Industries<span><img src="../../../../assets/images/chevron-right.svg" alt="" /></span>
								</li>
								<li (click)="openAccountMenu('about')">
									About<span><img src="../../../../assets/images/chevron-right.svg" alt="" /></span>
								</li>
								<li routerLink="/contactus" (click)="closeAll()">Contact</li>
								<li>
									<div class="hrdashline"></div>
								</li>
								<li *ngIf="hasAccountAccess" (click)="openAccountMenu('account')">
									My Account<span><img src="../../../../assets/images/chevron-right.svg" alt="" /></span>
								</li>
								<li *ngIf="user && user.isAdmin" (click)="openAccountMenu('account')">
									Settings
									<span><img src="../../../../assets/images/chevron-right.svg" /></span>
								</li>
							</ul>
						</div>

						<div class="bottombar box-shadow" *ngIf="hasCartAccess">
							<ul class="btul" *ngIf="hasCartAccess && isShowAllocation">
								<li class="mb-0">
									<div class="alocbox">
										<a href="javascript:void(0);" class="btn p-0" role="button">
											<app-allocation-loder (totalCalculated)="updateTotal($event)"
												(collections)="updateCollections($event)"></app-allocation-loder>
											<span class="fs-4">{{ total }} garment{{
												total == 1 ? "" : "s"
												}}</span>
										</a>
									</div>
									<div class="d-none">
										<img src="../../../../assets/images/optionIcon.svg" alt="" />
									</div>
								</li>
								<li class="d-none">
									<div class="hrdashline"></div>
								</li>
								<li class="d-none">
									<div class="mpname">
										<a href="#">DT</a>
										<span class="fs-4">Dominik Tyka</span>
									</div>
									<div>
										<img src="../../../../assets/images/optionIcon.svg" alt="" />
									</div>
								</li>
							</ul>
						</div>
					</div>

					<!-- Submenu for Small screen start here... -->
					<app-mobile-menu [class.d-none]="!isAccountMenuOpen" class="submenubox position-absolute"></app-mobile-menu>
					<div [class.d-none]="!isAllProductsOpen" class="submenubox position-absolute">
						<div class="allpcategory">
							<ul>
								<li *ngFor="
										let category of getCategoriesByParent() | slice : 0 : 4;
										let i = index
									" (click)="openproductCategory(category, i)">
									<div class="left-b">
										<div class="img-box">
											<img src="{{ fetchImage(i, 'collections') }}" alt="" />
										</div>
										<div>{{ category.name }}</div>
									</div>
									<div class="aricon">
										<img src="../../../../assets/images/arrowright.svg" alt="" />
									</div>
								</li>
							</ul>
							<div class="svgline">
								<svg xmlns="http://www.w3.org/2000/svg" width="195" height="2" viewBox="0 0 195 2" fill="none">
									<path d="M0 1H195" stroke="#ECEFF3" stroke-dasharray="8 8"></path>
								</svg>
							</div>
							<button class="btn btn-light w-100 bg-white" (click)="productRoute(); closeAll()">
								Show All Products ({{ totalProductCount }})
							</button>
						</div>
					</div>
					<div class="submenubox uniforms position-absolute" [class.d-none]="!isProductCategoryOpen">
						<div class="uniformsect">
							<div class="rightmenus" *ngIf="selectedCategoryMobile">
								<div class="tmitem">
									<div class="hbox">
										<div class="subheadname">
											{{ selectedCategoryMobile.name }}
										</div>
										<div class="imgab">
											<img src="{{ fetchImage(selectedIndex, 'categories') }}" alt="" />
										</div>
									</div>
									<ul class="subcalist">
										<li *ngFor="
												let category1 of getCategoriesByParent(
													selectedCategoryMobile
												) | slice : 0 : 4;
												let i = index
											">
											<a class="nav-link d-inline-block" role="button" (click)="
													handleCategoryClick(
														selectedCategoryMobile.name,
														category1.name
													);
													closeAll()
												">{{ category1.name }}</a>
										</li>
										<li>
											<a class="nav-link fw-bold d-inline-block" (click)="
													handleCategoryClick(selectedCategoryMobile.name);
													closeAll()
												">Show All</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div class="submenubox aboutmo position-absolute" [class.d-none]="!isAboutOpen">
						<div class="subabs">
							<ul>
								<li [routerLink]="['/aboutUs']" fragment="about" (click)="closeAll()">
									<div class="img-box">
										<img src="../../../../assets/images/telegram.svg" alt="" />
									</div>
									<div>
										<p>Our story</p>
										<p class="text-secondary">Learn our Origin story</p>
									</div>
								</li>
								<li [routerLink]="['/aboutUs']" fragment="location" (click)="closeAll()">
									<div class="img-box">
										<img src="../../../../assets/images/map-pin.svg" alt="" />
									</div>
									<div>
										<p>Our locations</p>
										<p class="text-secondary">National coverage</p>
									</div>
								</li>
								<li [routerLink]="['/aboutUs']" fragment="team" (click)="closeAll()">
									<div class="img-box">
										<img src="../../../../assets/images/users.svg" alt="" />
									</div>
									<div>
										<p>Our team</p>
										<p class="text-secondary">Meet our leaders</p>
									</div>
								</li>
								<li [routerLink]="['/aboutUs']" fragment="responsibility" (click)="closeAll()">
									<div class="img-box">
										<img src="../../../../assets/images/wifi.svg" alt="" />
									</div>
									<div>
										<p>Social responsibility</p>
									</div>
								</li>
								<li [routerLink]="['/aboutUs']" fragment="pillar" (click)="closeAll()">
									<div class="img-box">
										<img src="../../../../assets/images/target.svg" alt="" />
									</div>
									<div>
										<p>Our pillars</p>
										<p class="text-secondary">Company culture&nbsp;</p>
									</div>
								</li>
							</ul>
						</div>
					</div>
					<div class="submenubox aboutmo position-absolute" [class.d-none]="!isSolutionOpen">
						<div class="subabs">
							<ul>
								<li [routerLink]="['/safety-uniform-management-system']" (click)="closeAll()">
									<div class="img-box">
										<img src="../../../../assets/images/monitor.svg" alt="" />
									</div>
									<div>
										<p>SUMS</p>
										<p class="text-secondary">
											Safety Uniform Management System
										</p>
									</div>
								</li>
								<li [routerLink]="['/product-customisation']" (click)="closeAll()">
									<div class="img-box">
										<img src="../../../../assets/images/edit.svg" alt="" />
									</div>
									<div>
										<p>Product Customisation</p>
										<p class="text-secondary">
											In-house garment decoration services
										</p>
									</div>
								</li>
								<li [routerLink]="['/eurekaSafety']" (click)="closeAll()">
									<div class="img-box">
										<img src="../../../../assets/images/anchor.svg" alt="" />
									</div>
									<div>
										<p>Eureka Safety</p>
										<p class="text-secondary">Our own uniform brand</p>
									</div>
								</li>

								<li [routerLink]="['/diverseSupplyChain']" (click)="closeAll()">
									<div class="img-box">
										<img src="../../../../assets/images/truck.svg" alt="" />
									</div>
									<div>
										<p>Diverse Supply Chain</p>
										<p class="text-secondary">With national coverage</p>
									</div>
								</li>
							</ul>
						</div>
					</div>
					<div class="submenubox aboutmo position-absolute" [class.d-none]="!isIndustryOpen">
						<div class="subabs">
							<ul>
								<li [routerLink]="['/industries']" fragment="mining" (click)="closeAll()">
									<div class="img-box">
										<img src="../../../../assets/images/flag.svg" alt="" />
									</div>
									<div>
										<p>Mining</p>
										<p class="text-secondary d-none">Meet our core team</p>
									</div>
								</li>
								<li [routerLink]="['/industries']" fragment="construction" (click)="closeAll()">
									<div class="img-box">
										<img src="../../../../assets/images/tool.svg" alt="" />
									</div>
									<div>
										<p>Construction</p>
									</div>
								</li>
								<li [routerLink]="['/industries']" fragment="government" (click)="closeAll()">
									<div class="img-box">
										<img src="../../../../assets/images/briefcase.svg" alt="" />
									</div>
									<div>
										<p>Government</p>
									</div>
								</li>

								<li [routerLink]="['/industries']" fragment="healthcare" (click)="closeAll()">
									<div class="img-box">
										<img src="../../../../assets/images/heart.svg" alt="" />
									</div>
									<div>
										<p>Healthcare</p>
									</div>
								</li>
							</ul>
						</div>
					</div>
					<!-- Submenu for Small screen end here... -->
				</div>
			</div>
			<!-- Side Navbar for Small screen end here... -->
		</nav>
	</div>
</header>

<ng-template #searchpopup let-modal let-c="close" let-d="dismiss" class="deleteModal">
	<div class="modal-content">
		<div class="modal-body">
			<form>
				<div class="srctop">
					<div class="mb-3">
						<input #searchText class="form-control" id="productdatalist" (keyup)="updateSearchTerm(searchText.value)"
							placeholder="What are you looking for?" (keyup.enter)="navigateToProductPage(searchText.value)" />
					</div>
					<p class="heading text-secondary">Popular search</p>
					<div class="d-flex flex-wrap gap-25">
						<span class="badge rounded-pill bg-white text-secondary"
							(click)="handleCategoryClick('workwear', 'fire_retardant')">Fire Retardant</span>
						<span class="badge rounded-pill bg-white text-secondary"
							(click)="handleCategoryClick('workwear', 'casual_wear')">Casual Wear</span>
						<span class="badge rounded-pill bg-white text-secondary"
							(click)="handleCategoryClick('workwear', 'accessories')">Accesories</span>
					</div>
				</div>
				<div class="srchin">
					<p class="heading text-secondary">Last watched</p>
					<ul class="ulb">
						<li *ngFor="let data of queryResult.rows">
							<div class="img-box bg-light">
								<img src="{{
										data.imageUrl ||
											'../../../../assets/images/collection-product.png'
									}}" alt="" />
							</div>
							<div class="cntbx">
								<a (click)="navigategProductRoute(data.uri)" role="button" class="btn p-0 shadow-none">
									<p class="text-start">{{ data.name }}</p>
								</a>
								<p *ngIf="finalPrice(data)">$ {{ finalPrice(data) }}</p>
							</div>
						</li>
					</ul>
				</div>
			</form>
		</div>
	</div>
</ng-template>

<!-- LogOut Confirmation Model -->

<ng-template #logout_confirm_modal let-modal let-c="close" let-d="dismiss" class="deleteModal">
	<div class="modal-content">
		<div class="modal-body">
			<button type="button" class="btn btn-icon btn-cancel shadow-none" (click)="d('Cancel')">
				<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
					<path d="M14.5 5.5L5.5 14.5M5.5 5.5L14.5 14.5" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
						stroke-linejoin="round" />
				</svg>
			</button>
			<div class="delete-content">
				<div>
					<h4 class="title">Logout</h4>
					<p class="desc">Are you sure, you want to logout?</p>
				</div>
			</div>
			<div class="btns">
				<button type="button" class="btn btn-danger" (click)="c('Delete')">
					yes
				</button>
				<button type="button" class="btn btn-secondary" (click)="d('Cancel')">
					no
				</button>
			</div>
		</div>
	</div>
</ng-template>