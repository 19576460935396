import { Component, OnDestroy, OnInit } from '@angular/core';
import { SecurityService } from '../../services/security.service';
import { Subject, combineLatest, debounceTime, map, takeUntil } from 'rxjs';
import { CustomerUserService } from '../../services/customerUser.service';
import { NotificationsService } from 'angular2-notifications';
import { Router } from '@angular/router';
import { CartService } from '../../services/cart.service';
import { ServerCartItem } from '../../model/cart.model';
import { Utils } from '../../util/utils';
import { SessionApi } from '../../api/session.api';
import { GlobalApi } from '../../api/global.api';

@Component({
  selector: 'app-cart-list',
  templateUrl: './cart-list.component.html',
  styleUrls: []
})
export class CartListComponent implements OnInit, OnDestroy {
	public canCheckout$ = this.securityService.canCheckout();

  public availableBalance: number = 0;
  public customerId: number;
  private unsubscribe$ = new Subject<void>();

  constructor(
    private securityService: SecurityService,
    private customerUserService: CustomerUserService,
    private notifications: NotificationsService,
    public router: Router,
    public cartService: CartService,
    public utils: Utils,
    private session: SessionApi,
    private globals: GlobalApi
  ) { }

  ngOnInit() {
    window.scroll(0,0);
    this.session.$customerData.pipe(
      debounceTime(1000),
      takeUntil(this.unsubscribe$)
    ).subscribe((customerData) => {

      if (customerData?.id) {
        this.customerId = customerData.id;
        combineLatest([
          this.customerUserService.getCustomerUser(this.globals.customerUserId, { allocations: true }),
          this.cartService.getCartItems(this.customerId),
          this.cartService.getUserCart()
        ]).pipe(
          takeUntil(this.unsubscribe$)
        ).subscribe(
          ([customerUser]: any) => {
            this.availableBalance = 0;
            if (customerUser) {
              if (customerUser.userAllocations && customerUser.userAllocations.length > 0) {
                customerUser.userAllocations.forEach(allocation => {
                  if (allocation.type === 'dollar') this.availableBalance += allocation.balance || 0;
                });
              }
            }

            return this.verifyItemCount()
          },
          err => {
            if (err.error && err.error.message && err.error.message === "Order is not found") {
              this.verifyItemCount();
            }
          }
        );
      }
    }
    );
  }

  /**
  * @description ensures there is a sufficient item count to justify looking at this page and acts if there is not
  */
  verifyItemCount = async () => {
    if (this.cartService.items.length === 0) {
      console.log('User attempted to access cart with no products. Redirecting to products.');
      this.notifications.warn('Empty Cart', 'No Cart Items to Review');

      return this.continueShopping();
    }
  };

  /**
  * @description provides a centralised behaviour to perform when the user should continue selecting items
  */
  continueShopping = () => this.router.navigate(['/products']);

  anyItemHasPrice() {
    return this.cartService.items.find(item => item && item.product && item.product.price && item.product.price > 0);
  }

  /**
 * @description Deletes the passed item from the list of cart items, in response to a deleted event
 *
 * @param item
 */
  public handleItemDeleted = (item: ServerCartItem) => {
    this.cartService.removeCartItemById(item.id);

    this.verifyItemCount();
  }

  getBalanceText = (): string => {
    let balanceValue: number;
    const total = this.cartService.getGrandTotal();
    if (this.availableBalance > total) {
      balanceValue = total;
    } else {
      balanceValue = this.availableBalance;
    }

    return `$${this.utils.twoDecimalPlacesString(balanceValue)}`;
  }

  getGrandTotalText = (): string => {
    let balanceValue = this.cartService.getGrandTotal() - this.availableBalance;
    if (balanceValue < 0) balanceValue = 0;

    return `$${this.utils.twoDecimalPlacesString(balanceValue)}`;
  }

  /**
  * @description Publishes any changes to the cart comment
  */
  saveCart = () => {
    const notification = this.notifications.warn('Saving', 'Saving changes');

    return this.cartService.saveCartAttrs(this.customerId, ["comment"])
      .pipe(result => {
        this.notifications.remove(notification.id);
        this.notifications.success('Saved', 'Changes Saved');

        return result;
      });
  };


  /**
 * @description advances the user to the next step of the checkout process
 */
	checkout = () => this.saveCart().pipe(
    takeUntil(this.unsubscribe$),
	).subscribe(() => {
		// Trust the routing to handle the access to the route
		this.router.navigate(['/cart/checkout']);
	})

  handelCartUpdate() {
    this.cartService.getCartItems(this.customerId).pipe(takeUntil(this.unsubscribe$)).subscribe(() => { });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
