import { Injectable } from "@angular/core";
import { GenericApi } from "./generic.api";
import { IRole } from "../model/role.model";
import { BehaviorSubject, Observable, filter, map, mergeMap, of, tap } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { SessionApi } from "./session.api";
import { logger } from "../util/Logger";
import { IQueryFilter, QueryResult } from "../model/query.filter.class";
import { HasId } from "../model/generics";
import { JwtService } from "../services/jwt.service";

/**
 * This class is going to be used broadly across the entire application as the security service
 * as it will need to maintain an update to date, valid, cached list of every role in the application
 * and ever permission that this role has, at all times, so as to select the appropriate elements of the
 * application to show and hide various features and functionality relative to both the current user role
 * and any user role the current actor may be acting as, such as an assumed user role. This is a
 * completely excessive approach that should only be used in specific scenarios, rather than writing
 * such overly complex services that achieve somewhat of a middleground and assume its the cost effective
 * solution for the challenge.
 * 
 * To maximise performance even the cache will promise a response rather than bash the new date function
 * hundreds of times (potentially) throughout the lifecycle, ensuring a minimum response time of 50ms.
 * 
 * Unless there is a particularly compelling reason this doesn't need a socket into the server for milisecond
 * response to updated user roles.
 */

@Injectable()
export class RolesApi extends GenericApi<IRole> {
	private readonly className = "RolesApi";

	public path = 'user-role';

	/** Essentially an event that can be listened to */
	private _allRolesSignature: string = "";
	private _lastRolesUpdate: Date | null = null;
	public allRoles: BehaviorSubject<IRole[]> = new BehaviorSubject([]);

	constructor(
		public readonly httpClient: HttpClient,
		private readonly session: SessionApi,
		private jwtService: JwtService
	) {
		super(httpClient);

		const signature = this.className + ".constructor: ";
		if (this.jwtService.currentJwtPayload$.getValue()) {

			/**
			 * Perform a single cache refresh when the customer gets updated and then every 5 minutes
			 */
			this.initCacheRefresh(
				this.session.$sessionChanged
					.pipe(
						tap(readyState => logger.silly(signature + `Filtering Session Change by ReadyState[${readyState}]`)),
						filter(readyState => !!readyState),
						tap(() => logger.silly(signature + `Filtering Session Change by CacheMeta`)),
						filter(() => this.shouldRefreshCache()),
						tap(() => logger.silly(signature + `Updating Role Cache`)),
					)
			)
				.subscribe(() => { });
		}
	}

	shouldRefreshCache(): boolean {
		const knownRoleLength = this.allRoles.getValue().length;
		const signature = this.className + `.shouldRefreshCache: CurrentRoleCount[${knownRoleLength}] `;
		const now = new Date();

		if (knownRoleLength === 0) {
			this._lastRolesUpdate = now;
			logger.silly(signature + `Cache Refresh is Required due to no known roles`);
			return true;
		}

		if (this._lastRolesUpdate === null) {
			this._lastRolesUpdate = now;
			logger.silly(signature + `Cache Refresh is Required due to null lastRolesUpdate`);
			return true;
		}

		const cutoff = (now.getTime() - (1000 * 60 * 5));

		if (cutoff > this._lastRolesUpdate.getTime()) {
			logger.silly(signature + `Cache Refresh is Required due to ${(now.getTime() - (1000 * 60 * 5))}`);
			this._lastRolesUpdate = now;
			return true;
		}

		logger.silly(signature + 'Cache Refresh is not required');
		return false;
	}

	initCacheRefresh(observe: Observable<any>): Observable<any> {
		const signature = this.className + ".initCacheRefresh: ";
		return observe
			.pipe(
				tap(() => logger.silly(signature + "Fetching user role Cache")),
				// Build a new filter object
				map(() => new IQueryFilter({ limit: 1000 })),
				filter(() => !!this.jwtService.currentJwtPayload$.getValue()),
				// Fetch the results from the api
				mergeMap(query => this.list(query)),
				// Don't broadcast a change until one is actually detected
				tap(roleQuery => this.setRoles(roleQuery))
			);
	}

	setRoles(data: QueryResult<HasId & IRole> | null) {
		const signature = this.className + ".setRoles: ";
		let rolesChanged: boolean = false;
		if (!data) {
			rolesChanged = (this._allRolesSignature.length === 0);
		} else {
			rolesChanged = JSON.stringify(data.rows) !== this._allRolesSignature;
		}

		logger.silly(signature + `User Roles ${rolesChanged ? 'have' : 'have not'} changed`);
		this._allRolesSignature = JSON.stringify(data ? data.rows : []);
		this.allRoles.next(data ? data.rows : []);
	}

	/**
	 * Filters and emits all roles from the cache which have all of the supplied permissions in their permission set.
	 * 
	 * @param {string[]} permissionsToHave 
	 * @returns {Observable<boolean>}
	 */
	rolesWithPermission(...permissionsToHave: string[]): Observable<IRole[]> {
		return this.allRoles
			.pipe(
				map(allRoles => allRoles.filter(role => this.roleHasPermission(role, ...permissionsToHave)) || [])
			)
	}

	/**
	 * Filters all roles by the supplied name
	 * 
	 * @param name 
	 */
	roleByName(name: string): Observable<IRole | null | undefined> {
		const signature = this.className + ".roleByName: ";

		if (!name || !name.length) {
			logger.silly(signature + 'supplied role name was null or empty. Assuming null outcome.');
			return of(null);
		}

		const lcaseName = name.toLowerCase();

		return this.allRoles
			.pipe(
				// Filter roles by name
				map(allRoles => allRoles.find(role => role.name.toLowerCase() === lcaseName)),
				// Write to the log
				tap(result => logger.silly(signature + (result ? "Found" : "Did not Find") + ` role by Name[${name}]`))
			)
	}

	/**
	 * Filters and emits the role by the selected Id
	 * 
	 * @param {number} id
	 * @returns {Observable<IRole|Undefined>}
	 */
	roleById(id: number): Observable<IRole | null | undefined> {
		const signature = this.className + ".roleById: ";

		if (!id) {
			logger.silly(signature + 'supplied role id was either null or 0. Assuming null outcome.');
			return of(null);
		}

		return this.allRoles
			.pipe(
				// Filter roles by name
				map(allRoles => allRoles.find(role => role.id === id)),
				// Write to the log
				tap(result => logger.silly(signature + (result ? "Found" : "Did not Find") + ` role by Id[${id}]`))
			)
	}

	/**
	 * Determines if the supplied role has each of the supplied permissions
	 * 
	 * @param {IRole} role 
	 * @param {string[]} permissionsToHave 
	 * @returns {boolean}
	 */
	roleHasPermission(role: IRole, ...permissionsToHave: string[]): boolean {
		const signature = this.className + ".roleHasPermission: ";
		if (!role) {
			if (permissionsToHave && permissionsToHave.length) {
				logger.silly(signature + 'supplied role was null or undefined and permissions are required. Assuming false outcome.');
				return false;
			}
			logger.silly(signature + 'supplied role was null or undefined however no permissions are required. Assuming true outcome.');
			return true;
		}
		const lcasePermissionsToHave = permissionsToHave.map(permissionToHave => permissionToHave.toLowerCase());
		const rolePermissions = role.permissions.toLowerCase().split(",");
		const permissionNotFound = lcasePermissionsToHave.filter(lcasePermission => rolePermissions.indexOf(lcasePermission) === -1);
		if (permissionNotFound && permissionNotFound.length) {
			return false;
		}
		return true;
	}

	/**
	 * Determines if the supplied role has each of the supplied permissions
	 * 
	 * @param {IRole} role 
	 * @param {string[]} permissionsToHave 
	 * @returns {boolean}
	 */
	roleHasAnyPermission(role: IRole, ...permissionsToHave: string[]): boolean {
		const signature = this.className + ".roleHasAnyPermission: ";
		if (!role) {
			if (permissionsToHave && permissionsToHave.length) {
				logger.silly(signature + 'supplied role was null or undefined and permissions are required. Assuming false outcome.');
				return false;
			}
			logger.silly(signature + 'supplied role was null or undefined however no permissions are required. Assuming true outcome.');
			return true;
		}
		const lcasePermissionsToHave = permissionsToHave.map(permissionToHave => permissionToHave.toLowerCase());
		const rolePermissions = role.permissions.toLowerCase().split(",");
		const firstMatchedPermission = lcasePermissionsToHave.find(lcasePermission => rolePermissions.indexOf(lcasePermission) > -1);
		return !!firstMatchedPermission;
	}

}