import { Component, Input, OnDestroy } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'modal',
  template: `
            <div class="modal-header">
            <h1 class="modal-title" id="twoFactorauthLabel">Your report is ready.</h1>
            <button (click)="close()" type="button" class="btn btn-secondary-icon" data-bs-dismiss="modal" aria-label="Close">
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M14.5 5.5L5.5 14.5M5.5 5.5L14.5 14.5" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
              </span>
            </button>
          </div>
          <div class="modal-body">
            <div>
              <p class="desc" [innerHTML]="data">
              </p>
            </div>
            <div class="btns d-flex flex-column flex-md-row align-items-center justify-content-end gap-4 gap-md-25">
              <button type="button" class="btn btn-secondary w-sm-100" (click)="close()">
                Close
              </button>
            </div>
          </div>
    `
})
export class ModalComponent implements OnDestroy {
  @Input() data: any;
  constructor(
    public ngbModal: NgbModal,
  ) { }

  ngOnDestroy(): void {
    this.ngbModal.dismissAll();
  }

  close() {
    this.ngbModal.dismissAll();
  }
}
