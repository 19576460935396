import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from '../../services/admin.service';
import { User } from '../../model/user.model';
import { IQueryFilter, QueryResult } from 'src/app/main/model/query.filter.class';
import { IRole } from 'src/app/main/model/role.model';
import { CustomerUserService } from 'src/app/main/services/customerUser.service';
import { ShippingDetails } from '../../model/shippingDetail.model';
import { AdminRoleService } from '../../services/adminRole.service';
import { HasId } from '../../model/generics';
import { NotificationsService } from 'angular2-notifications';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html'
})
export class AdminEditComponent implements OnInit {
  public adminGuid: string;
  public userRoles: QueryResult<IRole & HasId<string>>;
  public admin: User = {
    UserID: '',
    actualUserId: 0,
    CustomerID: 0,
    firstName: '',
    lastName: '',
    SecureLoginType: 'none',
    email: '',
    ContactNumber: '',
    CostCenter: '',
    ApprovalLimit: '',
    Role: null,
    IsAdmin: true,
    lastLoginAt: null,
    BusinessUnit: undefined,
    IsGuest: false,
    CostAccount: '',
    EmployeeNumber: '',
    approverId: null,
    businessUnitId: 0,
    roleId: 0,
    jobTitle: '',
    startDate: '',
    actual: null,
    adminRoleId: null,
    permissions: '',
    userAllocations: [],
    userGroups: [],
    enableOrdersRequireApproval: true,
    ShippingDetails: new ShippingDetails,
    adminRole: undefined,
    loginSecretToken: null
  };
  public isEdit: boolean = true;
  public secureLoginType: string | undefined = 'none';
  public tfaBlock: boolean = false;
  public tfaVerifyBlock: boolean = false;
  public tfaRequiresVerification: boolean = false;
  public tfaVerificationSuccess: boolean = false;
  public wrongOtp: boolean = false;
  public qrImage: string;
  public secretCode: string;
  public email: string;
  public otp: string;
  public id: string;
  isSuperAdmin: boolean = true;

  constructor(
    public route: ActivatedRoute,
    private adminService: AdminService,
    public router: Router,
    private customerUserService: CustomerUserService,
    private adminRoleService: AdminRoleService,
    private notifications: NotificationsService
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.adminGuid = params['id'];

      if (this.adminGuid) {
        this.adminService.getById(this.adminGuid)
          .subscribe(admin => {
            this.conversion(admin);
            this.isSuperAdmin = this.admin.adminRoleId === null;
          });
      }
    });
    this.fetchRoles();
  }

  conversion(admin: any) {
    this.admin.id = admin.id;
    this.admin.UserID = admin.id;
    this.admin.actualUserId = admin.id;
    this.admin.firstName = admin.firstName;
    this.admin.lastName = admin.lastName;
    this.admin.SecureLoginType = admin.secureLoginType;
    this.admin.email = admin.email;
    this.admin.ContactNumber = admin.contactNumber;
    this.admin.IsAdmin = admin.isAdmin;
    this.admin.adminRoleId = admin.adminRoleId;
    this.admin.adminRole = admin.adminRole;
    this.secureLoginType = admin.secureLoginType;
  }

  fetchRoles() {
    let query: IQueryFilter = new IQueryFilter({
      sortBy: 'id',
      filter: {}
    });
    this.adminRoleService.list(query).subscribe(userRole => {
      this.userRoles = userRole;
    })
  }

  checkSecureLoginType(type?: string) {
    if (this.secureLoginType == 'none') {
      this.secureLoginType = 'email';
    } else {
      this.secureLoginType = 'none';
    }

    if (type) {
      this.secureLoginType = type;
    }

    if (this.secureLoginType == 'app' && this.isEdit) {
      if (this.admin?.email) {
        this.customerUserService.generateTfaSecret(this.admin.email).subscribe((data) => {
          if (data) {
            this.openTFABlock(data);
          } else {
            this.clearTFABlock();
          }
        });
      }
    } else {
      this.clearTFABlock();
    }
    // this.customerUserForm.get('user.secureLoginType')?.setValue(this.secureLoginType);
    if (this.admin) {
      this.admin.SecureLoginType = this.secureLoginType;
    }
  }

  verifyOtp() {
    if (this.otp) {
      this.customerUserService.verifyOtp({
        code: this.secretCode,
        otp: this.otp.toString(),
        type: this.secureLoginType,
        userId: +this.adminGuid,
        qrImage: this.qrImage,
      }).subscribe((data) => {
        if (data && data.verify) {
          this.notifications.success('Varification', 'OTP Varified');
          this.tfaRequiresVerification = false;
          this.tfaBlock = false;
          this.tfaVerificationSuccess = true;
        } else {
          this.wrongOtp = true;
        }
      });
    }
  }
  /**
 * @description Opens the two factor auth block for validation
 * @param data
 * @returns {void}
 */
  private openTFABlock(data: { qr: string, secret: string, uri: string }) {
    this.tfaBlock = true;
    this.tfaVerifyBlock = true;
    this.tfaRequiresVerification = true;
    const newUrl = `https://quickchart.io/chart?cht=qr&chs=200x200&chl=${data.uri}`;
    this.qrImage = newUrl;
    this.secretCode = data.secret;
    this.admin.loginSecretToken = this.secretCode;
  }

  /**
   * @description Clears the two factor auth block
   * @returns {void}
   */
  private clearTFABlock() {
    this.tfaBlock = false;
    this.tfaVerifyBlock = false;
    this.qrImage = "";
    this.secretCode = "";
    this.tfaRequiresVerification = false;
  }

  /**
  * @todo replace with angular forms
  * @description Performs validations on the current data to ensure it is ready for transmission
  * @returns {boolean}
  */
  isValid() {
    if (!this.admin.email || !this.admin.email.length)
      return false;

    if (!this.admin.firstName)
      return false;

    if (!this.admin.lastName)
      return false;

    if (!this.admin.IsAdmin && !this.admin.adminRoleId)
      return false;

    return true;
  }

  public updateAdmin() {
    if (!this.isValid()) {
      window.scroll(0, 0);
      this.notifications.warn('Add Admin', 'Please fill all required fields');

      window.scroll(0, 0);

      return;
    }
    if (this.admin.id) {
      this.adminService.update(Number(this.admin.id), this.admin).subscribe(() => {
        // this.router.navigate(['/manage/users'], { queryParams: { activeTab: 2 } });
      })
    } else {
      this.adminService.create(this.admin).subscribe(() => {
        this.router.navigate(['/manage/users'], { queryParams: { activeTab: 2 } });
      })
    }

  }

  /**
   * Broadcasts when the selected value is changed by user input
   *
   * @param value
   */
  valueChanged(val: any): void {
    if (val == 0) {
      this.admin.IsAdmin = true;
    } else {
      this.admin.adminRoleId = val;
      this.admin.IsAdmin = true;
    }
  }

  public isSuperAdminChange = () => {
    if (this.isSuperAdmin) {
      this.isSuperAdmin = false;
    } else {
      this.isSuperAdmin = true;
      this.admin.adminRoleId = null;
    }
  }

}




