import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { Observable, from, of, tap } from "rxjs";
import { AuthService } from "../services/auth.service";
import { logger } from "../util/Logger";

const className = "AuthGuard";


@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly authService: AuthService
  ) {
  }

  canActivate(): Observable<boolean> {
    const signature = className + '.canActivate: ';
    return from(of(this.authService.isAuthenticated()))
      .pipe(
        tap(isAuthenticated => {
          logger.silly(signature + `isAuthenticated[${isAuthenticated}]`);
          if (!isAuthenticated)
            this.router.navigate(['']);
        })
      );
  }
}