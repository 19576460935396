import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SecurityService } from '../../services/security.service';
import { Router } from '@angular/router';
import { CartService } from '../../services/cart.service';
import { BehaviorSubject, Subject, combineLatest, filter, switchMap, takeUntil, tap } from 'rxjs';
import { SessionApi } from '../../api/session.api';
import { Notification, NotificationsService } from 'angular2-notifications';
import { SubscriptionGroup } from '../../util/subscriptionGroup';
import { Utils } from '../../util/utils';
import { PaymentMethodComponent } from '../payment-method/payment-method.component';
import { IOrderCardPaymentPrepared } from '../../model/order.model';
import { FileUploadService } from '../../services/fileUpload.service';
import { CartCheckoutContactComponent } from '../cart-checkout-contact/cart-checkout-contact.component';
import { has } from 'lodash';
import { AuthApi } from '../../api/auth.api';
import { CustomUserFieldComponent } from '../../users/customer-users/user-edit/custom.field.component';
import { GlobalApi } from '../../api/global.api';

@Component({
  selector: 'app-cart-checkout',
  templateUrl: './cart-checkout.component.html',
  styleUrls: []
})
export class CartCheckoutComponent implements OnInit, OnDestroy {
  private readonly subscriptionGroup = new SubscriptionGroup();
  public allowPackOrderNames: boolean = false;
  public $addressChange = new BehaviorSubject<null>(null);
  @ViewChild('paymentMethod') paymentMethod: PaymentMethodComponent;
  @ViewChild('checkoutContact') checkoutContact: CartCheckoutContactComponent;
  @ViewChild('customUserFieldForm') customUserFieldForm!: CustomUserFieldComponent;

  init: boolean = false;
  ccTransaction: Partial<IOrderCardPaymentPrepared> = {};
  message: string | null;
  messageSeverity = 'danger';
  public customerId: number;
  public customerUserId: number
  public checkoutConfirmed: boolean = false;
  public getOrderEmail: boolean = false;
  public currentOrderId: number;
  private unsubscribe$ = new Subject<void>();
  public totalBalance: number = 0;

  public $firstName = this.securityService.sessionUserProperty('firstName', 'User');

  constructor(
    private securityService: SecurityService,
    public router: Router,
    public cartService: CartService,
    private session: SessionApi,
    public notifications: NotificationsService,
    public utils: Utils,
    private fileUploadService: FileUploadService,
    private authApi: AuthApi,
    public globals: GlobalApi
  ) { }

  ngOnInit() {
    /**
     * If the customer is on stopCredit, send them back to the cart
     */
    this.securityService.isStopCredit().pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(stopCredit => {
      if (stopCredit) {
        this.router.navigate(["/cart"]);
      }
    });

    this.session.$customerData.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(customer => {
      if (customer) {
        this.customerId = customer.id;
        this.cartService.getCartItems(customer.id).pipe(
          takeUntil(this.unsubscribe$)
        ).subscribe(() => {
          if (this.verifyItemLength()) {
            combineLatest(this.cartService.getUserCart()).pipe(
              takeUntil(this.unsubscribe$)
            ).subscribe(() => {
              this.init = true;
              this.paymentMethod.init();
              if (this.cartService.cart) {
                this.currentOrderId = this.cartService.cart.id;
              }
            });
          }

          this.monitorAccess();
          this.configureAddressChange(customer.id);
        });
        this.fetchCustomFields()
      }
    });

    this.session.$userData.pipe(takeUntil(this.unsubscribe$)).subscribe((user) => {
      if (user && user.customerUsers?.length) {
        user.customerUsers[0].userAllocations?.map(allocation => {
          if (allocation.type == "dollar") {
            this.totalBalance += allocation.balance;
          }
        });
      }
    });
  }

  public fetchCustomFields() {
    this.authApi.getInformation().pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe((res) => {
      if (res.customerUsers?.length) {
        this.customerUserId = res.customerUsers[0].id;
      }
    });
  }

  /**
 * @description ensures there is a sufficient item count to justify looking at this page and acts if there is not
 */
  verifyItemLength = (): boolean => {
    if (this.cartService.items.length === 0) {
      console.log('User attempted to access cart with no products. Redirecting to products.');
      this.notifications.warn('Empty Cart', 'No Cart Items to Review');

      this.continueShopping();

      return false;
    }

    return true;
  };

  /**
 * @description provides a centralised behaviour to perform when the user should continue selecting items
 */
  continueShopping = () => this.router.navigate(['/products']);

  monitorAccess() {
    this.subscriptionGroup.add(this.securityService.allowPackOrderNames().pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(hasAccess => this.allowPackOrderNames = hasAccess));
  }

  /**
 * Save the address that has been selected and check if it is a free shipping address
 */
  public configureAddressChange = (customerId: number) => {
    this.$addressChange.pipe(
      filter(() => !!this.session.getCustomerUser()),
      switchMap(() => this.cartService.saveCartAttrs(customerId, ['shippingDetails'])),
    ).pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(newAttrs => {
      if (newAttrs.shippingDetails) {
        this.cartService.cart.attrs.shippingDetails = newAttrs.shippingDetails;
        this.cartService.requestCCUpdate();
      }
    });
  }

  anyItemHasPrice() {
    return this.cartService.items.find(item => item && item.product && item.product.price && item.product.price > 0);
  }

  public addressUpdated() {
    this.$addressChange.next(null);
  }

  /**
 * Uploads Supporting Documents to an order
 *
 * @param event
 */
  public persistS3Documents(event) {
    const filesArray: File[] = [];
    const promises: Array<Promise<any>> = [];
    for (let i = 0; i < event.srcElement.files.length; i++) {
      filesArray.push(event.srcElement.files[i]);
      promises.push(
        new Promise((resolve, reject) => {
          this.fileUploadService.uploadSupportingDocument(event.srcElement.files[i], 'PACKNAME', (err, data) => {
            if (err)
              reject(err);
            else
              resolve(data);
          });
        }
        ));
    }

    let s3Prefix = 'https://s3-ap-southeast-2.amazonaws.com/static.reali.supply/';
    let cfDistribution = 'https://static.reali.supply/';

    Promise.all(promises).then((resData) => {

      if (!this.cartService.cart.attrs.supportingDocuments) {
        this.cartService.cart.attrs.supportingDocuments = [];
      }

      filesArray.forEach((theFile, index) => {
        const url = resData[index].Location.replace(s3Prefix, cfDistribution);

        this.cartService.cart.attrs.supportingDocuments?.push({
          fileName: theFile.name,
          fileSize: theFile.size,
          mimeType: theFile.type,
          s3Url: url,
          type: "PACKNAME"
        });
      });

      this.cartService.saveCartAttrs(this.customerId, ['supportingDocuments'])
        .subscribe(() => {
        });
    }).catch(err => {
      console.error('Exception while uploading files', err);
      this.notifications.error('Error', 'Unable to upload files.');
    });
  }

  /**
 * UI Helper method for getting the list of supporting documents on the current cart
 */
  public getSupportingDocuments = () =>
    this.cartService.cart &&
      this.cartService.cart.attrs &&
      this.cartService.cart.attrs.supportingDocuments ?
      this.cartService.cart.attrs.supportingDocuments :
      [];

  saveCustomFields() {
    if (this.customUserFieldForm.areAllFieldsFilled()) {
      return true;
    }
    return false;
  }

  /**
 * @description Send cart to server for processing
 */
  notification: Notification | null;
  placeOrder = (foo = true) => {
    if (!this.init) return;

    if (!this.saveCustomFields()) {
      window.scroll(0, 0);
      this.message = 'Please fill all required fields.';
      this.messageSeverity = 'danger';
      return;
    }

    this.customUserFieldForm.onSaveClick();
    this.cartService.cart.attrs.shippingDetails = this.checkoutContact.shippingDetails;
    this.message = null;

    if (!this.addressIsValid()) {
      window.scroll(0, 0);
      this.message = 'Invalid Shipping Address. Please ensure all details are filled.';
      this.messageSeverity = 'danger';
      return;
    }

    if (!this.checkContactValid()) {
      window.scroll(0, 0);
      this.message = 'Invalid Contact Details. Please ensure all details are filled.';
      this.messageSeverity = 'danger';
      return;
    }

    if (!this.poIsValid()) {
      window.scroll(0, 0);
      this.message = 'Invalid Purchase Order Number. Please ensure you enter a valid order number.';
      this.messageSeverity = 'danger';
      return;
    }

    if (!this.checkoutConfirmed) {
      window.scroll(0, 0);
      this.message = 'Please confirm you have checked your order.';
      this.messageSeverity = 'danger';
      return;
    }

    this.notification = this.notifications.warn('Placing Order', 'Sending your order.', {
      timeout: 5000
    });

    this.cartService.saveCartAttrs(this.customerId)
      .subscribe(res => {
        if (this.paymentMethod.isCCTransaction()) {
          this.processCC();
        } else {
          this.cartService.placeOrder(this.getOrderEmail, this.customerId)
            .subscribe(orderResult => {
              if (this.notification) {
                this.notifications.remove(this.notification.id);
                this.notification = null;
              }

              if (has(orderResult, 'orderNoCartItems') && orderResult.orderNoCartItems) {
                this.notifications.warn('Empty Cart', 'No Cart Items to Order');

                return this.continueShopping();
              }

              if (has(orderResult, 'orderSentForApproval') && orderResult.orderSentForApproval) {

                this.cartService.clearCart();
                this.cartService.getUserCart();

                this.notifications.success('Order Sent', 'Your order was sent for approval.');

                this.router.navigate(['/cart/pending_approval', this.currentOrderId]);
              } else {
                this.cartService.clearCart();
                this.cartService.getUserCart();
                this.notifications.success('Order Placed', 'Your order was placed.');
                this.router.navigate(['/cart/complete', this.currentOrderId]);
              }
            },
              error => {
                if (this.notification) {
                  this.notifications.remove(this.notification.id);
                  this.notification = null;
                }

                this.notifications.error("Error", "There was an error placing your order. Please contact Support", { timeOut: 10000 });
              });
        }
      });
  };

  /**
 * Helper method for validating the shipping address
 */
  addressIsValid() {
    if (!this.cartService.cart || !this.cartService.cart.attrs || !this.cartService.cart.attrs.shippingDetails)
      return false;

    let details = this.cartService.cart.attrs.shippingDetails;

    let props = [
      details.streetAddress,
      details.suburb,
      details.postalCode,
      details.region
    ];

    for (let i = 0; i < props.length; i++) {
      if (props[i] === null || props[i]?.length === 0)
        return false;
    }

    return true;
  }

  checkContactValid() {
    if (!this.cartService.cart || !this.cartService.cart.attrs)
      return false;

    let details = this.cartService.cart.attrs;

    let props = [
      details.contactFirstName,
      details.contactLastName,
      details.contactEmail
    ];

    for (let i = 0; i < props.length; i++) {
      if (props[i] === null || props[i]?.length === 0)
        return false;
    }

    return true;
  }

  /**
 * Helper method for validating the purchase order
 */
  poIsValid(): boolean {
    // if order pattern is not defined for the customer - use default pattern /^.+$/
    const customerData = this.session.$customerData.getValue();
    if (!customerData) {
      throw new Error("Expected to reliably have access to customer data");
    }

    if (!customerData.purchaseOrderPattern) {
      // The customer has no purchase order pattern defined - Allow them through
      return true;
    }

    const purchaseOrder = (this.cartService.cart.attrs.orderReference || '').trim();
    const matcher = new RegExp(customerData.purchaseOrderPattern);

    let matches = purchaseOrder.match(matcher);

    return !!matches;
  }

  processCC() {
    if (!this.init) return;

    const validationResult = this.paymentMethod.validate();

    if (this.notification) {
      this.notifications.remove(this.notification.id);
      this.notification = null;
    }

    if (validationResult.notification)
      this.notification = validationResult.notification;

    if (!validationResult.isValid) {
      return false;
    }

    this.paymentMethod.submit();
  }

  ngOnDestroy() {
    if (this.subscriptionGroup) {
      if (this.subscriptionGroup) {
        this.subscriptionGroup.unsubscribe();
      }
    }
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }


}
