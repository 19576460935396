<div class="main-right-content">
	<nav aria-label="breadcrumb" class="pageBreadcrumb">
		<ol class="breadcrumb mb-0">
			<li class="breadcrumb-item">
				<a [routerLink]="'/manage/products'">Manage products</a>
			</li>
			<li class="breadcrumb-item active" aria-current="page">
				Add new product
			</li>
		</ol>
	</nav>

	<div class="manageUser">
		<div class="manage-user-list">
			<div class="customer-wizard">
				<div class="wizard-tabs">
					<ul class="nav nav-pills" id="pills-tab" role="tablist">
						<li class="nav-item">
							<button
								class="nav-link active"
								type="button"
								(click)="setActive(1)"
								[class.active]="activeClassId == 1"
								[class.complete]="activeClassId >= 2"
							>
								<span>
									<svg
										class="incompleted"
										xmlns="http://www.w3.org/2000/svg"
										width="20"
										height="20"
										viewBox="0 0 20 20"
										fill="none"
									>
										<path
											d="M16 16.75V15.25C16 14.4544 15.6839 13.6913 15.1213 13.1287C14.5587 12.5661 13.7956 12.25 13 12.25H7C6.20435 12.25 5.44129 12.5661 4.87868 13.1287C4.31607 13.6913 4 14.4544 4 15.25V16.75M13 6.25C13 7.90685 11.6569 9.25 10 9.25C8.34315 9.25 7 7.90685 7 6.25C7 4.59315 8.34315 3.25 10 3.25C11.6569 3.25 13 4.59315 13 6.25Z"
											stroke="transparent"
											stroke-width="1.5"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
									</svg>
									<svg
										class="completed"
										xmlns="http://www.w3.org/2000/svg"
										width="20"
										height="20"
										viewBox="0 0 20 20"
										fill="none"
									>
										<path
											d="M17.5 9.31039V10.0004C17.4991 11.6177 16.9754 13.1914 16.007 14.4868C15.0386 15.7821 13.6775 16.7297 12.1265 17.1883C10.5756 17.6469 8.91794 17.5918 7.40085 17.0313C5.88376 16.4708 4.58849 15.435 3.70822 14.0782C2.82795 12.7214 2.40984 11.1164 2.51626 9.50262C2.62267 7.88881 3.24791 6.35263 4.29871 5.12319C5.34951 3.89375 6.76959 3.03692 8.34714 2.6805C9.92469 2.32407 11.5752 2.48714 13.0525 3.14539"
											stroke="transparent"
											stroke-width="1.5"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
										<path
											d="M17.5 4L10 11.5075L7.75 9.2575"
											stroke="transparent"
											stroke-width="1.5"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
									</svg>
								</span>
								<div>
									<h6>Step 1</h6>
									<h5>Overview</h5>
								</div>
							</button>
						</li>
						<li class="nav-item">
							<div class="border-top-item"></div>
						</li>
						<li class="nav-item">
							<button
								class="nav-link"
								type="button"
								(click)="setActive(2)"
								[class.active]="activeClassId == 2"
								[class.complete]="activeClassId >= 3"
							>
								<span>
									<svg
										class="incompleted"
										xmlns="http://www.w3.org/2000/svg"
										width="20"
										height="20"
										viewBox="0 0 20 20"
										fill="none"
									>
										<path
											d="M13.75 3.25023C13.947 3.05324 14.1808 2.89699 14.4382 2.79038C14.6956 2.68378 14.9714 2.62891 15.25 2.62891C15.5286 2.62891 15.8044 2.68378 16.0618 2.79038C16.3192 2.89699 16.553 3.05324 16.75 3.25023C16.947 3.44721 17.1032 3.68106 17.2098 3.93843C17.3165 4.1958 17.3713 4.47165 17.3713 4.75023C17.3713 5.0288 17.3165 5.30465 17.2098 5.56202C17.1032 5.81939 16.947 6.05324 16.75 6.25023L6.625 16.3752L2.5 17.5002L3.625 13.3752L13.75 3.25023Z"
											stroke="transparent"
											stroke-width="1.5"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
									</svg>
									<svg
										class="completed"
										xmlns="http://www.w3.org/2000/svg"
										width="20"
										height="20"
										viewBox="0 0 20 20"
										fill="none"
									>
										<path
											d="M17.5 9.31039V10.0004C17.4991 11.6177 16.9754 13.1914 16.007 14.4868C15.0386 15.7821 13.6775 16.7297 12.1265 17.1883C10.5756 17.6469 8.91794 17.5918 7.40085 17.0313C5.88376 16.4708 4.58849 15.435 3.70822 14.0782C2.82795 12.7214 2.40984 11.1164 2.51626 9.50262C2.62267 7.88881 3.24791 6.35263 4.29871 5.12319C5.34951 3.89375 6.76959 3.03692 8.34714 2.6805C9.92469 2.32407 11.5752 2.48714 13.0525 3.14539"
											stroke="transparent"
											stroke-width="1.5"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
										<path
											d="M17.5 4L10 11.5075L7.75 9.2575"
											stroke="transparent"
											stroke-width="1.5"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
									</svg>
								</span>
								<div>
									<h6>Step 2</h6>
									<h5>Descriptions</h5>
								</div>
							</button>
						</li>
						<li class="nav-item">
							<div class="border-top-item"></div>
						</li>
						<li class="nav-item">
							<button
								class="nav-link"
								type="button"
								(click)="setActive(3)"
								[class.active]="activeClassId == 3"
								[class.complete]="activeClassId >= 4"
							>
								<span>
									<svg
										class="incompleted"
										xmlns="http://www.w3.org/2000/svg"
										width="20"
										height="20"
										viewBox="0 0 20 20"
										fill="none"
									>
										<path
											d="M15.25 3.25H4.75C3.92157 3.25 3.25 3.92157 3.25 4.75V15.25C3.25 16.0784 3.92157 16.75 4.75 16.75H15.25C16.0784 16.75 16.75 16.0784 16.75 15.25V4.75C16.75 3.92157 16.0784 3.25 15.25 3.25Z"
											stroke="transparent"
											stroke-width="1.5"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
										<path
											d="M16.75 12.25L13 8.5L4.75 16.75"
											stroke="transparent"
											stroke-width="1.5"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
										<path
											d="M7.375 8.5C7.99632 8.5 8.5 7.99632 8.5 7.375C8.5 6.75368 7.99632 6.25 7.375 6.25C6.75368 6.25 6.25 6.75368 6.25 7.375C6.25 7.99632 6.75368 8.5 7.375 8.5Z"
											stroke="transparent"
											stroke-width="1.5"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
									</svg>
									<svg
										class="completed"
										xmlns="http://www.w3.org/2000/svg"
										width="20"
										height="20"
										viewBox="0 0 20 20"
										fill="none"
									>
										<path
											d="M17.5 9.31039V10.0004C17.4991 11.6177 16.9754 13.1914 16.007 14.4868C15.0386 15.7821 13.6775 16.7297 12.1265 17.1883C10.5756 17.6469 8.91794 17.5918 7.40085 17.0313C5.88376 16.4708 4.58849 15.435 3.70822 14.0782C2.82795 12.7214 2.40984 11.1164 2.51626 9.50262C2.62267 7.88881 3.24791 6.35263 4.29871 5.12319C5.34951 3.89375 6.76959 3.03692 8.34714 2.6805C9.92469 2.32407 11.5752 2.48714 13.0525 3.14539"
											stroke="transparent"
											stroke-width="1.5"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
										<path
											d="M17.5 4L10 11.5075L7.75 9.2575"
											stroke="transparent"
											stroke-width="1.5"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
									</svg>
								</span>
								<div>
									<h6>Step 3</h6>
									<h5>Images</h5>
								</div>
							</button>
						</li>
						<li class="nav-item">
							<div class="border-top-item"></div>
						</li>
						<li class="nav-item">
							<button
								class="nav-link"
								id="pills-Inventory-tab"
								type="button"
								(click)="setActive(4)"
								[class.active]="activeClassId == 4"
								[class.complete]="activeClassId >= 5"
							>
								<span>
									<svg
										class="incompleted"
										xmlns="http://www.w3.org/2000/svg"
										width="20"
										height="20"
										viewBox="0 0 20 20"
										fill="none"
									>
										<path
											d="M16.75 13V7C16.7497 6.73695 16.6803 6.4786 16.5487 6.25087C16.417 6.02313 16.2278 5.83402 16 5.7025L10.75 2.7025C10.522 2.57084 10.2633 2.50154 10 2.50154C9.7367 2.50154 9.47803 2.57084 9.25 2.7025L4 5.7025C3.7722 5.83402 3.58299 6.02313 3.45135 6.25087C3.31971 6.4786 3.25027 6.73695 3.25 7V13C3.25027 13.263 3.31971 13.5214 3.45135 13.7491C3.58299 13.9769 3.7722 14.166 4 14.2975L9.25 17.2975C9.47803 17.4291 9.7367 17.4985 10 17.4985C10.2633 17.4985 10.522 17.4291 10.75 17.2975L16 14.2975C16.2278 14.166 16.417 13.9769 16.5487 13.7491C16.6803 13.5214 16.7497 13.263 16.75 13Z"
											stroke="transparent"
											stroke-width="1.5"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
										<path
											d="M3.45215 6.21999L9.99965 10.0075L16.5471 6.21999"
											stroke="transparent"
											stroke-width="1.5"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
										<path
											d="M10 17.56V10"
											stroke="transparent"
											stroke-width="1.5"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
									</svg>
									<svg
										class="completed"
										xmlns="http://www.w3.org/2000/svg"
										width="20"
										height="20"
										viewBox="0 0 20 20"
										fill="none"
									>
										<path
											d="M17.5 9.31039V10.0004C17.4991 11.6177 16.9754 13.1914 16.007 14.4868C15.0386 15.7821 13.6775 16.7297 12.1265 17.1883C10.5756 17.6469 8.91794 17.5918 7.40085 17.0313C5.88376 16.4708 4.58849 15.435 3.70822 14.0782C2.82795 12.7214 2.40984 11.1164 2.51626 9.50262C2.62267 7.88881 3.24791 6.35263 4.29871 5.12319C5.34951 3.89375 6.76959 3.03692 8.34714 2.6805C9.92469 2.32407 11.5752 2.48714 13.0525 3.14539"
											stroke="transparent"
											stroke-width="1.5"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
										<path
											d="M17.5 4L10 11.5075L7.75 9.2575"
											stroke="transparent"
											stroke-width="1.5"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
									</svg>
								</span>
								<div>
									<h6>Step 4</h6>
									<h5>Inventory</h5>
								</div>
							</button>
						</li>
						<li class="nav-item">
							<div class="border-top-item"></div>
						</li>
						<li class="nav-item">
							<button
								class="nav-link"
								id="pills-Inventory-tab"
								type="button"
								(click)="setActive(5)"
								[class.active]="activeClassId == 5"
								[class.complete]="activeClassId >= 6"
							>
								<span>
									<svg
										class="incompleted"
										xmlns="http://www.w3.org/2000/svg"
										width="20"
										height="20"
										viewBox="0 0 20 20"
										fill="none"
									>
										<path
											d="M16 16.75V15.25C16 14.4544 15.6839 13.6913 15.1213 13.1287C14.5587 12.5661 13.7956 12.25 13 12.25H7C6.20435 12.25 5.44129 12.5661 4.87868 13.1287C4.31607 13.6913 4 14.4544 4 15.25V16.75M13 6.25C13 7.90685 11.6569 9.25 10 9.25C8.34315 9.25 7 7.90685 7 6.25C7 4.59315 8.34315 3.25 10 3.25C11.6569 3.25 13 4.59315 13 6.25Z"
											stroke="transparent"
											stroke-width="1.5"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
									</svg>
									<svg
										class="completed"
										xmlns="http://www.w3.org/2000/svg"
										width="20"
										height="20"
										viewBox="0 0 20 20"
										fill="none"
									>
										<path
											d="M17.5 9.31039V10.0004C17.4991 11.6177 16.9754 13.1914 16.007 14.4868C15.0386 15.7821 13.6775 16.7297 12.1265 17.1883C10.5756 17.6469 8.91794 17.5918 7.40085 17.0313C5.88376 16.4708 4.58849 15.435 3.70822 14.0782C2.82795 12.7214 2.40984 11.1164 2.51626 9.50262C2.62267 7.88881 3.24791 6.35263 4.29871 5.12319C5.34951 3.89375 6.76959 3.03692 8.34714 2.6805C9.92469 2.32407 11.5752 2.48714 13.0525 3.14539"
											stroke="transparent"
											stroke-width="1.5"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
										<path
											d="M17.5 4L10 11.5075L7.75 9.2575"
											stroke="transparent"
											stroke-width="1.5"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
									</svg>
								</span>
								<div>
									<h6>Step 5</h6>
									<h5>Customer Product</h5>
								</div>
							</button>
						</li>
					</ul>
				</div>
				<div class="tab-content" id="pills-tabContent">
					<div
						class="tab-pane fade"
						[class.show]="activeClassId == 1"
						[class.active]="activeClassId == 1"
					>
						<div class="edit-users">
							<div>
								<div class="edit-card card">
									<div class="card-head">
										<h4 class="head-title">
											<span>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="20"
													height="20"
													viewBox="0 0 20 20"
													fill="none"
												>
													<path
														d="M16 16.75V15.25C16 14.4544 15.6839 13.6913 15.1213 13.1287C14.5587 12.5661 13.7956 12.25 13 12.25H7C6.20435 12.25 5.44129 12.5661 4.87868 13.1287C4.31607 13.6913 4 14.4544 4 15.25V16.75"
														fill="url(#paint0_radial_0_17046)"
														fill-opacity="0.5"
													/>
													<path
														d="M10 9.25C11.6569 9.25 13 7.90685 13 6.25C13 4.59315 11.6569 3.25 10 3.25C8.34315 3.25 7 4.59315 7 6.25C7 7.90685 8.34315 9.25 10 9.25Z"
														fill="url(#paint1_radial_0_17046)"
														fill-opacity="0.5"
													/>
													<path
														d="M16 16.75V15.25C16 14.4544 15.6839 13.6913 15.1213 13.1287C14.5587 12.5661 13.7956 12.25 13 12.25H7C6.20435 12.25 5.44129 12.5661 4.87868 13.1287C4.31607 13.6913 4 14.4544 4 15.25V16.75M13 6.25C13 7.90685 11.6569 9.25 10 9.25C8.34315 9.25 7 7.90685 7 6.25C7 4.59315 8.34315 3.25 10 3.25C11.6569 3.25 13 4.59315 13 6.25Z"
														stroke="#272835"
														stroke-width="1.5"
														stroke-linecap="round"
														stroke-linejoin="round"
													/>
													<defs>
														<radialGradient
															id="paint0_radial_0_17046"
															cx="0"
															cy="0"
															r="1"
															gradientUnits="userSpaceOnUse"
															gradientTransform="translate(10 3.925) rotate(90) scale(9.7875 8.7)"
														>
															<stop stop-color="#8BD6EF" />
															<stop
																offset="1"
																stop-color="#8BD6EF"
																stop-opacity="0"
															/>
														</radialGradient>
														<radialGradient
															id="paint1_radial_0_17046"
															cx="0"
															cy="0"
															r="1"
															gradientUnits="userSpaceOnUse"
															gradientTransform="translate(10 3.925) rotate(90) scale(9.7875 8.7)"
														>
															<stop stop-color="#8BD6EF" />
															<stop
																offset="1"
																stop-color="#8BD6EF"
																stop-opacity="0"
															/>
														</radialGradient>
													</defs>
												</svg>
											</span>
											Display Options
										</h4>
									</div>
									<div class="card-body">
										<div class="row">
											<div class="col-12">
												<div class="form-group form-check">
													<input
														type="radio"
														class="form-check-input"
														name="accessMode"
														id="Approval1"
														[value]="2"
														[(ngModel)]="product.accessMode"
													/>
													<label class="form-check-label" for="Approval1"
														>This Product is available before user login
													</label>
												</div>
											</div>
											<div class="col-12">
												<div class="form-group form-check">
													<input
														type="radio"
														class="form-check-input"
														name="accessMode"
														id="Approval2"
														[value]="1"
														[(ngModel)]="product.accessMode"
													/>
													<label class="form-check-label" for="Approval2"
														>This Product is available to all logged in users
													</label>
												</div>
											</div>
											<div class="col-12">
												<div class="form-group form-check">
													<input
														type="radio"
														class="form-check-input"
														name="accessMode"
														id="Approval3"
														[value]="0"
														[(ngModel)]="product.accessMode"
													/>
													<label class="form-check-label" for="Approval3"
														>This Product is only visible to selected customers
													</label>
												</div>
											</div>
											<div class="col-12">
												<div class="form-group form-check">
													<input
														type="checkbox"
														class="form-check-input"
														id="Approval4"
														[value]="true"
														[(ngModel)]="product.inStock"
													/>
													<label class="form-check-label" for="Approval4"
														>Product in Stock
													</label>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="edit-card card">
									<div class="card-head">
										<h4 class="head-title">
											<span>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="20"
													height="20"
													viewBox="0 0 20 20"
													fill="none"
												>
													<path
														d="M16 16.75V15.25C16 14.4544 15.6839 13.6913 15.1213 13.1287C14.5587 12.5661 13.7956 12.25 13 12.25H7C6.20435 12.25 5.44129 12.5661 4.87868 13.1287C4.31607 13.6913 4 14.4544 4 15.25V16.75"
														fill="url(#paint0_radial_0_17046)"
														fill-opacity="0.5"
													/>
													<path
														d="M10 9.25C11.6569 9.25 13 7.90685 13 6.25C13 4.59315 11.6569 3.25 10 3.25C8.34315 3.25 7 4.59315 7 6.25C7 7.90685 8.34315 9.25 10 9.25Z"
														fill="url(#paint1_radial_0_17046)"
														fill-opacity="0.5"
													/>
													<path
														d="M16 16.75V15.25C16 14.4544 15.6839 13.6913 15.1213 13.1287C14.5587 12.5661 13.7956 12.25 13 12.25H7C6.20435 12.25 5.44129 12.5661 4.87868 13.1287C4.31607 13.6913 4 14.4544 4 15.25V16.75M13 6.25C13 7.90685 11.6569 9.25 10 9.25C8.34315 9.25 7 7.90685 7 6.25C7 4.59315 8.34315 3.25 10 3.25C11.6569 3.25 13 4.59315 13 6.25Z"
														stroke="#272835"
														stroke-width="1.5"
														stroke-linecap="round"
														stroke-linejoin="round"
													/>
													<defs>
														<radialGradient
															id="paint0_radial_0_17046"
															cx="0"
															cy="0"
															r="1"
															gradientUnits="userSpaceOnUse"
															gradientTransform="translate(10 3.925) rotate(90) scale(9.7875 8.7)"
														>
															<stop stop-color="#8BD6EF" />
															<stop
																offset="1"
																stop-color="#8BD6EF"
																stop-opacity="0"
															/>
														</radialGradient>
														<radialGradient
															id="paint1_radial_0_17046"
															cx="0"
															cy="0"
															r="1"
															gradientUnits="userSpaceOnUse"
															gradientTransform="translate(10 3.925) rotate(90) scale(9.7875 8.7)"
														>
															<stop stop-color="#8BD6EF" />
															<stop
																offset="1"
																stop-color="#8BD6EF"
																stop-opacity="0"
															/>
														</radialGradient>
													</defs>
												</svg>
											</span>
											General information
										</h4>
									</div>
									<div class="card-body">
										<div class="row">
											<div class="col-12 col-md-6">
												<div class="form-group">
													<label for="product-name" class="form-label"
														>Product Name</label
													>
													<input
														type="text"
														class="form-control"
														id="product-name"
														placeholder="Enter full product name...."
														(keyup)="resetUrlCheckState()"
														[(ngModel)]="product.name"
													/>
												</div>
											</div>
											<div class="col-12 col-md-6">
												<div class="form-group">
													<label for="code" class="form-label">Code</label>
													<input
														type="text"
														class="form-control"
														id="code"
														placeholder=""
														[(ngModel)]="product.code"
													/>
												</div>
											</div>
											<div class="col-12 col-md-6">
												<div class="form-group">
													<label for="Brand" class="form-label">Brand</label>
													<ng-select
														id="Brand"
														[virtualScroll]="true"
														placeholder="Select Brand"
														[searchable]="true"
														[clearable]="true"
														[(ngModel)]="selectedBrand"
														class="form-select-2"
														(change)="setBrand(selectedBrand)"
													>
														<ng-option
															*ngFor="let brand of brandSelectOptions"
															[value]="brand.id"
														>
															{{ brand.text }}
														</ng-option>
														>
													</ng-select>
												</div>
											</div>
											<div class="col-12 col-md-6">
												<div class="form-group">
													<label for="productWeight" class="form-label"
														>Product Weight <span>(grams)</span></label
													>
													<input
														type="number"
														placeholder="Enter product weight..."
														class="form-control"
														id="productWeight"
														[(ngModel)]="product.weight"
														min="0"
													/>
												</div>
											</div>
											<div class="col-12 col-md-6">
												<div class="form-group">
													<label for="Labels" class="form-label"
														>Labels <span>(Optional)</span></label
													>
													<select
														class="form-select"
														#selectedLabel
														aria-label="Default select example"
														id="Labels"
														(change)="selectLabels(selectedLabel.value)"
													>
														<option [selected]="!product.labels">
															Select Labels
														</option>
														<option
															*ngFor="let label of labelSelectOptions"
															[selected]="
																product.labels &&
																product.labels[0] &&
																product.labels[0].id == +label.id
															"
															[value]="label.text"
														>
															{{ label.text }}
														</option>
													</select>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="edit-card card">
									<div class="card-head">
										<h4 class="head-title">
											<span>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="20"
													height="20"
													viewBox="0 0 20 20"
													fill="none"
												>
													<path
														d="M17.5 15.25C17.5 15.6478 17.342 16.0294 17.0607 16.3107C16.7794 16.592 16.3978 16.75 16 16.75H4C3.60218 16.75 3.22064 16.592 2.93934 16.3107C2.65804 16.0294 2.5 15.6478 2.5 15.25V4.75C2.5 4.35218 2.65804 3.97064 2.93934 3.68934C3.22064 3.40804 3.60218 3.25 4 3.25H7.75L9.25 5.5H16C16.3978 5.5 16.7794 5.65804 17.0607 5.93934C17.342 6.22064 17.5 6.60218 17.5 7V15.25Z"
														stroke="#272835"
														stroke-width="1.5"
														stroke-linecap="round"
														stroke-linejoin="round"
													/>
												</svg>
											</span>
											Category
										</h4>
									</div>
									<div class="card-body">
										<div class="row">
											<div class="col-12 col-md-6">
												<div class="form-group">
													<label for="Category1" class="form-label"
														>Category 1</label
													>
													<select
														#category
														class="form-select"
														aria-label="Default select example"
														id="Category1"
														(change)="setCategory(category.value, 0)"
													>
														<option selected>
															{{ getCategoryName(0) || "Select" }}
														</option>
														<option
															*ngFor="let item of filterCategories()"
															[value]="item.id"
														>
															{{ item.name }}
														</option>
													</select>
												</div>
											</div>
											<div
												class="col-12 col-md-6"
												*ngFor="
													let options of subcategoryOptions;
													let i = index
												"
											>
												<div class="form-group">
													<label for="Category2" class="form-label"
														>Category {{ i + 2 }}
													</label>
													<select
														#category2
														class="form-select"
														aria-label="Default select example"
														id="Category2"
														(change)="setCategory(category2.value, i + 1)"
													>
														<option selected>
															{{ getCategoryName(i + 1) || "Select" }}
														</option>
														<option
															*ngFor="let item of options"
															[value]="item.id"
														>
															{{ item.name }}
														</option>
													</select>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div>
								<div class="edit-card card">
									<div class="card-head">
										<h4 class="head-title gap-1">
											<span>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="20"
													height="20"
													viewBox="0 0 20 20"
													fill="none"
												>
													<path
														d="M10 1.75V18.25"
														stroke="#272835"
														stroke-width="1.5"
														stroke-linecap="round"
														stroke-linejoin="round"
													/>
													<path
														d="M13.75 4.75H8.125C7.42881 4.75 6.76113 5.02656 6.26884 5.51884C5.77656 6.01113 5.5 6.67881 5.5 7.375C5.5 8.07119 5.77656 8.73887 6.26884 9.23116C6.76113 9.72344 7.42881 10 8.125 10H11.875C12.5712 10 13.2389 10.2766 13.7312 10.7688C14.2234 11.2611 14.5 11.9288 14.5 12.625C14.5 13.3212 14.2234 13.9889 13.7312 14.4812C13.2389 14.9734 12.5712 15.25 11.875 15.25H5.5"
														stroke="#272835"
														stroke-width="1.5"
														stroke-linecap="round"
														stroke-linejoin="round"
													/>
												</svg>
											</span>
											Pricing
										</h4>
									</div>
									<div class="card-body">
										<div class="row">
											<div class="col-12 col-lg-9">
												<div class="form-group">
													<label for="Amount" class="form-label"
														>Amount ($AUD)</label
													>
													<input
														type="number"
														class="form-control"
														id="Amount"
														placeholder="Base Price"
														[(ngModel)]="product.basePrice"
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="edit-card card">
									<div class="card-head">
										<h4 class="head-title">
											<span>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="20"
													height="20"
													viewBox="0 0 20 20"
													fill="none"
												>
													<path
														d="M5.5 16V11.5"
														stroke="#272835"
														stroke-width="1.5"
														stroke-linecap="round"
														stroke-linejoin="round"
													/>
													<path
														d="M14.5 16V8.5"
														stroke="#272835"
														stroke-width="1.5"
														stroke-linecap="round"
														stroke-linejoin="round"
													/>
													<path
														d="M10 16V4"
														stroke="#272835"
														stroke-width="1.5"
														stroke-linecap="round"
														stroke-linejoin="round"
													/>
												</svg>
											</span>
											Size chart
										</h4>
									</div>
									<div class="card-body" *ngIf="showSizeChart()">
										<div class="auths mb-lg-5">
											<div class="form-group mb-0">
												<h4 class="form-title mb-0">Color:</h4>
											</div>
											<div class="form-group form-check">
												<label
													class="form-check-label"
													[class.active]="!useTextSizeChart"
													for="Authentication1"
												>
													<span
														><input
															class="form-check-input"
															type="radio"
															name="Authentications"
															id="Authentication1"
															[value]="false"
															[(ngModel)]="useTextSizeChart"
														/>
													</span>
													Image chart
												</label>
											</div>
											<div class="row">
												<div class="col-12">
													<div class="form-group form-check">
														<label
															class="form-check-label"
															[class.active]="useTextSizeChart"
															for="Authentication2"
														>
															<span>
																<input
																	class="form-check-input"
																	type="radio"
																	name="Authentications"
																	id="Authentication2"
																	[value]="true"
																	[(ngModel)]="useTextSizeChart"
																/>
															</span>
															Text chart
														</label>
													</div>
												</div>
											</div>
										</div>
										<div class="row">
											<div
												class="col-12"
												*ngIf="!useTextSizeChart && showSizeChart"
											>
												<div class="form-group">
													<label
														for="ImageUploadChart3"
														class="form-Image-label"
													>
														<div class="uploader-position">
															<div
																class="Image-upload-icon"
																*ngIf="!product.sizeChartUrl"
															>
																<span>
																	<svg
																		xmlns="http://www.w3.org/2000/svg"
																		width="20"
																		height="21"
																		viewBox="0 0 20 21"
																		fill="none"
																	>
																		<path
																			d="M15.25 3.2908H4.75C3.92157 3.2908 3.25 3.96237 3.25 4.7908V15.2908C3.25 16.1192 3.92157 16.7908 4.75 16.7908H15.25C16.0784 16.7908 16.75 16.1192 16.75 15.2908V4.7908C16.75 3.96237 16.0784 3.2908 15.25 3.2908Z"
																			stroke="#666D80"
																			stroke-width="1.5"
																			stroke-linecap="round"
																			stroke-linejoin="round"
																		/>
																		<path
																			d="M16.75 12.2908L13 8.5408L4.75 16.7908"
																			stroke="#666D80"
																			stroke-width="1.5"
																			stroke-linecap="round"
																			stroke-linejoin="round"
																		/>
																		<path
																			d="M7.375 8.5408C7.99632 8.5408 8.5 8.03712 8.5 7.4158C8.5 6.79448 7.99632 6.2908 7.375 6.2908C6.75368 6.2908 6.25 6.79448 6.25 7.4158C6.25 8.03712 6.75368 8.5408 7.375 8.5408Z"
																			stroke="#666D80"
																			stroke-width="1.5"
																			stroke-linecap="round"
																			stroke-linejoin="round"
																		/>
																	</svg>
																</span>
															</div>
															<h4
																class="Image-upload-title"
																*ngIf="!product.sizeChartUrl"
															>
																Upload Image
															</h4>
														</div>
														<div
															class="preview-content"
															[class.d-none]="!product.sizeChartUrl"
														>
															<img
																src="{{ product.sizeChartUrl }}"
																alt="customer"
															/>
															<div class="dlt-top">
																<button
																	class="btn btn-secondary-icon"
																	type="button"
																	(click)="removeSizeChart()"
																>
																	<span>
																		<svg
																			xmlns="http://www.w3.org/2000/svg"
																			width="20"
																			height="20"
																			viewBox="0 0 20 20"
																			fill="none"
																		>
																			<path
																				d="M3.25 5.5H4.75H16.75"
																				stroke="#666D80"
																				stroke-width="1.5"
																				stroke-linecap="round"
																				stroke-linejoin="round"
																			/>
																			<path
																				d="M7 5.5V4C7 3.60218 7.15804 3.22064 7.43934 2.93934C7.72064 2.65804 8.10218 2.5 8.5 2.5H11.5C11.8978 2.5 12.2794 2.65804 12.5607 2.93934C12.842 3.22064 13 3.60218 13 4V5.5M15.25 5.5V16C15.25 16.3978 15.092 16.7794 14.8107 17.0607C14.5294 17.342 14.1478 17.5 13.75 17.5H6.25C5.85218 17.5 5.47064 17.342 5.18934 17.0607C4.90804 16.7794 4.75 16.3978 4.75 16V5.5H15.25Z"
																				stroke="#666D80"
																				stroke-width="1.5"
																				stroke-linecap="round"
																				stroke-linejoin="round"
																			/>
																		</svg>
																	</span>
																</button>
															</div>
														</div>
													</label>
													<input
														type="file"
														capture="user"
														class="form-control d-none"
														id="ImageUploadChart3"
														(change)="persistSizeChart($event)"
													/>
												</div>
											</div>
											<div class="col-12" *ngIf="useTextSizeChart">
												<div class="form-group">
													<label for="textChart" class="form-label"
														>Standerd Text Chart</label
													>
													<select
														#sizeChart
														class="form-select"
														aria-label="Default select example"
														id="textChart"
														(change)="handleStandardChart(sizeChart.value)"
													>
														<option [selected]="!product.sizeChartId">
															Select Charts
														</option>
														<option
															*ngFor="let data of sizeChartSelectOptions"
															[selected]="
																data.id && product.sizeChartId == +data.id
															"
															[value]="data.id"
														>
															{{ data.text }}
														</option>
													</select>
												</div>
											</div>
											<div
												class="col-12"
												*ngIf="useTextSizeChart && hasCustomSizeChart()"
											>
												<app-size-table
													[sizeChart]="sizeChart"
												></app-size-table>
											</div>
										</div>
									</div>
								</div>
								<div class="edit-card card">
									<div class="card-head">
										<h4 class="head-title">
											<span>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="20"
													height="20"
													viewBox="0 0 20 20"
													fill="none"
												>
													<path
														d="M16.75 13V6.99999C16.7497 6.73694 16.6803 6.4786 16.5487 6.25086C16.417 6.02312 16.2278 5.83401 16 5.70249L10.75 2.70249C10.522 2.57084 10.2633 2.50153 10 2.50153C9.7367 2.50153 9.47803 2.57084 9.25 2.70249L4 5.70249C3.7722 5.83401 3.58299 6.02312 3.45135 6.25086C3.31971 6.4786 3.25027 6.73694 3.25 6.99999V13C3.25027 13.263 3.31971 13.5214 3.45135 13.7491C3.58299 13.9769 3.7722 14.166 4 14.2975L9.25 17.2975C9.47803 17.4291 9.7367 17.4985 10 17.4985C10.2633 17.4985 10.522 17.4291 10.75 17.2975L16 14.2975C16.2278 14.166 16.417 13.9769 16.5487 13.7491C16.6803 13.5214 16.7497 13.263 16.75 13Z"
														stroke="#272835"
														stroke-width="1.5"
														stroke-linecap="round"
														stroke-linejoin="round"
													></path>
													<path
														d="M3.45215 6.22L9.99965 10.0075L16.5471 6.22"
														stroke="#272835"
														stroke-width="1.5"
														stroke-linecap="round"
														stroke-linejoin="round"
													></path>
													<path
														d="M10 17.56V10"
														stroke="#272835"
														stroke-width="1.5"
														stroke-linecap="round"
														stroke-linejoin="round"
													></path>
												</svg>
											</span>
											Pack Sizes
										</h4>
									</div>
									<div class="card-body">
										<div class="row">
											<div class="col-12">
												<div class="form-group form-check">
													<input
														type="radio"
														class="form-check-input"
														id="items1"
														name="items"
														[value]="false"
														[(ngModel)]="usePackSizes"
													/>
													<label class="form-check-label" for="items1"
														>Single items only
													</label>
												</div>
											</div>
											<div class="col-12">
												<div class="form-group form-check">
													<input
														type="radio"
														class="form-check-input"
														id="items2"
														name="items"
														[value]="true"
														[(ngModel)]="usePackSizes"
													/>
													<label class="form-check-label" for="items2"
														>Use pack sizes
													</label>
												</div>
											</div>
											<div class="col-12 col-md-8" *ngIf="usePackSizes">
												<h5 class="form-title">Packet Type/ Packet Size</h5>
											</div>
											<div
												class="col-12 col-md-4 mb-5 mb-md-0"
												*ngIf="usePackSizes"
											>
												<button
													class="btn btn-secondary w-100"
													type="button"
													(click)="newPackSize()"
												>
													<span>
														<svg
															xmlns="http://www.w3.org/2000/svg"
															width="21"
															height="20"
															viewBox="0 0 21 20"
															fill="none"
														>
															<path
																d="M10.5 4.75V15.25"
																stroke="#666D80"
																stroke-width="1.5"
																stroke-linecap="round"
																stroke-linejoin="round"
															/>
															<path
																d="M5.25 10H15.75"
																stroke="#272835"
																stroke-width="1.5"
																stroke-linecap="round"
																stroke-linejoin="round"
															/>
														</svg>
													</span>
													Add
												</button>
											</div>
											<div class="col-12 px-0" *ngIf="usePackSizes">
												<div
													class="row"
													*ngFor="let unit of product.packSizes; let i = index"
												>
													<div class="col-12 col-md-6 col-lg-5 col-xl-6">
														<div class="form-group">
															<label for="Name" class="form-label">Type</label>
															<input
																type="text"
																class="form-control"
																id="Name"
																placeholder="Packet Type (eg. Box)"
																[(ngModel)]="unit.name"
															/>
														</div>
													</div>
													<div class="col-12 col-md-5">
														<div class="form-group">
															<label for="Allocation" class="form-label"
																>Size</label
															>
															<input
																type="text"
																class="form-control"
																id="Allocation"
																placeholder="Units per Packet (eg. 12)"
																[(ngModel)]="unit.itemCount"
															/>
														</div>
													</div>
													<div
														class="col-12 col-md-1 col-lg-2 col-xl-1 align-self-end"
													>
														<div
															class="form-group remaining justify-content-md-center"
														>
															<button
																class="btn btn-secondary-icon"
																type="button"
																(click)="removePackSize(i)"
															>
																<span>
																	<svg
																		xmlns="http://www.w3.org/2000/svg"
																		width="20"
																		height="20"
																		viewBox="0 0 20 20"
																		fill="none"
																	>
																		<path
																			d="M3.25 5.5H4.75H16.75"
																			stroke="#272835"
																			stroke-width="1.5"
																			stroke-linecap="round"
																			stroke-linejoin="round"
																		></path>
																		<path
																			d="M7 5.5V4C7 3.60218 7.15804 3.22064 7.43934 2.93934C7.72064 2.65804 8.10218 2.5 8.5 2.5H11.5C11.8978 2.5 12.2794 2.65804 12.5607 2.93934C12.842 3.22064 13 3.60218 13 4V5.5M15.25 5.5V16C15.25 16.3978 15.092 16.7794 14.8107 17.0607C14.5294 17.342 14.1478 17.5 13.75 17.5H6.25C5.85218 17.5 5.47064 17.342 5.18934 17.0607C4.90804 16.7794 4.75 16.3978 4.75 16V5.5H15.25Z"
																			stroke="#272835"
																			stroke-width="1.5"
																			stroke-linecap="round"
																			stroke-linejoin="round"
																		></path>
																	</svg>
																</span>
															</button>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div
						class="tab-pane fade"
						[class.show]="activeClassId == 2"
						[class.active]="activeClassId == 2"
					>
						<div class="edit-users products">
							<div>
								<div class="edit-card card">
									<div class="card-head">
										<h4 class="head-title">
											<span>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="20"
													height="20"
													viewBox="0 0 20 20"
													fill="none"
												>
													<path
														d="M16 16.75V15.25C16 14.4544 15.6839 13.6913 15.1213 13.1287C14.5587 12.5661 13.7956 12.25 13 12.25H7C6.20435 12.25 5.44129 12.5661 4.87868 13.1287C4.31607 13.6913 4 14.4544 4 15.25V16.75"
														fill="white"
													/>
													<path
														d="M10 9.25C11.6569 9.25 13 7.90685 13 6.25C13 4.59315 11.6569 3.25 10 3.25C8.34315 3.25 7 4.59315 7 6.25C7 7.90685 8.34315 9.25 10 9.25Z"
														fill="white"
													/>
													<path
														d="M16 16.75V15.25C16 14.4544 15.6839 13.6913 15.1213 13.1287C14.5587 12.5661 13.7956 12.25 13 12.25H7C6.20435 12.25 5.44129 12.5661 4.87868 13.1287C4.31607 13.6913 4 14.4544 4 15.25V16.75M13 6.25C13 7.90685 11.6569 9.25 10 9.25C8.34315 9.25 7 7.90685 7 6.25C7 4.59315 8.34315 3.25 10 3.25C11.6569 3.25 13 4.59315 13 6.25Z"
														stroke="#272835"
														stroke-width="1.5"
														stroke-linecap="round"
														stroke-linejoin="round"
													/>
												</svg>
											</span>
											General description
										</h4>
									</div>
									<div class="card-body">
										<div class="row">
											<div class="col-12">
												<div class="desc-btns">
													<div class="btns">
														<ngx-editor-menu
															[editor]="editor"
															[toolbar]="toolbar"
														>
														</ngx-editor-menu>
													</div>
												</div>
											</div>
											<div class="col-12 col-md-8">
												<div class="form-group">
													<label for="generalDesc" class="form-label d-none"
														>Enter General Description</label
													>
													<ngx-editor
														[editor]="editor"
														[(ngModel)]="inputDescription"
													>
													</ngx-editor>
												</div>
											</div>
											<div class="col-12 col-md-4 align-self-end">
												<div class="form-group">
													<button
														class="btn btn-secondary w-100"
														type="button"
														(click)="addDescription()"
													>
														<span>
															<svg
																xmlns="http://www.w3.org/2000/svg"
																width="21"
																height="20"
																viewBox="0 0 21 20"
																fill="none"
															>
																<path
																	d="M10.5 4.75V15.25"
																	stroke="#666D80"
																	stroke-width="1.5"
																	stroke-linecap="round"
																	stroke-linejoin="round"
																></path>
																<path
																	d="M5.25 10H15.75"
																	stroke="#272835"
																	stroke-width="1.5"
																	stroke-linecap="round"
																	stroke-linejoin="round"
																></path>
															</svg>
														</span>
														Add Description
													</button>
												</div>
											</div>
										</div>
										<div
											class="row mb-4 mb-md-0"
											*ngFor="
												let description of selectDescriptions;
												let i = index
											"
										>
											<div class="col-12 col-md-9" *ngIf="selectDescriptions">
												<div class="form-group">
													<div
														[innerHTML]="description"
														class="form-control"
													></div>
												</div>
											</div>
											<div class="col-12 col-md-3 justify-self-end">
												<div
													class="d-flex align-items-center justify-content-center gap-3"
												>
													<button
														class="btn btn-secondary-icon"
														type="button"
														[class.invisible]="
															i == selectDescriptions.length - 1
														"
														(click)="descriptionIndexSwap(i, 'down')"
													>
														<span>
															<svg
																xmlns="http://www.w3.org/2000/svg"
																width="20"
																height="20"
																viewBox="0 0 20 20"
																fill="none"
															>
																<path
																	d="M6.40039 8.2002L10.0004 11.8002L13.6004 8.2002"
																	stroke="#272835"
																	stroke-width="1.5"
																	stroke-linecap="round"
																	stroke-linejoin="round"
																></path>
															</svg>
														</span>
													</button>
													<button
														class="btn btn-secondary-icon"
														*ngIf="description"
														type="button"
														(click)="removeDescription(i)"
													>
														<span>
															<svg
																xmlns="http://www.w3.org/2000/svg"
																width="20"
																height="20"
																viewBox="0 0 20 20"
																fill="none"
															>
																<path
																	d="M3.25 5.5H4.75H16.75"
																	stroke="#272835"
																	stroke-width="1.5"
																	stroke-linecap="round"
																	stroke-linejoin="round"
																></path>
																<path
																	d="M7 5.5V4C7 3.60218 7.15804 3.22064 7.43934 2.93934C7.72064 2.65804 8.10218 2.5 8.5 2.5H11.5C11.8978 2.5 12.2794 2.65804 12.5607 2.93934C12.842 3.22064 13 3.60218 13 4V5.5M15.25 5.5V16C15.25 16.3978 15.092 16.7794 14.8107 17.0607C14.5294 17.342 14.1478 17.5 13.75 17.5H6.25C5.85218 17.5 5.47064 17.342 5.18934 17.0607C4.90804 16.7794 4.75 16.3978 4.75 16V5.5H15.25Z"
																	stroke="#272835"
																	stroke-width="1.5"
																	stroke-linecap="round"
																	stroke-linejoin="round"
																></path>
															</svg>
														</span>
													</button>
													<button
														class="btn btn-secondary-icon"
														type="button"
														[class.invisible]="i < 1"
														(click)="descriptionIndexSwap(i, 'up')"
													>
														<span>
															<svg
																xmlns="http://www.w3.org/2000/svg"
																width="20"
																height="20"
																viewBox="0 0 20 20"
																fill="none"
															>
																<path
																	d="M13.6004 11.8002L10.0004 8.2002L6.40039 11.8002"
																	stroke="#272835"
																	stroke-width="1.5"
																	stroke-linecap="round"
																	stroke-linejoin="round"
																></path>
															</svg>
														</span>
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="edit-card card">
									<div class="card-head">
										<h4 class="head-title">
											<span>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="20"
													height="20"
													viewBox="0 0 20 20"
													fill="none"
												>
													<path
														d="M16 16.75V15.25C16 14.4544 15.6839 13.6913 15.1213 13.1287C14.5587 12.5661 13.7956 12.25 13 12.25H7C6.20435 12.25 5.44129 12.5661 4.87868 13.1287C4.31607 13.6913 4 14.4544 4 15.25V16.75"
														fill="white"
													/>
													<path
														d="M10 9.25C11.6569 9.25 13 7.90685 13 6.25C13 4.59315 11.6569 3.25 10 3.25C8.34315 3.25 7 4.59315 7 6.25C7 7.90685 8.34315 9.25 10 9.25Z"
														fill="white"
													/>
													<path
														d="M16 16.75V15.25C16 14.4544 15.6839 13.6913 15.1213 13.1287C14.5587 12.5661 13.7956 12.25 13 12.25H7C6.20435 12.25 5.44129 12.5661 4.87868 13.1287C4.31607 13.6913 4 14.4544 4 15.25V16.75M13 6.25C13 7.90685 11.6569 9.25 10 9.25C8.34315 9.25 7 7.90685 7 6.25C7 4.59315 8.34315 3.25 10 3.25C11.6569 3.25 13 4.59315 13 6.25Z"
														stroke="#272835"
														stroke-width="1.5"
														stroke-linecap="round"
														stroke-linejoin="round"
													/>
												</svg>
											</span>
											Decoration
										</h4>
									</div>
									<div class="card-body">
										<div class="row">
											<div class="col-12 col-lg-8">
												<div class="form-group">
													<label for="Waterproof" class="form-label"
														>Decoration Item</label
													>
													<ng-select
														#decoration
														placeholder="Select decoration"
														[items]="decorationResult.rows"
														bindLabel="name"
														[virtualScroll]="true"
														(scrollToEnd)="initDecorationSelect(true)"
														[searchable]="true"
														bindValue="id"
														(change)="selectDecorations()"
														[multiple]="true"
														(search)="onDecorationSearch($event)"
													>
													</ng-select>
												</div>
											</div>
											<div class="col-12 col-lg-4 align-self-end">
												<div class="form-group">
													<button
														class="btn btn-secondary w-100"
														type="button"
														(click)="addDecoration()"
													>
														<span>
															<svg
																xmlns="http://www.w3.org/2000/svg"
																width="21"
																height="20"
																viewBox="0 0 21 20"
																fill="none"
															>
																<path
																	d="M10.5 4.75V15.25"
																	stroke="#666D80"
																	stroke-width="1.5"
																	stroke-linecap="round"
																	stroke-linejoin="round"
																></path>
																<path
																	d="M5.25 10H15.75"
																	stroke="#272835"
																	stroke-width="1.5"
																	stroke-linecap="round"
																	stroke-linejoin="round"
																></path>
															</svg>
														</span>
														Add Decorations
													</button>
												</div>
											</div>
											<div
												*ngFor="
													let decoration of product.decorations;
													let i = index
												"
											>
												<div class="col-12">
													<div class="form-group remaining">
														<div class="w-100">
															<label for="code" class="form-label">Name</label>
															<input
																type="text"
																class="form-control"
																id="code"
																placeholder=""
																[value]="decoration.name"
																disabled
															/>
														</div>
														<button
															class="btn btn-secondary-icon"
															*ngIf="decoration"
															type="button"
															(click)="removeDecoration(decoration?.id)"
														>
															<span>
																<svg
																	xmlns="http://www.w3.org/2000/svg"
																	width="20"
																	height="20"
																	viewBox="0 0 20 20"
																	fill="none"
																>
																	<path
																		d="M3.25 5.5H4.75H16.75"
																		stroke="#272835"
																		stroke-width="1.5"
																		stroke-linecap="round"
																		stroke-linejoin="round"
																	></path>
																	<path
																		d="M7 5.5V4C7 3.60218 7.15804 3.22064 7.43934 2.93934C7.72064 2.65804 8.10218 2.5 8.5 2.5H11.5C11.8978 2.5 12.2794 2.65804 12.5607 2.93934C12.842 3.22064 13 3.60218 13 4V5.5M15.25 5.5V16C15.25 16.3978 15.092 16.7794 14.8107 17.0607C14.5294 17.342 14.1478 17.5 13.75 17.5H6.25C5.85218 17.5 5.47064 17.342 5.18934 17.0607C4.90804 16.7794 4.75 16.3978 4.75 16V5.5H15.25Z"
																		stroke="#272835"
																		stroke-width="1.5"
																		stroke-linecap="round"
																		stroke-linejoin="round"
																	></path>
																</svg>
															</span>
														</button>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="edit-card card">
									<div class="card-head">
										<h4 class="head-title">
											<span>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="20"
													height="20"
													viewBox="0 0 20 20"
													fill="none"
												>
													<path
														d="M16 16.75V15.25C16 14.4544 15.6839 13.6913 15.1213 13.1287C14.5587 12.5661 13.7956 12.25 13 12.25H7C6.20435 12.25 5.44129 12.5661 4.87868 13.1287C4.31607 13.6913 4 14.4544 4 15.25V16.75"
														fill="white"
													/>
													<path
														d="M10 9.25C11.6569 9.25 13 7.90685 13 6.25C13 4.59315 11.6569 3.25 10 3.25C8.34315 3.25 7 4.59315 7 6.25C7 7.90685 8.34315 9.25 10 9.25Z"
														fill="white"
													/>
													<path
														d="M16 16.75V15.25C16 14.4544 15.6839 13.6913 15.1213 13.1287C14.5587 12.5661 13.7956 12.25 13 12.25H7C6.20435 12.25 5.44129 12.5661 4.87868 13.1287C4.31607 13.6913 4 14.4544 4 15.25V16.75M13 6.25C13 7.90685 11.6569 9.25 10 9.25C8.34315 9.25 7 7.90685 7 6.25C7 4.59315 8.34315 3.25 10 3.25C11.6569 3.25 13 4.59315 13 6.25Z"
														stroke="#272835"
														stroke-width="1.5"
														stroke-linecap="round"
														stroke-linejoin="round"
													/>
												</svg>
											</span>
											Features
										</h4>
									</div>
									<div class="card-body">
										<div class="row">
											<div class="col-12">
												<div class="desc-btns">
													<div class="btns">
														<ngx-editor-menu
															[editor]="editor1"
															[toolbar]="toolbar"
														>
														</ngx-editor-menu>
													</div>
												</div>
											</div>
											<div class="col-12 col-md-8">
												<div class="form-group">
													<label for="generalDesc" class="form-label d-none"
														>Enter General Features</label
													>
													<ngx-editor
														[editor]="editor1"
														[(ngModel)]="inputFeature"
													>
													</ngx-editor>
												</div>
											</div>
											<div class="col-12 col-md-4 align-self-end">
												<div class="form-group">
													<button
														class="btn btn-secondary w-100"
														type="button"
														(click)="addFeatures()"
													>
														<span>
															<svg
																xmlns="http://www.w3.org/2000/svg"
																width="21"
																height="20"
																viewBox="0 0 21 20"
																fill="none"
															>
																<path
																	d="M10.5 4.75V15.25"
																	stroke="#666D80"
																	stroke-width="1.5"
																	stroke-linecap="round"
																	stroke-linejoin="round"
																></path>
																<path
																	d="M5.25 10H15.75"
																	stroke="#272835"
																	stroke-width="1.5"
																	stroke-linecap="round"
																	stroke-linejoin="round"
																></path>
															</svg>
														</span>
														Add Features
													</button>
												</div>
											</div>
										</div>
										<div
											class="row mb-4 mb-md-0 align-items-center"
											*ngFor="let feature of selectFeatures; let i = index"
										>
											<div class="col-12 col-md-9" *ngIf="selectFeatures">
												<div class="form-group">
													<div [innerHTML]="feature" class="form-control"></div>
												</div>
											</div>
											<div class="col-12 col-md-3 justify-self-end">
												<div
													class="d-flex align-items-center justify-content-center gap-3 mb-md-4"
												>
													<button
														class="btn btn-secondary-icon"
														type="button"
														[class.invisible]="i == selectFeatures.length - 1"
														(click)="featureIndexSwap(i, 'down')"
													>
														<span>
															<svg
																xmlns="http://www.w3.org/2000/svg"
																width="20"
																height="20"
																viewBox="0 0 20 20"
																fill="none"
															>
																<path
																	d="M6.40039 8.2002L10.0004 11.8002L13.6004 8.2002"
																	stroke="#272835"
																	stroke-width="1.5"
																	stroke-linecap="round"
																	stroke-linejoin="round"
																></path>
															</svg>
														</span>
													</button>
													<button
														class="btn btn-secondary-icon"
														*ngIf="feature"
														type="button"
														(click)="removeFeatures(i)"
													>
														<span>
															<svg
																xmlns="http://www.w3.org/2000/svg"
																width="20"
																height="20"
																viewBox="0 0 20 20"
																fill="none"
															>
																<path
																	d="M3.25 5.5H4.75H16.75"
																	stroke="#272835"
																	stroke-width="1.5"
																	stroke-linecap="round"
																	stroke-linejoin="round"
																></path>
																<path
																	d="M7 5.5V4C7 3.60218 7.15804 3.22064 7.43934 2.93934C7.72064 2.65804 8.10218 2.5 8.5 2.5H11.5C11.8978 2.5 12.2794 2.65804 12.5607 2.93934C12.842 3.22064 13 3.60218 13 4V5.5M15.25 5.5V16C15.25 16.3978 15.092 16.7794 14.8107 17.0607C14.5294 17.342 14.1478 17.5 13.75 17.5H6.25C5.85218 17.5 5.47064 17.342 5.18934 17.0607C4.90804 16.7794 4.75 16.3978 4.75 16V5.5H15.25Z"
																	stroke="#272835"
																	stroke-width="1.5"
																	stroke-linecap="round"
																	stroke-linejoin="round"
																></path>
															</svg>
														</span>
													</button>
													<button
														class="btn btn-secondary-icon"
														type="button"
														[class.invisible]="i < 1"
														(click)="featureIndexSwap(i, 'up')"
													>
														<span>
															<svg
																xmlns="http://www.w3.org/2000/svg"
																width="20"
																height="20"
																viewBox="0 0 20 20"
																fill="none"
															>
																<path
																	d="M13.6004 11.8002L10.0004 8.2002L6.40039 11.8002"
																	stroke="#272835"
																	stroke-width="1.5"
																	stroke-linecap="round"
																	stroke-linejoin="round"
																></path>
															</svg>
														</span>
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<!-- customer wizard form end here... -->
					</div>
					<div
						class="tab-pane fade"
						[class.show]="activeClassId == 3"
						[class.active]="activeClassId == 3"
					>
						<div class="d-flex align-items-start justify-content-between">
							<h1 class="title">Images</h1>
						</div>
						<div class="edit-users order-cards">
							<div
								class="form-group"
								*ngFor="let colour of getImageOptions(); let index = index"
							>
								<div class="form-Image-label images">
									<input
										type="checkbox"
										class="form-check-input default-image-checkbox"
										*ngIf="!!getImageUrl(colour)"
										(click)="setDefaultImage(colour)"
										[checked]="getImageUrl(colour) === product.imageUrl"
									/>
									<label
										class="uploader-position"
										[class.d-none]="getImageUrl(colour)"
										for="{{ 'ImageUpload' + index }}"
									>
										<div class="Image-upload-icon" *ngIf="!getImageUrl(colour)">
											<span>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="21"
													height="20"
													viewBox="0 0 21 20"
													fill="none"
												>
													<path
														d="M9 10.75C9.32209 11.1806 9.73302 11.5369 10.2049 11.7947C10.6768 12.0526 11.1986 12.2059 11.735 12.2443C12.2713 12.2827 12.8097 12.2053 13.3135 12.0174C13.8173 11.8294 14.2748 11.5353 14.655 11.155L16.905 8.90504C17.5881 8.19778 17.9661 7.25052 17.9575 6.26728C17.949 5.28404 17.5546 4.3435 16.8593 3.64822C16.164 2.95294 15.2235 2.55855 14.2403 2.55001C13.257 2.54146 12.3098 2.91944 11.6025 3.60254L10.3125 4.88503"
														stroke="#666D80"
														stroke-width="1.5"
														stroke-linecap="round"
														stroke-linejoin="round"
													/>
													<path
														d="M11.9997 9.24999C11.6776 8.81939 11.2666 8.4631 10.7947 8.20528C10.3229 7.94746 9.80103 7.79415 9.26467 7.75574C8.72832 7.71732 8.18997 7.79471 7.68615 7.98265C7.18233 8.17059 6.72483 8.46468 6.34466 8.84499L4.09466 11.095C3.41157 11.8022 3.03359 12.7495 3.04213 13.7327C3.05068 14.716 3.44506 15.6565 4.14034 16.3518C4.83562 17.0471 5.77617 17.4415 6.75941 17.45C7.74264 17.4586 8.6899 17.0806 9.39716 16.3975L10.6797 15.115"
														stroke="#666D80"
														stroke-width="1.5"
														stroke-linecap="round"
														stroke-linejoin="round"
													/>
												</svg>
											</span>
										</div>
										<h4 class="Image-upload-title" *ngIf="!getImageUrl(colour)">
											Upload Image
										</h4>
									</label>
									<div
										class="preview-content"
										[class.d-none]="!getImageUrl(colour)"
									>
										<img src="{{ getImageUrl(colour) }}" alt="product-jacket" />
										<div class="dlt-top" *ngIf="getImageUrl(colour)">
											<button
												class="btn btn-secondary-icon"
												type="button"
												(click)="deleteImage(colour, $event)"
											>
												<span>
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="20"
														height="20"
														viewBox="0 0 20 20"
														fill="none"
													>
														<path
															d="M3.25 5.5H4.75H16.75"
															stroke="#666D80"
															stroke-width="1.5"
															stroke-linecap="round"
															stroke-linejoin="round"
														/>
														<path
															d="M7 5.5V4C7 3.60218 7.15804 3.22064 7.43934 2.93934C7.72064 2.65804 8.10218 2.5 8.5 2.5H11.5C11.8978 2.5 12.2794 2.65804 12.5607 2.93934C12.842 3.22064 13 3.60218 13 4V5.5M15.25 5.5V16C15.25 16.3978 15.092 16.7794 14.8107 17.0607C14.5294 17.342 14.1478 17.5 13.75 17.5H6.25C5.85218 17.5 5.47064 17.342 5.18934 17.0607C4.90804 16.7794 4.75 16.3978 4.75 16V5.5H15.25Z"
															stroke="#666D80"
															stroke-width="1.5"
															stroke-linecap="round"
															stroke-linejoin="round"
														/>
													</svg>
												</span>
											</button>
										</div>
									</div>
									<span class="badge badge-front">{{ colour }}</span>
								</div>
								<input
									id="{{ 'ImageUpload' + index }}"
									type="file"
									class="form-control d-none"
									placeholder=""
									(change)="persistS3Image($event, colour)"
								/>
							</div>
						</div>
					</div>
					<div
						class="tab-pane fade"
						[class.show]="activeClassId == 4"
						[class.active]="activeClassId == 4"
					>
						<div class="d-flex align-items-start justify-content-between">
							<h1 class="title">Inventory</h1>
						</div>

						<!-- Add inventory start here... -->
						<div class="add-products">
							<div class="form-group" *ngIf="lastUnleashedProductListResult">
								<label for="selectInventory" class="form-label d-none"></label>
								<ng-select
									#inventory
									[virtualScroll]="true"
									[notFoundText]="noItemsFoundText"
									(scrollToEnd)="initInventorySelect()"
									(change)="selectInventoryItems()"
									(search)="onSearch($event)"
									[loading]="isLoading"
									[clearable]="true"
									[multiple]="true"
								>
									<ng-option
										*ngFor="let data of lastUnleashedProductListResult.Items"
										[value]="data.Guid"
										>{{ data.ProductCode }} -
										{{ data.ProductDescription }}</ng-option
									>
								</ng-select>
							</div>
							<div class="form-group">
								<a
									class="btn btn-primary w-sm-100"
									(click)="addSelectedInventoryItems()"
									role="button"
								>
									<span>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="20"
											height="20"
											viewBox="0 0 20 20"
											fill="none"
										>
											<path
												d="M10 4.75V15.25"
												stroke="white"
												stroke-width="1.5"
												stroke-linecap="round"
												stroke-linejoin="round"
											></path>
											<path
												d="M4.75 10H15.25"
												stroke="white"
												stroke-width="1.5"
												stroke-linecap="round"
												stroke-linejoin="round"
											></path>
										</svg>
									</span>
									Add Inventory Item(s)
								</a>
							</div>
						</div>
						<!-- Add inventory end here... -->
						<div class="edit-users">
							<div
								class="edit-card card"
								*ngFor="
									let variation of product.variations;
									let i = index;
									first as isFirst;
									last as isLast
								"
							>
								<div class="card-head">
									<h4 class="head-title">
										<span>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="20"
												height="20"
												viewBox="0 0 20 20"
												fill="none"
											>
												<path
													d="M16.75 13V6.99999C16.7497 6.73694 16.6803 6.4786 16.5487 6.25086C16.417 6.02312 16.2278 5.83401 16 5.70249L10.75 2.70249C10.522 2.57084 10.2633 2.50153 10 2.50153C9.7367 2.50153 9.47803 2.57084 9.25 2.70249L4 5.70249C3.7722 5.83401 3.58299 6.02312 3.45135 6.25086C3.31971 6.4786 3.25027 6.73694 3.25 6.99999V13C3.25027 13.263 3.31971 13.5214 3.45135 13.7491C3.58299 13.9769 3.7722 14.166 4 14.2975L9.25 17.2975C9.47803 17.4291 9.7367 17.4985 10 17.4985C10.2633 17.4985 10.522 17.4291 10.75 17.2975L16 14.2975C16.2278 14.166 16.417 13.9769 16.5487 13.7491C16.6803 13.5214 16.7497 13.263 16.75 13Z"
													stroke="#272835"
													stroke-width="1.5"
													stroke-linecap="round"
													stroke-linejoin="round"
												/>
												<path
													d="M3.45215 6.22L9.99965 10.0075L16.5471 6.22"
													stroke="#272835"
													stroke-width="1.5"
													stroke-linecap="round"
													stroke-linejoin="round"
												/>
												<path
													d="M10 17.56V10"
													stroke="#272835"
													stroke-width="1.5"
													stroke-linecap="round"
													stroke-linejoin="round"
												/>
											</svg>
										</span>
										Variation {{ i + 1 }}
									</h4>
									<button
										*ngIf="variation"
										class="btn btn-secondary-icon"
										type="button"
										(click)="removeInventoryItem(variation)"
									>
										<span>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="20"
												height="20"
												viewBox="0 0 20 20"
												fill="none"
											>
												<path
													d="M3.25 5.5H4.75H16.75"
													stroke="#272835"
													stroke-width="1.5"
													stroke-linecap="round"
													stroke-linejoin="round"
												/>
												<path
													d="M7 5.5V4C7 3.60218 7.15804 3.22064 7.43934 2.93934C7.72064 2.65804 8.10218 2.5 8.5 2.5H11.5C11.8978 2.5 12.2794 2.65804 12.5607 2.93934C12.842 3.22064 13 3.60218 13 4V5.5M15.25 5.5V16C15.25 16.3978 15.092 16.7794 14.8107 17.0607C14.5294 17.342 14.1478 17.5 13.75 17.5H6.25C5.85218 17.5 5.47064 17.342 5.18934 17.0607C4.90804 16.7794 4.75 16.3978 4.75 16V5.5H15.25Z"
													stroke="#272835"
													stroke-width="1.5"
													stroke-linecap="round"
													stroke-linejoin="round"
												/>
											</svg>
										</span>
									</button>
								</div>
								<div class="card-body">
									<div class="row">
										<div class="col-12">
											<h5 class="form-title">General information</h5>
										</div>
										<div class="col-12">
											<div class="form-group">
												<label for="code" class="form-label">Code</label>
												<input
													type="text"
													class="form-control"
													id="code"
													placeholder="Name...."
													[(ngModel)]="variation.name"
												/>
											</div>
										</div>
										<div class="col-12 col-md-6">
											<div class="form-group">
												<label for="productName" class="form-label"
													>Product name</label
												>
												<input
													type="text"
													class="form-control"
													id="productName"
													placeholder=""
													[(ngModel)]="variation.productCode"
												/>
											</div>
										</div>
										<div class="col-12 col-md-6">
											<div class="form-group">
												<label for="Size" class="form-label">Size </label>
												<input
													type="text"
													class="form-control"
													id="Size"
													placeholder=""
													[(ngModel)]="variation.size"
												/>
											</div>
										</div>
										<div class="col-12">
											<div class="form-group">
												<label for="Color" class="form-label">Color </label>
												<input
													type="text"
													class="form-control"
													id="Color"
													placeholder="Blue, Green, Red, Black....."
													[(ngModel)]="variation.colour"
												/>
											</div>
										</div>
										<div class="col-12">
											<h5 class="form-title">Access Mode</h5>
										</div>
										<div class="col-12">
											<div>
												<div class="form-check form-group">
													<input
														class="form-check-input"
														type="radio"
														name="all_customers_{{ i }}"
														id="all_customers_{{ i }}"
														[value]="0"
														[(ngModel)]="variation.accessMode"
													/>
													<label
														class="form-check-label"
														for="all_customers_{{ i }}"
													>
														To all Customers
													</label>
												</div>
												<div class="form-check form-group">
													<input
														class="form-check-input"
														type="radio"
														name="specific_customers_{{ i }}"
														id="all_customers2_{{ i }}"
														[value]="1"
														[(ngModel)]="variation.accessMode"
													/>
													<label
														class="form-check-label"
														for="all_customers2_{{ i }}"
													>
														To specific customers
													</label>
												</div>
											</div>
										</div>
										<div
											class="row align-items-center gy-4 mt-4"
											*ngIf="variation.accessMode == 1"
										>
											<div class="col-12">
												<h5 class="form-title mb-0">Customer</h5>
											</div>
											<div
												*ngFor="
													let customer of variation.customers;
													let cIdx = index
												"
											>
												<div class="col-12">
													<div
														class="form-group remaining"
														*ngIf="customer.customer"
													>
														<div class="w-100">
															<h5 class="form-title mb-0">
																{{ customer.customer.name }}
															</h5>
														</div>
														<button
															class="btn btn-secondary-icon"
															type="button"
															(click)="removeVariationCustomer(i, cIdx)"
														>
															<span>
																<svg
																	xmlns="http://www.w3.org/2000/svg"
																	width="20"
																	height="20"
																	viewBox="0 0 20 20"
																	fill="none"
																>
																	<path
																		d="M3.25 5.5H4.75H16.75"
																		stroke="#272835"
																		stroke-width="1.5"
																		stroke-linecap="round"
																		stroke-linejoin="round"
																	></path>
																	<path
																		d="M7 5.5V4C7 3.60218 7.15804 3.22064 7.43934 2.93934C7.72064 2.65804 8.10218 2.5 8.5 2.5H11.5C11.8978 2.5 12.2794 2.65804 12.5607 2.93934C12.842 3.22064 13 3.60218 13 4V5.5M15.25 5.5V16C15.25 16.3978 15.092 16.7794 14.8107 17.0607C14.5294 17.342 14.1478 17.5 13.75 17.5H6.25C5.85218 17.5 5.47064 17.342 5.18934 17.0607C4.90804 16.7794 4.75 16.3978 4.75 16V5.5H15.25Z"
																		stroke="#272835"
																		stroke-width="1.5"
																		stroke-linecap="round"
																		stroke-linejoin="round"
																	></path>
																</svg>
															</span>
														</button>
													</div>
												</div>
											</div>
											<div class="col-12 col-lg-8">
												<div class="form-group">
													<label for="select_customers_{{ i }}" class="form-label">Customer </label>
													<ng-select
														#customer
														placeholder="Select Customer"
														[items]="customerSelectOptions"
														bindLabel="text"
														[virtualScroll]="true"
														(scrollToEnd)="handleCustomerListGet(true)"
														[searchable]="true"
														bindValue="id"
														(search)="onCustomerSearch($event)"
														(change)="customerChange(i, $event)"
														[multiple]="true"
														id="select_customers_{{ i }}"
													>
													</ng-select>
												</div>
											</div>
											<div class="col-12 col-lg-4 align-self-end">
												<div class="form-group">
													<button
														class="btn btn-secondary w-100"
														type="button"
														(click)="addVariationCustomer(i)"
													>
														<span>
															<svg
																xmlns="http://www.w3.org/2000/svg"
																width="21"
																height="20"
																viewBox="0 0 21 20"
																fill="none"
															>
																<path
																	d="M10.5 4.75V15.25"
																	stroke="#666D80"
																	stroke-width="1.5"
																	stroke-linecap="round"
																	stroke-linejoin="round"
																></path>
																<path
																	d="M5.25 10H15.75"
																	stroke="#272835"
																	stroke-width="1.5"
																	stroke-linecap="round"
																	stroke-linejoin="round"
																></path>
															</svg>
														</span>
														Assign Customers
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="form-group">
								<!-- TODO: Reinstate Add Collection functionality
								<label for="ImageUpload2" class="form-Image-label images">
									<div class="uploader-position">
										<div class="Image-upload-icon">
											<span>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="21"
													height="20"
													viewBox="0 0 21 20"
													fill="none"
												>
													<path
														d="M10.5 4.75V15.25"
														stroke="#666D80"
														stroke-width="1.5"
														stroke-linecap="round"
														stroke-linejoin="round"
													/>
													<path
														d="M5.25 10H15.75"
														stroke="#666D80"
														stroke-width="1.5"
														stroke-linecap="round"
														stroke-linejoin="round"
													/>
												</svg>
											</span>
										</div>
										<h4 class="Image-upload-title">Add Collection</h4>
									</div>
									<div class="preview-content d-none">
										<img
											src="../../../../assets/images/logo.svg"
											alt="customer"
										/>
										<div class="dlt-top">
											<button class="btn btn-secondary-icon" type="button">
												<span>
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="20"
														height="20"
														viewBox="0 0 20 20"
														fill="none"
													>
														<path
															d="M3.25 5.5H4.75H16.75"
															stroke="#666D80"
															stroke-width="1.5"
															stroke-linecap="round"
															stroke-linejoin="round"
														></path>
														<path
															d="M7 5.5V4C7 3.60218 7.15804 3.22064 7.43934 2.93934C7.72064 2.65804 8.10218 2.5 8.5 2.5H11.5C11.8978 2.5 12.2794 2.65804 12.5607 2.93934C12.842 3.22064 13 3.60218 13 4V5.5M15.25 5.5V16C15.25 16.3978 15.092 16.7794 14.8107 17.0607C14.5294 17.342 14.1478 17.5 13.75 17.5H6.25C5.85218 17.5 5.47064 17.342 5.18934 17.0607C4.90804 16.7794 4.75 16.3978 4.75 16V5.5H15.25Z"
															stroke="#666D80"
															stroke-width="1.5"
															stroke-linecap="round"
															stroke-linejoin="round"
														></path>
													</svg>
												</span>
											</button>
										</div>
									</div>
								</label>
								<input type="file" capture="user" class="form-control d-none" id="ImageUpload2" placeholder="" />
								-->
							</div>
						</div>
					</div>
					<div
						class="tab-pane fade"
						[class.show]="activeClassId == 5"
						[class.active]="activeClassId == 5"
					>
						<div class="d-flex align-items-start justify-content-between">
							<h1 class="title" *ngIf="customerProducts.length !== 0">
								Customer Products
							</h1>
							<h1 class="title" *ngIf="customerProducts.length == 0">
								No Customer Products found
							</h1>
						</div>
						<div class="edit-users">
							<div
								class="edit-card card"
								*ngFor="let item of customerProducts; let i = index"
							>
								<div class="card-head">
									<h4 class="head-title">
										<span>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="20"
												height="20"
												viewBox="0 0 20 20"
												fill="none"
											>
												<path
													d="M16 16.75V15.25C16 14.4544 15.6839 13.6913 15.1213 13.1287C14.5587 12.5661 13.7956 12.25 13 12.25H7C6.20435 12.25 5.44129 12.5661 4.87868 13.1287C4.31607 13.6913 4 14.4544 4 15.25V16.75M13 6.25C13 7.90685 11.6569 9.25 10 9.25C8.34315 9.25 7 7.90685 7 6.25C7 4.59315 8.34315 3.25 10 3.25C11.6569 3.25 13 4.59315 13 6.25Z"
													stroke="#272835"
													stroke-width="1.5"
													stroke-linecap="round"
													stroke-linejoin="round"
												/>
											</svg>
										</span>
										Customer {{ i + 1 }}
									</h4>
									<button
										*ngIf="item.id"
										class="btn btn-secondary-icon"
										type="button"
										(click)="removeProductCustomer(item.id, i)"
									>
										<span>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="20"
												height="20"
												viewBox="0 0 20 20"
												fill="none"
											>
												<path
													d="M3.25 5.5H4.75H16.75"
													stroke="#272835"
													stroke-width="1.5"
													stroke-linecap="round"
													stroke-linejoin="round"
												/>
												<path
													d="M7 5.5V4C7 3.60218 7.15804 3.22064 7.43934 2.93934C7.72064 2.65804 8.10218 2.5 8.5 2.5H11.5C11.8978 2.5 12.2794 2.65804 12.5607 2.93934C12.842 3.22064 13 3.60218 13 4V5.5M15.25 5.5V16C15.25 16.3978 15.092 16.7794 14.8107 17.0607C14.5294 17.342 14.1478 17.5 13.75 17.5H6.25C5.85218 17.5 5.47064 17.342 5.18934 17.0607C4.90804 16.7794 4.75 16.3978 4.75 16V5.5H15.25Z"
													stroke="#272835"
													stroke-width="1.5"
													stroke-linecap="round"
													stroke-linejoin="round"
												/>
											</svg>
										</span>
									</button>
								</div>
								<div class="card-body">
									<div class="row">
										<div class="col-12">
											<h5 class="form-title">General information</h5>
										</div>
										<div class="col-12">
											<div class="form-group">
												<label for="code" class="form-label"
													>Customer Name</label
												>
												<input
													type="text"
													class="form-control"
													id="code"
													placeholder="Name...."
													value="{{ item.customer && item.customer.name }}"
													disabled
												/>
											</div>
										</div>
										<div class="col-12 col-md-6">
											<div class="form-group">
												<label for="productName" class="form-label"
													>Product name</label
												>
												<input
													type="text"
													class="form-control"
													id="productName"
													placeholder=""
													value="{{ item.product.name }}"
													disabled
												/>
											</div>
										</div>
										<div class="col-12 col-md-6">
											<div class="form-group">
												<label for="Code" class="form-label">Code </label>
												<input
													type="text"
													class="form-control"
													id="Code"
													placeholder=""
													value="{{ item.product.code }}"
													disabled
												/>
											</div>
										</div>
										<div class="col-12 col-md-6">
											<div class="form-group">
												<label for="price" class="form-label"
													>Customer Price</label
												>
												<input
													type="number"
													class="form-control"
													id="price"
													placeholder="Customer Price"
													[(ngModel)]="item.customPrice"
												/>
												<div class="form-text">
													Custom Price - Total Price (Client + Employee
													Contribution)
												</div>
											</div>
										</div>
										<div class="col-12 col-md-6">
											<div class="form-group">
												<label for="Contribution" class="form-label"
													>Client Contribution</label
												>
												<input
													type="number"
													class="form-control"
													id="Contribution"
													placeholder="0"
													[(ngModel)]="item.subsidyAmount"
												/>
												<div class="form-text">
													The Amount the client will Contribute to the total
													price
												</div>
											</div>
										</div>
										<div class="col-12 col-md-6">
											<button
												class="btn btn-sm btn-primary"
												(click)="updateCustomerProduct(item.id, item)"
											>
												Update
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<!-- customer-wizard footer start here... -->
					<div class="wizard-footer">
						<button
							class="btn btn-secondary"
							type="button"
							[routerLink]="['/manage/products']"
						>
							Cancel
						</button>
						<div
							class="d-flex flex-column flex-md-row align-items-center justify-content-end gap-4 gap-md-2"
						>
							<button
								(click)="previewProduct()"
								class="btn btn-secondary"
								type="button"
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									version="1.1"
									xmlns:xlink="http://www.w3.org/1999/xlink"
									width="20"
									height="20"
									x="0"
									y="0"
									viewBox="0 0 24 24"
									style="enable-background: new 0 0 512 512"
									xml:space="preserve"
									class=""
								>
									<g>
										<path
											d="M.5 7a.5.5 0 0 1-.5-.5v-2C0 3.122 1.121 2 2.5 2h2a.5.5 0 0 1 0 1h-2C1.673 3 1 3.673 1 4.5v2a.5.5 0 0 1-.5.5zM23.5 7a.5.5 0 0 1-.5-.5v-2c0-.827-.673-1.5-1.5-1.5h-2a.5.5 0 0 1 0-1h2C22.879 2 24 3.122 24 4.5v2a.5.5 0 0 1-.5.5zM4.5 22h-2A2.503 2.503 0 0 1 0 19.5v-2a.5.5 0 0 1 1 0v2c0 .827.673 1.5 1.5 1.5h2a.5.5 0 0 1 0 1zM21.5 22h-2a.5.5 0 0 1 0-1h2c.827 0 1.5-.673 1.5-1.5v-2a.5.5 0 0 1 1 0v2c0 1.378-1.121 2.5-2.5 2.5zM12 18c-3.853 0-7.136-2.418-7.985-5.881a.48.48 0 0 1 0-.238C4.864 8.418 8.147 6 12 6s7.136 2.418 7.985 5.881a.48.48 0 0 1 0 .238C19.136 15.582 15.853 18 12 18zm-6.984-6c.786 2.95 3.639 5 6.984 5s6.198-2.05 6.984-5C18.198 9.05 15.346 7 12 7s-6.198 2.05-6.984 5z"
											fill="#666D80"
											opacity="1"
											data-original="#666D80"
										></path>
										<path
											d="M12 15c-1.654 0-3-1.346-3-3s1.346-3 3-3 3 1.346 3 3-1.346 3-3 3zm0-5c-1.103 0-2 .897-2 2s.897 2 2 2 2-.897 2-2-.897-2-2-2z"
											fill="#666D80"
											opacity="1"
											data-original="#666D80"
										></path>
									</g>
								</svg>
								Preview
							</button>
							<button
								class="btn btn-secondary"
								type="button"
								(click)="saveProduct(true)"
							>
								<span
									><svg
										xmlns="http://www.w3.org/2000/svg"
										width="20"
										height="20"
										viewBox="0 0 20 20"
										fill="none"
									>
										<path
											d="M13.75 16.75V10.75H6.25V16.75M6.25 3.25V7H12.25M15.25 16.75H4.75C4.35218 16.75 3.97064 16.592 3.68934 16.3107C3.40804 16.0294 3.25 15.6478 3.25 15.25V4.75C3.25 4.35218 3.40804 3.97064 3.68934 3.68934C3.97064 3.40804 4.35218 3.25 4.75 3.25H13L16.75 7V15.25C16.75 15.6478 16.592 16.0294 16.3107 16.3107C16.0294 16.592 15.6478 16.75 15.25 16.75Z"
											stroke="#666D80"
											stroke-width="1.5"
											stroke-linecap="round"
											stroke-linejoin="round"
										/></svg
								></span>
								Save as draft
							</button>
							<button
								class="btn btn-primary"
								type="button"
								(click)="saveProduct()"
							>
								{{ activeClassId >= 5 ? "Save" : "Continue" }}
								<span>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="20"
										height="20"
										viewBox="0 0 20 20"
										fill="none"
									>
										<path
											d="M8.2002 13.6004L11.8002 10.0004L8.2002 6.40039"
											stroke="white"
											stroke-width="1.5"
											stroke-linecap="round"
											stroke-linejoin="round"
										/>
									</svg>
								</span>
							</button>
						</div>
					</div>
					<!-- customer-wizard footer end here... -->
				</div>
			</div>
		</div>
	</div>
</div>
