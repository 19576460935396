import { EventEmitter, Injectable, Output } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';
import { IQueryFilter, QueryResult } from '../model/query.filter.class';
import { FavoriteApi } from '../api/favorites.api';
import { GlobalApi, IFavoriteList } from '../api/global.api';
import { has } from 'lodash';
import { sortObjectsByField } from '../util/sortObjects';
import { IFavorite } from '../model/favorites.model';
import { HasId } from '../model/generics';
import { FavoritesProductApi } from '../api/favoritesProduct.api';
import { Observable } from 'rxjs';
import { apiCallWrapper } from '../api/api.util';

@Injectable()
export class FavoriteService {
  public favoriteLists: IFavoriteList[] = [];
  @Output() public favoritesUpdates = new EventEmitter<IFavoriteList[]>();
  public hasLoadedLists = false;

  constructor(
    private notifications: NotificationsService,
    private favoriteAPI: FavoriteApi,
    private globals: GlobalApi,
    private favoritesProductApi: FavoritesProductApi,
  ) {
  }


  public async createFavoriteList(ListName: string, IsShared: boolean) {

    const favorite = {
      name: ListName,
      isShared: IsShared,
      customerId: this.globals.customer?.id ? this.globals.customer?.id : undefined
    };

    const newFavorites = await this.favoriteAPI.create(favorite).toPromise() as IFavorite;

    if (newFavorites && newFavorites.id) {
      this.favoriteLists.push({
        Name: newFavorites.name,
        IsShared: newFavorites.isShared,
        Guid: newFavorites.id.toString(),
        UserID: newFavorites.userId.toString()
      });
    }

    this.favoriteLists = sortObjectsByField(this.favoriteLists, 'Name', 'ASC');
    this.favoritesUpdates.emit(this.favoriteLists);
    return newFavorites;
  }

  public list(query: IQueryFilter): Observable<QueryResult<IFavorite & HasId>> {
    return this.favoriteAPI.list(query)
  }

  public async removeFavoriteList(ListGuid: string) {
    const result = await this.favoriteAPI.delete(Number(ListGuid)).toPromise();

    if (result && result.success) {
      const index = this.favoriteLists.findIndex(fl => fl.Guid === ListGuid);
      if (index !== -1) {
        this.favoriteLists.splice(index, 1);
      }
    }

    return result;
  }

  public async getUserFavoriteLists() {
    this.favoriteLists = []
    if (!this.globals.customer)
      return;

    const allFavorites = await this.favoriteAPI.list(new IQueryFilter({
      limit: 500,
      filter: {
        customerId: this.globals.customer.id
      }
    })).toPromise();

    const result = this.parseQueryResult(allFavorites);

    if (result) {
      this.favoriteLists = result.Items;
      this.favoritesUpdates.emit(result.Items);
    }

    this.hasLoadedLists = true;

    return result;
  }

  private parseQueryResult(queryResult: QueryResult<any> | undefined) {
    if (!has(queryResult, 'rows')) {
      console.log(JSON.stringify(queryResult));
    }

    if (queryResult) {

      const result: { Items: IFavoriteList[], Count: number } = {
        Items: queryResult.rows.map((favorite: any) => {
          var resultProducts: { productGuid: string }[] | undefined = [];

          // if (!has(favorite, 'products')) {
          //   resultProducts = favorite.products?.map((product: any) => ({
          //     productGuid: product.productId.toString()
          //   }));
          // }

          return {
            Name: favorite.name,
            IsShared: favorite.isShared,
            Guid: favorite.id.toString(),
            UserID: favorite.userId.toString(),
            Products: resultProducts,
            ProductCount: favorite.productCount || 0
          }
        }),
        Count: 0
      };

      result.Count = result.Items.length;

      return result;
    }
  }

  public async renameFavoriteList(ListGuid: string, ListName: string, isShared: boolean) {
    const result = await this.favoriteAPI.update(Number(ListGuid), { name: ListName, isShared: isShared }).toPromise();

    if (result && result.id) {
      const index = this.favoriteLists.findIndex(fl => fl.Guid == result.id);

      if (index !== -1) {
        this.favoriteLists[index] = {
          Name: result.name,
          IsShared: result.isShared,
          Guid: result.id,
          UserID: result.userId.toString()
        };
        this.favoriteLists = sortObjectsByField(this.favoriteLists, 'Name', 'ASC');
        this.favoritesUpdates.emit(this.favoriteLists);
      }

      return {
        Name: result.name,
        IsShared: result.isShared,
        Guid: result.id,
        UserID: result.userId.toString()
      };
    }
  }

  public addProductToFavoriteList(ProductGuid: string, ListGuid: string) {
    return apiCallWrapper(
      this.favoritesProductApi.create(
        {
          favoritesListId: +ListGuid,
          productId: Number(ProductGuid)
        }
      ),
      {
        notificationsService: this.notifications,
        action: "Product Added to Favourites"
      }
    )
  }

  public async removeProductFromFavoriteList(ProductGuid: string, ListGuid: string) {
    const targetModels = await this.favoritesProductApi.list(new IQueryFilter({
      filter: {
        productId: Number(ProductGuid),
        favoritesListId: Number(ListGuid)
      }
    })).toPromise();

    if (targetModels && targetModels.rows.length) {
      const targetModel = targetModels.rows[0];

      await this.favoritesProductApi.delete(targetModel.id).toPromise();
    }
  }
}
