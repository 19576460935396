import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UnleashedService } from '../../services/unleashed.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationsService } from 'angular2-notifications';
import { CartService } from '../../services/cart.service';
import { GlobalApi } from '../../api/global.api';

@Component({
  selector: 'app-order-shipment',
  templateUrl: './order-shipment.component.html',
  styleUrls: []
})
export class OrderShipmentComponent implements OnInit {
  public orderNumber: string;
  public ShipmentDetails;
  showTrackingEdit: boolean = false;
  trackingDetailsTemp = [];
  trackingLinkTemp = [];
  myTrackingDetails = [];
  myTrackingLinks = [];
  public orderId: number;
  constructor(
    public route: ActivatedRoute,
    private router: Router,
    private unleashedApiService: UnleashedService,
    private modalService: NgbModal,
    private notifications: NotificationsService,
    private cartService: CartService,
    public globals: GlobalApi
  ) { }

  ngOnInit() {
    const order = this.route.snapshot.queryParamMap.get('orderId');
    if (order) {
      this.orderId = +order;
    }
    this.route.params.subscribe(params => {
      if (params.hasOwnProperty('id')) {
        this.orderNumber = params['id'];
      } else {
        this.router.navigate(['/manage/orders']);
      }
      this.unleashedApiService.getSalesShipmentsByOrderNumber(this.orderNumber).subscribe(salesShipments => {
        if (salesShipments.Items.length > 0) {
          this.ShipmentDetails = salesShipments.Items;
          this.ShipmentDetails.map(ShipmentDetail => {
            this.trackingDetailsTemp[`TrackingDetail_${ShipmentDetail.Guid}`] = ShipmentDetail.MyOrderShipmentDetails.trackingDetail;
            this.trackingLinkTemp[`TrackingDetailURL_${ShipmentDetail.Guid}`] = ShipmentDetail.MyOrderShipmentDetails.trackingLink;
            this.myTrackingDetails[`TrackingDetail_${ShipmentDetail.Guid}`] = ShipmentDetail.MyOrderShipmentDetails.trackingDetail;
            this.myTrackingLinks[`TrackingDetailURL_${ShipmentDetail.Guid}`] = ShipmentDetail.MyOrderShipmentDetails.trackingLink;
          });
        }
      });
    });
  }

  showTrackingEditModal() {
    this.showTrackingEdit = !this.showTrackingEdit;
  }

  saveTrackingInformation(guid: string) {
    console.log(this.ShipmentDetails, "pop-------------------------")
    if (this.trackingDetailsTemp[`TrackingDetail_${guid}`] !== this.myTrackingDetails[`TrackingDetail_${guid}`]) {
      this.myTrackingDetails[`TrackingDetail_${guid}`] = this.trackingDetailsTemp[`TrackingDetail_${guid}`];
    }
    if (this.myTrackingLinks[`TrackingDetailURL_${guid}`] != this.trackingLinkTemp[`TrackingDetailURL_${guid}`]) {
      this.myTrackingLinks[`TrackingDetailURL_${guid}`] = this.trackingLinkTemp[`TrackingDetailURL_${guid}`];
    }
    if (!this.myTrackingDetails[`TrackingDetail_${guid}`].length) {
      this.notifications.error('Error Updating Order', 'Tracking id cannot be blank');
      return;
    }

    this.notifications.warn('Processing', 'Updating Order.');

    this.cartService.updateOrderShippingDetails(guid,
      this.myTrackingDetails[`TrackingDetail_${guid}`], this.myTrackingLinks[`TrackingDetailURL_${guid}`]).subscribe((resp) => {
        this.showTrackingEdit = false;
        this.notifications.success('Order Updated', 'Tracking details updated.');
      });
  }

  resetTrackingInformation(guid: string) {
    this.trackingDetailsTemp[`TrackingDetail_${guid}`] = this.myTrackingDetails[`TrackingDetail_${guid}`];
    this.trackingLinkTemp[`TrackingDetailURL_${guid}`] = this.myTrackingLinks[`TrackingDetailURL_${guid}`];
  }

  open(content) {
    this.modalService.open(content, {
      windowClass: 'bulk-order',
      modalDialogClass: 'modal-dialog-centered'
    });
  }
}
