<div class="row">
	<div class="col-12">
		<h5 class="form-title mb-0">Payment method</h5>
		<ul class="list-view mb-3">
			<li>
				<p class="form-text">All transactions are secure and encrypted.</p>
			</li>
		</ul>
	</div>
	<div>
		<div class="auths checkout-auth mb-4" *ngIf="!isLoading && !freeCCTransaction && (showPaymentOptions$ | async)">
			<div class="form-group form-check" *ngIf="canUseInvoicePayment$ | async">
				<label class="form-check-label active" for="Authentication1">
					<p>
						<span>
							<input class="form-check-input" type="radio" name="Authentications" id="Authentication1" value="Invoice"
								[checked]="paymentType == 'Invoice'" (change)="paymentType = 'Invoice'" />
						</span>
						Charge to our account
					</p>

					<span class="balance ms-md-auto">
						Balance: {{totalRemainingGarments}} garment{{totalRemainingGarments > 1 ? 's': ''}}
					</span>
				</label>
			</div>
			<div class="form-group form-check" *ngIf="canUseCreditCardPayment$ | async">
				<label class="form-check-label" for="Authentication2">
					<p>
						<span>
							<input class="form-check-input" type="radio" name="Authentications" id="Authentication2" value="CC"
								[checked]="paymentType == 'CC'" (change)="paymentType = 'CC'" />
						</span>
						Pay via Credit Card
						<span *ngIf="!isLoading && paymentType === 'CC' && !freeCCTransaction">(${{ ccTransaction["EPS_AMOUNT"]
							}})</span>
					</p>
				</label>
			</div>
			<form ngNoForm [action]="ccTransaction['Endpoint']"
				*ngIf="!isLoading && paymentType === 'CC' && !freeCCTransaction" #CCForm method="post">
				<div class="checkout-payment-by-card card main-shadow" *ngIf="paymentType === 'CC'">
					<div class="card-body">
						<div class="row">
							<input type="hidden" name="EPS_MERCHANT" [value]="ccTransaction['EPS_MERCHANT']" />
							<input type="hidden" name="EPS_TXNTYPE" [value]="ccTransaction['EPS_TXNTYPE']" />
							<input type="hidden" name="EPS_REFERENCEID" [value]="ccTransaction['EPS_REFERENCEID']" />
							<input type="hidden" name="EPS_AMOUNT" [value]="ccTransaction['EPS_AMOUNT']" />
							<input type="hidden" name="EPS_TIMESTAMP" [value]="ccTransaction['EPS_TIMESTAMP']" />
							<input type="hidden" name="EPS_FINGERPRINT" [value]="ccTransaction['EPS_FINGERPRINT']" />
							<input type="hidden" name="EPS_RESULTURL" [value]="getCallbackUrl()" />
							<input type="hidden" name="EPS_CALLBACKURL" [value]="ccTransaction['EPS_CALLBACKURL']" />
							<input type="hidden" name="EPS_REDIRECT" [value]="true" />

							<div class="col-12 col-md-6">
								<div class="form-group">
									<label for="cardNumber" class="form-label">Card Number
									</label>
									<input type="number" class="form-control" id="cardNumber" placeholder="*******************"
										name="EPS_CARDNUMBER" [(ngModel)]="ccTransaction['EPS_CARDNUMBER']" />
								</div>
							</div>
							<div class="col-12 col-md-6">
								<div class="form-group">
									<input id="ccExpMonth" type="hidden" name="EPS_EXPIRYMONTH"
										[(ngModel)]="ccTransaction['EPS_EXPIRYMONTH']" [ngModelOptions]="{ standalone: true }" />

									<label for="expiryMonth" class="form-label">Expiry Month</label>
									<select #month class="form-select" aria-label="Default select example" id="expiryMonth"
										(change)="ccTransaction['EPS_EXPIRYMONTH'] = month.value">
										<option selected="">Select Month</option>
										<option value="01">Janurary</option>
										<option value="02">Feburary</option>
										<option value="03">March</option>
										<option value="04">April</option>
										<option value="05">May</option>
										<option value="06">June</option>
										<option value="07">July</option>
										<option value="08">August</option>
										<option value="09">September</option>
										<option value="10">October</option>
										<option value="11">November</option>
										<option value="12">December</option>
									</select>
								</div>
							</div>
							<div class="col-12 col-md-6">
								<div class="form-group">
									<label for="expiryYear" class="form-label">Expiry Year</label>
									<input type="text" class="form-control" id="expiryYear" name="EPS_EXPIRYYEAR"
										[(ngModel)]="ccTransaction['EPS_EXPIRYYEAR']" placeholder="2020"
										[ngModelOptions]="{ standalone: true }" />
								</div>
							</div>
							<div class="col-12 col-md-6">
								<div class="form-group">
									<label for="CCVnumber" class="form-label">CCV </label>
									<input type="number" class="form-control" id="cardNumber" name="EPS_CCV"
										[(ngModel)]="ccTransaction['EPS_CCV']" placeholder="xxxx" [ngModelOptions]="{ standalone: true }" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
		<div *ngIf="freeCCTransaction">
			<div class="remaining-garments no-payment">
				<span class="icon">
					<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
						<path fill-rule="evenodd" clip-rule="evenodd"
							d="M6.16023 4.24023C5.09985 4.24023 4.24023 5.09985 4.24023 6.16023V13.8402C4.24023 14.9006 5.09985 15.7602 6.16023 15.7602H13.8402C14.9006 15.7602 15.7602 14.9006 15.7602 13.8402V6.16023C15.7602 5.09985 14.9006 4.24023 13.8402 4.24023H6.16023ZM11.3073 13.0114L11.2 13.4501C10.878 13.5772 10.6209 13.6739 10.4294 13.7404C10.2377 13.8071 10.015 13.8402 9.76118 13.8402C9.37146 13.8402 9.06832 13.7448 8.85212 13.5551C8.63592 13.3647 8.52779 13.1235 8.52779 12.8308C8.52779 12.7175 8.53564 12.601 8.55195 12.4823C8.5684 12.3634 8.59456 12.2295 8.63035 12.0799L9.03267 10.6559C9.06846 10.5195 9.09888 10.3903 9.12325 10.2682C9.14796 10.1467 9.15987 10.035 9.15987 9.93447C9.15987 9.75261 9.12222 9.62541 9.04726 9.5539C8.9723 9.48259 8.82927 9.44638 8.61693 9.44638C8.51292 9.44638 8.40603 9.46304 8.29707 9.49525C8.18763 9.5276 8.09408 9.55885 8.01555 9.5879L8.12313 9.14889C8.38668 9.04159 8.63861 8.9497 8.87959 8.87343C9.12057 8.79696 9.34826 8.75869 9.56363 8.75869C9.95067 8.75869 10.2493 8.85223 10.4591 9.03931C10.6688 9.22653 10.7736 9.46923 10.7736 9.76831C10.7736 9.83019 10.7667 9.93922 10.7519 10.095C10.7375 10.2512 10.7106 10.3943 10.6713 10.5245L10.2707 11.9428C10.2379 12.0567 10.2084 12.1869 10.1827 12.3334C10.1562 12.479 10.1435 12.5903 10.1435 12.6649C10.1435 12.8532 10.1855 12.9818 10.2697 13.0501C10.3545 13.1185 10.5006 13.1525 10.708 13.1525C10.8054 13.1525 10.9162 13.1352 11.0394 13.1013C11.1622 13.0673 11.2517 13.0375 11.3073 13.0114ZM11.4089 7.05711C11.4089 7.30421 11.3157 7.51525 11.1286 7.68877C10.9419 7.86292 10.717 7.95006 10.4538 7.95006C10.1899 7.95006 9.96437 7.86292 9.77556 7.68877C9.5871 7.51518 9.49267 7.30421 9.49267 7.05711C9.49267 6.81048 9.5871 6.5991 9.77556 6.42338C9.96403 6.24793 10.1899 6.16023 10.4538 6.16023C10.7169 6.16023 10.9419 6.24813 11.1286 6.42338C11.3159 6.5991 11.4089 6.81055 11.4089 7.05711Z"
							fill="white" />
					</svg>
				</span>
				<div>
					<p>No payment required</p>
				</div>
			</div>
		</div>
	</div>
</div>