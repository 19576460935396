import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { GlobalApi } from '../../api/global.api';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-deny-invite',
  templateUrl: './deny-invite.component.html',
  styleUrls: []
})
export class DenyInviteComponent {
  pendingApproval: boolean = true;
  title: string;
  message: string;
  isAccepted: boolean = false;
  isLoading: boolean = false;
  constructor(
    public route: ActivatedRoute,
    public notifications: NotificationsService,
    public globals: GlobalApi,
    private readonly userService: AuthService
  ) {
  }

  ngOnInit() {
    console.log('DenyInviteComponent: Denying Invite');

    this.globals.withSession(this.denyInvite.bind(this));
  }

  denyInvite() {
    this.route.queryParams.subscribe(params => {
      if (!params.hasOwnProperty('email') || !params.hasOwnProperty('secret')) {
        this.pendingApproval = false;
        this.title = 'Invalid Invite Details.';
        this.message = 'Please refer to your invite email or contact us for assistance.';
        return;
      }

      this.notifications.warn('Denying Invite', 'Please wait while we process your invite');
      this.isLoading = true;

      this.userService.denyGuestInvite(params['email'], params['secret'])
        .subscribe(
          () => {
            this.isLoading = false;
            this.isAccepted = true;
            this.title = 'Invite Denied';
            this.message = 'Your invite was cancelled.';

            this.notifications.success(this.title, this.message);
          },
          (err) => {
            this.isLoading = false;
            this.title = 'Error';
            let handledError = false;

            if (Array.isArray(err)) {
              let firstError = err[0];
              if (firstError.code === 'INVALIDINVITEDETAILS') {
                this.message = 'Your invite details were not correct. Please refer to your invite or contact us for assistance.';
                handledError = true;
              }
            }

            if (!handledError) {
              this.message = 'Error Denying Invite. Please contact support.';
            }

            this.notifications.error(this.title, this.message);

            return;
          }
        );
    });
  }

}
