import { Component, OnInit } from '@angular/core';
import { SizeChartEntry } from '../../model/sizeChart.model';
import { SizeChartService } from '../../services/sizeChart.service';
import { IQueryFilter } from '../../model/query.filter.class';
import { Subject, Subscription, debounceTime, distinctUntilChanged } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'app-sizechart-list',
  templateUrl: './sizechart-list.component.html',
  styleUrls: []
})
export class SizechartListComponent implements OnInit {
  public sizeCharts: SizeChartEntry[] = [];
  public totalCountActive: number = 0;
  public currentPageActive: number = 1;
  private searchTerms: Subject<string> = new Subject<string>();
  private searchSubscription: Subscription;
  query: IQueryFilter = new IQueryFilter({
    limit: 10,
    sortBy: 'name'
  });

  sortOptions = [{
    id: 1,
    text: "Size Chart Name",
    field: "name"
  }];

  constructor(
    private sizeChartService: SizeChartService,
    private modalService: NgbModal,
    private notifications: NotificationsService
  ) { }

  ngOnInit(): void {
    this.loadSizeChart();
    this.search();
  }

  loadSizeChart() {
    this.query.skip = (this.currentPageActive * this.query.limit) - this.query.limit;

    this.sizeChartService.list(this.query)
      .subscribe((res) => {
        this.sizeCharts = res.rows;
        this.totalCountActive = res.count;
      });
  }

  pageChangedActive(page: number) {
    this.currentPageActive = page;
    this.loadSizeChart();
  }

  /**
  * @description Ensures the page number is in sync across multiple pagination components
  *
  * @param {number} pageSize Broadcast pageSize value
  */
  pageSizeChanged(pageSize: number): void {
    this.query.limit = pageSize;
    this.currentPageActive = 1;
    this.loadSizeChart();
  }

  updateSearchTerm(searchTerm: string): void {
    this.searchTerms.next(searchTerm);
  }

  updateSortField(sort: string) {
    this.query.sortBy = sort;
    this.loadSizeChart();
  }

  /**
 * @description UI helper method for retrieving the text of the selected sort option
 * @returns {string | undefined}
 */
  getSortText = (): string | undefined => {
    if (!this.query.sortBy)
      return undefined;

    const sortOption = this.sortOptions.find(option => option.field === this.query.sortBy);

    if (sortOption)
      return sortOption.text;
    return undefined;
  };

  deleteChart(id: number | undefined) {
    if (id) {
      this.sizeChartService.delete(id)
        .subscribe(
          res => {
            this.loadSizeChart();
          },
          err => {
            console.error('Error while removing size chart', err);
          }
        );
    }
  }

  search() {
    this.searchSubscription = this.searchTerms.pipe(
      debounceTime(500),
      distinctUntilChanged(),
    ).subscribe(searchTerm => {
      if (searchTerm && searchTerm.length)
        this.query.filter.name = { $like: '%' + searchTerm + '%' };
      else
        delete this.query.filter.name;

      this.loadSizeChart();
    });
  }

  public openRemoveModal(id, content) {
    this.modalService.open(content, {
      size: 'sm', centered: true, backdrop: true, windowClass: 'deleteModal'
    }).result.then(() => {
      this.notifications.warn('Processing', 'Deleting the sizechart.');
      this.deleteChart(id);
    }, () => { });
  }
}
