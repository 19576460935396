<div class="card-img" *ngIf="data.product">
	<img src="{{
			fetchImage() ||
				'../../../../assets/images/collection-product.png'
		}}" alt="collection-product" />
	<div class="badges d-none">
		<span class="badge bg-aqua-25">New</span>
	</div>
</div>
<div class="card-content" *ngIf="data.product">
	<h4 class="title text-truncate">
		{{ data.product.name }}
	</h4>
	<p class="price" *ngIf="data.product.price || data.product.basePrice">
		$ {{ data.product.price || data.product.basePrice }}
	</p>
	<div class="quick-select">
		<div class="row">
			<div class="col-12">
				<div class="form-group" *ngIf="cartItem.getUniqueColours()">
					<select #color class="form-select" aria-label="Default select example" id="Colour"
						(change)="cartItem.selectedColour = color.value">
						<option [selected]="!cartItem.selectedColour">Select your colour</option>
						<option *ngFor="let colour of cartItem.getUniqueColours()" [selected]="cartItem.selectedColour == colour"
							[value]="colour">
							{{ colour || "Default" }}
						</option>
					</select>
				</div>
				<div class="no-more-content" *ngIf="!cartItem.getUniqueColours()">
					<h4 class="no-more-title">
						No Colors
					</h4>
				</div>
			</div>
			<div class="col-12">
				<div class="form-group" *ngIf="usePackSizes()">
					<select #packSize class="form-select" (change)="
							selectPackSize(packSize.value); packSize.value = 'Select Pack'
						" id="Colour">
						<option [selected]="!cartItem.selectedPack">Pack Size</option>
						<option *ngFor="let unit of product.packSizes" [value]="unit.name" [selected]="
								cartItem.selectedPack && cartItem.selectedPack.name == unit.name
							">
							{{ unit.name | titlecase }} :
							{{ unit.itemCount }}
						</option>
					</select>
				</div>
				<div class="no-more-content" *ngIf="!usePackSizes()">
					<h4 class="no-more-title">
						No Pack Sizes
					</h4>
				</div>
			</div>
			<div class="col-7 col-lg-6">
				<div class="form-group" *ngIf="cartItem.getUniqueSizes()">
					<select #size class="form-select main-shadow" aria-label="Default select example" id="Size"
						(change)="cartItem.selectedSize = size.value.toString()">
						<option [selected]="!cartItem.selectedSize">Select Size</option>
						<option *ngFor="let size of cartItem.getUniqueSizes()" value="{{ size }}">
							{{ size }}
						</option>
					</select>
				</div>
				<div class="no-more-content" *ngIf="!cartItem.getUniqueSizes()">
					<h4 class="no-more-title">
						No Sizes
					</h4>
				</div>
			</div>
			<div class="col-5 col-lg-6">
				<div class="form-group">
					<div class="qty-input main-shadow w-100">
						<button (click)="cartItem.incrementQuantity(-1)" class="btn qty-count p-0" data-action="minus"
							type="button">
							<span>
								<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
									<path d="M5 12H19" stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
									</path>
								</svg>
							</span>
						</button>
						<input class="product-qty" type="number" name="product-qty" min="0" max="10" value="1"
							[(ngModel)]="cartItem.selectedQuantity" readonly />
						<button (click)="cartItem.incrementQuantity(1)" class="btn qty-count p-0" data-action="add" type="button">
							<span>
								<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
									<path d="M12 5V19M5 12H19" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
										stroke-linejoin="round"></path>
								</svg>
							</span>
						</button>
					</div>
				</div>
			</div>
		</div>
		<button *ngIf="product.decorations && product.decorations.length" (click)="decorationModel(decoration)"
			class="btn btn-secondary mb-4 shadow-none" type="button">
			<span>
				<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
					<path d="M10 4.75V15.25" stroke="#36394A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
					<path d="M4.75 10H15.25" stroke="#36394A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
				</svg>
			</span>
			Decoration Included
		</button>
		<div class="d-grid">
			<button [disabled]="!canAddToCart()" (click)="addToCart()" *ngIf="hasCartAccess" class="btn btn-primary w-100"
				type="button">
				Add to cart
			</button>
			<div class="d-flex align-items-center gap-3 d-none">
				<button class="btn btn-secondary w-100" type="button" disabled>
					Added
				</button>
				<button class="btn btn-secondary-icon" type="button">
					<span>
						<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
							<path d="M3.25 5.5H4.75H16.75" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
								stroke-linejoin="round" />
							<path
								d="M7 5.5V4C7 3.60218 7.15804 3.22064 7.43934 2.93934C7.72064 2.65804 8.10218 2.5 8.5 2.5H11.5C11.8978 2.5 12.2794 2.65804 12.5607 2.93934C12.842 3.22064 13 3.60218 13 4V5.5M15.25 5.5V16C15.25 16.3978 15.092 16.7794 14.8107 17.0607C14.5294 17.342 14.1478 17.5 13.75 17.5H6.25C5.85218 17.5 5.47064 17.342 5.18934 17.0607C4.90804 16.7794 4.75 16.3978 4.75 16V5.5H15.25Z"
								stroke="#666D80" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
						</svg>
					</span>
				</button>
			</div>
		</div>
	</div>
</div>

<ng-template #decoration let-close="close" let-d="dismiss">
	<div class="modal-content">
		<div class="modal-header">
			<h1 class="modal-title" id="bulkOrderLabel">Add Decorations</h1>
			<button (click)="d('Cross click')" type="button" class="btn btn-secondary-icon" data-bs-dismiss="modal"
				aria-label="Close">
				<span>
					<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
						<path d="M14.5 5.5L5.5 14.5M5.5 5.5L14.5 14.5" stroke="#666D80" stroke-width="1.5" stroke-linecap="round"
							stroke-linejoin="round" />
					</svg>
				</span>
			</button>
		</div>
		<div class="modal-body">
			<div class="order-bulk d-block">
				<div class="order-content">
					<div class="form-group" *ngIf="product.decorations && product.decorations.length">
						<label for="Colour" class="form-label">Decoration</label>
						<select #decc class="form-select" aria-label="Default select example" id="Colour"
							*ngIf="selectableDecorations.length" (change)="addDecorationToCart(decc.value, true, false)">
							<option *ngFor="let decoration of selectableDecorations" [value]="decoration.id">
								{{ decoration.name }} -
								<span *ngIf="hasCartAccess">${{
									utils.twoDecimalPlacesString(decoration.price)
									}}/ea</span>
							</option>
						</select>
					</div>
					<div class="edit-card card-body decoration-collapse"
						*ngIf="product.decorations && product.decorations.length">
						<ul>
							<li *ngFor="
									let decoration of cartItem.selectedDecorations;
									let i = index
								">
								<p>
									<span *ngIf="hasCartAccess">+${{ utils.twoDecimalPlacesString(decoration.price) }}/ea
										-</span>
									{{ decoration.name }}
									<span class="badge bg-red-25 ps-3 py-1" *ngIf="decoration.isRequired">
										- Required
									</span>
									<button *ngIf="!decoration.isRequired"
										class="btn btn-secondary-icon shadow-none p-0 rounded-0 ms-auto" type="button">
										<span (click)="removeDecorationAtIndex(i)">
											<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
												<path d="M14.5 5.5L5.5 14.5M5.5 5.5L14.5 14.5" stroke="#666D80" stroke-width="1.5"
													stroke-linecap="round" stroke-linejoin="round"></path>
											</svg>
										</span>
									</button>
								</p>
								<div class="form-group" *ngIf="decoration.options && decoration.options.length">
									<div *ngFor="let option of decoration.options">
										<label id="clientRequired {{ option.value }}" class="sele_color"
											*ngIf="option.name && option.name.length">{{ option.name }}
											<span *ngIf="option.required"> - Required</span></label>
										<input type="text" class="form-control" id="clientRequired {{ option.value }}"
											placeholder="{{ option.name }}" [required]="option.required" [(ngModel)]="option.value" />
									</div>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<div class="modal-footer">
			<button (click)="d('Cross click')" type="button" class="btn btn-secondary" data-bs-dismiss="modal">
				Cancel
			</button>
		</div>
	</div>
</ng-template>