export function legacyFeatures(features: string) {
	try {
		return JSON.parse(features);
	} catch(e) {
		// Leacy format features
		return features
			.trim()
			.replace(/<\/?((li)|(ul))[^>]*>/g, '\n|\n')
			.split('\n|\n')
			.map(line => line.replace(/<[^>]+>/g, '').trim())
			.filter(line => line !== '');
	}
}
