import { Injectable } from '@angular/core';
import { Observable, combineLatest, of } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { logger } from '../util/Logger';
import { SecurityService } from '../services/security.service';
import { GuardsCheckStart, Router } from '@angular/router';
import { FunctionKeys } from 'src/app/types';
import { SessionApi } from '../api/session.api';

/**
 * Generic Permission Guard Handler
 * This permission guard replaces all other guards in the application using the current injection syntax for future versions of angular.
 * The configuration objects passed into the guard can be simple or complex permission sets across multiple user roles
 *
 * Example:
 * canActivate: [() => inject(PermissionsGuard).canActivate([{ superAdmin: true }])] // SuperAdminOnly
 * canActivate: [() => inject(PermissionsGuard).canActivate([{ superAdmin: true }, { adminRoles: 'manage_customers'}, {userRoles: 'manage_users'}])] //SuperAdmin, Admin with manage_customers OR user with manage_roles
 */
export type IPermissionGuardSecurityFunctionConfig = { security: keyof SecurityService };
export type IPermissionGuardAuthenticatedConfig = { isAuthenticated: true };
export type IPermissionGuardAdminConfig = { admin: true; };
export type IPermissionGuardCustomerUserConfig = { customerUser: true; };
export type IPermissionGuardSuperAdminConfig = { superAdmin: true; };
export type IPermissionGuardAdminRoleConfig = { adminRoles: string | string[]; mode?: 'ANY' | 'ALL' };
export type IPermissionGuardUserRoleConfig = { userRoles: string | string[]; mode?: 'ANY' | 'ALL' };
export type IPermissionGuardPermission = IPermissionGuardSuperAdminConfig | IPermissionGuardAdminRoleConfig | IPermissionGuardUserRoleConfig | IPermissionGuardAuthenticatedConfig | IPermissionGuardSecurityFunctionConfig | IPermissionGuardSuperAdminConfig | IPermissionGuardAdminConfig;
export type IPermissionGuardConfig = IPermissionGuardPermission[];

const className = 'PermissionsGuard';

@Injectable()
export class PermissionsGuard {
	private _lastKnownRoute: string | null = null;

	private get lastKnownRoute() {
		return this._lastKnownRoute || this.router.url;
	}

	private set lastKnownRoute(val: string | null) {
		this._lastKnownRoute = val;
	}

	constructor(
		private readonly securityService: SecurityService,
		private readonly router: Router,
		private readonly session: SessionApi
	) {
		router.events.subscribe(evt => {
			if (evt instanceof GuardsCheckStart) {
				this.lastKnownRoute = evt.urlAfterRedirects;
			}
		});
	}

	public shouldActivate(config: IPermissionGuardConfig = [], mode: 'ANY' | 'ALL' = 'ANY'): Observable<boolean> {
		const signature = className + '.canActivate: ';
		if (!config.length) {
			return of(true).pipe(
				tap(() => logger.warn(`${signature} lastKnownRoute[${this.lastKnownRoute}] No permissions required`))
			)
		}

		const guards: Observable<boolean>[] = [];

		for (const cfg of config) {
			if ('superAdmin' in cfg && cfg.superAdmin) {
				guards.push(this.securityService.isSuperAdmin());
				continue;
			}

			if ('security' in cfg && cfg.security) {
				const cfgVal = this.securityService[cfg.security];

				if (typeof cfgVal === 'function') {
					const valResult = cfgVal.call(this.securityService);
					if (valResult instanceof Observable) {
						guards.push(
							valResult.pipe(
								map(result => {
									logger.debug(`${signature} lastKnownRoute[${this.lastKnownRoute}] Raw Result[${JSON.stringify(result)}]`);

									return !!result;
								})
							)
						);
					} else {
						logger.warn(`${signature} lastKnownRoute[${this.lastKnownRoute}] Falling back to truthy from Result[${valResult}] due Object is not Observable`);
						guards.push(of(!!valResult));
					}
				} else {
					logger.warn(`${signature} lastKnownRoute[${this.lastKnownRoute}] Falling back to truthy from HandlerVal[${cfgVal}] due Object is not a Function`);
					guards.push(of(!!cfgVal));
				}
				continue;
			}

			if ('adminRoles' in cfg && cfg.adminRoles) {
				guards.push(this.securityService.isLoggedInWithPermission(cfg.adminRoles, cfg.mode, true));
				continue;
			}

			if ('userRoles' in cfg && cfg.userRoles) {
				guards.push(this.securityService.isLoggedInWithPermission(cfg.userRoles, mode, false));
				continue;
			}

			if ('authenticated' in cfg && cfg.authenticated) {
				guards.push(this.securityService.isAuthenticated());
				continue;
			}

			logger.error(`${signature} lastKnownRoute[${this.lastKnownRoute}] Unsure how to handle permissionConfig[${JSON.stringify(cfg)}]`);
		}

		return this.session.$sessionChanged.pipe(
			filter(ready => !!ready),
			switchMap(() => combineLatest(guards)),
			map(guardResult => {
				logger.debug(`${signature} lastKnownRoute[${this.lastKnownRoute}] Result[${JSON.stringify(guardResult)}]`);

				if (mode === 'ALL') {
					const firstFalseResult = guardResult.find(v => !v);
					// !(the first false result)
					return firstFalseResult || true;
				}

				// !!(the first true result)
				const firstTrueResult = guardResult.find(v => v);
				return firstTrueResult || false;
			})
		);
	}

	public canActivate(config: IPermissionGuardConfig = [], mode: 'ANY' | 'ALL' = 'ANY'): Observable<boolean> {
		const signature = className + '.canActivate: ';
		return this.shouldActivate(config, mode).pipe(
			tap(canAccess => {
				if (!canAccess) {
					logger.error(`${signature} lastKnownRoute[${this.lastKnownRoute}] User does not match permissionConfig[${JSON.stringify(config)}] with Mode[${mode}]. Redirecting to home`);
					this.router.navigate(['/home']);
				}
			})
		)
	}
}
