import { BrowserModule } from "@angular/platform-browser";
import { APP_INITIALIZER, ErrorHandler, NgModule, isDevMode } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MainTemplateComponent } from "./template/main-template/main-template.component";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { Router } from "@angular/router";
import * as Sentry from "@sentry/angular-ivy";
import { HomeComponent } from "./view/home/home.component";
import { JwtInterceptor } from "./interceptor/jwt.interceptor";
import { JwtService } from "./services/jwt.service";
import { StorageService } from "./services/storage.service";
import { AuthService } from "./services/auth.service";
import { SecurityService } from "./services/security.service";
import { environment } from "src/environments/environment";
import { SimpleNotificationsModule } from "angular2-notifications";
import { AuthApi } from "./api/auth.api";
import { AppHeaderComponent } from './template/app-header/app-header.component';
import { AppFooterComponent } from './template/app-footer/app-footer.component';
import { AuthTemplateComponent } from './template/auth-template/auth-template.component';
import { AuthGuard } from './guard/auth.guard';
import { UnAuthGuard } from './guard/unauth.guard';
import { LoginComponent } from './auth/login/login.component';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DashboardComponent } from './dashboard/dashboard.component';
import { ResetComponent } from './auth/reset/reset.component';
import { SessionApi } from "./api/session.api";
import { AppTemplateComponent } from './template/app-template/app-template.component';
import { MenuComponent } from './template/menu/menu.component';
import { RolesApi } from './api/roles.api';
import { GlobalApi } from './api/global.api';
import { ConversionService } from './services/conversion.service';
import { CartApi } from './api/cart.api';
import { CartService } from './services/cart.service';
import { ProductApi } from './api/product.api';
import { ProductService } from './services/product.service';
import { UsersComponent } from './users/users.component';
import { TabSetComponent } from './view/tab-set/tab-set.component';
import { UserListComponent } from './users/customer-users/user-list/user-list.component';
import { CustomerUserApi } from './api/customerUser.api';
import { CustomerUserService } from './services/customerUser.service';
import { PaginationComponent } from './template/pagination/pagination.component';
import { NgxPaginationModule } from "ngx-pagination";
import { UserEditComponent } from './users/customer-users/user-edit/user-edit.component';
import { UserInformationEditComponent } from './users/customer-users/user-edit/user-information-edit/user-information-edit.component';
import { BusinessUnitApi } from './api/businessUnit.api';
import { BusinessUnitService } from './services/businessUnit.service';
import { UserAllocationEditComponent } from './users/customer-users/user-edit/user-allocation-edit/user-allocation-edit.component';
import { CollectionApi } from './api/colllection.api';
import { CollectionService } from './services/collection.service';
import { NgSelectModule } from "@ng-select/ng-select";
import { AllocationLogsComponent } from './users/customer-users/user-edit/user-allocation-edit/allocation-logs/allocation-logs.component';
import { AllocationLogApi } from './api/allocationLog.api';
import { AllocationLogService } from './services/allocationLog.service';
import { UserGroupEditComponent } from './users/customer-users/user-edit/user-group-edit/user-group-edit.component';
import { GroupApi } from './api/group.api';
import { GroupService } from './services/group.service';
import { CustomersComponent } from './customers/customers-list.component';
import { CustomerApi } from './api/customer.api';
import { CustomerService } from './services/customer.service';
import { ViewCustomerComponent } from './customers/customer-action/tabs/customer-action-tab.component';
import { ViewComponent } from './customers/customer-action/view/view.component';
import { CustomerProductApi } from './api/customerproduct.api';
import { CustomerProductService } from './services/customerProduct.service';
import { ProductsComponent } from './customers/customer-action/products/products.component';
import { ShippingDetailApi } from './api/shippingDetail.api';
import { ShippingDetailService } from './services/shippingDetail.service';
import { AddressComponent } from './customers/customer-action/address/address.component';
import { DocumentComponent } from './customers/customer-action/document/document.component';
import { DocumentApi } from './api/document.api';
import { DocumentService } from './services/document.service';
import { CustomerEditComponent } from './customers/customer-action/customer-edit/customer-edit.component';
import { OverviewEditComponent } from './customers/customer-action/overview-edit/overview-edit.component';
import { UnleashedApi } from './api/unleashed.api';
import { UnleashedService } from './services/unleashed.service';
import { FileUploadApi } from './api/fileUpload.api';
import { FileUploadService } from './services/fileUpload.service';
import { CustomerUserRoleService } from './services/customerUserRole.service';
import { LandingPageEditComponent } from './customers/customer-action/overview-edit/landing.page.edit.component';
import { PurchaseModeEditComponent } from './customers/customer-action/overview-edit/purchase.mode.edit.component';
import { PriceDiscountEditComponent } from './customers/customer-action/overview-edit/price.discount.edit.component';
import { ListComponent } from './customers/customer-action/business-unit/list/list.component';
import { GroupListComponent } from './groups/list/list.component';
import { SearchComponent } from './template/search/search.component';
import { OverviewComponent } from './groups/overview/overview.component';
import { GroupTabComponent } from './groups/group-tab/group-tab.component';
import { CollectionsOverviewComponent } from './groups/overview/collections-overview/collections-overview.component';
import { AdminApi } from './api/admin.api';
import { AdminService } from './services/admin.service';
import { AdminListComponent } from './admin/list/list.component';
import { EditAddressComponent } from './customers/customer-action/address/edit-address/edit-address.component';
import { BusinessEditComponent } from './customers/customer-action/business-unit/business-edit/business-edit.component';
import { CustomerDomainComponent } from './customers/customer-action/customer-domain/customer-domain.component';
import { CustomerDomainApi } from './api/customerDomain.api';
import { CustomerDomainService } from './services/customerDomain.service';
import { AdminEditComponent } from './admin/edit/edit.component';
import { DocumentEditComponent } from './customers/customer-action/document/document-edit/document-edit.component';
import { CollectionListComponent } from './collections/list/list.component';
import { CustomerUserField } from './customers/customer-action/overview-edit/customerUserField.component';
import { OrderTabComponent } from './manage-orders/order-tab/order-tab.component';
import { OrderListComponent } from './manage-orders/order-list/order-list.component';
import { OrderDetailComponent } from './manage-orders/order-detail/order-detail.component';
import { OrderShipmentComponent } from './manage-orders/order-shipment/order-shipment.component';
import { ProductListComponent } from './manage-products/product-list/product-list.component';
import { ProductEditComponent } from './manage-products/product-edit/product-edit.component';
import { CategoryApi } from './api/category.api';
import { CategoryService } from './services/category.service';
import { LabelApi } from './api/label.api';
import { LabelService } from './services/label.service';
import { BrandApi } from './api/brand.api';
import { BrandService } from './services/brand.service';
import { SizeChartApi } from './api/sizeChart.api';
import { SizeChartService } from './services/sizeChart.service';
import { GroupEditComponent } from './groups/group-edit/group-edit.component';
import { AdminRoleApi } from './api/adminRole.api';
import { AdminRoleService } from './services/adminRole.service'
import { NgxEditorModule } from 'ngx-editor';
import { DecorationApi } from './api/decoration.api';
import { DecorationService } from './services/decoration.service';
import { DecorationListComponent } from './manage-decorations/decoration-list/decoration-list.component';
import { DecorationEditComponent } from './manage-decorations/decoration-edit/decoration-edit.component';
import { CollectionEditComponent } from './collections/collection-edit/collection-edit.component';
import { SizechartListComponent } from './manage-sizechart/sizechart-list/sizechart-list.component';
import { SizechartEditComponent } from './manage-sizechart/sizechart-edit/sizechart-edit.component';
import { EnumComponent } from './enum/enum.component';
import { EnumListItemsComponent } from './enum/enum-list-items/enum-list-items.component';
import { UserRoleListComponent } from './manage-roles/user-role-list/user-role-list.component';
import { UserRoleEditComponent } from './manage-roles/user-role-edit/user-role-edit.component';
import { AdminRoleEditComponent } from './manage-roles/admin-role-edit/admin-role-edit.component';
import { AdminRoleListComponent } from './manage-roles/admin-role-list/admin-role-list.component';
import { ManageSystemComponent } from './manage-system/manage-system.component';
import { EmailTestComponent } from './email-test/email-test.component';
import { CustomerMergeApi } from './api/customerMerge.api';
import { CustomerMergeService } from './services/customerMerge.service';
import { RegisterComponent } from './auth/register/register.component';
import { UserAllocationComponent } from './reports/user-allocation/user-allocation.component';
import { ReportsComponent } from './reports/reports.component';
import { ReportApi } from "./api/report.api";
import { ReportService } from "./services/report.service";
import { UserlistReportComponent } from './reports/userlist-report/userlist-report.component';
import { OrderlistReportComponent } from './reports/orderlist-report/orderlist-report.component';
import { BusinessUnitReportComponent } from './reports/business-unit-report/business-unit-report.component';
import { SalesReportComponent } from './reports/sales-report/sales-report.component';
import { OverspendComponent } from './reports/overspend/overspend.component';
import { RoleGuard } from './guard/role.guard';
import { AdminReportsComponent } from './admin-reports/admin-reports.component';
import { StaleProductReportComponent } from './admin-reports/stale-product-report/stale-product-report.component';
import { AbnormalCartReportComponent } from "./admin-reports/abnormal-cart-report/abnormal-cart-report.component";
import { ProductViewComponent } from './products/product-list/product-view.component';
import { SumsComponent } from './view/solutions/sums/sums.component';
import { ProductCustomizationComponent } from './view/solutions/product-customization/product-customization.component';
import { EurekaSafetyComponent } from './view/solutions/eureka-safety/eureka-safety.component';
import { SupplyChainComponent } from './view/solutions/supply-chain/supply-chain.component';

import { ContactUsComponent } from './view/contactus/contact-us/contact-us.component';
import { AboutUsComponent } from './view/about/about-us/about-us.component';
import { IndustriesComponent } from './view/industries/industries/industries.component';
import { FavoritesListComponent } from './favorites/favorites-list/favorites-list.component';
import { ManageEmployesComponent } from './manage-employes/manage-employes.component';
import { FavoriteApi } from "./api/favorites.api";
import { FavoriteService } from "./services/favorites.service";
import { FavoritesProductApi } from "./api/favoritesProduct.api";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FavoritesDetailComponent } from './favorites/favorites-detail/favorites-detail.component';
import { CartListComponent } from './manage-cart/cart-list/cart-list.component';
import { CategoriesComponent } from './products/categories/categories.component';
import { ProductDetailComponent, WrapTextPipe } from './products/product-detail/product-detail.component';
import { ProductBreadcrumbsComponent } from './products/product-breadcrumbs/product-breadcrumbs.component';
import { Utils } from "./util/utils";
import { FavoriteSelectComponent } from './favorites/favorite-select/favorite-select.component';
import { CartItemsComponent } from './manage-cart/cart-items/cart-items.component';
import { CartCheckoutComponent } from './manage-cart/cart-checkout/cart-checkout.component';
import { BulkOrderModelComponent } from './products/bulk-order-model/bulk-order-model.component';
import { CartCheckoutContactComponent } from './manage-cart/cart-checkout-contact/cart-checkout-contact.component';
import { CartCheckoutItemsComponent } from './manage-cart/cart-checkout-items/cart-checkout-items.component';
import { PaymentMethodComponent } from './manage-cart/payment-method/payment-method.component';
import { SizeTableComponent } from './manage-sizechart/size-table/size-table.component';
import { CartCompleteComponent } from './manage-cart/cart-complete/cart-complete.component';
import { ViewEmployeComponent } from './manage-employes/view-employe/view-employe.component';
import { SlickCarouselModule } from "ngx-slick-carousel";
import { CustomerDecorationComponent } from './customers/customer-action/customer-decoration/customer-decoration.component';
import { QuickOrderCategoriesComponent } from './products/quick-order-categories/quick-order-categories.component';
import { QuickOrderListComponent } from './products/quick-order-list/quick-order-list.component';
import { QuickOrderListItemComponent } from './products/quick-order-list-item/quick-order-list-item.component';
import { NgApexchartsModule } from "ng-apexcharts";
import { UserRoleGuard } from "./guard/user_role.guard";
import { PermissionsGuard } from "./guard/permissions.guard";
import { MobileMenuComponent } from './template/mobile-menu/mobile-menu.component';
import { UserProfileEditComponent } from './users/customer-users/user-edit/user-profile-edit/user-profile-edit.component';
import { BusinessDetailComponent } from './customers/customer-action/business-unit/business-detail/business-detail.component';
import { SSOService } from './services/sso.service';
import { ShippingDeliveryComponent } from './view/shipping-delivery/shipping-delivery.component';
import { ReturnsExchangesComponent } from './view/returns-exchanges/returns-exchanges.component';
import { UnauthProductPageComponent } from './view/unauth-product-page/unauth-product-page.component';
import { BalanceDetailComponent } from './users/balance-detail/balance-detail.component';
import { RecaptchaModule } from "ng-recaptcha";
import { NgxIntlTelInputModule } from "ngx-intl-tel-input";
import { WebSocketService } from "./services/webSocket.service";
import { WebsocketInterceptor } from './interceptor/webSocket.interceptor';
import { ModalComponent } from './template/model.component';
import { AcceptInviteComponent } from './users/accept-invite/accept-invite.component';
import { DenyInviteComponent } from './users/deny-invite/deny-invite.component';
import { CartApproveComponent } from './manage-cart/cart-approve/cart-approve.component';
import { CartCancelComponent } from './manage-cart/cart-cancel/cart-cancel.component';
import { CustomUserFieldComponent } from './users/customer-users/user-edit/custom.field.component';
import { NoNegativeDirective } from './view/directive/negative.directive';
import { SharedOrderModelComponent } from './shared-order-model/shared-order-model.component';
import { AllocationLoderComponent } from './view/allocation-loder/allocation-loder.component';
@NgModule({
	declarations: [
		HomeComponent,
		MainTemplateComponent,
		AppHeaderComponent,
		AppFooterComponent,
		AuthTemplateComponent,
		LoginComponent,
		DashboardComponent,
		ResetComponent,
		AppTemplateComponent,
		MenuComponent,
		UsersComponent,
		TabSetComponent,
		UserListComponent,
		PaginationComponent,
		UserEditComponent,
		UserInformationEditComponent,
		UserAllocationEditComponent,
		AllocationLogsComponent,
		UserGroupEditComponent,
		CustomersComponent,
		ViewCustomerComponent,
		ViewComponent,
		ProductsComponent,
		AddressComponent,
		DocumentComponent,
		CustomerEditComponent,
		OverviewEditComponent,
		LandingPageEditComponent,
		PurchaseModeEditComponent,
		PriceDiscountEditComponent,
		ListComponent,
		GroupListComponent,
		SearchComponent,
		OverviewComponent,
		GroupTabComponent,
		CollectionsOverviewComponent,
		AdminListComponent,
		EditAddressComponent,
		BusinessEditComponent,
		CustomerDomainComponent,
		AdminEditComponent,
		DocumentEditComponent,
		CollectionListComponent,
		CustomerUserField,
		OrderTabComponent,
		OrderListComponent,
		OrderDetailComponent,
		OrderShipmentComponent,
		ProductListComponent,
		ProductEditComponent,
		GroupEditComponent,
		DecorationListComponent,
		DecorationEditComponent,
		CollectionEditComponent,
		SizechartListComponent,
		SizechartEditComponent,
		EnumComponent,
		EnumListItemsComponent,
		UserRoleListComponent,
		UserRoleEditComponent,
		AdminRoleEditComponent,
		AdminRoleListComponent,
		ManageSystemComponent,
		EmailTestComponent,
		RegisterComponent,
		UserAllocationComponent,
		ReportsComponent,
		UserlistReportComponent,
		OrderlistReportComponent,
		BusinessUnitReportComponent,
		SalesReportComponent,
		OverspendComponent,
		AdminReportsComponent,
		StaleProductReportComponent,
		ProductViewComponent,
		SumsComponent,
		ProductCustomizationComponent,
		EurekaSafetyComponent,
		SupplyChainComponent,
		AbnormalCartReportComponent,

		IndustriesComponent,
		AboutUsComponent,
		ContactUsComponent,
		FavoritesListComponent,
		ManageEmployesComponent,
		FavoritesDetailComponent,
		CartListComponent,
		CategoriesComponent,
		ProductDetailComponent,
		ProductBreadcrumbsComponent,
		FavoriteSelectComponent,
		CartItemsComponent,
		CartCheckoutComponent,
		BulkOrderModelComponent,
		CartCheckoutContactComponent,
		CartCheckoutItemsComponent,
		PaymentMethodComponent,
		SizeTableComponent,
		CartCompleteComponent,
		ViewEmployeComponent,
		CustomerDecorationComponent,
		QuickOrderCategoriesComponent,
		QuickOrderListComponent,
		QuickOrderListItemComponent,
		WrapTextPipe,
		MobileMenuComponent,
		UserProfileEditComponent,
		BusinessDetailComponent,
		ShippingDeliveryComponent,
		ReturnsExchangesComponent,
		UnauthProductPageComponent,
		BalanceDetailComponent,
		ModalComponent,
		AcceptInviteComponent,
		DenyInviteComponent,
		CartApproveComponent,
		CartCancelComponent,
		CustomUserFieldComponent,
		NoNegativeDirective,
  SharedOrderModelComponent,
  AllocationLoderComponent
	],
	imports: [
		BrowserModule,

		AppRoutingModule,
		HttpClientModule,
		FormsModule,
		ReactiveFormsModule,
		NgxPaginationModule,
		NgSelectModule,
		NgxEditorModule,

		/* Alerts - https://www.npmjs.com/package/angular2-notifications */
		BrowserAnimationsModule,
		SimpleNotificationsModule.forRoot({
			timeOut: 3500,
			showProgressBar: true,
			clickToClose: true
		}),
		NgbModule,
		SlickCarouselModule,
		NgApexchartsModule,
		RecaptchaModule,
		NgxIntlTelInputModule
	],
	providers: [

		AuthApi,
		AuthService,

		JwtService,
		StorageService,

		AuthGuard,
		UnAuthGuard,
		RoleGuard,
		UserRoleGuard,
		PermissionsGuard,

		SessionApi,
		SecurityService,

		RolesApi,
		GlobalApi,

		ConversionService,

		CartApi,
		CartService,

		ProductApi,
		ProductService,

		CustomerUserApi,
		CustomerUserService,

		BusinessUnitApi,
		BusinessUnitService,

		CollectionApi,
		CollectionService,

		AllocationLogApi,
		AllocationLogService,

		GroupApi,
		GroupService,

		CustomerApi,
		CustomerService,

		CustomerProductApi,
		CustomerProductService,

		ShippingDetailApi,
		ShippingDetailService,

		DocumentApi,
		DocumentService,

		UnleashedApi,
		UnleashedService,

		FileUploadApi,
		FileUploadService,

		CustomerUserRoleService,

		AdminApi,
		AdminService,

		CustomerDomainApi,
		CustomerDomainService,

		CategoryApi,
		CategoryService,

		LabelApi,
		LabelService,

		BrandApi,
		BrandService,

		SizeChartApi,
		SizeChartService,

		AdminRoleApi,
		AdminRoleService,

		DecorationApi,
		DecorationService,

		CustomerMergeApi,
		CustomerMergeService,

		ReportApi,
		ReportService,

		FavoriteApi,
		FavoriteService,

		FavoritesProductApi,
		Utils,

		SSOService,

		WebSocketService,
		WebsocketInterceptor,

		/** Sentry */
		{
			provide: HTTP_INTERCEPTORS,
			useClass: JwtInterceptor,
			multi: true,
		},
		{
			provide: ErrorHandler,
			useValue: Sentry.createErrorHandler({
				showDialog: false,
			}),
		},
		{
			provide: Sentry.TraceService,
			deps: [Router],
		},
		{
			provide: APP_INITIALIZER,
			useFactory: () => () => { },
			deps: [Sentry.TraceService],
			multi: true,
		},

		{ provide: 'initialHref', useValue: window.location.href }
	],
	exports: [
		WrapTextPipe
	],
	bootstrap: [MainTemplateComponent],
})
export class AppModule { }
