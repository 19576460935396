import { Component } from '@angular/core';
import { GlobalApi } from '../../api/global.api';
import { IUserAllocation } from '../../model/userAllocation.model';
import { QueryResult } from '../../model/query.filter.class';
import { ICollection } from '../../model/collection.model';

@Component({
  selector: 'app-balance-detail',
  templateUrl: './balance-detail.component.html',
  styleUrls: []
})
export class BalanceDetailComponent {
  allocations: IUserAllocation[] = [];

  queryResult: QueryResult<ICollection> = new QueryResult();

  constructor(
    public globals: GlobalApi,
  ) { }

  ngOnInit() {
    this.globals.withSession(() => {
      if (this.globals.customer && this.globals.customer.CustomerUser) {
        this.allocations = this.globals.customer.CustomerUser.userAllocations;
      }
    })
  }

}
