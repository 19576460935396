import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NewDecoration, NewProduct } from '../../model/ddb.model';
import { NewCartItem } from '../../model/cart.model';
import { ProductService } from '../../services/product.service';
import { isNullOrUndefined } from 'util';
import { SecurityService } from '../../services/security.service';
import { NotificationsService } from 'angular2-notifications';
import { CartService } from '../../services/cart.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { assign } from 'lodash';
import { Router } from '@angular/router';
import { SessionApi } from '../../api/session.api';
import { CollectionService } from '../../services/collection.service';
import { ICollection, IShortAllocationCollection } from '../../model/collection.model';
import { QueryResult } from '../../model/query.filter.class';
import { Utils } from '../../util/utils';
import { QuickOrderListComponent } from '../quick-order-list/quick-order-list.component';
import { GlobalApi } from '../../api/global.api';
import { Subject, debounceTime, takeUntil } from 'rxjs';

@Component({
  selector: 'app-quick-order-list-item',
  templateUrl: './quick-order-list-item.component.html',
  styleUrls: []
})
export class QuickOrderListItemComponent implements OnChanges, OnDestroy {

  @Input() data;
  public product: NewProduct;
  cartItem: NewCartItem = new NewCartItem();
  public selectableDecorations: NewDecoration[] = [];
  public hasCartAccess: boolean;
  public collectionResult: QueryResult<ICollection> = new QueryResult();
  public allocationCollections: (IShortAllocationCollection & { name: string })[] = [];
  @Output() addToCartClicked: EventEmitter<any> = new EventEmitter<any>();
  public customerId: number;
  private unsubscribe$ = new Subject<void>();

  constructor(
    private securityService: SecurityService,
    public notifications: NotificationsService,
    private cartService: CartService,
    private modalService: NgbModal,
    public router: Router,
    private collectionsService: CollectionService,
    public utils: Utils,
    public global: GlobalApi
  ) { }

  ngOnChanges(): void {
    if (this.data.product)
      this.withProduct(this.data.product)

    this.monitorCartAccess();

    this.global.withSession(() => {

      if (this.global.customer?.id) {
        this.customerId = this.global.customer.id;
      }
    });
  }

  withProduct(product: NewProduct) {
    this.product = product;
    if (this.product.variations && this.product.variations.length) {
      this.product.variations = this.product.variations.sort((a: any, b: any) =>
        a.displayOrder === b.displayOrder && a.id && b.id ? a.id - b.id : a.displayOrder - b.displayOrder
      );
    }

    this.cartItem = new NewCartItem(this.product);
    this.cartItem.selectedColour = null;
    this.cartItem.selectedSize = null;
    // if (this.product.variations && this.product.variations.length) {
    //   this.cartItem.selectedVariation = this.product.variations[0];
    // }

    this.addRequiredDecorations();

    this.setSelectableDecorations();

    this.monitorCartAccess();

  };

  private addRequiredDecorations() {
    if (!this.product) throw new Error("Cannot add required decorations without a product");
    if (!this.product.decorations || !this.product.decorations.length) return;
    this.product.decorations.forEach(decoration => {
      if (decoration.isRequired) this.addDecorationToCart(decoration.id, false, true);
    });
  }

  addDecorationToCart(decorationId: number | string | undefined, updateSelectable: boolean = true, isRequired = true) {
    if (decorationId) {
      const decoration = this.product.decorations.find(decc => decc.id == +decorationId);

      const decorationClone: NewDecoration = JSON.parse(JSON.stringify(decoration));

      decorationClone.isRequired = isRequired;

      this.cartItem.selectedDecorations?.push(decorationClone);

      if (updateSelectable) {
        this.setSelectableDecorations();
      }
    }
  }

  /**
* @description Removes the indiciated decoration from the cartItem
*/
  public removeDecorationAtIndex(index: number) {
    this.cartItem.selectedDecorations?.splice(index, 1);

    this.setSelectableDecorations();
  }


  public setSelectableDecorations(): void {
    const signature = "ProductDetailComponent.getSelec ableDecorations: ";
    if (!this.product.decorations || !this.product.decorations.length) {
      this.selectableDecorations = [];
      return;
    }

    if (!this.cartItem || !this.cartItem.selectedDecorations || !this.cartItem.selectedDecorations.length) {
      this.selectableDecorations = this.product.decorations;
      return;
    }

    this.selectableDecorations = this.product.decorations.filter(decoration => {
      if (isNullOrUndefined(decoration.maxPerProduct)) {
        return true;
      }

      // Ensure the product doesn't have the maxed out decorations
      const instances = this.cartItem.selectedDecorations?.filter(
        selected => selected.id === decoration.id
      ).length;

      if (instances === 0) {
        return true
      }

      const result = instances && instances < decoration.maxPerProduct;
      return result;
    });
  }

  //product.packSizes && product.packSizes.length
  usePackSizes = (): boolean => !!(this.product.packSizes && this.product.packSizes.length);

  private monitorCartAccess() {
    this.securityService.hasCartAccess()
      .pipe(
        takeUntil(this.unsubscribe$)
      )
      .subscribe(hasCartAccess => this.hasCartAccess = hasCartAccess);
  }

  selectPackSize(name: string) {
    const pack = this.product.packSizes.find(pack => pack.name == name);
    if (pack) {
      this.cartItem.selectedPack = pack;
    }
  }

  canAddToCart = (): boolean => !!this.cartItem.selectedVariation && (
    !this.usePackSizes() || !!this.cartItem.selectedPack
  );

  addToCart() {

    if (this.cartItem.selectedDecorations && this.cartItem.selectedDecorations.length) {
      let hasDecorationOptionError = false;
      for (let i = 0; i < this.cartItem.selectedDecorations.length; i++) {
        const decoration = this.cartItem.selectedDecorations[i];
        if (!decoration.options || !decoration.options.length) { continue; }

        const requiredMissingData = decoration.options.filter(option => option.required && (!option.value || !option.value.length));
        if (requiredMissingData && requiredMissingData.length) {
          hasDecorationOptionError = true;
          break;
        }
      }

      if (hasDecorationOptionError) {
        this.notifications.warn('Processing', '"Ensure required options for all selected decorations have been correctly filled.');
        return;
      }
    }

    if (this.product && this.product.id) {
      let collectionData = this.collectionsService.getCollectionAllocationsForProduct(this.product.id, this.customerId);
      collectionData
        .pipe(
          debounceTime(1000),
          takeUntil(this.unsubscribe$)
        ).subscribe(result => {
          if (result && result.count && result.rows.some(item => !!item.allocationAvailable)) {
            this.collectionResult = result;
            this.allocationCollections = [];

            result.rows
						.filter(row => row.products.find( collectionProduct => collectionProduct.productId === this.product.id))
						.forEach(allocationCollection => {
              if (allocationCollection.allocationAvailableDistribution) {
                allocationCollection.allocationAvailableDistribution.forEach(distribution => {
                  const namedCollection = assign(distribution, {
                    name: allocationCollection.name
                  });

                  this.allocationCollections.push(namedCollection);
                })
              }
            });

            this.allocationCollections = this.allocationCollections.filter(collection => !!collection.count);

            if (this.allocationCollections.length === 0) {
              this.performAddToCart();
            } else {
              // Use the first matching collection
              const suitableCollections = this.allocationCollections.filter(collection => collection.count >= Number(this.cartItem.selectedQuantity));

              if (suitableCollections.length) {
                this.cartItem.allocationCollectionId = suitableCollections[0].id;
                this.performAddToCart();
              }
            }
          } else {
            this.performAddToCart();
          }
        });
    }
  }

  performAddToCart() {
    const addItemSubscription = this.cartService.addItemToCart(this.cartItem);
    if (addItemSubscription) {
      addItemSubscription
        .pipe(
          debounceTime(1000),
          takeUntil(this.unsubscribe$)
        ).subscribe(() => {
          this.notifications.success('Adding Item Complete', 'Adding item to cart was successful.');
          this.collectionsService.loadCollections();
          this.cartItem.allocationCollectionConsumption = undefined;
          this.cartItem.allocationCollectionId = undefined;
          this.modalService.dismissAll();
          this.addToCartClicked.emit();
        });
    }
  }

  public decorationModel(content) {
    this.modalService.open(content, { size: 'lg', centered: true, windowClass: "bulk-order" });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  fetchImage() {
    if (this.data.product.images) {
      const fetchColorUrl = this.data.product.images.find((image) => image.name.toLowerCase() == this.cartItem.selectedColour);
      if (fetchColorUrl) {
        return fetchColorUrl.url;
      } else {
        return this.product.imageUrl;
      }
    } else {
      return this.product.imageUrl;
    }
  }
}
