<div class="main-right-content">
	<!-- Page Breadcrumb start here... -->
	<nav aria-label="breadcrumb" class="pageBreadcrumb">
		<ol class="breadcrumb mb-0">
			<li class="breadcrumb-item"><a [routerLink]="['../../']">Orders </a></li>
			<li class="breadcrumb-item">
				<a [routerLink]="['/manage/orders', orderId]">{{ orderNumber }}</a>
			</li>
			<li class="breadcrumb-item active" aria-current="page">
				Shipment Details
			</li>
		</ol>
	</nav>
	<!-- Page Breadcrumb end here... -->

	<!-- manage-user main start here... -->
	<div class="manageUser">
		<div class="d-flex align-items-start justify-content-between">
			<h1 class="title">Shipment Details</h1>
		</div>

		<div class="manage-user-list">
			<div class="no-content-ship ship-h" *ngIf="!ShipmentDetails">
				<div class="no-content-icon">
					<span>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="20"
							height="20"
							viewBox="0 0 20 20"
							fill="none"
						>
							<path
								d="M13.7503 2.50003H15.7528C16.1772 2.49252 16.5897 2.64113 16.9118 2.91764C17.234 3.19415 17.4434 3.57932 17.5003 4.00003V9.25003C17.4434 9.67073 17.234 10.0559 16.9118 10.3324C16.5897 10.6089 16.1772 10.7575 15.7528 10.75H13.7503M8.50027 12.25V15.25C8.50027 15.8468 8.73732 16.4191 9.15928 16.841C9.58123 17.263 10.1535 17.5 10.7503 17.5L13.7503 10.75V2.50003H5.29027C4.92852 2.49594 4.57748 2.62272 4.30184 2.85702C4.02619 3.09132 3.84451 3.41735 3.79027 3.77503L2.75527 10.525C2.72264 10.74 2.73714 10.9595 2.79776 11.1683C2.85839 11.3772 2.96369 11.5703 3.10637 11.7344C3.24906 11.8985 3.42571 12.0296 3.62409 12.1186C3.82247 12.2076 4.03784 12.2525 4.25527 12.25H8.50027Z"
								stroke="#666D80"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
						</svg>
					</span>
				</div>
				<h4 class="no-content-title">
					Currently no shipment details available
				</h4>
			</div>
			<div
				class="edit-users order-ship"
				*ngFor="let ShipmentDetail of ShipmentDetails"
			>
				<div class="edit-card card order-card">
					<div class="card-head">
						<h4 class="head-title">Shipment Details</h4>
					</div>
					<div class="card-body">
						<div class="order-add">
							<p>
								<span>Shipment Number</span>
								{{ ShipmentDetail.ShipmentNumber || "Not Specified" }}
							</p>
						</div>
						<div class="order-add">
							<p>
								<span>Order Number</span>
								SO-RS-0049512
							</p>
						</div>
						<div class="order-add">
							<p>
								<span>Tracking details</span>
								{{
									myTrackingDetails["TrackingDetail_" + ShipmentDetail.Guid] ||
										"Not Specified"
								}}
								<a
									(click)="showTrackingEditModal(); open(content)"
									*ngIf="globals.isAdmin"
									class="btn btn-secondary-icon shadow-none p-0 d-inline-block"
								>
									<span>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="20"
											height="20"
											viewBox="0 0 20 20"
											fill="none"
										>
											<path
												d="M10 15.9999H16.75M13.375 3.62493C13.6734 3.32656 14.078 3.15894 14.5 3.15894C14.7089 3.15894 14.9158 3.20009 15.1088 3.28004C15.3019 3.36 15.4773 3.47719 15.625 3.62493C15.7727 3.77266 15.8899 3.94805 15.9699 4.14108C16.0498 4.33411 16.091 4.54099 16.091 4.74993C16.091 4.95886 16.0498 5.16574 15.9699 5.35877C15.8899 5.5518 15.7727 5.72719 15.625 5.87493L6.25 15.2499L3.25 15.9999L4 12.9999L13.375 3.62493Z"
												stroke="#818898"
												stroke-width="1.5"
												stroke-linecap="round"
												stroke-linejoin="round"
											/>
										</svg>
									</span>
								</a>
							</p>

              <ng-template #content let-c="close" let-d="dismiss">
                <div class="modal-header">
                  <h4 class="modal-title" id="modal-basic-title">
                    Shipment Tracking Details Edit
                  </h4>
                </div>
                <div class="modal-body">
                  <div class="row mb-0">
                    <div class="col-12">
                      <div class="form-group mb-3">
                        <label class="form-label" for="TrackingDetail">Tracking Detail</label>
                        <input
                          id="TrackingDetail"
                          type="text"
                          class="form-control"
                          placeholder="Tracking Details"
                          [(ngModel)]="
                            trackingDetailsTemp['TrackingDetail_' + ShipmentDetail.Guid]
                          "
                        />
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group">
                        <label class="form-label" for="TrackingDetailURL"
                          >Tracking Link</label
                        >
                        <input
                          id="TrackingDetailURL"
                          type="text"
                          class="form-control"
                          placeholder="Tracking Link"
                          [(ngModel)]="
                            trackingLinkTemp['TrackingDetailURL_' + ShipmentDetail.Guid]
                          "
                        />
                      </div>
                    </div>
                  </div>
                  <div class="d-flex align-items-center justify-content-end gap-3">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      (click)="resetTrackingInformation(ShipmentDetail.Guid); c('Cancel click')"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      class="btn btn-primary px-4"
                      (click)="saveTrackingInformation(ShipmentDetail.Guid); c('Save click')"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </ng-template>

							<p>
								<a
									*ngIf="
										myTrackingLinks['TrackingDetailURL_' + ShipmentDetail.Guid]
									"
									[href]="
										myTrackingLinks['TrackingDetailURL_' + ShipmentDetail.Guid]
									"
									target="_blank"
									class="btn btn-secondary p-0 shadow-none"
								>
									Tracking Link
									<span>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="20"
											height="20"
											viewBox="0 0 20 20"
											fill="none"
										>
											<path
												d="M8.5 10.75C8.82209 11.1806 9.23302 11.5369 9.70491 11.7947C10.1768 12.0525 10.6986 12.2058 11.235 12.2442C11.7713 12.2826 12.3097 12.2052 12.8135 12.0173C13.3173 11.8294 13.7748 11.5353 14.155 11.155L16.405 8.90497C17.0881 8.19772 17.4661 7.25046 17.4575 6.26722C17.449 5.28398 17.0546 4.34343 16.3593 3.64815C15.664 2.95287 14.7235 2.55849 13.7403 2.54995C12.757 2.5414 11.8098 2.91938 11.1025 3.60247L9.8125 4.88497"
												stroke="#818898"
												stroke-width="1.5"
												stroke-linecap="round"
												stroke-linejoin="round"
											/>
											<path
												d="M11.4997 9.24992C11.1776 8.81933 10.7666 8.46304 10.2947 8.20522C9.82285 7.9474 9.30103 7.79409 8.76467 7.75567C8.22832 7.71726 7.68997 7.79465 7.18615 7.98259C6.68233 8.17053 6.22483 8.46462 5.84466 8.84492L3.59466 11.0949C2.91157 11.8022 2.53359 12.7494 2.54213 13.7327C2.55068 14.7159 2.94506 15.6565 3.64034 16.3517C4.33562 17.047 5.27617 17.4414 6.25941 17.45C7.24264 17.4585 8.1899 17.0805 8.89716 16.3974L10.1797 15.1149"
												stroke="#818898"
												stroke-width="1.5"
												stroke-linecap="round"
												stroke-linejoin="round"
											/>
										</svg>
									</span>
								</a>
							</p>
						</div>
					</div>
				</div>
				<div class="manage-table main-table">
					<table class="table">
						<thead>
							<tr>
								<th>Ln</th>
								<th>Product Code</th>
								<th>Details</th>
								<th>Quantity</th>
							</tr>
						</thead>

						<tbody>
							<tr
								*ngFor="
									let SalesShipmentLines of ShipmentDetail.SalesShipmentLines
								"
							>
								<td>
									<p>{{ SalesShipmentLines.LineNumber }}</p>
								</td>
								<td>
									<p>{{ SalesShipmentLines.Product.ProductCode }}</p>
								</td>
								<td>
									<p>{{ SalesShipmentLines.Product.ProductDescription }}</p>
								</td>
								<td>
									<p>{{ SalesShipmentLines.ShipmentQty }}</p>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
	<!-- manage-user main end here... -->
</div>
