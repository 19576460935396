import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Subject, Subscription, combineLatest, debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';
import { IQueryFilter, QueryResult } from '../../model/query.filter.class';
import { CartService } from '../../services/cart.service';
import { CartAttrs } from '../../model/cart.model';
import { SessionApi } from '../../api/session.api';
import { GlobalApi } from '../../api/global.api';
import { ActivatedRoute, Router } from '@angular/router';
import { Utils } from '../../util/utils';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CartApi } from '../../api/cart.api';

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: []
})
export class OrderListComponent implements OnChanges, OnInit, OnDestroy {
  @Input() orderStatus: string | undefined;
  public totalCountActive: number = 0;
  public currentPageActive: number = 1;
  private searchTerms: Subject<string> = new Subject<string>();
  private searchSubscription: Subscription;
  queryResult: QueryResult<CartAttrs> = new QueryResult();
  public customerId: number | undefined = undefined;
  private unsubscribe$ = new Subject<void>();
  public isLoading = false;
  public detailEditForm: { orderNumber: string | null, notes: string | null } = {
    orderNumber: '',
    notes: ''
  };
  public currentCart: CartAttrs;
  query: IQueryFilter = new IQueryFilter({
    filter: {
      status: {
        $ne: "PENDING"
      }
    },
    sortBy: 'updated_at',
    order: 'desc'
  });

  public get isAdmin(): boolean {
    return !!this.globals.isAdmin;
  }

  ngOnChanges(): void {
    this.query.filter.status = this.orderStatus;
    this.loadOrders();
  }

  sortOptions = [{
    id: 1,
    text: "Last Modified",
    field: "updated_at"
  }, {
    id: 2,
    text: "Status",
    field: "status"
  }, {
    id: 3,
    text: "Total",
    field: "grand_total"
  }, {
    id: 4,
    text: "Order Number",
    field: "order_number"
  }, {
    id: 5,
    text: "Order Reference",
    field: "order_reference"
  }, {
    id: 6,
    text: "Date Ordered",
    field: "ordered_at"
  }];
  @ViewChild('cancel_model') public cancel_model: ElementRef;
  public cancelReason: string;
  public deletedOrderId: number;
  constructor(
    private cartService: CartService,
    private globals: GlobalApi,
    public utils: Utils,
    public route: ActivatedRoute,
    private router: Router
  ) {
    this.search();
  }
  ngOnInit(): void {
    this.route.queryParams.subscribe(queryparams => {
      this.route.params.subscribe(params => {
        if (params['id']) {
          //@ts-ignore
          this.query.userId = params['id'];
        }
        if (params['customerId']) {
          //@ts-ignore
          this.query.customerId = params['customerId'];
        }
        this.currentPageActive = +queryparams['page'] || 1;
        this.query.limit = +queryparams['pageSize'] || 10;
        this.loadOrders();
      })
    });
  }

  pageChangedActive(page: number) {
    this.currentPageActive = page;
    this.loadOrders();
    this.search();
  }

  /**
  * @description Ensures the page number is in sync across multiple pagination components
  *
  * @param {number} pageSize Broadcast pageSize value
  */
  pageSizeChanged(pageSize: number): void {
    this.query.limit = pageSize;
    this.currentPageActive = 1;
    this.loadOrders();
  }

  updateSearchTerm(searchTerm: string): void {
    this.searchTerms.next(searchTerm);
  }

  updateSortField(sort: string) {
    this.query.sortBy = sort;
    this.loadOrders();
  }

  loadOrders() {
    this.isLoading = true;
    this.query.skip = (this.currentPageActive * this.query.limit) - this.query.limit;
    this.cartService.getOrderAttrsList(this.query)
      .pipe(
        debounceTime(1000),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(queryResult => {
        this.isLoading = false;
        this.queryResult = JSON.parse(JSON.stringify(queryResult));
        this.totalCountActive = queryResult.count;
      });
  }

  search() {
    this.searchSubscription = this.searchTerms.pipe(
      debounceTime(2000),
      distinctUntilChanged(),
    ).subscribe(searchTerm => {
      if (this.query) {

        if (searchTerm && searchTerm.length)
          this.query.filter['$or'] = {
            orderNumber: { $like: '%' + searchTerm + '%' },
            contactFirstName: { $like: '%' + searchTerm + '%' },
            contactLastName: { $like: '%' + searchTerm + '%' },
            contactEmail: { $like: '%' + searchTerm + '%' },
            contactPhone: { $like: '%' + searchTerm + '%' },
            orderReference: { $like: '%' + searchTerm + '%' },
            status: { $like: '%' + searchTerm + '%' }
          };
        else
          delete this.query.filter['$or'];
      }
      this.loadOrders();
    });
  }


  /**
 * @description UI helper method for retrieving the text of the selected sort option
 * @returns {string | undefined}
 */
  getSortText = (): string | undefined => {
    if (!this.query.sortBy)
      return undefined;

    const sortOption = this.sortOptions.find(option => option.field === this.query.sortBy);

    if (sortOption)
      return sortOption.text;
    return undefined;
  };

  /**
 * @description UI helper method for getting display text for the business unit column
 *
 * @param {CartAttrs} item
 */
  getBusinessUnitText = (item: CartAttrs): string | null => {
    if (item.businessUnit) {
      let result = item.businessUnit.name;
      if (item.businessUnit.code && item.businessUnit.code.length) {
        return result + ` (${item.businessUnit.code})`;
      }
      return result;
    }
    return 'Not Found';
  }


  /**
   * @description For use in the UI to determine if it is appropriate to show the approval functionality
   */
  hasAnyActions(item: CartAttrs): boolean | number {
    return (this.showOrderApproval(item) || this.showResendConfirmationEmail(item) || this.showResendApprovalEmail(item) || this.showOrderCancel(item) || this.showReOrder(item) || this.showDelete(item));
  }

  /**
   * @description UI helper method to determine if the order approval functionality should be presented to the user
   *
   * @param item
   */
  showOrderApproval(item: CartAttrs): boolean | null | number {
    return (
      item.status.toUpperCase() === 'APPROVAL'
      && (
        this.isAdmin
        || this.isApprovingManager(item)
      )
    );
  }

  /**
 * @description UI helper method to determine if the order approval functionality should be presented to the user
 *
 * @param item
 */
  showDelete(item: CartAttrs): boolean | number {
    return (
      item.status.toUpperCase() === 'APPROVAL'
      && !this.isAdmin
    );
  }

  showOrderCancel(item: CartAttrs): boolean {
    return (item.status.toUpperCase() === 'APPROVAL' && (this.isAdmin))
  }

  /**
   * @description UI helper method to determine if the resend confirmation functionality should be presented to the user
   *
   * @param item
   */
  showResendConfirmationEmail(item: CartAttrs): boolean {
    return (
      this.isAdmin
      && item.status.toUpperCase() !== 'APPROVAL'
      && item.status.toUpperCase() !== 'BACKORDERED'
      && item.status.toUpperCase() !== 'REJECTED'
    );
  }

  onSaveCompleted() {
    this.loadOrders();
  }

  /**
   * @description UI helper method to determine if the resendApproval functionality should be presented to the user
   *
   * @param item
   */
  showResendApprovalEmail(item: CartAttrs): boolean {
    return (
      this.isAdmin
      && item.status.toUpperCase() === 'APPROVAL'
    )
  }

  /**
   * @description UI Helper method to determine if the current user is the approving manager
   *
   * @param item
   */
  isApprovingManager(item: CartAttrs) {
    if (!this.globals.User) return false;
    return item.approvedById && this.globals.User.actualUserId === item.approvedById;
  }

  /**
   * @description Resends the order confirmation email
   *
   * @param item
   */
  resendConfirmationEmail(item: CartAttrs) {

    this.cartService.sendOrderEmail(item.orderId)
      .subscribe(() => {
      },
        (err) => {
          console.log(err);
        })
  }

  /**
   * @description Resends the order approval email
   *
   * @param item
   */
  resendApprovalEmail(item: CartAttrs) {
    this.cartService.sendOrderApprovalEmail(item.orderId)
      .subscribe(() => { },
        (err) => {
          console.log(err);
        })
  }

  /**
   * @description Approves an order
   *
   * @param item
   */
  approveOrder(item: CartAttrs) {
    this.cartService.approveCart(item.orderId)
      .subscribe(() => {
        this.loadOrders();
      },
        (err) => {
          console.log(err);
        })
  }


  /**
 * @description Returns the destination url that the user should access in order to view the order detail
 *
 * @param {number} orderId The target order
 */
  getDetailLink(orderId: number | undefined) {
    if (this.isAdmin && orderId) {
      return '/manage/orders/' + orderId;
    } else if (orderId) {
      return '/account/orders/' + orderId;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public capitalizeFirstLetter = (str: string) => {
    str = str.toLowerCase();
    return str ? str[0].toUpperCase() + str.slice(1) : '';
  };

  fetchColor(status: string) {
    if (status === "PROCESSING") {
      return 'aqua';
    }
    if (status === "COMPLETED") {
      return 'green';
    }
    if (status === "BACKORDERED") {
      return 'orange';
    }
    if (status === "APPROVAL") {
      return 'purple';
    }
    if (status === "DELETED") {
      return 'red';
    }
    if (status === "PLACED") {
      return 'blue';
    }
    if (status === "REJECTED") {
      return 'brown';
    }
  }

  showReOrder(item: CartAttrs): boolean {
    const statuses = ["COMPLETED", "DELETED", "REJECTED"];
    return statuses.includes(item.status);
  }

  reOrder(cartId: number | undefined) {
    if (cartId) {
      this.cartService.reOrder(cartId).subscribe(() => {
        if (this.globals.User.IsAdmin) {
          this.loadOrders();
        } else {
          this.router.navigate(['/cart']);
        }
      });
    }
  }
}
